<div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <span class="text-blue text-125">Registro Producto</span>

          <div class="card-toolbar ml-auto no-border pr-2 d-none">
            <label>
              <span class="align-middle d-block d-sm-inline">Validation:</span>
              <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
            </label>
          </div>
          <div class="card-toolbar pl-3">
            <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
              <i class="fa fa-chevron-left"></i>
            </button>
            <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
              <i class="fa fa-chevron-right"></i>
            </button>
            <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
              <i class="fa fa-arrow-right"></i>
            </button>
            <button (click)="cerrarRegistroProducto()" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
                <i class="fas fa-times-circle"></i>
              </button>
          </div>
        </div>

        <div class="card-body px-2">
          <div id="{{wizard_edicion_producto}}" class="d-none">
            <ul class="mx-auto">
              <li class="wizard-progressbar"></li>
              <li>
                <a href="#step-1">
                  <span class="step-title">
                      1
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-description">
                        Datos del Producto
                </span>
              </li>
              <li>
                <a href="#step-2">
                  <span class="step-title">
                      2
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-description">
                        Datos Adicionales
                </span>
              </li>
              <li *ngIf="usuario.empresa.usar_consumos">
                <a href="#step-3">
                  <span class="step-title">
                      3
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-description">
                        Datos de Formulación
                </span>
              </li>
            </ul>

            <div class="px-2 py-2 mb-4">
              <div id="step-1" class="">

                <form novalidate>
                    <div class="  form-row">
                        <div *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones)" class="col-sm-2 col-form-label text-sm-right text-grey">
                                Código
                        </div>
                        <div *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones)" class="col-sm-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input [disabled]="codigo_automatico" type="text" id="codigo" name="codigo" [(ngModel)]="producto.codigo" placeholder="Código" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                              <i class="fas fa-exclamation-triangle text-orange text-125 ml-n425 pos-rel"></i>
                            </div>
  
                            <span class="form-text d-inline-block text-orange-d2 text-600 text-95 ml-sm-2"> Código! </span>
                        </div>
                        <div [ngClass]="{'text-danger-m1':(!producto.nombre)}" class="col-sm-2 col-form-label text-sm-right">
                            Nombre
                        </div>

                        <div class="col-sm-6">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <input required type="text" id="nombre" name="nombre" [(ngModel)]="producto.nombre" placeholder="Nombre" [ngClass]="{'brc-danger-m1':(!producto.nombre)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                <i *ngIf="!producto.nombre" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                            </div>


                            <div *ngIf="!producto.nombre" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                <div class="arrow brc-danger-m1 mt-0"></div>
                                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                            </div>

                            <span *ngIf="!producto.nombre" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                        </div>
                    </div>
                    <div class="  form-row">
                        <ng-container *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones)">
                            <div [ngClass]="{'text-danger-m1':(!producto.unidad_medida)}" class="col-1 col-form-label text-sm-right">
                                Unid. Med.
                            </div>
                            <div class="col-2">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input required type="text" id="und" name="und" [(ngModel)]="producto.unidad_medida" placeholder="unidad de medida" [ngClass]="{'brc-danger-m1':(!producto.unidad_medida)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                    
                                    <i *ngIf="!producto.unidad_medida" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                                </div>


                                <div *ngIf="!producto.unidad_medida" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                    <div class="arrow brc-danger-m1 mt-0"></div>
                                    <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                                </div>

                                <span *ngIf="!producto.unidad_medida" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea">
                            <div [ngClass]="{'text-danger-m1':(!producto.sin_unidad_medida)}" class="col-3 col-form-label text-sm-right">
                                S.I.N. Unid. Med.
                            </div>
                            <div class="col-2">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <select (change)="establecerUnidadMedidaSin()" style="width:300px;" [compareWith]="compararObjectos" name="sin_unidad_medida" class="form-control" [(ngModel)]="producto.sin_unidad_medida">
                                        <option [ngValue]="sin_unidad_medida" *ngFor="let sin_unidad_medida of sin_unidades_medidas">{{sin_unidad_medida.nombre}}</option>
                                    </select>
                                    
                                    <i *ngIf="!producto.sin_unidad_medida" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                                </div>


                                <div *ngIf="!producto.sin_unidad_medida" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                    <div class="arrow brc-danger-m1 mt-0"></div>
                                    <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                                </div>

                                <span *ngIf="!producto.sin_unidad_medida" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                            </div>
                        </ng-container>
                    </div>
                    <div class="  form-row">
                        <ng-container *ngIf="usuario.empresa.usar_consumos">
                            <div [ngClass]="{'text-danger-m1':(!producto.unidad_medida_receta)}" class="col-1 col-form-label text-sm-right" >
                                Unid. Med. Receta
                            </div>
                            <div class="col-2">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input required type="text" id="unidad_medida_receta" name="unidad_medida_receta" [(ngModel)]="producto.unidad_medida_receta" placeholder="unidad de medida recetario" [ngClass]="{'brc-danger-m1':(!producto.unidad_medida_receta)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                    <i *ngIf="!producto.unidad_medida_receta" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                                </div>
    
    
                                <div *ngIf="!producto.unidad_medida_receta" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right bgc-danger-d1">
                                    <div class="arrow brc-danger-m1 mt-0"></div>
                                    <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                                </div>
    
                                <span *ngIf="!producto.unidad_medida_receta" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                            </div>
    
                            <div [ngClass]="{'text-danger-m1':(!producto.equivalencia)}" class="col-1 col-form-label text-sm-right">
                                Equiv.
                            </div>
                            <div class="col-5">
    
                                <div class="d-inline-flex align-items-center col-3 col-sm-3">1 {{producto.unidad_medida}} = </div>
                                <div class="d-inline-flex align-items-center col-7 col-sm-7">
                                    <input required type="number" id="equivalencia" name="equivalencia" [(ngModel)]="producto.equivalencia" placeholder="unidad de medida recetario" [ngClass]="{'brc-danger-m1':(!producto.equivalencia)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                    <i *ngIf="!producto.unidad_medida_receta" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                                </div>
                                <div class="d-inline-flex align-items-center col-2 col-sm-2">{{producto.unidad_medida_receta}}</div>
    
    
                                <div *ngIf="!producto.equivalencia" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right bgc-danger-d1">
                                    <div class="arrow brc-danger-m1 mt-0"></div>
                                    <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                                </div>
    
                                <span *ngIf="!producto.equivalencia" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                            </div>
                        </ng-container>
                    </div>
                    <div class="  form-row">
                        <div class="col-1 col-form-label text-sm-right">
                            Desc.
                        </div>

                        <div class="col-4">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input required type="text" id="descripcion" name="descripcion" [(ngModel)]="producto.descripcion" placeholder="Descripción" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                        <div class="col-1 col-form-label text-sm-right">
                            Imágen
                        </div>
                        <div class="col-4">
                            <form id="dropzone" action="/upload" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1 mt-3">
                                <div class="fallback d-none">
                                  <input name="file" type="file" (change)="subirImagen($event)"/>
                                </div>
                
                                <div class="dz-default dz-message">
                                  <span class="text-150  text-grey-d2">
                                  <span class="text-130 font-bolder"><i class="fa fa-caret-right text-danger-m1"></i> Arrastra la imagen</span>
                                  para subir
                                  <span class="text-90 text-grey-m1">(o click)</span>
                                  <br />
                                  <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-3x mt-4"></i>
                                  </span>
                                </div>
                              </form>
                
                              <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                              <div id="preview-template" class="d-none">
                                <div class="dz-preview dz-file-preview">
                                  <div class="dz-image">
                                    <img alt="Archivo" data-dz-thumbnail="" />
                                  </div>
                
                                  <div class="dz-details">
                                    <div class="dz-size">
                                      <span data-dz-size=""></span>
                                    </div>
                
                                    <div class="dz-filename">
                                      <span data-dz-name=""></span>
                                    </div>
                                  </div>
                
                                  <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                                    <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                                  </div>
                
                                  <div class="dz-error-message">
                                    <span data-dz-errormessage=""></span>
                                  </div>
                
                                  <div class="dz-success-mark">
                                    <span class="fa-stack fa-lg text-150">
                                      <i class="fa fa-circle fa-stack-2x text-white"></i>
                                      <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                                  </span>
                                  </div>
                
                                  <div class="dz-error-mark">
                                    <span class="fa-stack fa-lg text-150">
                                      <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                                      <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                                  </span>
                                  </div>
                                </div>
                              </div>
                        </div>
                        <div class="col-2" *ngIf="producto.id">
                            <img  src="{{rest_server+producto.imagen}}" width="100">
                        </div>
                    </div>
                </form>

              </div>

              <div id="step-2">
                <div class="  form-row">
                    <div class="col-sm-6 col-form-label text-sm-center text-grey" *ngIf="usuario.empresa.usar_precios_productos || usuario.empresa.usar_precios_productos_sucursal">
                        <div class="card bgc-primary brc-primary radius-0">
                            <div class="card-header">
                              <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Conceptos de precios</h5>
                            </div>
        
                            <div class="card-body p-0 bg-white">
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th *ngIf="usuario.empresa.usar_precios_productos_sucursal">Sucursal</th>
                                            <th>Concepto</th>
                                            <th>Precio Unitario</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                                                <select [compareWith]="compararObjectos" class="form-control" required name="id_sucursal" [(ngModel)]="producto_precio.sucursal">
                                                    <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select [compareWith]="compararObjectos" class="form-control" required name="id_concepto" [(ngModel)]="producto_precio.concepto">
                                                    <option [ngValue]="precios_producto" *ngFor="let precios_producto of tipo_producto_precio.clases">{{precios_producto.nombre}}</option>
                                                </select>
                                                <span *ngIf="usuario.empresa.usar_precios_productos" class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_PRODUCTO_PRECIOS,'obtenerConceptosPreciosProductos')"></span> &nbsp;
                                            </td>
                                            <td>
                                                <input name="id_precio" id="id_precio" class="form-control text-center" type="number" [(ngModel)]="producto_precio.precio_unitario" step="0.001"
                                                min="0">
                                            </td>
                                            <td>
                                                <button *ngIf="usuario.empresa.usar_precios_productos && !usuario.empresa.usar_precios_productos_sucursal" id="agregar_detalle_producto_precio" type="submit" (click)="agregarProductoPrecio(producto_precio)"
                                                        class="btn btn-success btn-sm">
                                                    <i class="ace-icon fa fa-arrow-right bigger-110"></i>
                                                </button>
                                                <button *ngIf="usuario.empresa.usar_precios_productos_sucursal" id="agregar_detalle_producto_precio" type="submit" (click)="agregarProductoPrecio(producto_precio)"
                                                        class="btn btn-success btn-sm">
                                                    <i class="ace-icon fa fa-arrow-right bigger-110"></i>
                                                </button>
                                            </td>
    
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-form-label text-sm-center text-grey" *ngIf="usuario.empresa.usar_precios_productos || usuario.empresa.usar_precios_productos_sucursal">
                        <div class="card bgc-primary brc-primary radius-0">
                            <div class="card-header">
                              <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Precios</h5>
                            </div>
        
                            <div class="card-body p-0 bg-white">
                                <table class="table table-striped table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                                                Sucursal
                                            </th>
                                            <th>
                                                Concepto
                                            </th>
                                            <th>
                                                Precio Unitario
                                            </th>
                                            <th>
                                                Por Defecto
                                            </th>
                                            <th>
                                                Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let producto_precio of producto.precios">
                                            <tr *ngIf="!producto_precio.eliminado">
                                                <td *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                                                    {{producto_precio.sucursal?producto_precio.sucursal.nombre:''}}
                                                </td>
                                                <td>
                                                    {{producto_precio.concepto?producto_precio.concepto.nombre:''}}
                                                </td>
                                                <td>
                                                    <input class="form-control text-center" (change)="cambiarPrecio(producto_precio)" required type="number" [(ngModel)]="producto_precio.precio_unitario" step="0.001" min="0">
                                                </td>
                                                <td>
                                                    <label>
                                                        <input (change)="establecerPrecioPorDefecto(producto_precio)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto_precio.por_defecto"/>
                                                    </label>
                                                </td>
                                                <td>
                                                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" e-disabled="(!usuario.empresa.usar_precios_productos && !usuario.empresa.usar_precios_productos_sucursal)" href="javascript:void(0)" (click)="eliminarDetalleProductosPrecio(producto_precio)">
                                                        <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="form-row input-number">
                    <ng-container *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones)">
                        <div class="col-1 col-form-label text-sm-right">
                            Código fábrica
                            <a *ngIf="usuario.empresa.usar_generador_codigos_barras" class="card-toolbar-btn text-primary-m1" href="javascript:void(0)" (click)="generarCodigoFabrica()">
                                <i class="fa fa-sync bigger-130 red2"></i>
                            </a>
                        </div>

                        <div class="col-2 p-0">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="codigo_fabrica" name="codigo_fabrica" [(ngModel)]="producto.codigo_fabrica" placeholder="Código de fabrica" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-7">
                        <div class="row" *ngIf="usuario.empresa.usar_pedidos_en_linea">
                            <div class="col-6 col-form-label text-sm-right">¿Activar Precio?</div>
                            <div class="col-6">
                                <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                    <label>
                                        <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.activar_precio"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-row" *ngIf="producto.activar_precio">
                            <ng-container *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones)">
                                <div class="col-2 col-form-label text-sm-right">
                                    Costo Unitario
                                </div>
        
                                <div class="col-1 p-0">
                                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                        <input type="number" id="costo_unitario" name="costo_unitario" [(ngModel)]="producto.costo_unitario" placeholder="Costo Unitario" step="0.001" min="0" class="form-control form-control-lg col-12 p-1"  />
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones) && !(usuario.empresa.usar_precios_productos || usuario.empresa.usar_precios_productos_sucursal)">
                                <div class="col-2 col-form-label text-sm-right">
                                    % Utilidad
                                </div>
        
                                <div class="col-1 p-0">
                                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                        <input (keyup)="calcularPrecioUnitario()" type="number" id="porcentaje_utilidad" name="porcentaje_utilidad" [(ngModel)]="producto.porcentaje_utilidad" placeholder="Porcentaje Utilidad" step="0.001" min="1" class="form-control form-control-lg col-12 p-1"  />
                                    </div>
                                </div>
                            </ng-container>
                            <div [ngClass]="{'text-danger-m1':(!producto.precio_unitario)}" class="col-2 col-form-label text-sm-right">
                                Precio Unitario
                            </div>
                            <div class="col-1 p-0">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input required type="number" (keyup)="establecerPrecioUnitario()" [disabled]="usuario.empresa.usar_precios_productos || usuario.empresa.usar_precios_productos_sucursal" id="precio_unitario" name="precio_unitario" [(ngModel)]="producto.precio_unitario" placeholder="Precio Unitario" [ngClass]="{'brc-danger-m1':(!producto.precio_unitario)}" step="0.001" min="0" class="form-control form-control-lg col-12 p-1"  />
                                    <i *ngIf="!producto.precio_unitario" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                                </div>
        
        
                                <div *ngIf="!producto.precio_unitario" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                    <div class="arrow brc-danger-m1 mt-0"></div>
                                    <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                                </div>
        
                                <span *ngIf="!producto.precio_unitario" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                            </div>  
                            <div class="col-2 col-form-label text-sm-right">
                                Descuento Unitario<input name="tipo-descuento" class="ace-switch input-lg ace-switch-desc bgc-blue-d1" type="checkbox" [(ngModel)]="producto.tipo_descuento"/>
                            </div>
                            <div class="col-1 p-0">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="number" id="descuento" name="descuento" [(ngModel)]="producto.descuento" placeholder="Descuento" step="0.001" min="0" class="form-control form-control-lg col-12 p-1"  />
                                </div>
                            </div>  
                        </div>
                    </div>
                    <ng-container *ngIf="usuario.empresa.usar_inventario && !producto.id && !usuario.empresa.usar_sucursales && producto.activar_inventario">
                        <div class="col-1 col-form-label text-sm-right">
                            Cantidad
                        </div>
                        <div class="col-1">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="number" id="cantidad" name="cantidad" [(ngModel)]="producto.cantidad" placeholder="Cantidad" step="0.001" min="1" class="form-control form-control-lg col-12 pl-25"  />
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="  form-row">
                    <ng-container *ngIf="usuario.empresa.usar_pedidos_en_linea">
                        <div class="col-2 col-form-label text-sm-right">¿Publicar Tienda?</div>
                        <div class="col-1">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <label>
                                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.publicar_tienda"/>
                                </label>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-2 col-form-label text-sm-right">¿Publicar Panel?</div>
                    <div class="col-1">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <label>
                                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.publicar_panel"/>
                            </label>
                        </div>
                    </div>
                    <ng-container *ngIf="usuario.empresa.usar_inventario">
                        <div class="col-2 col-form-label text-sm-right">¿Activar Inventario?</div>
                        <div class="col-1">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <label>
                                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.activar_inventario"/>
                                </label>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="usuario.empresa.usar_vencimientos">
                        <div class="col-2 col-form-label text-sm-right">¿Activar Vencimiento?</div>
                        <div class="col-1">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <label>
                                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.activar_vencimiento"/>
                                </label>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="usuario.empresa.usar_produccion">
                        <div class="col-2 col-form-label text-sm-right">¿Producible?</div>
                        <div class="col-1">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <label>
                                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.producible"/>
                                </label>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="  form-row">
                    <ng-container *ngIf="usuario.empresa.usar_inventario && usuario.empresa.usar_inventario_minimo">
                        <div class="col-2 col-form-label text-sm-right">Inventario Mínimo</div>
                        <div class="col-1">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="number" id="inventario_minimo" name="inventario_minimo" [(ngModel)]="producto.inventario_minimo" placeholder="Inventario Mínimo" step="0.001" min="0" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </ng-container>
                    
                    <div class="col-1 col-form-label text-sm-right">
                        {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_GRUPO)}}
                    </div>
                    <div class="col-3">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <select (change)="establecerGrupo()" [compareWith]="compararObjectos" class="form-control" required name="grupo_producto" [(ngModel)]="producto.grupo">
                                <option [ngValue]="grupoProducto" *ngFor="let grupoProducto of tipo_grupo.clases">{{grupoProducto.nombre}}</option>
                            </select>
                            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_PRODUCTO_GRUPOS,'alActualizarGrupos')"></span>
                        </div>
                    </div>
                    <ng-container *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones || usuario.empresa.usar_consumo_de_agua || usuario.empresa.usar_hoteles)">
                        <div class="col-2 col-form-label text-sm-right">
                            {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_SUBGRUPO)}}
                        </div>
                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <select [compareWith]="compararObjectos" class="form-control" required name="subgrupo_producto" [(ngModel)]="producto.subgrupo">
                                    <option [ngValue]="subgrupoProducto" *ngFor="let subgrupoProducto of tipo_subgrupo.clases">{{subgrupoProducto.nombre}}</option>
                                </select>
                                <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_PRODUCTO_SUBGRUPOS,'obtenerSubGruposProductosEmpresa')"></span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="usuario.empresa.usar_consumo_de_agua && producto.grupo.nombre=='OCASIONAL'">
                        <div class="col-2 col-form-label text-sm-right">¿Comunitario?</div>
                        <div class="col-1">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <label>
                                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.comunitario"/>
                                </label>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="  form-row" *ngIf="usuario.empresa.usar_consumo_de_agua && !producto.comunitario && producto.grupo.nombre=='OCASIONAL'">
                    <div class="col-sm-12 col-form-label text-sm-center text-grey">
                        <div class="card bgc-primary brc-primary radius-0">
                          <div class="card-header">
                            <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Vecino</h5>
                          </div>
                
                          <div class="card-body p-0 bg-white">
                            <table class="table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th>Nombre o Código</th>
                                  <th>Código Medidor</th>
                                  <th>Acción</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td >
                                      <div class="row">
                                          <div class="col-10">
                                            <input  [inputFormatter]="formatter" (selectItem)="establecerVecino($event)" [resultTemplate]="rt" 
                                                id="typeahead-http" type="text" class="form-control mx-sm-3"  [disabled]="vecino.id"
                                                [(ngModel)]="vecino_busqueda" [ngbTypeahead]="buscarVecino" placeholder="Buscar Vecino"
                                                [inputFormatter]="formatter" />
                                                <ng-template #rt let-r="result" let-t="term">
                                                    <img [src]="rest_server+r.cliente.persona.imagen" class="mr-1" style="width: 16px">
                                                    <ngb-highlight [result]="r.cliente.codigo+' '+r.cliente.persona.apellido_paterno+' '+r.cliente.persona.apellido_materno+' '+r.cliente.persona.nombres" [term]="t"></ngb-highlight>
                                                </ng-template>
                                          </div>
                                          <div class="col-2">
                                            <a class="card-toolbar-btn text-danger-m1" href="javascript:void(0)" *ngIf="vecino.id" (click)="reiniciarVecino()">
                                                <i class="fa fa-trash bigger-130 red2"></i>
                                            </a>
                                          </div>
                                      </div>
                                  </td>
                                  <td>
                                    <ng-container *ngIf="vecino.id">
                                        {{vecino.acciones_agua[0].codigo_medidor}}-{{vecino.acciones_agua[0].categoria.nombre}}
                                    </ng-container>
                                  </td>
                                  <td>
                                    <button type="button" [disabled]="!vecino.id" (click)="agregarVecino(vecino)"
                                        class="btn btn-success btn-sm">
                                      <i class="ace-icon fa fa-arrow-down bigger-110"></i>
                                    </button>
                                  </td>
              
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-form-label text-sm-center text-grey">
                        <div class="card bgc-success brc-success radius-0">
                          <div class="card-header" data-toggle="collapse">
                            <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px "></i> Vecinos Asignados </h5>
                          </div>
                
                          <div class="card-body p-0 bg-white" id="composicionoculta">
                            <table class="table table-striped table-hover mb-0">
                              <thead>
                                <tr>
                                  <th>
                                    Código
                                  </th>
                                  <th>
                                    Vecino
                                  </th>
                                  <th>
                                    Código medidor
                                  </th>
                                  <th>
                                    Acciones
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngFor="let concepto_pago of producto.conceptos_pago">
                                  <tr *ngIf="concepto_pago.activo">
                                    <td>
                                      {{concepto_pago.accion_agua.vecino.cliente.codigo}}
                                    </td>
                                    <td>
                                      {{concepto_pago.accion_agua.vecino.cliente.persona.apellido_paterno}} {{concepto_pago.accion_agua.vecino.cliente.persona.apellido_materno}} {{concepto_pago.accion_agua.vecino.cliente.persona.nombres}}
                                    </td>
                                    <td>
                                      {{concepto_pago.accion_agua.codigo_medidor}} - {{concepto_pago.accion_agua.categoria.nombre}}
                                    </td>
                                    <td>
                                      <button type="button" (click)="eliminarVecino(concepto_pago)"
                                          class="btn btn-danger btn-sm">
                                        <i class="fa fa-trash bigger-110"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                </div>
                <div class="  form-row" *ngIf="usuario.empresa.usar_pantalla_despacho">
                    <div class="col-sm-3 col-form-label text-sm-right">Fuente despacho</div>
                    <div class="col-sm-9">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <select [compareWith]="compararObjectos" class="form-control" required name="fuente_despacho" [(ngModel)]="producto.fuente_despacho">
                                <option [ngValue]="fuente_despacho" *ngFor="let fuente_despacho of fuentes_despacho">{{fuente_despacho.nombre}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="  form-row" *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea">
                    <label class="col-sm-3 control-label no-padding-right">Producto Servicio S.I.N.</label>
                    <div class="col-sm-9">
                        <select style="width:300px;" [compareWith]="compararObjectos" name="producto_sin" class="form-control" [(ngModel)]="producto.producto_sin">
                            <option [ngValue]="sin_producto_servicio" *ngFor="let sin_producto_servicio of sin_productos_servicios">{{sin_producto_servicio.codigo_actividad+'-'+sin_producto_servicio.codigo_producto_servicio+'-'+sin_producto_servicio.descripcion}}</option>
                        </select>
                    </div>
                </div>
              </div>

              <div id="step-3" *ngIf="usuario.empresa.usar_consumos">
                        <div class=" ">
                            <label class="control-label col-xs-12 col-sm-3 no-padding-right">Tipo Producto</label>
                            <div class="col-xs-8 col-sm-4">
                                <select [compareWith]="compararObjectos" class="form-control" required name="grupo_producto" [(ngModel)]="producto.tipoProducto">
                                    <option [ngValue]="tipoProducto" *ngFor="let tipoProducto of tipos_productos">{{tipoProducto.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="usuario.empresa.usar_consumos && (producto.tipoProducto) && producto.tipoProducto.nombre_corto!=global_variable.Dictionary.TIPO_PRODUCTO_BASE">

                            <table class="table table-striped table-bordered table-hover" *ngIf="producto.tipoProducto && producto.tipoProducto.nombre_corto!=global_variable.Dictionary.TIPO_PRODUCTO_BASE">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Producto</label>
                                        </th>
                                        <th>
                                            <label>Und. Medida</label>
                                        </th>
                                        <th>
                                            <label>Formulación</label>
                                        </th>
                                        <th>
                                            <label>Acciones</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input autocomplete="off"
                                                [disabled]="productoBase.id" id="id_producto" [(ngModel)]="producto_busqueda"
                                                [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                                                [inputFormatter]="formatterProducto"  (selectItem)="establecerProductoBase($event)"
                                            type="text" class="form-control"/>

                                            <ng-template #rtp let-r="result" let-t="term">
                                                <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                            </ng-template>

                                            <a href="javascript:void(0)" *ngIf="productoBase && productoBase.id" (click)="iniciarDetalleVenta()" class="card-toolbar-btn text-danger-m1">
                                                <i class="ace-icon fa fa-trash bigger-130"></i>
                                            </a>
                                        </td>
                                        <td>
                                            {{productoBase.unidad_medida_receta}}
                                        </td>
                                        <td>
                                            <input class="form-control" type="number" [(ngModel)]="productoBase.formulacion" step="0.001"
                                             min="0">
                                        </td>
                                        <td>
                                            <button type="button" (click)="agregarDetalleProductosBase(productoBase,productoBaseSeleccion.producto)"
                                                class="btn btn-success btn-sm">
                                                <i class="ace-icon fa fa-arrow-down bigger-110"></i>
                                            </button>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                            <div class="widget-box" *ngIf="producto.productosBase.length>0">
                                <div class="widget-header">
                                    <h4 class="widget-title">COMPOSICIÓN</h4>
                                </div>
                                <div class="widget-body">
                                    <div class="widget-main">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <label>Nº</label>
                                                    </th>
                                                    <th>
                                                        <label>Producto</label>
                                                    </th>
                                                    <th>
                                                        <label>Und. Medida</label>
                                                    </th>
                                                    <th>
                                                        <label>Formulación</label>
                                                    </th>
                                                    <th>
                                                        <label>Acciones</label>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let productoBase of producto.productosBase;let i=index;">
                                                    <tr *ngIf="!productoBase.eliminado">
                                                        <td>
                                                            {{(i+1)}}
                                                        </td>
                                                        <td>
                                                            {{productoBase.productoBase.nombre}}
                                                        </td>
                                                        <td>
                                                            {{productoBase.productoBase.unidad_medida_receta}}
                                                        </td>
                                                        <td>
                                                            {{productoBase.formulacion}}
                                                        </td>
                                                        <td>
                                                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarDetalleProductosBase(productoBase)">
                                                                <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
          </div>

        </div>
      </div> <!-- .card -->

    </div>
  </div>