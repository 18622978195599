import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PedidosService } from '../../services/pedidos/pedidos.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { Util } from 'src/app/utils/utils';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { PagoVentaComponent } from '../pago-venta/pago-venta.component';
import { VentaComponent } from '../../../base/components/venta/venta.component';
import { VistaPedidoComponent } from '../vista-pedido/vista-pedido.component';
import { PedidoPanelComponent } from '../pedido-panel/pedido-panel.component';
import { ConfiguracionAplicacionPedidosComponent } from 'src/app/base/components/configuracion-aplicacion-pedidos/configuracion-aplicacion-pedidos.component';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { ReportesService } from '../../services/reportes/reportes.service';
import { PedidoComponent } from '../pedido/pedido.component';
import { SinListaEventosSignificativosComponent } from '../sin-lista-eventos-significativos/sin-lista-eventos-significativos.component';

declare const PDFDocument:any;
declare const blobStream:any;
declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent extends BaseComponent implements OnInit {

  
  @BlockUI() blockUI: NgBlockUI;
  pedidos:any[]=[];
  mesas:any[]=[];
  mesas_unidas:any[]=[];
  salaVista:any;
  salas:any[]=[];
  modalPedido:NgbModalRef;
  modalVenta:NgbModalRef;
  transacciones:any[];

  tipos_despacho:any[]=[];
  estados:any[];

  pedido:any;
  mesaAcambiar:any;
  mesa:any;

  
  
  mostrar_productos:boolean=false;
  sucursales:any[];

  configuracion_aplicacion_modal:NgbModalRef;
  @ViewChild('configuracion_aplicacion_modal')
  private configuracion_aplicacion_ref: TemplateRef<any>;

  cambiomesa_modal:NgbModalRef;
  @ViewChild('cambiomesa')
  private cambiomesa_modal_ref: TemplateRef<any>;

  limipeza_datos_servidor:NgbModalRef;
  @ViewChild('limipeza_datos_servidor')
  private limipeza_datos_servidor_ref: TemplateRef<any>;
  

	pedidos_restaurante: any;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    public pedidosService:PedidosService,
    public ventasService:VentasService,
	private toastr: ToastrService,
	private reportesService: ReportesService,
	private pdfService:PdfService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));

	this.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
    let fecha_actual=new Date();
    this.filter={
      id_empresa:this.usuario.id_empresa,
      ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
      sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal: {nombre:"TODOS",id:0},
      usuarios:[{nombre_usuario:"TODOS",id:0}],
      usuario: {nombre_usuario:"TODOS",id:0},
      estados_validez:[{nombre:"TODOS",id:-1},{nombre:"ACTIVOS",id:1},{nombre:"ANULADOS",id:0}],
      validez: {nombre:"TODOS",id:-1},
      estados:[{nombre:"TODOS",id:0}],
      estado: {nombre:"TODOS",id:0},
      estados_mesa:[{nombre:"TODOS",id:0}],
	  estado_mesa: {nombre:"TODOS",id:0},
	  tipos_pago:[GlobalVariable.Dictionary.SELECCION_TODOS],
	  tipo_pago: GlobalVariable.Dictionary.SELECCION_TODOS,
	  tipos_despacho:[{nombre:"TODOS",id:0}],
	  tipo_despacho: {nombre:"TODOS",id:0},
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      }
    }
	this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_PEDIDOS);

    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
        this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
    }
	if(this.usuario.empresa.usar_sucursales){
		if(!es_usuario_administrador){	
			this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
			this.filter.sucursal=this.filter.sucursales[0];
		}
	}
    
    this.obtenerEstadosPedido();
    this.obtenerTiposDespachos();
	this.obtenerTiposDePago();

    if(this.usuario.empresa.usar_mesas){
      this.obtenerEstadosMesa();
    }
	
	if(this.usuario.empresa.usar_salas){
		this.obtenerSalas(this.filter.sucursales[1].id);
	}
	this.obtenerMovimientosEgreso();

    //$localStorage.color = {'style': 'red-style', 'stylebutton': 'red-style-button'};
		//this.color = {'style': 'red-style', 'stylebutton': 'red-style-button'};
    
    this.column = "fecha";
		this.direction = "desc";
		this.itemsPerPage=0;
  }

  ngAfterViewInit() {
		//aplicarScriptPaginaProductos();	
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		this.obtenerTextosEmpresa();
	}

	obtenerTiposDePago(){
		this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
		this.filter.tipos_pago=this.filter.tipos_pago.concat(entidad.clases);
		});
	}	

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  obtenerEstadosPedido(){
    this.generalService.obtenerClases("ESTPEDI").subscribe((entidad:any) => {
	  this.filter.estados=this.filter.estados.concat(entidad.clases);
	  this.estados=entidad.clases;
    });
  }	

  obtenerEstadosMesa(){
    this.generalService.obtenerClases("EM").subscribe((entidad:any) => {
      this.filter.estados_mesa=this.filter.estados_mesa.concat(entidad.clases);
    });
  }

  async obtenerTiposDespachos(){
	  if(this.usuario.empresa.usar_pedidos_en_linea){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"REST_DESP").subscribe((entidad:any) => {
			this.filter.tipos_despacho=this.filter.tipos_despacho.concat(entidad.clases);
			this.tipos_despacho=entidad.clases;
		  });
	  }else if(this.usuario.empresa.usar_clientes_contactos){
		let entidad:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
		this.filter.tipos_despacho=this.filter.tipos_despacho.concat(entidad.clases);
		this.tipos_despacho=entidad.clases;
	  }else{
		this.generalService.obtenerClases("REST_DESP").subscribe((entidad:any) => {
			this.filter.tipos_despacho=this.filter.tipos_despacho.concat(entidad.clases);
			this.tipos_despacho=entidad.clases;
		  });
	  }
  }
  
  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.pedidosService.obtenerListaPedidos(this).subscribe((pedidos:any[]) => {
			this.setPages(1);
			this.pedidos=pedidos;
			this.blockUI.stop();
		});
  }

	crearNuevoPedidoPanel(){
		this.modalPedido = this.modalService.open(PedidoPanelComponent, {windowClass:'edicion-pedido',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modalPedido.componentInstance.usuario = this.usuario;
		this.modalPedido.componentInstance.sucursales = this.sucursales;
		this.modalPedido.componentInstance.estados = this.estados;
		this.modalPedido.componentInstance.mesas = this.mesas;
    this.modalPedido.componentInstance.tipos_despacho = this.tipos_despacho;
	this.modalPedido.componentInstance.aplicacion=this.aplicacion;
	this.modalPedido.componentInstance.configuracion_pagina=this.configuracion_pagina;
	
		this.modalPedido.componentInstance.alTerminar.subscribe(async ($e) => {
			if($e.confirm){
				this.getItems();
				if($e.liberar_mesa){
					let me=this;
					setTimeout(function() {
						for(let i=0;($e.pedido.mesasPedidoRestaurante && i<$e.pedido.mesasPedidoRestaurante.length);i++){
						var mesa=me.mesas.filter(e => e.id == $e.pedido.mesasPedidoRestaurante[i].mesa.id)[0];
						me.liberarMesa(mesa);
						}
					},1000);
				}

				if($e.venta){
					if($e.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
						let pdf=await this.imprimirVenta($e.venta.id,true);
						if($e.venta.cliente.email){
							await this.enviarCorreoElectronico($e.venta,pdf);
						}
					}else{
						if(this.configuracion_pagina.object.datos.opciones_permisos.impresion.solo_comanda){
							this.imprimirPedido($e.pedido);
						}else{
							this.imprimirVenta($e.venta.id,true);
						}
					}
				}
			}
			this.modalPedido.close();
		});
  	}

	abrirPopupConfirmacionEnvioCorreo(venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar el documento de la venta por correo electrónico?";
		this.popupConfirmacion.componentInstance.data = venta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.enviarCorreoElectronico($e.data,null);
			}
			this.popupConfirmacion.close();
        });
	}

	async enviarCorreoElectronico(venta,pdf_recibido){
		if(venta.cliente.email){
			venta=await this.ventasService.obtenerVenta(venta.id).toPromise();
			let me=this;
			var fecha=new Date(venta.fecha);
			var pdf=pdf_recibido?pdf_recibido:await this.pdfService.generarPdfDocumentoVenta(venta);
			var reader = new window.FileReader();
			reader.readAsDataURL(pdf);
			reader.onloadend = function () {
				let base64data = reader.result;
				let en={
					documento:base64data,
					xml:venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA?(venta.sin_en_linea?'./files/empresa-'+me.usuario.id_empresa+'/facturas-online-'+venta.sucursal.id+'/'+venta.cuf+'.xml':'./files/empresa-'+me.usuario.id_empresa+'/facturas-offline-'+venta.sucursal.id+'/'+venta.cuf+'.xml'):null,
					nombre_xml:venta.cuf+".xml",
					cliente:venta.cliente,
					nombre_documento:(venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA)?"Factura":"Nota de Venta",
					numero_documento:venta.factura,
					detalle_documento:venta.detallesVenta,
					fecha:fecha.getDate()+"-"+(fecha.getMonth()+1)+"-"+fecha.getFullYear(),
					nombre_empresa:me.usuario.empresa.nombre,
					telefono:me.usuario.empresa.telefono1
				};
				me.blockUI.start();
				me.ventasService.enviarDocumentoVentaCorreo(en).subscribe((res:any) => {
					if(res.tiene_error){
						me.toastr.error(res.mensaje);
					}else{
						me.toastr.success(res.mensaje);
					}
					me.blockUI.stop();
				});
			}
		}else{
			this.toastr.error("Debe agregar una dirección de correo electrónico para el cliente");
		}
	}

  	crearNuevoPedido(){
		this.modalPedido = this.modalService.open(PedidoComponent, {windowClass:'pedido-popup',scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modalPedido.componentInstance.pedido.usuario = this.usuario;
		this.modalPedido.componentInstance.usuario = this.usuario;
		this.modalPedido.componentInstance.configuracion_pagina = this.configuracion_pagina;
		this.modalPedido.componentInstance.pedido.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalPedido.componentInstance.busqueda_codigo_barra = this.configuracion_pagina.getOrden().busqueda_codigo_barra;	
		this.modalPedido.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
		this.modalPedido.componentInstance.nombre_corto_tipo_pago=this.configuracion_pagina.getOrden().tipo_pago_por_defecto;
		this.modalPedido.componentInstance.nombre_corto_transaccion=this.configuracion_pagina.getOrden().transaccion_por_defecto;
		this.modalPedido.componentInstance.cantidad_decimal=this.configuracion_pagina.getOrden().cantidad_decimal;
		
		this.modalPedido.componentInstance.alTerminar.subscribe((res) => {
			if(res && res.confirm){
				this.getItems();
			}
			this.modalPedido.close();
		});	
	}


		

  verMesa(mesa){
    this.modalPedido = this.modalService.open(PedidoPanelComponent, {windowClass:'edicion-pedido',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.modalPedido.componentInstance.mesa = mesa;
    this.modalPedido.componentInstance.usuario = this.usuario;
		this.modalPedido.componentInstance.sucursales = this.sucursales;
		this.modalPedido.componentInstance.estados = this.estados;
		this.modalPedido.componentInstance.mesas = this.mesas;
    this.modalPedido.componentInstance.tipos_despacho = this.tipos_despacho;
	this.modalPedido.componentInstance.aplicacion=this.aplicacion;
	this.modalPedido.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.modalPedido.componentInstance.alTerminar.subscribe(async ($e) => {
			if($e.confirm){
				this.getItems();
				if($e.liberar_mesa){
					let me=this;
					setTimeout(function() {
						for(let i=0;($e.pedido.mesasPedidoRestaurante && i<$e.pedido.mesasPedidoRestaurante.length);i++){
						var mesa=me.mesas.filter(e => e.id == $e.pedido.mesasPedidoRestaurante[i].mesa.id)[0];
						me.liberarMesa(mesa);
						}
					},1000);
				}

				if($e.venta){
					if($e.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
						let pdf=await this.imprimirVenta($e.venta.id,true);
						if($e.venta.cliente.email){
							await this.enviarCorreoElectronico($e.venta,pdf);
						}
					}else{
						if(this.configuracion_pagina.object.datos.opciones_permisos.impresion.solo_comanda){
							this.imprimirPedido($e.pedido);
						}else{
							this.imprimirVenta($e.venta.id,true);
						}
					}
				}
			}
			this.modalPedido.close();
		});
  }
  
  modificarPedido(pedido){
		this.modalPedido = this.modalService.open(PedidoPanelComponent, {windowClass:'edicion-pedido',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.modalPedido.componentInstance.pedido = pedido;
    this.modalPedido.componentInstance.usuario = this.usuario;
		this.modalPedido.componentInstance.sucursales = this.sucursales;
		this.modalPedido.componentInstance.estados = this.estados;
		this.modalPedido.componentInstance.mesas = this.mesas;
    this.modalPedido.componentInstance.tipos_despacho = this.tipos_despacho;
	this.modalPedido.componentInstance.aplicacion=this.aplicacion;
	this.modalPedido.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.modalPedido.componentInstance.alTerminar.subscribe(async ($e) => {
			if($e.confirm){
				this.getItems();
				if($e.liberar_mesa){
					let me=this;
					setTimeout(function() {
						for(let i=0;($e.pedido.mesasPedidoRestaurante && i<$e.pedido.mesasPedidoRestaurante.length);i++){
						var mesa=me.mesas.filter(e => e.id == $e.pedido.mesasPedidoRestaurante[i].mesa.id)[0];
						me.liberarMesa(mesa);
						}
					},1000);
				}

				if($e.venta){
					if($e.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
						let pdf=await this.imprimirVenta($e.venta.id,true);
						if($e.venta.cliente.email){
							await this.enviarCorreoElectronico($e.venta,pdf);
						}
					}else{
						if(this.configuracion_pagina.object.datos.opciones_permisos.impresion.solo_comanda){
							this.imprimirPedido($e.pedido);
						}else{
							this.imprimirVenta($e.venta.id,true);
						}
					}
				}
			}
			this.modalPedido.close();
		});
	}

  abrirPopupConfirmacionEliminacion(pedido){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el pedido?";
		this.popupConfirmacion.componentInstance.data = pedido;
		this.popupConfirmacion.componentInstance.es_venta = pedido.id_venta?true:false;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarPedido($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
	async eliminarPedido(pedido){
		this.blockUI.start();
		if(pedido.id_venta){
			let venta={id:pedido.id_venta,motivo_anulacion:pedido.motivo_anulacion};
			let res_anulacion_venta:any=await this.ventasService.anularVenta(venta).toPromise();
			if(res_anulacion_venta.tiene_error){
				this.toastr.error(res_anulacion_venta.mensaje);
				this.blockUI.stop();
				return;
			}
			/*if(pedido.transaccion_nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
				this.enviarCorreoElectronicoAnulacion(venta);
			}*/
			if(this.usuario.empresa.punto_venta_offline){
				await this.sincronizarAnulacionVenta({id:pedido.id_venta});
			}
		}
		
		await this.pedidosService.inactivarPedido(pedido).toPromise();
		if(pedido.ids_mesas){
			await this.pedidosService.inactivarPedidoMesa(pedido).toPromise();
			let mesas_pedido_restaurante=pedido.ids_mesas.split(',');
			for(let i=0;i<mesas_pedido_restaurante.length;i++){
				let mesa=this.mesas.filter(e => e.id == mesas_pedido_restaurante[i])[0];
				await this.liberarMesa(mesa);
			}
		}
		this.blockUI.stop();
		this.toastr.success('Pedido anulado exitosamente!');
		this.getItems();
	}
  
  sumarMonto(){
		var suma=0;
		for(var i=0;i<this.pedidos.length;i++){
			if(this.pedidos[i].activo){
				suma=suma+this.pedidos[i].total;
			}
		}
		return Math.round(suma*100)/100;
  }
  
  async sincronizarAnulacionVenta(venta){
		await this.ventasService.sincronizarAnulacionVenta({id_venta:venta.id}).toPromise();
  }
  
  async liberarMesa(mesa){
    this.blockUI.start();
    let mesaPedido:any=await this.pedidosService.obtenerPedidoMesa(mesa.id).toPromise();
    if(mesaPedido && mesaPedido.pedidosRestaurante){
      this.toastr.error("La mesa no puede ser liberada porque aun existe un pedido pendiente!");
    }else{
      mesa.estado=this.filter.estados_mesa.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTADO_MESA_DISPONIBLE)[0];
      await this.pedidosService.actualizarMesa(mesa).toPromise();
      this.toastr.success('Mesa liberada exitosamente!');
    }
    this.blockUI.stop();
  }
  
  verPedido(pedido){
    this.modalPedido = this.modalService.open(VistaPedidoComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'vista-pedido', backdrop: 'static'});
		this.modalPedido.componentInstance.pedido = pedido;
		this.modalPedido.componentInstance.usuario = this.usuario;
		this.modalPedido.componentInstance.alTerminar.subscribe((res) => {
			this.modalPedido.close();
		});
  }

  crearPagoVenta(pedido){
		this.modalPedido = this.modalService.open(PagoVentaComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalPedido.componentInstance.venta = {id:pedido.id_venta};
		this.modalPedido.componentInstance.usuario = this.usuario;
		this.modalPedido.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.getItems();
					this.modalPedido.close();
				}
			}else{
				this.modalPedido.close();
			}
			
		});
  }
  
  async imprimirPedido(pedido_i){
    this.pdfService.imprimirPedido(pedido_i);
  }
  
  async imprimirVenta(id_venta,es_guardar){
	let pdf_respuesta;
	if(es_guardar){
		let configuracion_aplicacion_ventas:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
		if(configuracion_aplicacion_ventas.datos.opciones_permisos.impresion.al_guardar){
			
			pdf_respuesta=await this.pdfService.generarPdfDocumentoVenta({id:id_venta});
			var w = window.open(window.URL.createObjectURL(pdf_respuesta), '_blank', 'location=no');
			setTimeout(function () {w.print();}, 500);
			if(this.usuario.empresa.usar_inventario && configuracion_aplicacion_ventas.datos.opciones_permisos.impresion.nota_almacen){
				let ventaConsultada:any=await this.ventasService.obtenerVenta({id:id_venta}).toPromise();
				ventaConsultada.fecha=new Date(ventaConsultada.fecha);
				ventaConsultada.fechaTexto=ventaConsultada.fecha.getDate()+"/"+(ventaConsultada.fecha.getMonth()+1)+"/"+ventaConsultada.fecha.getFullYear();
				this.pdfService.imprimirNotaAlmacenCartaOficio(ventaConsultada,configuracion_aplicacion_ventas.datos.opciones_permisos.impresion,this.usuario);
			}
		}
	}else{
		pdf_respuesta=await this.pdfService.generarPdfDocumentoVenta({id:id_venta});
		var w = window.open(window.URL.createObjectURL(pdf_respuesta), '_blank', 'location=no');
		setTimeout(function () {w.print();}, 500);
	}
	return pdf_respuesta;
  }

  abrirPopupConfirmacionCambioEstadoPedido(pedido){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.title = "Confirmación de cambio de estado de Pedido!";
		this.popupConfirmacion.componentInstance.message = pedido.mensaje;
		this.popupConfirmacion.componentInstance.data = pedido;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.cambiarEstadoPedido($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
  
  cambiarEstadoPedido(dato){
    this.blockUI.start();
    var nuevo_estado=this.filter.estados.filter(e => e.nombre_corto == dato.nombre_corto_estado)[0];
	dato.pedido.estado=nuevo_estado;
	dato.pedido.id_empresa=this.usuario.id_empresa;
    this.pedidosService.actualizarEstadoPedido(dato.pedido).subscribe((resP:any)=>{
		this.blockUI.stop();
      this.toastr.success(resP.mensaje);
      this.getItems();
    });
  }
  	
 	async generarVentaPedido(pedidoP){
		this.blockUI.start();
		let configuracion_aplicacion_ventas:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
		this.pedidosService.obtenerPedido(pedidoP.id).subscribe( (pedido:any)=>{
			pedido.usuario=pedido.usuario?pedido.usuario:this.usuario;
			pedido.usuario.empresa=this.usuario.empresa;

			this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalVenta.componentInstance.venta.usuario = pedido.usuario;
			this.modalVenta.componentInstance.usuario = this.usuario;console.log(configuracion_aplicacion_ventas);
			this.modalVenta.componentInstance.configuracion_pagina = configuracion_aplicacion_ventas;
			this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
			this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);

			let sucursal_pedido=this.filter.sucursales.filter(e => e.id == pedido.sucursal.id)[0];
			this.modalVenta.componentInstance.venta.cliente=pedido.cliente;
			this.modalVenta.componentInstance.venta.sucursal=sucursal_pedido;
			this.modalVenta.componentInstance.nombre_corto_tipo_pago=this.global_variable.Dictionary.TIPO_PAGO_CRE;
			this.modalVenta.componentInstance.nombre_corto_canal_directo=pedido.publico?this.global_variable.Dictionary.CANAL_VENTA_ECOMERCE:this.global_variable.Dictionary.CANAL_VENTA_DIRECTA;

			if(this.usuario.empresa.usar_despachos){
				this.modalVenta.componentInstance.venta.descontar_almacen=false;
				if(pedidoP.estado_nombre_corto==this.global_variable.Dictionary.ESTPED_ENTGD){
					//es pedido entregado
					this.modalVenta.componentInstance.venta.detallesVenta=pedido.detallesPedido;
				}else{
					//es pedido entregado incompleto
					var detalles_venta=pedido.detallesPedido;
					var detalles_seleccionado=[];
					for(var k=0;k<detalles_venta.length;k++){
						detalles_venta[k].cantidad=0;
						detalles_venta[k].importe=0;
						detalles_venta[k].total=0;
						for(var i=0;i<pedido.despachos.length;i++){
							var producto_encontrado=pedido.despachos[i].detallesDespacho.filter(e => e.id_producto == detalles_venta[k].id_producto);
							if(producto_encontrado.length>0){
								detalles_venta[k].cantidad=detalles_venta[k].cantidad+producto_encontrado[0].cantidad;
							}
						}
						if(detalles_venta[k].cantidad>0){
							detalles_venta[k].importe=detalles_venta[k].precio_unitario*detalles_venta[k].cantidad;
							detalles_venta[k].total=detalles_venta[k].importe;
							detalles_seleccionado.push(detalles_venta[k]);
						}
					}
		//realizar algoritmo para juntar todos los despachos de prodcutos en un solo detalle
		this.modalVenta.componentInstance.venta.detallesVenta=detalles_seleccionado;
				}
			}else{
				this.modalVenta.componentInstance.venta.descontar_almacen=this.usuario.empresa.usar_inventario?true:false;
				this.modalVenta.componentInstance.venta.detallesVenta=pedido.detallesPedido;
			}
					
			this.modalVenta.componentInstance.inhabilitar_busqueda_item=true;
			this.modalVenta.componentInstance.inhabilitar_sucursal=true;
			this.modalVenta.componentInstance.inhabilitar_almacen=true;
			this.modalVenta.componentInstance.inhabilitar_descontar_almacen=true;
			this.modalVenta.componentInstance.venta.pedido=pedido;
			this.modalVenta.componentInstance.venta.id_contacto_cliente=pedidoP.id_contacto_cliente;
			this.blockUI.stop();
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
						this.toastr.error(res.stack.substring(0,500));
					}else{
						this.toastr.success(res.mensaje);
						this.imprimirVenta(res.venta.id,true);
						this.modalVenta.close();
						this.getItems();
					}
				}else{
					this.modalVenta.close();
				}
				
			});
		});
		/*this.blockUI.start();
		let configuracion_aplicacion_ventas=await this.obtenerConfiguracionPaginaVentas();
		this.pedidosService.obtenerPedido(pedidoP.id).subscribe( (pedido:any)=>{
			if(this.usuario.empresa.usar_despachos){
				pedido.usuario=pedido.usuario?pedido.usuario:this.usuario;
				pedido.usuario.empresa=this.usuario.empresa;

				this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
				this.modalVenta.componentInstance.venta.usuario = pedido.usuario;
				this.modalVenta.componentInstance.usuario = this.usuario;
				this.modalVenta.componentInstance.configuracion_pagina = configuracion_aplicacion_ventas;
				this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
				this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);

				let sucursal_pedido=this.filter.sucursales.filter(e => e.id == pedido.sucursal.id)[0];
				this.modalVenta.componentInstance.venta.cliente=pedido.cliente;
				this.modalVenta.componentInstance.venta.sucursal=sucursal_pedido;
				this.modalVenta.componentInstance.nombre_corto_tipo_pago=this.global_variable.Dictionary.TIPO_PAGO_CRE;
				this.modalVenta.componentInstance.nombre_corto_canal_directo=pedido.publico?this.global_variable.Dictionary.CANAL_VENTA_ECOMERCE:this.global_variable.Dictionary.CANAL_VENTA_DIRECTA;
				this.modalVenta.componentInstance.venta.descontar_almacen=false;
				if(pedidoP.estado_nombre_corto==this.global_variable.Dictionary.ESTPED_ENTGD){
					//es pedido entregado
					this.modalVenta.componentInstance.venta.detallesVenta=pedido.detallesPedido;
				}else{
					//es pedido entregado incompleto
					var detalles_venta=pedido.detallesPedido;
					var detalles_seleccionado=[];
					for(var k=0;k<detalles_venta.length;k++){
						detalles_venta[k].cantidad=0;
						detalles_venta[k].importe=0;
						detalles_venta[k].total=0;
						for(var i=0;i<pedido.despachos.length;i++){
							var producto_encontrado=pedido.despachos[i].detallesDespacho.filter(e => e.id_producto == detalles_venta[k].id_producto);
							if(producto_encontrado.length>0){
								detalles_venta[k].cantidad=detalles_venta[k].cantidad+producto_encontrado[0].cantidad;
							}
						}
						if(detalles_venta[k].cantidad>0){
							detalles_venta[k].importe=detalles_venta[k].precio_unitario*detalles_venta[k].cantidad;
							detalles_venta[k].total=detalles_venta[k].importe;
							detalles_seleccionado.push(detalles_venta[k]);
						}
					}
					//realizar algoritmo para juntar todos los despachos de prodcutos en un solo detalle
					this.modalVenta.componentInstance.venta.detallesVenta=detalles_seleccionado;
				}
				this.modalVenta.componentInstance.inhabilitar_busqueda_item=true;
				this.modalVenta.componentInstance.inhabilitar_sucursal=true;
				this.modalVenta.componentInstance.inhabilitar_almacen=true;
				this.modalVenta.componentInstance.inhabilitar_descontar_almacen=true;
				this.modalVenta.componentInstance.venta.pedido=pedido;
				this.modalVenta.componentInstance.venta.id_contacto_cliente=pedidoP.id_contacto_cliente;
				this.blockUI.stop();
				this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
					if(res){
						if(res.tiene_error){
							this.toastr.error(res.mensaje);
							this.toastr.error(res.stack.substring(0,500));
						}else{
							this.toastr.success(res.mensaje);
							this.imprimirVenta(res.venta.id);
							this.modalVenta.close();
							this.getItems();
						}
					}else{
						this.modalVenta.close();
					}
				});
			}else{
				this.modalPedido = this.modalService.open(PedidoComponent, {windowClass:'pedido-popup',scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
				this.modalPedido.componentInstance.pedido = pedido
				this.modalPedido.componentInstance.usuario = this.usuario;
				this.modalPedido.componentInstance.configuracion_pagina = this.configuracion_pagina;
				this.modalPedido.componentInstance.pedido.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
				this.modalPedido.componentInstance.busqueda_codigo_barra = this.configuracion_pagina.getOrden().busqueda_codigo_barra;	
				this.modalPedido.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
				this.modalPedido.componentInstance.nombre_corto_tipo_pago=this.configuracion_pagina.getOrden().tipo_pago_por_defecto;
				this.modalPedido.componentInstance.nombre_corto_transaccion=this.configuracion_pagina.getOrden().transaccion_por_defecto;
				this.modalPedido.componentInstance.cantidad_decimal=this.configuracion_pagina.getOrden().cantidad_decimal;

				this.modalPedido.componentInstance.inhabilitar_cobrar_pedido=true;
				this.modalPedido.componentInstance.inhabilitar_busqueda_item=true;
				this.modalPedido.componentInstance.inhabilitar_sucursal=true;
				this.modalPedido.componentInstance.inhabilitar_almacen=true;
				this.modalPedido.componentInstance.inhabilitar_descontar_almacen=true;
				this.blockUI.stop();
				this.modalPedido.componentInstance.alTerminar.subscribe((res) => {
					if(res && res.confirm){
						this.getItems();
					}
					this.modalPedido.close();
				});	
			}
		});*/
  	}

  async obtenerConfiguracionPaginaVentas () {
	let configuracion_aplicacion_ventas:any=Util.obtenerConfiguracionAplicacion(GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this);
	let configuracion_pagina=new PageConfiguration({
		crear: true,
		id_empresa: this.usuario.id_empresa,
		id_usuario:this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:null,
		datos: configuracion_aplicacion_ventas.visualizacion_columnas,
		orden:configuracion_aplicacion_ventas.opciones_permisos
	},this.usuario.aplicacionesUsuario.filter(app => app.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_VENTAS)[0].aplicacion.id,
	this.usuarioService);
	await configuracion_pagina.compareAndUpdateConfigurations(this.usuario.id_empresa,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0);
	return configuracion_pagina;
  }
  
  obtenerSalas(idSucursal){
    this.blockUI.start();
    this.pedidosService.obtenerSalasSucursal(idSucursal).subscribe((salas:any[])=>{
      this.mesas=[];
			this.salas = salas;
			if(this.salas.length>=1){
				this.verSala(this.salas[0]);
			}
			this.blockUI.stop();
    });
  }
  
  verSala(sala){
		this.salaVista=sala;
		this.mesas = sala.mesas;
		//this.obtenerPedidosSucursal(sala.id_sucursal);
	    this.llenarMesasOpciones();
  }
  
  llenarMesasOpciones(){
		this.mesas_unidas=[];
		for(var i=0;i<this.mesas.length;i++){
			var mesa={
				name:this.mesas[i].numero+"",
				numero:this.mesas[i].numero,
				maker: "",
				ticked:false,
				id:this.mesas[i].id
			}
			this.mesas_unidas.push(mesa);
		}
  }

  abrirPopupConfirmacionEliminacionMesaPedido(mesa){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el pedido?";
		this.popupConfirmacion.componentInstance.data = mesa;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.inactivarPedidoMesa($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	inactivarPedidoMesa(mesa){
		this.blockUI.start();
		this.pedidosService.obtenerPedidoMesa(mesa.id).subscribe((mesaPedido:any)=>{
			if(mesaPedido && mesaPedido.pedidosRestaurante){
				var pedido=mesaPedido.pedidosRestaurante[0].pedidoRestaurante;
				if(pedido.id_venta){
					this.ventasService.anularVenta({id:pedido.id_venta}).subscribe((res:any)=>{

					});
				}
				this.pedidosService.inactivarPedido(pedido).subscribe((res:any)=>{
					pedido.activo=false;
					if(this.usuario.empresa.usar_pantalla_despacho){
						//socket.emit('pedidoAnulado',{pedido_creado:pedido,id_empresa:this.usuario.id_empresa});
					}
					if(pedido.mesasPedidoRestaurante.length>0){
						this.pedidosService.inactivarPedidoMesa(pedido).subscribe((resInact:any)=>{
							for(var i=0;i<pedido.mesasPedidoRestaurante.length;i++){
								var mesa=this.mesas.filter(e => e.id == pedido.mesasPedidoRestaurante[i].mesa.id)[0];
								this.liberarMesa(mesa);
							}
							this.blockUI.stop();
						});
					}
					this.blockUI.stop();
					this.toastr.error('Pedido de la mesa anulado exitosamente!');
					this.getItems();
				})
			}else{
				this.blockUI.stop();
				this.toastr.info('No existe pedido en la mesa, puede liberar la misma!');
			}
		});
	}

	abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionPedidosComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

	async obtenerMovimientosEgreso(){
		let entidad:any=await this.generalService.obtenerClases("MOVEGR").toPromise();
		let moingorg=entidad.clases;
		this.transacciones=moingorg.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_PROFORMA);
		if(this.usuario.empresa.usar_facturacion){console.log("sdfds");
		this.transacciones=this.transacciones.concat(moingorg.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION));
		}
	  }	

	  descargarExcelResumenDespachoPorUsuarios(){
		this.blockUI.start();
		this.reportesService.obtenerPedidosProductosUsuarios(this).subscribe((dato:any)=>{
			let productos_usuario=dato.pedidos_productos_usuarios;
			var data = [];
			var total_cantidad=0,total_despacho=0,total_requerido=0,numero=1;
			data.push(["N°","Usuario","Producto","Cantidad","Total"]);
			let pu=0;
			for(var i=0;i<productos_usuario.length;i++){
				var columns=[];
				columns.push(numero);
				columns.push(productos_usuario[i].nombre_usuario);
				columns.push(productos_usuario[i].nombre);
				columns.push(productos_usuario[i].cantidad);
				columns.push(productos_usuario[i].pu);
				total_cantidad=total_cantidad+productos_usuario[i].cantidad;
				pu=pu+productos_usuario[i].pu;
				numero++;
				data.push(columns);
			}
			
			data.push([null,null,"TOTALES",total_cantidad,pu,null]);

			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet("Resumen Despachos");
			data.forEach(d => {
				let row = worksheet.addRow(d);
			});

			const dobCol2 = worksheet.getColumn(2);
			dobCol2.width = 40;
			const dobCol3 = worksheet.getColumn(3);
			dobCol3.width = 20;

			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, "Resumen-Despachos.xlsx");
			})
			this.blockUI.stop();
		});
	}

	descargarPdfResumenDespacho(){
		var doc = new PDFDocument({size:[612, 792],margin:10});
		var stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		var total_items=0,numero=1,total_cantidad=0,total_despacho=0,total_requerido=0;
		/*for(var i=0;i<this.pedidos.length;i++){
			total_items=total_items+this.pedidos[i].detallesPedido.length;
		}*/
		var y=170,items=0,itemsPorPagina=19,pagina=1,totalPaginas=Math.ceil(total_items/itemsPorPagina);
		var titulo="RESUMEN DE DESPACHO";
		this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
			this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursales[1].nombre,this.filter.sucursales[1].direccion,
				(this.filter.sucursales[1].telefono1!=null?this.filter.sucursales[1].telefono1:"")+
			(this.filter.sucursales[1].telefono2!=null?"-"+this.filter.sucursales[1].telefono2:"")+
			(this.filter.sucursales[1].telefono3!=null?"-"+this.filter.sucursales[1].telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
			fecha_reporte,{imprimir_usuario:true});
		this.escribirCabeceraPdfResumenDespacho(doc,this.filter);
		for(var i=0;i<this.pedidos.length && items <= itemsPorPagina;i++){
			//for(var j=0;j<this.pedidos[i].detallesPedido.length;j++){
				doc.font('Helvetica',8);
				doc.text(numero,55,y,{width:70});
				this.pedidos[i].tiempo_ingreso=new Date(this.pedidos[i].tiempo_ingreso);
				var fecha=this.pedidos[i].tiempo_ingreso.getDate()+"/"+(this.pedidos[i].tiempo_ingreso.getMonth()+1)+"/"+this.pedidos[i].tiempo_ingreso.getFullYear();
				doc.text(fecha,75,y,{width:70});
				doc.text(this.pedidos[i].numero_pedido,120,y,{width:70});
				doc.text(this.pedidos[i].cliente,155,y-5,{width:85});
				doc.text(this.pedidos[i].activo?"ACTIVO":"ANULADO",240,y,{width:70});
				/*doc.text(this.pedidos[i].detallesPedido[j].producto.nombre,290,y,{width:200});
				doc.text(this.pedidos[i].detallesPedido[j].cantidad,450,y,{width:200});
				doc.text(this.pedidos[i].detallesPedido[j].cantidad_despachado,500,y,{width:200});
				doc.text(this.pedidos[i].detallesPedido[j].cantidad-this.pedidos[i].detallesPedido[j].cantidad_despachado,550,y,{width:200});*/
				numero++;

				/*total_cantidad=total_cantidad+this.pedidos[i].detallesPedido[j].cantidad;
				total_despacho=total_despacho+this.pedidos[i].detallesPedido[j].cantidad_despachado;
				total_requerido=total_requerido+this.pedidos[i].detallesPedido[j].cantidad-this.pedidos[i].detallesPedido[j].cantidad_despachado;
*/
				doc.rect(50,y-15,540,30).stroke();
			
				y=y+30;
				
				items++;

				if (items == itemsPorPagina && (numero) < total_items) {
					doc.addPage({size:[612, 792],margin:10});
					y = 170;
					items = 0;
					pagina = pagina + 1;
					this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
						this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursales[1].nombre,this.filter.sucursales[1].direccion,
							(this.filter.sucursales[1].telefono1!=null?this.filter.sucursales[1].telefono1:"")+
						(this.filter.sucursales[1].telefono2!=null?"-"+this.filter.sucursales[1].telefono2:"")+
						(this.filter.sucursales[1].telefono3!=null?"-"+this.filter.sucursales[1].telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
						fecha_reporte,{imprimir_usuario:true});
					this.escribirCabeceraPdfResumenDespacho(doc,this.filter);
				}
			//}
		}
		
		doc.font('Helvetica-Bold',8);
		doc.rect(50,y-15,540,30).stroke();
		/*doc.text("TOTALES : ",290,y);
		doc.text(total_cantidad,450,y);
		doc.text(total_despacho,500,y);
		doc.text(total_requerido,550,y);
		*/
		doc.font('Helvetica', 8);

		//doc.text(pagina + " de " + totalPaginas, 540, y+25);
		doc.end();
		stream.on('finish', function() {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL,'_blank','location=no');
		});
		this.blockUI.stop();
	}

	escribirCabeceraPdfResumenDespacho(doc,filtro){
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(380,55,195,43,5).stroke();
		//doc.text("DEL : " + filtro.fechaInicioTexto,390,60);
		//doc.text("AL : "+ filtro.fechaFinTexto,480,60);
		doc.text("USUARIO : "+ filtro.usuario.nombre_usuario,390,75);
		doc.text("ESTADO : "+ filtro.estado.nombre,390,90);
		doc.roundedRect(50,130,540,25,5).fillOpacity(0.8).stroke();
		doc.text("Nº",55,140);
		doc.text("FECHA",75,140);
		doc.text("NUM.",115,135,{width:30});
		doc.text("PEDIDO",115,145,{width:40});
		doc.text("CLIENTE",155,140);
		doc.text("VALIDEZ",240,140,{width:50});
		/*doc.text("PRODUCTO",290,140);		
		doc.text("CANT.",450,135,{width:30});
		doc.text("CANT. DESP.",500,135,{width:30});
		doc.text("CANT. REQ.",550,135,{width:30});*/
	}

	descargarExcelResumenDespachoPorClientes(){
		this.blockUI.start();
		var data = [];
		var total_cantidad=0,total_despacho=0,total_requerido=0,numero=1;
		data.push(["N°","Fecha","Num. Pedido","Cliente","Direccion","Telefono","Validez"/*,"Producto","Cantidad","Cantidad Despacho","Cantidad Requerida"*/]);
		for(var i=0;i<this.pedidos.length;i++){
			//for(var j=0;j<this.pedidos[i].detallesPedido.length;j++){
				var columns=[];
				columns.push(numero);
				this.pedidos[i].tiempo_ingreso=new Date(this.pedidos[i].tiempo_ingreso);
				var fecha=this.pedidos[i].tiempo_ingreso.getDate()+"/"+(this.pedidos[i].tiempo_ingreso.getMonth()+1)+"/"+this.pedidos[i].tiempo_ingreso.getFullYear();
				columns.push(fecha);
				columns.push(this.pedidos[i].numero_pedido);
				columns.push(this.pedidos[i].cliente);
				columns.push(this.pedidos[i].cliente_direccion);
				columns.push(this.pedidos[i].cliente_telefono);
				columns.push(this.pedidos[i].activo?"ACTIVO":"ANULADO");
				/*columns.push(this.pedidos[i].detallesPedido[j].producto.nombre);
				columns.push(this.pedidos[i].detallesPedido[j].cantidad);
				columns.push(this.pedidos[i].detallesPedido[j].cantidad_despachado);
				columns.push(this.pedidos[i].detallesPedido[j].cantidad-this.pedidos[i].detallesPedido[j].cantidad_despachado);

				total_cantidad=total_cantidad+this.pedidos[i].detallesPedido[j].cantidad;
				total_despacho=total_despacho+this.pedidos[i].detallesPedido[j].cantidad_despachado;
				total_requerido=total_requerido+this.pedidos[i].detallesPedido[j].cantidad-this.pedidos[i].detallesPedido[j].cantidad_despachado;*/
				numero++;
				data.push(columns);
			//}
		}

		//data.push([null,null,null,null,null,"TOTALES",total_cantidad,total_despacho,total_requerido]);

		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("Resumen Despachos");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 40;
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 20;

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fs.saveAs(blob, "Resumen-Despachos.xlsx");
		})
		this.blockUI.stop();
	}

	async abrirPopupCambioMesa(mesa){
		this.blockUI.start();
		this.mesaAcambiar=null;
		this.mesa=mesa;
		let mesaPedido:any=await this.pedidosService.obtenerPedidoMesa(mesa.id).toPromise();
      	if(mesaPedido && mesaPedido.pedidosRestaurante){
        	this.pedido=mesaPedido.pedidosRestaurante[0].pedidoRestaurante;
			this.cambiomesa_modal=this.modalService.open(this.cambiomesa_modal_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
			this.cambiomesa_modal.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			  }, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			  });
		}else{
			this.toastr.info("¡La mesa no tiene pedido asignado, puede liberarla!");
		}
		this.blockUI.stop();
	}

  	async cambiarMesa(mesaACambiar){
		this.blockUI.start();
		if(!this.pedido.mesas){
			//inactivar mesas pedidos restaurante
			await this.pedidosService.inactivarPedidoMesa(this.pedido).toPromise();
			for(var i=0;i<this.pedido.mesasPedidoRestaurante.length;i++){
				var mesa=this.mesas.filter(e => e.id == this.pedido.mesasPedidoRestaurante[i].mesa.id)[0];
				this.liberarMesa(mesa);
			}
		}
		//agregar las nuevas mesas asignadas
		let estado_ocupado=this.filter.estados_mesa.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTADO_MESA_OCUPADO)[0];
		let res:any=await this.pedidosService.agregarMesaPedido({id_pedido:this.pedido.id,id_mesa:mesaACambiar.id,id_estado:estado_ocupado.id}).toPromise();
		this.obtenerSalas(this.filter.sucursales[1].id);
		this.blockUI.stop();
		this.toastr.success(res.mensaje);
		this.cambiomesa_modal.close();
	}

	obtenerMesasLibres(){
		if(this.mesas){
			var mesasDisponibles=[];
			for(var i=0;i<this.mesas.length;i++){
				if(this.mesas[i].estado.nombre_corto==GlobalVariable.Dictionary.ESTADO_MESA_DISPONIBLE){
					mesasDisponibles.push(this.mesas[i]);
				}
			}
			return mesasDisponibles;
		}
	}

	abrirEliminacionItemsServidor(){
		this.limipeza_datos_servidor=this.modalService.open(this.limipeza_datos_servidor_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.limipeza_datos_servidor.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
	}

	eliminarDatosServidor(){
		this.blockUI.start();
		let fecha=Util.convertirObjetoAfecha(this.filter.fecha_limpieza);
		this.ventasService.sincronizarElimincionDatos(fecha).subscribe((res:any)=>{
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
			}
			this.blockUI.stop();
		});
	}

	verEventosSignificativos(){
		this.modalVenta = this.modalService.open(SinListaEventosSignificativosComponent, {windowClass:'lista-eventos-significativos', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
			
		});
	}
}
