import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { GlobalVariable } from 'src/app/global';
import { CursosService } from '../../services/cursos/cursos.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';

@Component({
  selector: 'app-registro-rapido-estudiante',
  templateUrl: './registro-rapido-estudiante.component.html',
  styleUrls: ['./registro-rapido-estudiante.component.css']
})
export class RegistroRapidoEstudianteComponent extends BaseComponent implements OnInit {

  estudiante:any={persona:{genero:{},usuario:{rol:{}},cliente:{}}};
  generos:any[]=[];
  gestiones:any[]=[];
  inscripcion_pago:any;
  inscripcion:any;
  tipo_categoria:any={clases:[]};
  fecha_nacimiento_minimo ={year: new Date().getUTCFullYear()-60,month: 12, day: 31}

  @Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();

  registro_inscripcion_colegio_modal:NgbModalRef;
  @ViewChild('registro_inscripcion_colegio_modal')
  private registro_inscripcion_colegio_modal_ref: TemplateRef<any>;

  constructor(public persistenciaService:PersistenciaService,
      public modalService: NgbModal,
      public generalService:GeneralService,
      private toastr: ToastrService,
      private estudiantesService:EstudiantesService,
      public usuarioService:UsuarioService,
    public cursosService:CursosService) {
        super(persistenciaService,modalService,generalService,usuarioService);
       }

  async ngOnInit(): Promise<void> {
      this.filter={
        sucursales:Util.obtenerSucursalesUsuario(this.usuario)
      }
      let entidad_gnr:any=await this.generalService.obtenerClases("GNR").toPromise();
      this.generos=entidad_gnr.clases;
      if(this.estudiante.id){
        await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE);
        this.estudiante.persona.fecha_nacimiento=this.estudiante.persona.fecha_nacimiento?new Date(this.estudiante.persona.fecha_nacimiento):null;
        this.estudiante.persona.fecha_nacimiento_texto=this.estudiante.persona.fecha_nacimiento?{
          year:this.estudiante.persona.fecha_nacimiento.getFullYear(),
          month:this.estudiante.persona.fecha_nacimiento.getMonth()+1,
          day:this.estudiante.persona.fecha_nacimiento.getDate()
        }:null;
        this.estudiante.codigo_nuevo_asignado=this.estudiante.codigo?false:true;
        this.estudiante.codigo=this.estudiante.codigo?this.estudiante.codigo:await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE);
      }else{
        let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE);
        this.estudiante={
           codigo:codigo_nuevo,
           persona:{
             activo:true,
             genero:{},
             imagen:"img/icon-user-default.png",
             usuario:{cierre_sesion_automatico:true,activo:true,multidispositivo:false,enviar_correo:false,id_sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0].id},cliente:{nit:0}},
           tutores:[],
           inscripciones_instituto:[],
           inscripciones_colegio:[]
        }
        this.estudiante.sucursal=this.filter.sucursales[0];
      }
      this.obtenerCategoriasEstudiantesEmpresa();
    }

    async obtenerCategoriasEstudiantesEmpresa(){
      this.tipo_categoria=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_ESTUDIANTE_CATEGORIAS).toPromise();
    }

    establecerRazonSocial(){
      this.estudiante.persona.cliente.razon_social=(this.estudiante.persona.apellido_paterno?this.estudiante.persona.apellido_paterno+" ":"")+(this.estudiante.persona.apellido_paterno?this.estudiante.persona.apellido_materno+" ":"")+(this.estudiante.persona.nombres?this.estudiante.persona.nombres+" ":"");
    }

    cerrarRegistroEstudiante(){
      this.onConfirm.emit({confirm:false});
    }

    async guardar(){
      this.blockUI.start();
      if(await this.validarFormularioRegistroEstudiante()){
        this.estudiante.persona.fecha_nacimiento=this.estudiante.persona.fecha_nacimiento_texto?Util.convertirObjetoAfecha(this.estudiante.persona.fecha_nacimiento_texto):null;
        this.estudiante.id_empresa=this.usuario.id_empresa;
        if(this.estudiante.id){
          this.estudiantesService.actualizarEstudiante(this.estudiante).subscribe((res:any) => {
            this.blockUI.stop();
            if(res.tiene_error){
              this.toastr.error(res.mensaje);
            }else{
              if(this.estudiante.codigo_nuevo_asignado){
                this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE);
              }
              this.toastr.success(res.mensaje);
              this.onConfirm.emit({confirm:true});
            }
          });
        }else{
          this.estudiante.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE):this.estudiante.codigo;
          this.estudiantesService.guardarEstudiante(this.estudiante).subscribe((res:any) => {
            this.blockUI.stop();
            if(res.tiene_error){
              this.toastr.error(res.mensaje);
            }else{
              this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE);
              this.toastr.success(res.mensaje);
              this.onConfirm.emit({confirm:true});
            }
          });
        }
      }else{
        this.blockUI.stop();
      }
    }

    async validarFormularioRegistroEstudiante(){
      let res=true;
      if(!this.estudiante.codigo){
        this.toastr.error("Debe ingresar el código del estudiante!");
        res=false;
      }
      if(!this.estudiante.persona.nombres){
        this.toastr.error("Debe ingresar el nombre del estudiante!");
        res=false;
      }
      if(!this.estudiante.persona.genero || !this.estudiante.persona.genero.id){
        this.toastr.error("Debe ingresar el género del estudiante!");
        res=false;
      }
      return res;
    }

    async crearNuevaInscripcionColegio(){
      let fecha_actual=new Date();
      this.inscripcion={
        activo:true,
        consolidado:false,
        id_usuario_creador:this.usuario.id,
        fecha_inicio_texto:{
          year: fecha_actual.getFullYear(), 
          month: (fecha_actual.getMonth()+1),
          day:fecha_actual.getDate()
        }
      }
      this.inscripcion_pago=this.inscripcion;
      this.filter={};
      await this.obtenerEscuela();
      this.obtenerGestiones();
      this.obtenerNivelesEscolares();
      this.abrirRegistroInscripcionColegio();
    }

    obtenerGestiones(){
      this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).subscribe((entidad:any)=>{
        this.gestiones=entidad.clases
      });
    }

    async obtenerNivelesEscolares(){
      let niveles_escolares:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.SCH.TIPO_NIVEL_ESCOLAR).toPromise();
      this.filter.niveles_escolares=niveles_escolares.clases;
    }

    abrirRegistroInscripcionColegio(){
      this.registro_inscripcion_colegio_modal=this.modalService.open(this.registro_inscripcion_colegio_modal_ref, {scrollable:true, windowClass:'inscripcion_instituto',ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
      this.registro_inscripcion_colegio_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    async obtenerCursosPorNivel(id_nivel_escolar){
      let cursos:any=await this.cursosService.obtenerCursosNivel(this.usuario.id_empresa,id_nivel_escolar).toPromise();
      this.filter.cursos=cursos;
    }

    agregarInscripcionColegio(){
      if(this.validarInscripcionColegio()){
        this.inscripcion.fecha_inicio=this.inscripcion.fecha_inicio_texto?Util.convertirObjetoAfecha(this.inscripcion.fecha_inicio_texto):null;
        if(this.inscripcion.id){
          //this.estudiante.inscripciones_colegio.push(this.inscripcion);
        }else{
          this.estudiante.inscripciones_colegio.push(this.inscripcion);
        }
        this.registro_inscripcion_colegio_modal.close();
      }
    }
  
    validarInscripcionColegio(){
      let res=true;
      if(!this.inscripcion.gestion){
        res=res && false;
        this.toastr.error("Debe especificar la gestión!");
      }
      if(!this.inscripcion.curso){
        res=res && false;
        this.toastr.error("Debe especificar el curso!");
      }
      return res;
    }

    async modificarInscripcionColegio(inscripcion){
      this.inscripcion=inscripcion
      this.filter={};
      await this.obtenerEscuela();
      await this.obtenerGestiones();
      await this.obtenerNivelesEscolares();
      await this.abrirRegistroInscripcionColegio();
      this.inscripcion.nivel=this.filter.niveles_escolares.filter(n => n.id==this.inscripcion.curso.id_nivel)[0];
      this.obtenerCursosPorNivel(this.inscripcion.nivel.id)
    }

    abrirPopupConfirmacionEliminacion(inscripcion){
        this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
        this.popupConfirmacion.componentInstance.message = "¿Esta seguro de anular la inscripción?";
        this.popupConfirmacion.componentInstance.data = inscripcion;
        
        this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
          if($e.confirm){
            this.anularInscripcion($e.data);
          }
          this.popupConfirmacion.close();
        });
    }

    anularInscripcion(inscripcion){
      if(inscripcion.id){
        inscripcion.activo=false;
      }else{
        inscripcion.eliminado=true;
      }
    }

}
