import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { InventariosService } from '../../services/inventarios/inventarios.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fecha-vencimiento-modificacion',
  templateUrl: './fecha-vencimiento-modificacion.component.html',
  styleUrls: ['./fecha-vencimiento-modificacion.component.css']
})
export class FechaVencimientoModificacionComponent extends BaseComponent implements OnInit {

  inventario:any;
  producto:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(
      public generalService:GeneralService,
      public persistenciaService:PersistenciaService,
      public modalService: NgbModal,
      public usuarioService:UsuarioService,
    public inventariosService:InventariosService,
    private toastr: ToastrService
    ) {
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.inventario.fecha_vencimiento=new Date(this.inventario.fecha_vencimiento);
    this.inventario.fecha_vencimiento_texto = Util.convertirTextoAObjectoFecha(this.inventario.fecha_vencimiento.getDate()+"/"+(this.inventario.fecha_vencimiento.getMonth()+1)+"/"+this.inventario.fecha_vencimiento.getFullYear());
  }

  guardarFechaLoteInventario () {
      this.blockUI.start();
      if(this.producto.activar_inventario){
        this.inventario.fecha_vencimiento=Util.convertirObjetoAfecha(this.inventario.fecha_vencimiento_texto);
      }
      this.inventariosService.actualizarCantidadInventario(this.inventario).subscribe((data:any)=>{
        this.blockUI.stop();
        this.alTerminar.emit(true);
        //this.modificacion_fecha_lote.close();
        this.toastr.success(data.message);
        //this.getItems();
      });
    }

    cerrarComponente(){
      this.alTerminar.emit();
    }

}
