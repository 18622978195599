import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampeonatosComponent } from './components/campeonatos/campeonatos.component';
import { EscenariosComponent } from './components/escenarios/escenarios.component';
import { BaseModule } from '../base/base.module';
import { FormsModule } from '@angular/forms';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RolPartidosComponent } from './components/rol-partidos/rol-partidos.component';
import { OrderModule } from 'ngx-order-pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RegistroJugadorComponent } from './components/registro-jugador/registro-jugador.component';
import { ConsultaMarcadorPartidoComponent } from './components/consulta-marcador-partido/consulta-marcador-partido.component';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { GlobalVariable } from '../global';
import { ClubComponent } from './components/club/club.component';
import { TarjetasJugadorComponent } from './components/tarjetas-jugador/tarjetas-jugador.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const config: SocketIoConfig = { url: GlobalVariable.SERVER_URL, options: {} };

@NgModule({
  declarations: [
    CampeonatosComponent,
    EscenariosComponent,
    RolPartidosComponent,
    RegistroJugadorComponent,
    ConsultaMarcadorPartidoComponent,
    ClubComponent,
    TarjetasJugadorComponent
  ],
  imports: [
    CommonModule,
    BaseModule,
    FormsModule,
    OrderModule,
    NgbModule,
    NgxBootstrapMultiselectModule,
    ImageCropperModule,
    GoogleMapsModule,
    BrowserAnimationsModule,
    SocketIoModule.forRoot(config),
  ]
})
export class TorneoModule { }
