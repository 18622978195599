import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { CampeonatosService } from '../../services/campeonatos/campeonatos.service';
import { Util } from 'src/app/utils/utils';
import { GlobalVariable } from 'src/app/global';
import { RolesPartidosService } from '../../services/roles-partidos/roles-partidos.service';
import * as fs from 'file-saver';
import { OrderPipe } from 'ngx-order-pipe';
import * as CryptoJS from 'crypto-js';
import { RegistroJugadorComponent } from '../registro-jugador/registro-jugador.component';
import { NgbModalRef } from 'node_modules/@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { TarjetasJugadorComponent } from '../tarjetas-jugador/tarjetas-jugador.component';

declare const PDFDocument:any;
declare const blobStream:any;
declare const qr:any

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.css']
})
export class ClubComponent extends BaseComponent implements OnInit {

  id_cliente:any;
  cliente:any;
  partidos:any=[];
  detalles_ventas_pendientes:any=[];
  pendientes_seleccionado:boolean=false;
  total_deuda:any=0;
  equipo_tab:any={};
  modalJugador:NgbModalRef;
  modal_impresion_tarjetas_jugador:NgbModalRef;

  constructor(private _Activatedroute:ActivatedRoute,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    public campeonatoService:CampeonatosService,
  public rolesPartidosService:RolesPartidosService,
  private toastr: ToastrService,
  private orderPipe: OrderPipe) {
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.id_cliente=CryptoJS.AES.decrypt(this._Activatedroute.snapshot.paramMap.get("id_cliente").toString().replaceAll('Por21Ld', '/'), GlobalVariable.Dictionary.TIPO_RECURSOS_MULTIMEDIA).toString(CryptoJS.enc.Utf8);
    await this.obtenerEquiposCliente(this.id_cliente);
    await this.establecerEquipoTab(this.cliente.equipos[0]);

  }

  establecerEquipoTab(equipo){
    this.equipo_tab=equipo;
    this.obtenerPartidosEquipo();
    this.pendientes_seleccionado=false;
  }

  async obtenerEquiposCliente(id_cliente){
    this.blockUI.start();
    this.cliente=await this.campeonatoService.obtenerClienteEquipos(id_cliente).toPromise();
    for(let i=0;i<this.cliente.equipos.length;i++){
      this.cliente.equipos[i].jugadores=this.orderPipe.transform(this.cliente.equipos[i].jugadores, 'persona.nombre_completo',false);
    }
    this.blockUI.stop();
  }

  async obtenerPartidosEquipo(){
    this.blockUI.start();
    this.partidos=await this.campeonatoService.obtenerPartidosEquipo(this.equipo_tab.id).toPromise();
    this.blockUI.stop();
  }

  async generarListaConvocados(jugadores,partido){
    this.blockUI.start();
    let doc = new PDFDocument({size:[612, 612],margin:10});
    let stream = doc.pipe(blobStream());
    let fondo=await Util.convertirUrlToBase64Image(this.rest_server+"img/fondo-rol-partido.png");
    await this.dibujarCabeceraPartido(doc,fondo,this.equipo_tab.campeonato,partido.rol_partido);
    
    let y_doc=jugadores.length>0?90:180;
    let logo_equipo_1=await Util.convertirUrlToBase64Image(this.rest_server+partido.equipo_1.logo);
    let logo_equipo_2=await Util.convertirUrlToBase64Image(this.rest_server+partido.equipo_2.logo);
    doc.image(logo_equipo_1,45,y_doc, { width: 150, height: 150}); 
    doc.image(logo_equipo_2,415,y_doc, { width: 150, height: 150}); 

    doc.font('Helvetica-Bold',40);
    doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
    doc.text("VS",0,y_doc+30,{width:612,align:"center"});
    doc.roundedRect(201,y_doc+60,210,90,5).fill(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();;
    doc.font('Helvetica-Bold',20);
    let fecha_hora=new Date(partido.fecha_hora);
    doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.text(fecha_hora.toLocaleDateString("es-ES", { weekday: 'long' }).toUpperCase(),0,y_doc+70,{width:612,align:"center"});
    doc.text(fecha_hora.getDate()+" "+fecha_hora.toLocaleString('es-ES', { month: 'long' }).toUpperCase()+" "+fecha_hora.getFullYear(),0,y_doc+90,{width:612,align:"center"});
    doc.text(fecha_hora.getHours()+":"+fecha_hora.getMinutes(),0,y_doc+110,{width:612,align:"center"});
    doc.text(this.equipo_tab.categoria.nombre,0,y_doc+130,{width:612,align:"center"});

    doc.font('Helvetica-Bold',13);
    doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
    doc.text(partido.equipo_1.nombre,30,y_doc+210,{width:200,align:"center"});
    doc.text(partido.equipo_2.nombre,382,y_doc+210,{width:200,align:"center"});

    let y_jugador=360;
    if(jugadores.length>0){
      doc.font('Helvetica-Bold',20);
      doc.roundedRect(156,335,300,23,5).fill(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();;
      doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.enunciados_color);
      doc.text("LISTA DE CONVOCADOS",0,340,{width:612,align:"center"});
      
      doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
      doc.font('Helvetica-Bold',15);
     
      for(let i=0;i<jugadores.length;i++){
        doc.text((i+1)+".- "+jugadores[i].persona.nombre_completo,150,y_jugador,{width:400,align:"left"});
        y_jugador=y_jugador+15;
      }
    }

    doc.font('Helvetica-Bold',12);
    doc.rect(0,550,GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0],80).fill(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
    doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.text("ESCENARIO:",20,560,{width:572,align:"center"});
    doc.text(partido.escenario.nombre.toUpperCase()+" - "+partido.escenario.direccion.toUpperCase(),20,572,{width:572,align:"center"});

    doc.end();
    let me=this;
    stream.on('finish', async function(d) {
      var reader = new FileReader();
      reader.readAsDataURL(stream.toBlob('application/pdf')); 
      reader.onloadend = async function() {
        var base64data = reader.result;                
        me.rolesPartidosService.convertirPdfImg({base64:base64data,nombre:"rol-partidos.png"}).subscribe(async (res:any)=>{
          fs.saveAs(GlobalVariable.SERVER_URL+res.path, "rol-partidos.png");
          me.blockUI.stop();
        });
      }
      /*var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL,'_blank','location=no');*/
    });
  }

  async dibujarCabeceraPartido(doc,fondo,campeonato,rol_partido){
    doc.image(fondo,0,0, { width: GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0], height: GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[1],align:'right' });
    doc.rect(0,0,GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0],80).fill(campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
    let logo=await Util.convertirUrlToBase64Image(this.rest_server+campeonato.logo);
    let logo_empresa=await Util.convertirUrlToBase64Image(this.rest_server+this.cliente.empresa.imagen);
    doc.image(logo_empresa,20,5, { width: 70, height: 70,align:'left' }); 
    doc.fillColor(campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.font('Times-Bold',30);
    doc.text(campeonato.nombre.toUpperCase(),0,20,{align:"center"});
    doc.font('Times-Bold',15);
    doc.text(rol_partido.rol.nombre,0,50,{align:"center"});
    doc.image(logo,522,5, { width: 70, height: 70,align:'right' });
    doc.font('Helvetica-Bold',16);
  }

  async crearNuevoJugador(equipo){
    this.modalJugador = this.modalService.open(RegistroJugadorComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalJugador.componentInstance.equipo = equipo;
		this.modalJugador.componentInstance.usuario = {id_empresa:this.cliente.id_empresa};
		
		this.modalJugador.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.obtenerEquiposCliente(this.id_cliente);
					this.modalJugador.close();
				}
			}else{
				this.modalJugador.close();
			}
			
		});
  }

  async modificarJugador(jugador_a_modificar){
    let jugador:any=jugador_a_modificar;
    jugador.persona.fecha_nacimiento_texto=jugador.persona.fecha_nacimiento?Util.convertirFechaAObjectoFecha(new Date(jugador.persona.fecha_nacimiento)):null;
    jugador.id_empresa=this.cliente.id_empresa;

    this.modalJugador = this.modalService.open(RegistroJugadorComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalJugador.componentInstance.jugador = jugador;
		this.modalJugador.componentInstance.usuario = {id_empresa:this.cliente.id_empresa};
		
		this.modalJugador.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.obtenerEquiposCliente(this.id_cliente);
					this.modalJugador.close();
				}
			}else{
				this.modalJugador.close();
			}
		});
  }

  abrirPopupConfirmacionEliminacionJugador(jugador){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar al jugador?";
		this.popupConfirmacion.componentInstance.data = jugador;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarJugador($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

  eliminarJugador(jugador){
		this.blockUI.start();
		this.campeonatoService.eliminarJugador(jugador).subscribe((dato:any)=>{
			this.blockUI.stop();
			if(dato.tiene_error){
				this.toastr.error(dato.mensaje);
			}else{
        this.obtenerEquiposCliente(this.id_cliente);
				this.toastr.success(dato.mensaje);
			}
		});
	}

 async verPendientesEconomicos(){
  this.pendientes_seleccionado=true;
  this.total_deuda=0;
  this.blockUI.start();
    this.detalles_ventas_pendientes=await this.rolesPartidosService.obtenerDetallesVentaPendientes(this.cliente.id).toPromise();
    for(let i=0;i<this.detalles_ventas_pendientes.length;i++){
      this.total_deuda=this.total_deuda+this.detalles_ventas_pendientes[i].venta.saldo;
    }
  this.blockUI.stop();
  }

  abrirModalGeneracionPdfTarjetasJugador(jugadores,equipo){
    this.modal_impresion_tarjetas_jugador = this.modalService.open(TarjetasJugadorComponent, {windowClass:'tarjetas-jugador',scrollable:true,ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modal_impresion_tarjetas_jugador.componentInstance.usuario = this.usuario;
    this.modal_impresion_tarjetas_jugador.componentInstance.equipo = equipo;
    for(let i=0;i<jugadores.length;i++){
      jugadores[i].equipo=equipo;
    }
    this.modal_impresion_tarjetas_jugador.componentInstance.impresion_tarjetas_jugador.lista_jugadores =jugadores;
		
		this.modal_impresion_tarjetas_jugador.componentInstance.alTerminar.subscribe((res) => {
			this.modal_impresion_tarjetas_jugador.close();
		});
  }

  async generarTarjetaIdentificacion(jugadores_seleccionados,equipo){
      for(let i=0;i<jugadores_seleccionados.length;i++){
        jugadores_seleccionados[i].equipo=equipo;
      }
      var doc = new PDFDocument({layout:'landscape',margin:0,size:GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA});
      var stream = doc.pipe(blobStream());
      let canvas:any=document.getElementById('qr-code');
      // draw some text
      doc.fontSize(8);
      var yCard=20,items=0,page=1,carnets_por_hoja=8,x_c=45;
      let fecha_inicio=new Date();
      let fecha_fin=new Date();
      fecha_fin.setDate(fecha_inicio.getDate()+365-1);
      for(var i=0;i<jugadores_seleccionados.length;i++){
        doc.switchToPage(page-1);
        items++;
        let campeonato=jugadores_seleccionados[i].equipo.campeonato;
        //Carnet Cara Principal
        doc.rect(x_c, yCard, 227, 142).fill(campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
        let logo=await Util.convertirUrlToBase64Image(this.rest_server+campeonato.logo);
        doc.image(logo,x_c+170,yCard,{width: 50});
        doc.fillColor('white');
        doc.font('Helvetica-Bold',11);
        if(campeonato.configuracion_tarjeta_jugador.campeonato_propio){
          doc.text(this.usuario.empresa.nombre,x_c+10,yCard+10,{width:150});
        }else{
          doc.fillColor(campeonato.configuracion_tarjeta_jugador.datos_color);
          doc.text(campeonato.nombre.toUpperCase(),x_c+10,yCard+10,{width:150});
        }
        doc.font('Helvetica-Bold',10);
  
        doc.fillColor(campeonato.configuracion_tarjeta_jugador.titulo_color_jugador);
        doc.text("TARJETA DE JUGADOR",x_c+30,yCard+42);
        doc.fontSize(8);
        doc.fillColor(campeonato.configuracion_tarjeta_jugador.enunciados_color);
        doc.text("NOMBRE:",x_c+80,yCard+55);
        doc.text("EQUIPO:",x_c+80,yCard+75);
        doc.text("CATEGORIA:",x_c+80,yCard+115);
        doc.text("DISCIPLINA:",x_c+80,yCard+125);
        doc.text("TORNEO:",x_c+80,yCard+95);
        doc.fontSize(8);
        doc.fillColor(campeonato.configuracion_tarjeta_jugador.datos_color);
        let imagen_persona=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+jugadores_seleccionados[i].persona.imagen);
        doc.image(imagen_persona,x_c+9,(yCard+60),{width: 70,height:70});
        doc.text(jugadores_seleccionados[i].persona.nombre_completo.toUpperCase(),x_c+118,yCard+55,{width:100});
        doc.text((equipo?equipo.nombre:jugadores_seleccionados[i].equipo.nombre),x_c+118,yCard+75,{width:180});
        doc.text((equipo?equipo.categoria.nombre:jugadores_seleccionados[i].equipo.categoria.nombre),x_c+130,yCard+115,{width:100});
        doc.text((equipo?equipo.disciplina.nombre:jugadores_seleccionados[i].equipo.disciplina.nombre),x_c+130,yCard+125,{width:100});
        doc.text(campeonato.nombre.toUpperCase(),x_c+118,yCard+95,{width:100});
        doc.rect(x_c+9, yCard+125, 70,5).fill(campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
        doc.fillColor(campeonato.configuracion_tarjeta_jugador.enunciados_color);
        doc.fontSize(7);
        let texto_carnet=jugadores_seleccionados[i].persona.ci?jugadores_seleccionados[i].persona.ci:"";
        let fecha_nacimiento:any=jugadores_seleccionados[i].persona.fecha_nacimiento?new Date(jugadores_seleccionados[i].persona.fecha_nacimiento):null;
        texto_carnet=texto_carnet+(fecha_nacimiento?" "+(fecha_nacimiento.getDate()+"/"+(fecha_nacimiento.getMonth()+1)+"/"+fecha_nacimiento.getFullYear()):"");
        doc.text(texto_carnet,x_c+9,yCard+126,{width:70,align:'center'});
        
        //Carnet Cara trasera
        doc.rect(x_c+228, yCard, 227, 142).fillAndStroke("#ffffff","#000000");
        qr.canvas({
          canvas: canvas,
          value: jugadores_seleccionados[i].codigo
        },function(){console.log("created");});
        var qrImage=canvas.toDataURL('image/png');
        doc.image(qrImage,x_c+360,(yCard+5),{width: 70});	
        //doc.rect(295, yCard+16, 155,85).fillAndStroke("yellow", "#900");
        doc.fillColor('black');
        doc.fontSize(9);
        doc.text("¡Cuanto más dificil es la victoria, mayor es la felicidad de ganar!",x_c+240,yCard+10,{width:125});
        doc.fontSize(6);
        doc.text("-------------------------------------------",x_c+240,yCard+110);
        doc.text("JUGADOR",x_c+240,yCard+120,{width:80,align:"center"});
        doc.text("-------------------------------------------",x_c+340,yCard+110);
        if(campeonato.configuracion_tarjeta_jugador.campeonato_propio){
          doc.text("L.D.C.M. ORGANIZACIÓN",x_c+340,yCard+120,{width:80,align:"center"});
        }else{
          doc.text("ORGANIZACIÓN",x_c+340,yCard+120,{width:80,align:"center"});
        }
  
        if(items%2==0){
          yCard=yCard+143;
          x_c=45;
        }else{
          yCard=yCard;
          x_c=x_c+228*2;
        }
        
        if(items==carnets_por_hoja){
          items=0;
          yCard=20;
          doc.addPage({layout:'landscape',margin:0,size:GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA});
          page++;
        }
      }
      
      doc.end();
  
      stream.on('finish', function () {
        var fileURL = stream.toBlobURL('application/pdf');
        var w = window.open(fileURL, '_blank', 'location=no');
        setTimeout(function () {
          w.print();
        }, 500);
      });
    }

  
}
