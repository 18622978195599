import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { PacientesService } from '../../services/pacientes/pacientes.service';
import { BaseComponent } from 'src/app/models/base-component';
import { RegistroPacienteComponent } from '../registro-paciente/registro-paciente.component';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Util } from 'src/app/utils/utils';
import { ProductosService } from 'src/app/snapquick/services/productos/productos.service';
import { LaboratoriosService } from '../../services/laboratorios/laboratorios.service';
import { GlobalVariable } from 'src/app/global';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { OrderPipe } from 'ngx-order-pipe';

declare const $:any;
declare const aplicarSubidaArchivo:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-registro-laboratorio',
  templateUrl: './registro-laboratorio.component.html',
  styleUrls: ['./registro-laboratorio.component.css']
})
export class RegistroLaboratorioComponent extends BaseComponent implements OnInit {

  laboratorio:any={paciente:{cliente:{}},pruebas:[],solicitud:null,resultado:null};
  dato_paciente_lista:boolean=false;
  pacientes:any[]=[];
  modalRegistroPaciente:NgbModalRef;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  searching = false;
  searchFailed = false;
  util:any=Util;
  producto_busqueda:any;
  sub_grupos:any[]=[];
  prueba:any;

  inhabilitar_paciente:boolean=false;
  inhabilitar_busqueda_item:boolean=false;
  inhabilitar_eliminacion_detalle:boolean=false;
  inhabilitar_fecha:boolean=false;

  modal_seleccion_pruebas:NgbModalRef;
  @ViewChild('modal_seleccion_pruebas')
  private modal_seleccion_pruebas_ref: TemplateRef<any>;

  modal_registro_resultados:NgbModalRef;
  @ViewChild('modal_registro_resultados')
  private modal_registro_resultados_ref: TemplateRef<any>;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public productosService:ProductosService,
    private pacientesService:PacientesService,
    private laboratoriosService:LaboratoriosService,
    private pdfService:PdfService,
  private orderPipe: OrderPipe) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  async ngOnInit(): Promise<void> {
    if(this.laboratorio.id){
      await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_LABORATORIO);
      if(!this.laboratorio.codigo){
        let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_LABORATORIO);
        this.laboratorio.codigo = codigo_nuevo;
      }
      let fecha_hora=new Date(this.laboratorio.fecha_hora);
      this.laboratorio.fecha_texto={
        year: fecha_hora.getFullYear(), 
        month: (fecha_hora.getMonth()+1),
        day:fecha_hora.getDate()
      }
    }else{
      let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_LABORATORIO);
      this.laboratorio.codigo = codigo_nuevo;
      let fecha_hora_actual=new Date();
      this.laboratorio.fecha_texto={
        year: fecha_hora_actual.getFullYear(), 
        month: (fecha_hora_actual.getMonth()+1),
        day:fecha_hora_actual.getDate()
      }
    }
    this.usuario.empresa.imagen_portada=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+this.usuario.empresa.imagen_portada);
    this.laboratorio.id_empresa=this.usuario.id_empresa;
    this.laboratorio.id_sucursal=this.usuario.sucursalesUsuario[0].id_sucursal;
    this.obtenerPacientes();
  }

  ngAfterViewInit(){
    aplicarSubidaArchivo('form-documentos-laboratorio','template-documentos-laboratorio',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:2});
    aplicarSubidaArchivo('form-resultado-laboratorio','template-resultado-laboratorio',this,'subirArchivoResultado','eliminarArchivoResultado',{maximo_tamano_archivo:2});
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  cambiarPresentacionPaciente(){
    this.dato_paciente_lista=!this.dato_paciente_lista;
  }

  obtenerPacientes(){
    this.pacientesService.obtenerPacientesEmpresa(this.usuario.id_empresa).subscribe((res:any)=>{
      this.pacientes=res;
    });
  }

  crearNuevoPaciente(){
		this.modalRegistroPaciente = this.modalService.open(RegistroPacienteComponent, { ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
			this.modalRegistroPaciente.componentInstance.usuario = this.usuario;
			this.modalRegistroPaciente.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						
					}else{
            this.obtenerPacientes();
            this.laboratorio.paciente=res.paciente;
						this.modalRegistroPaciente.close();
					}
				}else{
					this.modalRegistroPaciente.close();
				}
				
			});
  }

  buscarPaciente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.pacientesService.buscarPaciente(this.usuario.id_empresa,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatter = (x: any) => (x.id?(x.cliente.identificacion?x.cliente.identificacion:""):"");

  establecerPaciente(event){
    let paciente=(event.id)?event:event.item;
    this.laboratorio.paciente_texto=paciente.cliente.idetificacion;
    this.laboratorio.paciente=paciente;
  }

  reiniciarPaciente(){
    this.laboratorio.paciente={cliente:{}};
    this.laboratorio.paciente_texto=null;
    $("#paciente_texto").val("");
  }

  modificarPaciente(paciente){
		this.modalRegistroPaciente = this.modalService.open(RegistroPacienteComponent, { ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
			this.modalRegistroPaciente.componentInstance.usuario = this.usuario;
      this.modalRegistroPaciente.componentInstance.paciente = paciente;
			this.modalRegistroPaciente.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						
					}else{
            this.obtenerPacientes();
            this.laboratorio.paciente=res.paciente;
						this.modalRegistroPaciente.close();
					}
				}else{
					this.modalRegistroPaciente.close();
				}
				
			});
  }

  buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterProducto = (x: any) => (x?(x.codigo+'-'+x.nombre+'-'+(x.grupo?x.grupo.nombre:'')+'-'+(x.subgrupo?x.subgrupo.nombre:'')):"");

  establecerProducto($event){
    this.laboratorio.producto=$event.item
  }

  iniciarAdhseionLaboratorio(){
    this.producto_busqueda="";
    this.laboratorio.producto={};
    $("#id_producto").val("");
  }

  agregarPrueba(){
    this.laboratorio.pruebas.push({prueba:this.laboratorio.producto});
    this.iniciarAdhseionLaboratorio();
  }

  agregarPruebaFormulario(prueba){
    if(prueba.seleccionada){
      if(this.laboratorio.pruebas.filter(p => p.prueba.id==prueba.id).length<=0){
        this.laboratorio.pruebas.push({prueba:prueba});
      }
    }
    this.iniciarAdhseionLaboratorio();
  }

  sumarPrecios(){
    let suma=0;
    for(let i=0;i<this.laboratorio.pruebas.length;i++){
      if(!this.laboratorio.pruebas[i].eliminado){
        suma=suma+this.laboratorio.pruebas[i].prueba.precio_unitario;
      }
    }
    return suma.toFixed(2);
  }

  validarLaboratorio(){
    let res=true;
    if(!this.laboratorio.paciente.cliente.id){
      res=res && false;
      this.toastr.error("Debe especificar el nombre del paciente!");
    }
    if(this.laboratorio.fecha_texto && (!this.laboratorio.fecha_texto.year || !this.laboratorio.fecha_texto.month || !this.laboratorio.fecha_texto.day)){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de rregistro completa!");
    }
    if(this.laboratorio.pruebas.length==0){
      res=res && false;
      this.toastr.error("Debe especificar al menos una prueba de laboratorio!");
    }
    return res;
  }

  async guardarLaboratorio(){
    if(this.validarLaboratorio()){
      this.blockUI.start();
      this.laboratorio.fecha_hora=this.laboratorio.fecha_texto?Util.convertirObjetoAfecha(this.laboratorio.fecha_texto):null;
      if (this.laboratorio.id) {
        this.laboratoriosService.actualizarLaboratorio(this.laboratorio).subscribe((res:any) => {
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      } else {
        this.laboratorio.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_LABORATORIO):this.laboratorio.codigo;
        this.laboratoriosService.guardarLaboratorio(this.laboratorio).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_LABORATORIO);
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      }
    }
  }

  eliminarPrueba(prueba){
    prueba.eliminado=true;
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.laboratorio.solicitud=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.laboratorio.solicitud=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  subirArchivoResultado(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.laboratorio.resultado=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivoResultado(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.laboratorio.resultado=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  obtenerPruebasLaboratorio(){
    this.blockUI.start();
    this.productosService.obtenerPruebasLaboratorio(this.usuario.id_empresa).subscribe((res:any)=>{
      this.sub_grupos=res;
      this.blockUI.stop();
      this.abrirModalSeleccionPruebas();
    });
  }

  abrirModalSeleccionPruebas(){
    this.modal_seleccion_pruebas=this.modalService.open(this.modal_seleccion_pruebas_ref, {scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.modal_seleccion_pruebas.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async abrirModalRegistroResultados(prueba){
    this.prueba=prueba;

    if(!this.prueba.resultado){
      this.blockUI.start();
      this.prueba.resultado=await this.laboratoriosService.obtenerPruebasResultadoProducto(prueba.prueba.id).toPromise();
      this.blockUI.stop();
    }

    this.modal_registro_resultados=this.modalService.open(this.modal_registro_resultados_ref, {windowClass:'registro-resultado',scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_registro_resultados.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  guardarResultadosPrueba(){
    this.blockUI.start();
    this.laboratoriosService.guardarResultadosExamen(this.prueba).subscribe((res:any) => {
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
      this.modal_registro_resultados.close();
    });
  }

  async generarPdfResultadosPrueba(prueba){
    prueba.selected=true;
    this.generarPdfResultadosSeleccionados([prueba]);
  }

  async generarPdfResultadosSeleccionados(pruebas){
    var doc = new PDFDocument({margin:10,size:GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA});
    var stream = doc.pipe(blobStream());
    for(var i=0;i<pruebas.length;i++){
      if(pruebas[i].selected){
        this.generarPdfResultados(doc,pruebas[i]);
        if(i+1<pruebas.length){
          doc.addPage({margin:10,size:GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA});
        }
      }
    }

    doc.end();
    stream.on('finish', function () {
      var fileURL = stream.toBlobURL('application/pdf');
      var w = window.open(fileURL, '_blank', 'location=no');
      setTimeout(function () {
        w.print();
      }, 500);
    });
  }

  async generarPdfResultados(doc,prueba){
    if(!doc){
      doc = new PDFDocument({margin:10,size:GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA});
    }
    var y = 140,itemsPorPagina=14, pagina = 1, totalPaginas = 1,fecha_reporte=new Date();
    this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"RESULTADOS",pagina,totalPaginas,this.usuario,
			this.usuario.empresa.imagen_portada,"REUMALAB","Laboratorio de Analisis Clinico","Av. Papa Paulo # 1151",
			("71759992")+"-"+
		("70745592"),"e-mail: laboratorio.reumalab@gmail.com",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{imprimir_usuario:false});
    this.dibujarCabeceraPdfResultados(doc);
    doc.font('Helvetica-Bold',12);
    doc.font('Helvetica',12);
    doc.font('Helvetica-Bold',12);
    doc.fillColor("#0f34a3");
    doc.text(prueba.prueba.nombre.toUpperCase(),0,110,{align:'center',underline:true});
    //doc.text(this.historia_paciente.descripcion,120,130);
    doc.fillColor("black");
    doc.font('Helvetica',10);
    doc.text("Analisis",80,y-15);
    doc.text("Dentro de Rango",240,y-15);
    doc.text("Fuera de Rango",340,y-15);
    doc.text("Rango de Referencia",450,y-15);
    let resultados=[];
    prueba.resultado=this.orderPipe.transform(prueba.resultado, 'orden',false);
    let id_clasificaciones = prueba.resultado.filter((prueba, i, arr) => arr.findIndex(t => t.id_clasificacion === prueba.id_clasificacion) === i);

		for(var i=0;i<id_clasificaciones.length;i++){
			var items=prueba.resultado.filter(e => e.id_clasificacion == id_clasificaciones[i].id_clasificacion);
			var resultado={
				id:items[0].id_clasificacion,
        clasificacion:items[0].clasificacion?items[0].clasificacion.nombre:"",
        pruebas:items
			}
			resultados.push(resultado);
		}
    for(let i=0;i<resultados.length;i++){
      doc.font('Helvetica-Bold',10);
      doc.fillColor("#0f34a3");
      doc.text(resultados[i].clasificacion.toUpperCase(),60,y,{underline:true});
      doc.font('Helvetica',10);
      for(let j=0;j<resultados[i].pruebas.length;j++){
        doc.text(resultados[i].pruebas[j].resultado_etiqueta,80,y+15);
        doc.fillColor("black");
        doc.text(resultados[i].pruebas[j].dentro_rango?resultados[i].pruebas[j].dentro_rango:'',250,y+15,{width:330});
        doc.text(resultados[i].pruebas[j].fuera_rango?resultados[i].pruebas[j].fuera_rango:'',350,y+15,{width:330});
        doc.fillColor("#0f34a3");
        doc.text(resultados[i].pruebas[j].rango_referencia?resultados[i].pruebas[j].rango_referencia:'',430,y+15);
        if(resultados[i].pruebas[j].observacion){
          doc.text("Observación:",80,y+24);
          doc.fillColor("black");
          doc.text(resultados[i].pruebas[j].observacion,140,y+24);
          doc.fillColor("#0f34a3");
          y=y+20;
        }else{
          y=y+15;
        }
      }
      y=y+20;
    }
  }

  dibujarCabeceraPdfResultados(doc){
    let texto=(this.laboratorio.paciente.cliente.persona.nombres+" "+(this.laboratorio.paciente.cliente.persona.apellido_paterno?this.laboratorio.paciente.cliente.persona.apellido_paterno:"")+" "+(this.laboratorio.paciente.cliente.persona.apellido_materno?this.laboratorio.paciente.cliente.persona.apellido_materno:""));
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(360,50,230,45,5).stroke();
		doc.text("PACIENTE: "+texto,370,55);
		doc.text("EDAD : "+Util.calcularEdad(this.laboratorio.paciente.cliente.persona.fecha_nacimiento)+" AÑOS",370,65);
    doc.text("FECHA : "+(this.laboratorio.fecha_texto.day+"/"+this.laboratorio.fecha_texto.month+"/"+this.laboratorio.fecha_texto.year),370,75);
    doc.text("CÓDIGO : "+(this.laboratorio.codigo),370,85);
	}

}
