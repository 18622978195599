import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { MensajesEmpresaService } from 'src/app/marketing/services/mensajes-empresa/mensajes-empresa.service';
import { BaseComponent } from 'src/app/models/base-component';
import { CursosService } from 'src/app/school/services/cursos/cursos.service';
import { EstudiantesService } from 'src/app/school/services/estudiantes/estudiantes.service';
import { Util } from 'src/app/utils/utils';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { ClientesService } from 'src/app/snapquick/services/clientes/clientes.service';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-vencimiento-pagos-estudiantes',
  templateUrl: './vencimiento-pagos-estudiantes.component.html',
  styleUrls: ['./vencimiento-pagos-estudiantes.component.css']
})
export class VencimientoPagosEstudiantesComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  usuario:any;
  estado:boolean;
  titulo_ventana:string="Lista de estudiantes con pagos vencidos";
  pagos_estudiantes_todos:any[]=[];
  pagos_estudiantes_filtrados:any[]=[];

  constructor(
    public persistenciaService:PersistenciaService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public pdfService:PdfService,
    public usuarioService:UsuarioService,
    public estudiantesService:EstudiantesService,
    private mensajesEmpresaService:MensajesEmpresaService,
    private socket: Socket,
    public cursosService:CursosService,
    public clientesService:ClientesService,
    public conceptoIngresoService:ConceptosIngresosService
  ) {
    super(persistenciaService,modalService,generalService,usuarioService)
   }

  async ngOnInit(): Promise<void> {
    this.filter={
      gestiones:[GlobalVariable.Dictionary.SELECCION_TODOS],
      gestion:GlobalVariable.Dictionary.SELECCION_TODOS,
      cursos:[GlobalVariable.Dictionary.SELECCION_TODOS],
      curso:GlobalVariable.Dictionary.SELECCION_TODOS,
      conceptoPagos:[{id:0,concepto:GlobalVariable.Dictionary.SELECCION_TODOS}],
      conceptoPagosTodos:[{id:0,concepto:GlobalVariable.Dictionary.SELECCION_TODOS}],
      conceptoPago:{id:0,concepto:GlobalVariable.Dictionary.SELECCION_TODOS},
      sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal:GlobalVariable.Dictionary.SELECCION_TODOS
    }
    await this.obtenerPagosEstudiantesVencidos();
    this.obtenerGestiones();
    this.obtenerCursos();
    this.obtenerConceptoPago();
    if(!this.estado){
      this.titulo_ventana="Lista de estudiantes anulados con pagos vencidos";
    }
  }

  obtenerGestiones(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).subscribe((entidad:any)=>{
      this.filter.gestiones=this.filter.gestiones.concat(entidad.clases);
    });
  }

  async obtenerConceptoPago(){
    let escuela:any=await this.generalService.obtenerEscuela(this.usuario.id_empresa).toPromise();
    this.conceptoIngresoService.obtenerListaConceptosPagosColegio({
      filter:{
        escuela:escuela,
        gestion:{id:0}
      },
      currentPage:1,
      itemsPerPage:0,
      search:0,
      column:"createdAt",
      direction:"asc"
    }).subscribe((dato:any) => {
      this.filter.conceptoPagos=this.filter.conceptoPagos.concat(dato.conceptos_pagos);
      this.filter.conceptoPagosTodos=this.filter.conceptoPagos;
     });
  }

  async obtenerCursos(){
    let cursos:any=await this.cursosService.obtenerCursosColegio(this.usuario.id_empresa).toPromise();
    this.filter.cursos=this.filter.cursos.concat(cursos);
  }

  async obtenerPagosEstudiantesVencidos(){
    this.blockUI.start();
    let pagos_estudiantes:any=this.estado?await this.estudiantesService.obtenerListaPagosEstudiantesVencidos(this.usuario.id_empresa).toPromise():await this.estudiantesService.obtenerListaPagosEstudiantesVencidosAnulados(this.usuario.id_empresa).toPromise();
    this.pagos_estudiantes_todos = pagos_estudiantes;
    this.cambiarFiltro();
    this.blockUI.stop();
  }

  cerrarComponente(){
    this.alTerminar.emit();
   }

   imprimirListaVencimientoPagosEstudiantes () {
    this.blockUI.start();
    var doc = new PDFDocument({ size: 'letter', margin: 10 });
    var stream = doc.pipe(blobStream());
    // draw some text
    var titulo = "REPORTE DE PAGOS VENCIDOS ESTUDIANTES";
    var fecha_reporte = new Date();

    var y = 140, itemsPorPagina = 30, items = 0, pagina = 1, totalPaginas = Math.ceil(this.pagos_estudiantes_todos.length / itemsPorPagina);
    let suma_pagado=0,suma_saldo=0;
    this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
      this.usuario.empresa.imagen,this.usuario.empresa.nombre.toUpperCase(),"CENTRAL",this.usuario.empresa.direccion.toUpperCase(),
      (this.usuario.empresa.telefono1 != null ? this.usuario.empresa.telefono1 : "") +
      (this.usuario.empresa.telefono2 != null ? "-" + this.usuario.empresa.telefono2 : ""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
    fecha_reporte,{imprimir_usuario:false});
    this.dibujarCabeceraImpresionReportePagosVencidos(doc);
    for (var i = 0; i < this.pagos_estudiantes_filtrados.length && items <= itemsPorPagina; i++) {

      doc.rect(30, y - 10, 555, 20).stroke();
      doc.font('Helvetica', 8);
      doc.text(i+1, 35, y);
      if (this.pagos_estudiantes_filtrados[i].codigo_estudiante == null) {
        doc.text("", 50, y, { width: 50 });
      } else {
        doc.text(this.pagos_estudiantes_filtrados[i].codigo_estudiante, 50, y, { width: 50 });
      }
      doc.text(this.pagos_estudiantes_filtrados[i].estudiante, 100, y);
      doc.text(this.pagos_estudiantes_filtrados[i].concepto, 290, y-7,{width:100});
      doc.text(this.pagos_estudiantes_filtrados[i].cuota, 400, y);
      doc.text(this.pagos_estudiantes_filtrados[i].importe, 420, y,{width:30,align:'right'});
      doc.text(this.pagos_estudiantes_filtrados[i].descuento, 445, y,{width:20,align:'right'});
      doc.text(this.pagos_estudiantes_filtrados[i].pagado, 468, y,{width:30,align:'right'});
      doc.text(this.pagos_estudiantes_filtrados[i].saldo, 500, y,{width:29,align:'right'});
      this.pagos_estudiantes_filtrados[i].fecha_vencimiento = new Date(this.pagos_estudiantes_filtrados[i].fecha_vencimiento);
      doc.text(this.pagos_estudiantes_filtrados[i].fecha_vencimiento.getDate() + "/" + (this.pagos_estudiantes_filtrados[i].fecha_vencimiento.getMonth() + 1) + "/" + this.pagos_estudiantes_filtrados[i].fecha_vencimiento.getFullYear(), 535, y, { width: 50 });
      y = y + 20;
      items++;
      suma_pagado=suma_pagado+this.pagos_estudiantes_filtrados[i].pagado;
      suma_saldo=suma_saldo+this.pagos_estudiantes_filtrados[i].saldo;
      if (items == itemsPorPagina) {
        doc.addPage({ margin: 0, bufferPages: true });
        y = 140;
        items = 0;
        pagina = pagina + 1;

        this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
          this.usuario.empresa.imagen,this.usuario.empresa.nombre.toUpperCase(),"CENTRAL",this.usuario.empresa.direccion.toUpperCase(),
          (this.usuario.empresa.telefono1 != null ? this.usuario.empresa.telefono1 : "") +
          (this.usuario.empresa.telefono2 != null ? "-" + this.usuario.empresa.telefono2 : ""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
        fecha_reporte,{imprimir_usuario:false});
        this.dibujarCabeceraImpresionReportePagosVencidos(doc);
        doc.font('Helvetica', 8);
      }
    }
    doc.text("TOTALES", 415, y, { width: 40 });
    doc.text(Math.round(suma_pagado*100)/100, 460, y, { width: 35 });
    doc.text(Math.round(suma_saldo*100)/100, 500, y, { width: 35 });
    doc.end();
    stream.on('finish', function () {
      var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL, '_blank', 'location=no');
    });
    this.blockUI.stop();

  }

  dibujarCabeceraImpresionReportePagosVencidos (doc) {
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(30,105,555,25,5).fillOpacity(0.8).stroke();
		doc.text("Nº", 35, 115);
    doc.text("CÓDIGO", 50, 115);
    doc.text("ESTUDIANTE", 100, 115);
    doc.text("CONCEPTO DE PAGO", 290, 115);
    doc.text("CUOTA", 390, 115);
    doc.text("IMP.", 425, 115);
    doc.text("DESC.", 445, 115);
    doc.text("PAG.", 475, 115);
    doc.text("SALDO", 500, 115);
    doc.text("FECHA DE VENCIMIENTO", 530, 110);
	}

  abrirPopupConfirmacionEliminacion(concepto_pago_estudiante){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el pago?";
		this.popupConfirmacion.componentInstance.data = concepto_pago_estudiante;

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarEstudianteConceptoPago($e.data);
			}
			this.popupConfirmacion.close();
        });
  }

  eliminarEstudianteConceptoPago(concepto_pago_estudiante){
    this.blockUI.start();
    let promesa;
    if(this.usuario.empresa.usar_ingresos_economicos_instituto){
      promesa=this.estudiantesService.eliminarConceptoPagoEstudiante(concepto_pago_estudiante.id);
    }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
      promesa=this.estudiantesService.eliminarConceptoPagoEstudianteColegio({id:concepto_pago_estudiante.id,id_empresa:this.usuario.id_empresa});
    }
    promesa.subscribe((dato:any) => {
      this.obtenerPagosEstudiantesVencidos();
      this.toastr.success(dato.mensaje);
      this.blockUI.stop();
    });
  }

  abrirPopupConfirmacionEnvioWhatsapp(pago_estudiante){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar el recordatorio por Whatsapp?";
		this.popupConfirmacion.componentInstance.data = pago_estudiante;

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.enviarRecordatorioWhatsapp($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	async enviarRecordatorioWhatsapp(pago_estudiante){
		if(pago_estudiante.telefono_movil){
      this.blockUI.start();
			let fecha_vencimiento=new Date(pago_estudiante.fecha_vencimiento);
      let cliente={
        identificacion:pago_estudiante.estudiante,
        telefono1:pago_estudiante.telefono_movil,
        fecha_vencimiento:fecha_vencimiento,
        codigo_empresa:this.usuario.empresa.codigo,
        codigo_estudiante:pago_estudiante.codigo_estudiante
      }
      let datos={
        id_empresa:this.usuario.id_empresa,
        id_sucursal:pago_estudiante.id_sucursal,
        cliente:cliente
      };
      this.clientesService.construirEnviarMensajeRecordatorioPagoWhatsapp(datos).subscribe((res:any)=>{
        this.blockUI.stop();
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.toastr.success(res.mensaje);
        }
      });
		}else{
			this.toastr.error("Debe agregar un numero de whatsapp para el cliente");
		}
	}

  cambiarFiltro(){
    this.filter.conceptoPagos=(this.filter.gestion.id==0)?this.filter.conceptoPagosTodos:[{id:0,concepto:GlobalVariable.Dictionary.SELECCION_TODOS}].concat(this.filter.conceptoPagosTodos.filter(cp => cp.id_gestion==this.filter.gestion.id));
    this.pagos_estudiantes_filtrados=this.pagos_estudiantes_todos.filter(concepto_estudiante => { if(this.filter.sucursal.id==0){return true}else{return (this.filter.sucursal.id==concepto_estudiante.id_sucursal)} });
    this.pagos_estudiantes_filtrados=this.pagos_estudiantes_filtrados.filter(concepto_estudiante => { if(this.filter.gestion.id==0){return true}else{return (this.filter.gestion.id==concepto_estudiante.id_gestion)} });
    this.pagos_estudiantes_filtrados=this.pagos_estudiantes_filtrados.filter(concepto_estudiante => { if(this.filter.curso.id==0){return true}else{return (this.filter.curso.nombre==concepto_estudiante.curso)} });
    this.pagos_estudiantes_filtrados = this.pagos_estudiantes_filtrados.filter(concepto_estudiante => {
      if(this.filter.conceptoPago.id == 0){
        return true;
      }else{
        return (this.filter.conceptoPago.concepto.nombre==concepto_estudiante.concepto);
      }
    });
  }

  generarExcelVencimientoPagosEstudiantes(){
    this.blockUI.start();
    let nombre_archivo='REPORTE DE PAGOS VENCIDOS ESTUDIANTES GESTION-'+this.filter.gestion.nombre+' CURSO-'+this.filter.curso.nombre+'.xlsx';
		let data = [["","REPORTE DE PAGOS VENCIDOS ESTUDIANTES"],
					["",""],
					["","GESTIÓN:"+this.filter.gestion.nombre],
					["","CURSO:"+this.filter.curso.nombre]];
    let cabecera=[],totalColumns=[],columsWidth=[];
		cabecera.push("Nº");
		cabecera.push("GESTIÓN");
    cabecera.push("CURSO");
    cabecera.push("CÓDIGO");
    cabecera.push("ESTUDIANTE");
    cabecera.push("TELÉFONO-CELULAR");
    cabecera.push("CONCEPTO DE PAGO");
    cabecera.push("CUOTA");
    cabecera.push("IMPORTE");
    cabecera.push("DESCUENTO");
    cabecera.push("PAGADO");
    cabecera.push("SALDO");
    cabecera.push("FECHA DE VENCIMIENTO");
    data.push(cabecera);
		for(let i=0;i<this.pagos_estudiantes_filtrados.length;i++){
      let datos=[];
			datos.push(i+1);
      datos.push(this.pagos_estudiantes_filtrados[i].gestion);
      datos.push(this.pagos_estudiantes_filtrados[i].curso);
      datos.push(this.pagos_estudiantes_filtrados[i].codigo_estudiante);
      datos.push(this.pagos_estudiantes_filtrados[i].estudiante);
      datos.push((this.pagos_estudiantes_filtrados[i].telefono?this.pagos_estudiantes_filtrados[i].telefono+"-":"")+(this.pagos_estudiantes_filtrados[i].telefono_movil?this.pagos_estudiantes_filtrados[i].telefono_movil:""));
      datos.push(this.pagos_estudiantes_filtrados[i].concepto);
      datos.push(this.pagos_estudiantes_filtrados[i].cuota);
      datos.push(this.pagos_estudiantes_filtrados[i].importe);
      datos.push(this.pagos_estudiantes_filtrados[i].descuento);
      datos.push(this.pagos_estudiantes_filtrados[i].pagado);
      datos.push(this.pagos_estudiantes_filtrados[i].saldo);
      let fecha_vencimiento=new Date(this.pagos_estudiantes_filtrados[i].fecha_vencimiento);
      datos.push((fecha_vencimiento.getDate()+"/"+(fecha_vencimiento.getMonth()+1)+"/"+fecha_vencimiento.getFullYear()));
      data.push(datos);
		}

		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("REPORTE");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol1 = worksheet.getColumn(1);
		dobCol1.width = 5;
    const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 10;
    const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 25;
		const dobCol4 = worksheet.getColumn(4);
		dobCol4.width = 10;
    const dobCol5 = worksheet.getColumn(5);
		dobCol5.width = 30;
    const dobCol6 = worksheet.getColumn(6);
		dobCol6.width = 20;
    const dobCol14 = worksheet.getColumn(7);
		dobCol14.width = 25;
    const dobCol19 = worksheet.getColumn(8);
		dobCol19.width = 10;
    const dobCol9 = worksheet.getColumn(9);
		dobCol9.width = 10;
    const dobCol10 = worksheet.getColumn(10);
		dobCol10.width = 10;
    const dobCol11 = worksheet.getColumn(11);
		dobCol11.width = 10;
    const dobCol12 = worksheet.getColumn(12);
		dobCol12.width = 10;
    const dobCol13 = worksheet.getColumn(13);
		dobCol13.width = 15;


		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		  fs.saveAs(blob, nombre_archivo);
		})
		this.blockUI.stop();
  }

}
