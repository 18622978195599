<div class="modal-header card-header">
    <div class="col-4">
        <div class="row">
            <div class="col-6">
                <span class="text-blue text-125">Datos Venta</span>
            </div>
            <div class="col-6">
                <button  id="venta_guardar" type="button" class="btn btn-primary" (click)="guardar()">
                    <i class="fa fa-save align-top bigger-125"></i>
                </button>&nbsp;
                <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="cerrarComponente()" >
                    <i class="fa fa-times red2 align-top bigger-125"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-8">
        <div class="row">
            <div class="col-12">
                <span class="text-blue text-125">Búsqueda Item</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="row">
            <div class="col-12">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <div class="row">
                                <div class="col-2 col-md-2 col-sm-2 col-xs-2">
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <label for="fefin">Fecha </label>&nbsp;
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <div class="input-group">
                                            <input [disabled]="inhabilitar_fecha" required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                                    name="dp" [(ngModel)]="venta.fechaTexto" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button [disabled]="inhabilitar_fecha" class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div *ngIf="venta.usuario.empresa.usar_sucursales" class="col-md-1 col-sm-2 col-xs-12 pl-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <label>Sucursal</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <select [compareWith]="compararObjectos" id="venta_sucursal" name="sucursal" [disabled]="inhabilitar_sucursal" class="form-control" [(ngModel)]="venta.sucursal" (change)="establecerSucursal(venta.sucursal)">
                                            <option [ngValue]="sucursal" *ngFor="let sucursal of venta.sucursales">{{sucursal.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-xs-2 pl-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0" >
                                        <label>Estudiante</label>&nbsp;
                                        <a class="card-toolbar-btn text-danger-m1" [disabled]="inhabilitar_cliente" href="javascript:void(0)" *ngIf="venta.cliente.id" (click)="reiniciarCliente()">
                                            <i class="fa fa-trash bigger-130 red2"></i>
                                        </a>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <input required id="venta_razon_social" name="venta_razon_social" [disabled]="inhabilitar_cliente || venta.cliente.id" 
                                            [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerCliente($event)"
                                            type="text" class="form-control" [(ngModel)]="venta.estudiante" 
                                            [ngbTypeahead]="buscarCliente" placeholder="Buscar estudiante" 
                                            (keypress)="enfocarElemento($event,'venta_id_producto')" autocomplete="off"
                                            />

                                        <ng-template #rt let-r="result" let-t="term">
                                            <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                            <ngb-highlight [result]="r.codigo+' - '+r.apellido_paterno+' '+r.apellido_materno+' '+r.nombres" [term]="t"></ngb-highlight>
                                          </ng-template>
                                    </div>
                                </div>
                                <div class="col-1 col-md-2 col-sm-2 col-xs-2 pl-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0" >
                                        <label>Razon Social</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <input class="form-control" autocomplete="off" name="razon_social" type="text" [(ngModel)]="estudiante.persona.cliente.razon_social" placeholder="Razon Social">
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-xs-2 pl-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0" >
                                        <label>Observación</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <input class="form-control" autocomplete="off" id="venta_observacion" name="observacion" type="text" [(ngModel)]="venta.observacion" placeholder="Observacion">
                                    </div>
                                </div>
                                <div class="col-md-1 col-sm-12 col-xs-12 pl-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <label>Tipo Pago</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <select [compareWith]="compararObjectos" id="venta_pago" name="pago" [disabled]="inhabilitar_tipo_pago" class="form-control" [(ngModel)]="venta.tipoPago" (change)="cambiarTipoPago()">
                                            <option [ngValue]="tipoPago" *ngFor="let tipoPago of tiposPago">{{tipoPago.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-2 col-md-1 col-sm-2 col-xs-2 pl-0" *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CONT">
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <label >Pagado (Bs):</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <input class="form-control" id="venta_pagado" name="pagado" required type="number" step="0.01" min="{{pagoMinimo}}" [(ngModel)]="venta.pagado" (keyup)="calcularCambio()"/>
                                    </div>
                                </div>
                                <div class="pl-0 col-2 col-md-1 col-sm-2 col-xs-2" *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CONT">
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0" >
                                        <label>Cambio (Bs):</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <input class="form-control" disabled type="number" step="0.01" [(ngModel)]="venta.cambio"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-5">
                <div class="widget-box">
                    <div class="card bgc-dark">
                        <div class="card-header card-header-sm">
                            <h5 class="card-title text-110 text-white font-light pt-1">Cobros Pendientes</h5>
                        </div>
                        <div tabindex="0"  class="focus-data card-body bg-white p-0">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th>
                                            <label>Concepto de Pago</label>
                                        </th>
                                        <th>
                                            <label>Cuota</label>
                                        </th>
                                        <th>
                                            <label>Importe (Bs)</label>
                                        </th>
                                        <th>
                                            <label>Desc. (Bs)</label>
                                        </th>
                                        <th>
                                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                            <div class="fht-cell"></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let servicio_pendiente of servicios_pendientes; let i=index; ">
                                        <tr  *ngIf="!servicio_pendiente.eliminado">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td *ngIf="usuario.empresa.usar_ingresos_economicos_instituto">
                                                {{servicio_pendiente.concepto_pago.concepto.nombre}}<br>
                                                {{servicio_pendiente.concepto_pago.carrera.nombre}}<br>
                                                {{servicio_pendiente.concepto_pago.gestion?servicio_pendiente.concepto_pago.gestion.nombre:''}}<br>
                                                {{servicio_pendiente.concepto_pago.semestre?servicio_pendiente.concepto_pago.semestre.nombre:''}}<br>
                                            </td>
                                            <td *ngIf="usuario.empresa.usar_ingresos_economicos_colegio">
                                                {{servicio_pendiente.concepto_pago.concepto.nombre}}<br>
                                                {{servicio_pendiente.concepto_pago.gestion?servicio_pendiente.concepto_pago.gestion.nombre:''}}<br>
                                            </td>
                                            <td>
                                                {{servicio_pendiente.cuota}}
                                            </td>
                                            <td>
                                                {{servicio_pendiente.importe}}
                                            </td>
                                            <td>
                                                {{servicio_pendiente.descuento}}
                                            </td>
                                            <td>
                                                <button (click)="agregarServicioPendiente(servicio_pendiente)" class="btn btn-primary btn-sm"><i class="fa fa-plus bigger-130"></i></button>&nbsp;<br>&nbsp;<br>
                                                <button (click)="abrirPopupConfirmacionEliminacion(servicio_pendiente)" class="btn btn-danger btn-sm"><i class="fa fa-trash bigger-130"></i></button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            <b>TOTALES</b>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            {{sumarServiciosPendientes()}}
                                        </td>
                                        <td >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-7">
                <div class="widget-box">
                    <div class="card bgc-dark" *ngIf="venta.detallesVenta.length>0">
                        <div class="card-header card-header-sm">
                            <h5 class="card-title text-110 text-white font-light pt-1">DETALLE DE VENTA</h5>
                        </div>
                        <div class="card-body bg-white p-0" *ngIf="venta.detallesVenta.length>0">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th>
                                            <label>Concepto de Pago</label>
                                        </th>
                                        <th>
                                            <label>Cuota</label>
                                        </th>
                                        <th>
                                            <label>Importe (Bs)</label>
                                        </th>
                                        <th>
                                            <label>Desc. (Bs)</label>
                                        </th>
                                        <th>
                                            <label>Total (Bs)</label>
                                        </th>
                                        <th>
                                            <label>Pagado (Bs)</label>
                                        </th>
                                        <th>
                                            <label>Saldo (Bs)</label>
                                        </th>
                                        <th>
                                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                            <div class="fht-cell"></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let detalleVenta of venta.detallesVenta | orderBy:['id_estudiante_concepto_pago','cuota']; let i=index;">
                                        <tr  *ngIf="!detalleVenta.eliminado">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td *ngIf="usuario.empresa.usar_ingresos_economicos_instituto">
                                                {{detalleVenta.concepto_pago.concepto.nombre}}<br>
                                                {{detalleVenta.concepto_pago.carrera.nombre}}<br>
                                                {{detalleVenta.concepto_pago.gestion?detalleVenta.concepto_pago.gestion.nombre:''}}<br>
                                                {{detalleVenta.concepto_pago.semestre?detalleVenta.concepto_pago.semestre.nombre:''}}<br>
                                            </td>
                                            <td *ngIf="usuario.empresa.usar_ingresos_economicos_colegio">
                                                {{detalleVenta.concepto_pago.concepto.nombre}}<br>
                                                {{detalleVenta.concepto_pago.gestion?detalleVenta.concepto_pago.gestion.nombre:''}}<br>
                                            </td>
                                            <td>
                                                {{detalleVenta.cuota}}
                                            </td>
                                            <td>
                                                {{detalleVenta.importe}}
                                            </td>
                                            <td class="input-cal">
                                                <input class="form-control" name="descuento_{{detalleVenta.concepto_pago.id}}" required type="number" step="0.01" min="0" [(ngModel)]="detalleVenta.descuento" (keyup)="calcularDescuentoDetalleVenta(detalleVenta)"/>
                                            </td>
                                            <td>
                                                {{detalleVenta.total}}
                                            </td>
                                            <td class="input-cal">
                                                <input class="form-control" name="pagado_{{detalleVenta.concepto_pago.id}}" required type="number" step="0.01" min="0.10" [(ngModel)]="detalleVenta.pagado" (keyup)="establecerPagadoDetalleVenta(detalleVenta)"/>
                                            </td>
                                            <td>
                                                {{detalleVenta.saldo}}
                                            </td>
                                            <td>
                                                <button (click)="eliminarDetalleVenta(detalleVenta)" class="btn btn-danger btn-sm"><i class="fa fa-trash bigger-130"></i></button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            <b>TOTALES</b>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            {{venta.importe}}
                                        </td>
                                        <td>
                                            {{venta.descuento}}
                                        </td>
                                        <td>
                                            {{venta.total}}
                                        </td>
                                        <td>
                                            <i class="eva eva-paper-plane-outline text-160 radius-round bgc-success-l4 text-success p-2 border-2 brc-success-m3">{{calcularPagado()}}</i>
                                        </td>
                                        <td >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>