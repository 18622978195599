<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Reporte Curso</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
          <i class="fa fa-times red2 text-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-md-2 col-sm-12" *ngIf="usuario.empresa.usar_sucursales">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <label for="feini">Sucursal </label>&nbsp;
              </div>  
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <select [compareWith]="compararObjectos" name="sucursal" class="form-control" [(ngModel)]="filter.sucursal">
                  <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                </select>
              </div>  
            </div>
          </div>  
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                  <label for="feini">Gestión </label>&nbsp;
              </div>  
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <select [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="filter.gestion">
                  <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones | filterBy:{habilitado:true}">{{gestion.nombre}}</option>
                </select>
              </div>  
            </div>
          </div>
        </div>
        <div class="col-sm-12" [ngClass]="{'col-md-3':!usuario.empresa.usar_sucursales,'col-md-2':usuario.empresa.usar_sucursales}">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <label for="feini">Nivel </label>&nbsp;
              </div>  
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <select (change)="obtenerCursosPorNivel(filter.nivel.id)" [compareWith]="compararObjectos" name="nivel" class="form-control" [(ngModel)]="filter.nivel">
                  <option [ngValue]="nivel" *ngFor="let nivel of filter.niveles">{{nivel.nombre}}</option>
                </select>
              </div>  
            </div>
          </div>  
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <label for="feini">Curso </label>&nbsp;
              </div>  
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <select [compareWith]="compararObjectos" name="curso" class="form-control" [(ngModel)]="filter.curso">
                  <option [ngValue]="null" [selected]="true">Seleccionar curso</option>
                    <option [ngValue]="curso" *ngFor="let curso of filter.cursos">{{curso.nombre}}</option>
                </select>
              </div>  
            </div>
          </div>   
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <label for="feini">Estado </label>&nbsp;
              </div>  
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <select [compareWith]="compararObjectos" class="form-control" required name="estado" [(ngModel)]="filter.estado">
                  <option [ngValue]="estado" *ngFor="let estado of filter.estados">{{estado.nombre}}</option>
                </select>
              </div>  
            </div>
          </div>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-2 p-1">
          <button (click)="buscarCurso()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
            <i class="d-block h-4 fa fa fa-search text-150"></i>
          </button>&nbsp;
          <button *ngIf="estudiantes.length>0" [disabled]="!filter.curso" (click)="abrirRegistroInscripcionMasiva()" class="btn btn-xs btn-app btn-light my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
            <i class="d-block h-4 fas fa-mars-double text-150"></i>
          </button>
        </div>
    </div>
    <hr>
    <div class="row" *ngIf="estudiantes.length>0" >
      <div class="col-2">
        <div class="row">
            <div class="col-12 text-center">
                <h5 class="page-title text-primary-d2 text-120">
                    Plantillas
                </h5>
            </div>
        </div>  
        <div class="row">
          <div class="col-6 text-center">
            <button (click)="generarPdfAsistencia()" class="btn btn-lg btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Asistencia">
              <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
            </button>
          </div>
          <div class="col-6 text-center">
            <button (click)="generarPdfCalificaciones()" class="btn btn-lg btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Calificaciones">
              <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
            <div class="col-12 text-center">
                <h5 class="page-title text-primary-d2 text-120">
                    Datos de estudiantes
                </h5>
            </div>
        </div>  
        <div class="row">
          <div class="col-6 text-center">
            <button (click)="descargarExcelDatosEstudiantes()" class="btn btn-lg btn-success my-1 btn-" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
              <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
            </button>
          </div>
          <div class="col-6 text-center">
            <button (click)="generarPdfDatosEstudiantes()" class="btn btn-lg btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Datos">
              <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-2" *ngIf="usuario.empresa.usar_ingresos_economicos_colegio">
        <div class="row">
            <div class="col-12 text-center">
                <h5 class="page-title text-primary-d2 text-120">
                    Reporte de Pagos
                </h5>
            </div>
            <div class="col-12 text-center reporte-pagos">
              <ngx-bootstrap-multiselect *ngIf="tipo_concepto_pago" [options]="conceptos_pagos" [(ngModel)]="filter.conceptos"></ngx-bootstrap-multiselect>
          </div>
        </div>  
        <div class="row">
          <div class="col-4 text-center">
            <button (click)="descargarExcelEstudiantesConceptosPago()" class="btn btn-lg btn-success my-1 btn-" type="button" name="fullscreen" aria-label="Fullscreen" title="Conceptos de Pago">
              <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
            </button>
          </div>
          <div class="col-4 text-center">
            <button [disabled]="filter.conceptos.length==0" (click)="generarReporteExcelPagos()" class="btn btn-lg btn-success my-1 btn-" type="button" name="fullscreen" aria-label="Fullscreen" title="Reporte por Nº de Cuotas">
              <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
            </button>
          </div>
          <div class="col-4 text-center">
            <button [disabled]="filter.conceptos.length==0" (click)="generarReporteExcelPagosMes()" class="btn btn-lg btn-success my-1 btn-" type="button" name="fullscreen" aria-label="Fullscreen" title="Reporte por Meses">
              <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
            </button>
          </div>
        </div>
        <ng-container *ngIf="usuario.empresa.usar_importacion_pagos">
          <div class="row">
              <div class="col-12 text-center">
                  <h5 class="page-title text-primary-d2 text-120">
                      Importación de Pagos
                  </h5>
              </div>
              <div class="col-12 text-center importacion-pagos">
                <ngx-bootstrap-multiselect *ngIf="tipo_concepto_pago" [options]="conceptos_pagos" [(ngModel)]="filter.conceptos_importacion"></ngx-bootstrap-multiselect>
              </div>
          </div>  
          <div class="row">
            <div class="col-6 text-center">
              <button (click)="descargarExcelImportacionPagos()" class="btn btn-lg btn-success my-1 btn-" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="d-block h-4 fa fa fa-file-download text-150"></i>
              </button>
            </div>
            <div class="col-6 text-center">
              <button [disabled]="!filter.conceptos_importacion || filter.conceptos_importacion.length==0" (click)="clickBotonSubir('subir-excel-importacion-pagos')" class="btn btn-lg btn-primary my-1 btn-" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                <i class="fa fa-file-upload text-red fileUpload-button"></i>
                <input id="subir-excel-importacion-pagos" style="display:none" type="file" class="form-control" (change)="subirExcelImportacionPagos($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-3">
        <div class="row">
            <div class="col-12 text-center">
                <h5 class="page-title text-primary-d2 text-120">
                    Boletin de Calificaciones
                </h5>
            </div>
          <div class="col-6">
              Calcular Promedios Hasta:
          </div>
          <div class="col-6 text-center">
            <select [compareWith]="compararObjectos" name="intervalo_escolar" class="form-control" [(ngModel)]="filter.intervalo_escolar">
              <option [ngValue]="intervalo_escolar" *ngFor="let intervalo_escolar of filter.tipo_intervalo.clases">{{intervalo_escolar.nombre}}</option>
            </select>
          </div>
          <div class="col-6">
            Seleccionar por:
          </div>
          <div class="col-6 text-center">
            <ngx-bootstrap-multiselect *ngIf="datos_materias_reporte" [options]="materias_reporte" [(ngModel)]="filter.materias"></ngx-bootstrap-multiselect>
          </div>
        </div>  
        <div class="row">
          <div class="col-6 text-center">
            <button *ngIf="filter.nivel.nombre_corto!='INICIAL'" [disabled]="filter.materias.length==0" (click)="generarBoletinCalificaciones(filter.intervalo_escolar,filter.materias,0)" class="btn btn-lg btn-danger my-1 btn-" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
              <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
            </button>
            <button *ngIf="filter.nivel.nombre_corto=='INICIAL'" [disabled]="filter.materias.length==0" (click)="generarBoletinCalificacionesInicial(filter.intervalo_escolar,filter.materias,0)" class="btn btn-lg btn-danger my-1 btn-" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
              <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
            </button>
          </div>
          <div class="col-6 text-center">
            
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
            <div class="col-12 text-center">
                <h5 class="page-title text-primary-d2 text-120">
                    Reporte Promedios
                </h5>
            </div>
          <div class="col-5">
              Ordenar por:
          </div>
          <div class="col-7 text-center">
            <select [compareWith]="compararObjectos" name="opcion" class="form-control" [(ngModel)]="filter.opcion_reporte_promedios">
              <option [ngValue]="opcion" *ngFor="let opcion of opciones_reporte_promedios">{{opcion.nombre}}</option>
            </select>
          </div>
          <div class="col-5">
            Seleccionar por:
          </div>
          <div class="col-7 text-center">
            <ngx-bootstrap-multiselect *ngIf="datos_materias_reporte" [options]="materias_reporte" [(ngModel)]="filter.materias"></ngx-bootstrap-multiselect>
          </div>
        </div>  
        <div class="row">
          <div class="col-6 text-center">
            <button [disabled]="filter.materias.length==0" (click)="generarPromediosCalificaciones()" class="btn btn-lg btn-success my-1 btn-" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
              <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
            </button>
          </div>
          <div class="col-6 text-center">
            
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="estudiantes.length>0" >
        <div class="col-12">
          <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th class="center">Fotografía </th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('apellido_paterno')">Apellido Paterno <span id="apellido_paterno" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('apellido_materno')">Apellido Materno <span id="apellido_materno" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('nombres')">Nombres <span id="nombres" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('nombres')">¿Consolidado? <span id="consolidado" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('nombres')">¿Activo? <span id="consolidado" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('nombres')">Observación <span id="consolidado" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('nombres')">Fecha Registro <span id="consolidado" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('nombres')">Fecha Actualización <span id="consolidado" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center;" data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #estudiantes *ngFor="let estudiante of estudiantes; let i=index;">
                <td>
                  {{(i+1)}}
                </td>
                <td>
                    <div class="imagen-estudiante-vista clearfix">
                        <a href="{{rest_server+estudiante.persona.imagen}}" data-rel="colorbox" title="{{estudiante.persona.nombre_completo}}">
                            <img alt="sin imagen" src="{{rest_server+estudiante.persona.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
                        </a>
                    </div>
                </td>
                <td>
                    {{estudiante.codigo}}
                </td>
                <td>
                    {{estudiante.persona.apellido_paterno}}
                </td>
                <td>
                    {{estudiante.persona.apellido_materno}}
                </td>
                <td>
                    {{estudiante.persona.nombres}}
                </td>
                <td *ngIf="estudiante.inscripciones_colegio[0].consolidado"><span class="badge badge-md badge-success">SÍ</span></td>
                <td *ngIf="!estudiante.inscripciones_colegio[0].consolidado"><span class="badge badge-md badge-warning">NO</span></td>
                <td *ngIf="estudiante.inscripciones_colegio[0].activo"><span class="badge badge-md badge-success">SÍ</span></td>
                <td *ngIf="!estudiante.inscripciones_colegio[0].activo"><span class="badge badge-md badge-danger">NO</span></td>
                <td>
                  {{estudiante.inscripciones_colegio[0].observacion}}
                </td>
                <td>
                  {{estudiante.inscripciones_colegio[0].createdAt | date:'dd/MM/yyyy'}}
                </td>
                <td>
                  {{estudiante.inscripciones_colegio[0].updatedAt | date:'dd/MM/yyyy'}}
                </td>
                <td style="text-align: center; ">
                  <div class="action-buttons">
                    <a *ngIf="aplicacion_estudiantes.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarEstudiante(estudiante)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
                    </a>
                    <a *ngIf="aplicacion_estudiantes.puede_ver" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verEstudiante(estudiante)">          
                      <i class="fa fa-search text-105"></i>        
					          </a>
                    <a title="Ficha Económica" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirFichaEconomica(estudiante)">
                      <i class="ace-icon fas fa-money-check-alt bigger-130"></i>
                    </a>
                    <a *ngIf="usuario.empresa.usar_hoja_rude" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="imprimirRude(estudiante)">          
                      <i class="fa fa-file-pdf text-105"></i>        
                    </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopuDocumentosEstudiante(estudiante)">          
                      <i class="fas fa-file-upload text-105"></i>
                    </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirPopupAsuntoAdministrativo(estudiante)">          
                      <i class="fas fa-bell text-105"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</div>



<ng-template #importacion_pagos_estudiantes_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Pagos a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button [disabled]="importacion_pagos_estudiantes.estudiantes.length==0" class="btn btn-primary" type="button" (click)="guardarImportacionPagosEstudiantes()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
    <div class="row">
      <div class="col-3"><label>Gestión Escolar : {{filter.gestion.nombre}} </label></div>
      <div class="col-5"><label>Nivel Escolar :  {{filter.nivel.nombre}}</label></div>
      <div class="col-4"><label>Curso : {{filter.curso.nombre}} </label></div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12 col-12">
        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
          <thead fix-head class="table-head-color sticky-nav">
            <tr>
              <th>
                Nº
              </th>
              <th>
                Código
              </th>
              <th>
                 Estudiante
              </th>
              <th [attr.colspan]="concepto_pago.cuotas" *ngFor="let concepto_pago of conceptos_pagos">
                {{concepto_pago.concepto.nombre}}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let estudiante of importacion_pagos_estudiantes.estudiantes; let i=index;">
              <td>{{i+1}}</td>
              <td>{{estudiante.codigo_estudiante}}</td>
              <td>{{estudiante.nombre}}</td>
              <td *ngFor="let pago of estudiante.pagos">
                {{pago.pagado}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
	</div>
</ng-template>

<ng-template #inscripcion_masiva_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Inscripción Masiva</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" (click)="abrirPopupConfirmacionEliminacionRegistroMasivo()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
    <div class="row">
      <div class="col-1">
        <div class="row">
          <div class="col-12 font-weight-bold"><label>Gestión Origen:</label></div>
          <div class="col-12"><label>{{filter.gestion.nombre}} </label></div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-12 font-weight-bold"><label>Nivel Origen:</label></div>
          <div class="col-12"><label>{{filter.nivel.nombre}} </label></div>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-12 font-weight-bold"><label>Curso Origen:</label></div>
          <div class="col-12"><label>{{filter.curso.nombre}} </label></div>
        </div>
      </div>
      <div class="col-1">
        <div class="row">
          <div class="col-12 font-weight-bold"><label>Gestión Destino:</label></div>
          <div class="col-12">
            <select [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="filtro_masivo.gestion">
              <option [ngValue]="gestion" *ngFor="let gestion of obtenerGestionesMasivo(filter.gestiones)">{{gestion.nombre}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-12 font-weight-bold"><label>Nivel Destino:</label></div>
          <div class="col-12">
            <select (change)="obtenerCursosPorNivelMasivo(filtro_masivo.nivel.id)" [compareWith]="compararObjectos" name="nivel" class="form-control" [(ngModel)]="filtro_masivo.nivel">
              <option [ngValue]="nivel" *ngFor="let nivel of niveles_escolares">{{nivel.nombre}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-12 font-weight-bold"><label>Curso Destino:</label></div>
          <div class="col-12">
            <select [compareWith]="compararObjectos" name="curso" class="form-control" [(ngModel)]="filtro_masivo.curso">
              <option [ngValue]="null" [selected]="true">Seleccionar curso</option>
                <option [ngValue]="curso" *ngFor="let curso of filtro_masivo.cursos">{{curso.nombre}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-6">
        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
          <thead fix-head class="table-head-color sticky-nav">
            <tr>
              <th>
                Nº
              </th>
              <th>
                Código
              </th>
              <th>
                 Estudiante
              </th>
              <th style="text-align: center;" data-field="tools">
                <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                <div class="fht-cell"></div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let estudiante of estudiantes; let i=index;">
              <td>{{i+1}}</td>
              <td>{{estudiante.codigo}}</td>
              <td>{{estudiante.persona.nombre_completo}}</td>
              <td >
                <a title="Inscribir" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="agregarInscripcionMasivaEstudiante(estudiante)">
                  <i class="fas fa-arrow-right bigger-130"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
          <thead fix-head class="table-head-color sticky-nav">
            <tr>
              <th>
                Nº
              </th>
              <th>
                Código
              </th>
              <th>
                 Estudiante
              </th>
              <th style="text-align: center;" data-field="tools">
                <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                <div class="fht-cell"></div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let estudiante of inscripciones_masivas | orderBy:'persona.nombre_completo'; let i=index;">
              <td>{{i+1}}</td>
              <td>{{estudiante.codigo}}</td>
              <td>{{estudiante.persona.nombre_completo}}</td>
              <td >
                <a title="Eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarInscripcionMasivaEstudiante(estudiante)">
                  <i class="fas fa-trash bigger-130"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
	</div>
</ng-template>