import { GlobalVariable } from 'src/app/global';
declare const enfocarElemento:any;
declare const clickearElemento:any;
declare const aplicarVisorImagenes:any;
declare const Swiper:any;

export class Util {

    constructor(){}

    static obtenerIdsSucursales(usuario){
        let sucursalesUsuario="";
        for(let i=0;i<usuario.sucursalesUsuario.length;i++){
            sucursalesUsuario=sucursalesUsuario+usuario.sucursalesUsuario[i].sucursal.id;
            if(i+1!=usuario.sucursalesUsuario.length){
                sucursalesUsuario=sucursalesUsuario+',';
            }
        }
        return sucursalesUsuario;
    }

    static esUsuarioAdministrador(usuario){
        let roles=usuario.rolesUsuario.filter(e => e.rol.nombre == GlobalVariable.Dictionary.ROL_ADMINISTRADOR);
        return roles.length>0;
    }

    static esRolUsuario(rol,usuario){
        let roles=usuario.rolesUsuario.filter(e => e.rol.nombre.toUpperCase() == rol.toUpperCase());
        return roles.length>0;
    }

    static tieneRol(rol,usuario){
        let roles=usuario.rolesUsuario.filter(e => e.rol.nombre == rol);
        return roles.length>0;
    }

    static obtenerSucursalesUsuario(usuario){
        let sucursales=[];
		for(var i=0;i<usuario.sucursalesUsuario.length;i++){
			sucursales.push(usuario.sucursalesUsuario[i].sucursal);
		}
		return sucursales;
    }

    static colorearInventario(inventarioDisponible,producto) {
        var arreglo=[];
        if(inventarioDisponible==0){
            arreglo.push("100");
            arreglo.push("red");
        }else if(inventarioDisponible>((producto.inventario_minimo*3)+1)){
            arreglo.push("100");
            arreglo.push("green");
        }else if(inventarioDisponible>((producto.inventario_minimo*2)+1)){
            arreglo.push("75");
            arreglo.push("green");
        }else if(inventarioDisponible>((producto.inventario_minimo*1.5)+1)){
            arreglo.push("50");
            arreglo.push("green");
        }else if(inventarioDisponible==(producto.inventario_minimo+1)){
            arreglo.push("38");
            arreglo.push("yellow");
        }else if(inventarioDisponible==producto.inventario_minimo){
            arreglo.push("25");
            arreglo.push("red");
        }else if(inventarioDisponible<producto.inventario_minimo && inventarioDisponible>0){
            arreglo.push("12");
            arreglo.push("red");
        }
        return arreglo;
    }

    static convertirObjetoAfecha(texto_fecha) {
        let fecha_hora_actual=new Date();
        return new Date(texto_fecha.year,texto_fecha.month-1,texto_fecha.day,fecha_hora_actual.getHours(),fecha_hora_actual.getMinutes(),fecha_hora_actual.getSeconds());
    }

    static convertirTextoAfecha(fecha) {
        var dia = fecha.split('/')[0];
        var mes = fecha.split('/')[1];
        var año = fecha.split('/')[2];
        if (año == undefined) {
            año = new Date().getFullYear();
        }
        return new Date(mes + '/' + dia + '/' + año);
    }

    static convertirTextoAObjectoFecha(fecha) {
        var dia = fecha.split('/')[0];
        var mes = fecha.split('/')[1];
        var año = fecha.split('/')[2];
        return {
			year: parseInt(año),
			month: parseInt(mes),
			day:parseInt(dia)
		}
    }

    static convertirFechaAObjectoFecha(fecha) {
        var dia = fecha.getDate();
        var mes = fecha.getMonth()+1;
        var año = fecha.getFullYear();
        return {
			year: parseInt(año),
			month: parseInt(mes),
			day:parseInt(dia)
		}
    }

    static verificarDescuentos(detalles){
        var existe = false;
        for (var i = 0; i < detalles.length; i++) {
            if (detalles[i].descuento > 0 || detalles[i].recargo > 0 || detalles[i].ice > 0 || detalles[i].excento > 0) {
                existe = true;
            }
        }
        return existe;
    }

    static convertirUrlToBase64Image(url)
    {
        return new Promise(
            function(resolve, reject) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = ()=>{
                let canvas:any = document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'), dataURL;
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                dataURL = canvas.toDataURL('image/jpg');
                resolve(dataURL);
                canvas = null;
            };
            img.onerror = function() {
                reject("Error loading " + this.src);
            };
            img.src = url;

        });
    }

    static sumarDiasFecha(d, fecha) {

        var Fecha = new Date(fecha);
        var dia = Fecha.getDate(),
            mes = Fecha.getMonth() + 1,
            anio = Fecha.getFullYear(),
            addTime = d * 86400;
        var date = Fecha.setSeconds(addTime)
        /*console.log("Fecha actual: " + dia + "/" + mes + "/" + anio)
        console.log("Tiempo añadido: " + d )
        console.log("Fecha final: " + Fecha.getDate() + "/" + (Fecha.getMonth() + 1) + "/" + Fecha.getFullYear())*/
        fecha = new Date(Fecha);
        return (Fecha);
    }

    static obtenerDiferenciaDiasFechas(fecha_final, fecha_inicial) {
        let difference= Math.abs(fecha_final-fecha_inicial);
        return difference/(1000 * 3600 * 24);
    }

    static enfocarElemento(elemento){
        enfocarElemento(elemento);
    }

    static aplicarSwiper(slidesPerView, slidesPerColumn, paginationClickable, spaceBetween) {
        var swiper = new Swiper('.swiper-container', {
            //pagination: '.swiper-pagination',
            slidesPerView: slidesPerView,
            slidesPerColumn: slidesPerColumn,
            paginationClickable: paginationClickable,
            spaceBetween: spaceBetween
        });
    }

    static establecerPermisos(usuario){
        usuario.empresa.usar_ventas=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_VENTAS && e.habilitado)).length>0);
        usuario.empresa.usar_compras=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_COMPRAS && e.habilitado) ).length>0);
        usuario.empresa.usar_usuarios=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_USUARIOS && e.habilitado) ).length>0);
        usuario.empresa.usar_control_por_usuarios=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CONTROL_POR_USUARIOS && e.habilitado) ).length>0);
        usuario.empresa.usar_consumo_de_agua=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CONSUMO_DE_AGUA && e.habilitado) ).length>0);
        usuario.empresa.usar_combos_conceptos=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_COMBOS_CONCEPTOS && e.habilitado) ).length>0);
        usuario.empresa.usar_conteo_de_dinero=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CONTEO_DE_DINERO && e.habilitado)).length>0);
        usuario.empresa.usar_caja_pos=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CAJA_POS && e.habilitado)).length>0);
        usuario.empresa.usar_caja_general=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CAJA_GENERAL && e.habilitado)).length>0);
        usuario.empresa.usar_aplicaciones_por_rol=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_APLICACIONES_POR_ROL && e.habilitado)).length>0);
        usuario.empresa.usar_acceso_nivel_escolar=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_ACCESO_NIVEL_ESCOLAR && e.habilitado)).length>0);
        usuario.empresa.usar_acceso_gestion_escolar=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_ACCESO_GESTION_ESCOLAR && e.habilitado)).length>0);
        usuario.empresa.usar_registro_pedagogico=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_REGISTRO_PEDAGOGICO && e.habilitado)).length>0);
        usuario.empresa.usar_calendario_cursos=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CALENDARIO_CURSOS && e.habilitado)).length>0);
        usuario.empresa.usar_imagenes_impresion_cotizacion=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_IMAGENES_IMPRESION_COTIZACION && e.habilitado)).length>0);
        usuario.empresa.usar_vehiculos=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_VEHICULOS && e.habilitado)).length>0);
        usuario.empresa.usar_buses=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_BUSES && e.habilitado)).length>0);
        usuario.empresa.usar_mantenimiento_vehiculos=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_MANTENIMIENTO_VEHICULOS && e.habilitado)).length>0);
        usuario.empresa.usar_mensajeria=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_MENSAJERIA && e.habilitado)).length>0);
        usuario.empresa.usar_almacenes=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_ALMACENES && e.habilitado)).length>0);
        usuario.empresa.usar_almacenes_usuario=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_ALMACENES_USUARIO && e.habilitado)).length>0);
        usuario.empresa.usar_gece=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_GECE && e.habilitado)).length>0);
        usuario.empresa.usar_georeferenciacion_clientes=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_GEOREFERENCIACION_CLIENTES && e.habilitado)).length>0);
        usuario.empresa.usar_pedidos_delivery=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_DELIVERY && e.habilitado)).length>0);
        usuario.empresa.usar_permisos_avanzados_usuarios=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PERMISOS_AVANZADOS_USUARIOS && e.habilitado)).length>0);
        usuario.empresa.usar_estudiantes_colegio=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_ESTUDIANTES_COLEGIO && e.habilitado)).length>0);
        usuario.empresa.usar_estudiantes_instituto=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_ESTUDIANTES_INSTITUTO && e.habilitado)).length>0);
        usuario.empresa.usar_cuentas_estudiantes=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CUENTAS_ESTUDIANTES && e.habilitado)).length>0);
        usuario.empresa.usar_hoja_rude=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_HOJA_RUDE && e.habilitado)).length>0);
        usuario.empresa.usar_ingresos_economicos_colegio=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_INGRESOS_ECONOMICOS_COLEGIO && e.habilitado)).length>0);
        usuario.empresa.usar_ingresos_economicos_instituto=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_INGRESOS_ECONOMICOS_INSTITUTO && e.habilitado)).length>0);


        usuario.empresa.usar_facturacion=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_FACTURACION && e.habilitado) ).length>0);
        usuario.empresa.usar_inventario=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_INVENTARIO && e.habilitado) ).length>0);
        usuario.empresa.usar_vencimientos=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_VENCIMIENTOS_PRODUCTOS && e.habilitado) ).length>0);
        usuario.empresa.usar_vencimiento_economico_ventas=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_VENCIMIENTOS_ECONOMICOS_VENTAS && e.habilitado) ).length>0);
        usuario.empresa.usar_vencimiento_economico_compras=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_VENCIMIENTOS_ECONOMICOS_COMPRAS && e.habilitado) ).length>0);
        usuario.empresa.usar_pedidos_restaurante=(usuario.empresa.modulos.filter(e => ((e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PEDIDOS_RESTAURANTE) && e.habilitado) ).length>0);
        usuario.empresa.usar_pedidos_distribucion=(usuario.empresa.modulos.filter(e => ((e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PEDIDOS_DISTRIBUCION) && e.habilitado) ).length>0);
        usuario.empresa.usar_pantalla_cliente=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PANTALLA_CLIENTE && e.habilitado) ).length>0);
        usuario.empresa.usar_pantalla_despacho=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PANTALLA_DESPACHO && e.habilitado) ).length>0);
        usuario.empresa.usar_mesas=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_MESAS && e.habilitado) ).length>0);
        usuario.empresa.usar_salas=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_SALAS && e.habilitado) ).length>0);
        usuario.empresa.usar_consumos=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_RECETAS && e.habilitado) ).length>0);
        usuario.empresa.usar_inventario_externo=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_INVENTARIO_EXTERNO && e.habilitado) ).length>0);
        usuario.empresa.usar_precios_productos=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PRECIOS_PRODUCTOS && e.habilitado) ).length>0);
        usuario.empresa.usar_medicina_dietas=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_MEDICINA_DIETAS && e.habilitado) ).length>0);
        usuario.empresa.usar_medicina_consultorio=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_MEDICINA_CONSULTORIO && e.habilitado) ).length>0);
        usuario.empresa.usar_reposiciones=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_REPOSICIONES && e.habilitado) ).length>0);
        usuario.empresa.usar_combos=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_COMBOS && e.habilitado) ).length>0);
        usuario.empresa.usar_devoluciones_ventas=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_DEVOLUCIONES_VENTAS && e.habilitado) ).length>0);
        usuario.empresa.usar_precios_productos_sucursal=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PRECIOS_PRODUCTOS_SUCURSAL && e.habilitado) ).length>0);
        usuario.empresa.usar_georeferenciacion_usuarios=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_GEOREFERENCIACION_USUARIOS && e.habilitado) ).length>0);
        usuario.empresa.usar_cotizaciones=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_COTIZACIONES && e.habilitado) ).length>0);
        usuario.empresa.usar_pedidos_produccion=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PEDIDOS_PRODUCCION && e.habilitado) ).length>0);
        usuario.empresa.usar_servicios=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_SERVICIOS && e.habilitado) ).length>0);
        usuario.empresa.usar_precios_productos_clientes=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PRECIOS_PRODUCTOS_CLIENTES && e.habilitado) ).length>0);
        usuario.empresa.usar_precios_productos_usuarios=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PRECIOS_PRODUCTOS_USUARIOS && e.habilitado) ).length>0);
        usuario.empresa.usar_sucursales=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_SUCURSALES && e.habilitado) ).length>0);
        usuario.empresa.usar_facturacion_a_terceros=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_FACTURACION_A_TERCEROS && e.habilitado) ).length>0);
        usuario.empresa.usar_despachos=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_DESPACHOS && e.habilitado) ).length>0);
        usuario.empresa.usar_clientes_sucursal=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CLIENTES_SUCURSAL && e.habilitado) ).length>0);
        usuario.empresa.usar_clientes_contactos=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CLIENTES_CONTACTOS && e.habilitado) ).length>0);
        usuario.empresa.usar_obsequios=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_OBSEQUIOS && e.habilitado) ).length>0);
        usuario.empresa.usar_restriccion_productos_venta=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_RESTRICCION_PRODUCTOS_VENTA && e.habilitado) ).length>0);
        usuario.empresa.usar_contabilidad_semi_automatica=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CONTABILIDAD_SEMI_AUTOMATICA && e.habilitado) ).length>0);
        usuario.empresa.usar_inventario_minimo=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_INVENTARIO_MINIMO && e.habilitado) ).length>0);
        usuario.empresa.usar_inventario_flexible=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_INVENTARIO_FLEXIBLE && e.habilitado) ).length>0);
        usuario.empresa.usar_kardex_inventario_productos=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_KARDEX_INVENTARIO_PRODUCTOS && e.habilitado) ).length>0);
        usuario.empresa.usar_pedidos_en_linea=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PEDIDOS_EN_LINEA && e.habilitado) ).length>0);

        usuario.empresa.usar_empleados=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_EMPLEADOS && e.habilitado)).length>0);
        usuario.empresa.usar_biometrico_asistencia=(usuario.empresa.modulos.filter(e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_BIOMETRICO_ASISTENCIA && e.habilitado) ).length>0);
        usuario.empresa.usar_cuentas_empleados=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CUENTAS_EMPLEADOS && e.habilitado)).length>0);
        usuario.empresa.usar_profesores_instituto=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PROFESORES_INSTITUTO && e.habilitado)).length>0);
        usuario.empresa.usar_profesores_colegio=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PROFESORES_COLEGIO && e.habilitado)).length>0);
        usuario.empresa.usar_mensajeria_redes_sociales=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_MENSAJERIA_REDES_SOCIALES && e.habilitado)).length>0);
        usuario.empresa.usar_generador_codigos_barras=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_GENERADOR_CODIGOS_BARRAS && e.habilitado)).length>0);

        usuario.empresa.usar_facturacion_computarizada_en_linea=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_FACTURACION_COMPUTARIZADA_EN_LINEA && e.habilitado)).length>0);
        usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_FACTURACION_COMPUTARIZADA_EN_LINEA_POR_SUCURSAL && e.habilitado)).length>0);
        usuario.empresa.usar_facturacion_computarizada_en_linea_por_empresa=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_FACTURACION_COMPUTARIZADA_EN_LINEA_POR_EMPRESA && e.habilitado)).length>0);
        usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_FACTURACION_COMPUTARIZADA_EN_LINEA_POR_SUCURSAL_EMPRESA && e.habilitado)).length>0);
        usuario.empresa.usar_productos_sucursal=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PRODUCTOS_SUCURSAL && e.habilitado)).length>0);

        usuario.empresa.usar_hoteles=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_HOTELES && e.habilitado)).length>0);

        usuario.empresa.usar_precios_productos_grupos=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PRECIOS_PRODUCTOS_GRUPOS && e.habilitado)).length>0);
        usuario.empresa.usar_pagos_servicios_empresas=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PAGOS_SERVICIOS_EMPRESAS && e.habilitado)).length>0);
        usuario.empresa.usar_almacenes_empleado=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_ALMACENES_EMPLEADO && e.habilitado)).length>0);
        usuario.empresa.usar_libro_ventas_v2=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_LIBRO_VENTAS_V2 && e.habilitado)).length>0);
        usuario.empresa.usar_cobros_banco_colegio=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_COBROS_BANCO_COLEGIO && e.habilitado)).length>0);
        usuario.empresa.usar_cobros_caja_colegio=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_COBROS_CAJA_COLEGIO && e.habilitado)).length>0);
        usuario.empresa.usar_descuento_global=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_DESCUENTO_GLOBAL && e.habilitado)).length>0);
        usuario.empresa.usar_ventas_app_offline=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_VENTAS_APP_OFFLINE && e.habilitado)).length>0);
        usuario.empresa.usar_fechas_vencimientos_pagos_estudiantes=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_FECHAS_VENCIMIENTO_PAGOS_ESTUDIANTES && e.habilitado)).length>0);

        usuario.empresa.usar_produccion=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PRODUCCION && e.habilitado)).length>0);
        usuario.empresa.usar_caja_pos_sucursal=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_CAJA_POS_SUCURSAL && e.habilitado)).length>0);

        usuario.empresa.usar_facturacion_manual=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_FACTURACION_MANUAL && e.habilitado)).length>0);
        usuario.empresa.usar_bitacora_usuario=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_BITACORA && e.habilitado)).length>0);

        usuario.empresa.usar_periodismo=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_PERIODISMO && e.habilitado)).length>0);
        usuario.empresa.usar_impresion_nativa=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_IMPRESION_NATIVA && e.habilitado)).length>0);

        usuario.empresa.usar_importacion_pagos=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_IMPORTACION_PAGOS && e.habilitado)).length>0);

        usuario.empresa.usar_bloqueo_clientes=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_BLOQUEO_CLIENTES && e.habilitado)).length>0);
        usuario.empresa.usar_automatizacion_mensajes_redes=(usuario.empresa.modulos.filter( e => (e.modulo.titulo == GlobalVariable.Dictionary.MODULO_AUTOMATIZACION_MENSAJES_REDES && e.habilitado)).length>0);

        return usuario;
    }

    static sumaFecha(d, fecha) {
        var Fecha = new Date(fecha);
        var dia = Fecha.getDate(),
            mes = Fecha.getMonth() + 1,
            anio = Fecha.getFullYear(),
            addTime = d * 86400;
        var date = Fecha.setSeconds(addTime)
        fecha = new Date(Fecha);
        return Fecha;
    }

    static generarMenus (usuario) {
        usuario.aplicaciones = [];

        for (var j = 0; j < usuario.aplicacionesUsuario.length; j++) {
          usuario.aplicacionesUsuario[j].aplicacion.url = usuario.aplicacionesUsuario[j].aplicacion.url.replace("?", usuario.persona.id + "");
          if (usuario.aplicacionesUsuario[j].aplicacion.id_padre == null) {
            var app = usuario.aplicacionesUsuario[j];
            app.subaplicaciones = [];
            if(usuario.id_empresa && usuario.empresa.punto_venta_offline){
              if(usuario.empresa.en_linea){
                if(app.aplicacion.fuera_de_linea){
                  usuario.aplicaciones.push(app);
                }
              }else{
                usuario.aplicaciones.push(app);
              }
            }else{
              usuario.aplicaciones.push(app);
            }
          }
        }


        for (var j = 0; j < usuario.aplicacionesUsuario.length; j++) {
          for (var z = 0; z < usuario.aplicaciones.length; z++) {
            if (usuario.aplicacionesUsuario[j].aplicacion.id_padre == usuario.aplicaciones[z].aplicacion.id) {
              if(usuario.id_empresa && usuario.empresa.punto_venta_offline){
                if(usuario.empresa.en_linea){
                  if(usuario.aplicacionesUsuario[j].aplicacion.fuera_de_linea){
                    usuario.aplicaciones[z].subaplicaciones.push(usuario.aplicacionesUsuario[j]);
                  }
                }else{
                  usuario.aplicaciones[z].subaplicaciones.push(usuario.aplicacionesUsuario[j]);
                }
              }else{
                usuario.aplicaciones[z].subaplicaciones.push(usuario.aplicacionesUsuario[j]);
              }
            }
          }
        }

    }

    static obtenerConfiguracionAplicacion(codigo_aplicacion,objeto){
        let configuracion_aplicacion:any={};
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_VENTAS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    transaccion: { value: "Transaccion", show: true },
                    factura: { value: "Num. Doc.", show:true },
                    sucursal: { value: "Sucursal", show: objeto.usuario.empresa.usar_sucursales },
                    almacen: { value: "Almacen", show: objeto.usuario.empresa.usar_almacenes },
                    despacho: { value: "Despacho", show: objeto.usuario.empresa.usar_pedidos_restaurante || objeto.usuario.empresa.usar_pedidos_distribucion || objeto.usuario.empresa.usar_pedidos_en_linea },
                    cliente: { value: "Cliente", show: true },
                    razon_social: { value: objeto.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL), show: true },
                    nit: { value: objeto.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_NIT), show: true },
                    cliente_categoria: { value: objeto.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_CATEGORIA), show: false },
                    fecha: { value: "Fecha Doc.", show: true },
                    createdAt: { value: "Hora - Fecha", show: true},
                    importe: { value: "Importe", show: false },
                    descuento: { value: "Descuento", show: false },
                    total: { value: "Total", show: true },
                    cobrado: { value: "Cobrado", show: false },
                    saldo: { value: "Saldo", show: false },
                    dias_credito: { value: "Dias Cred.", show: false },
                    dias_mora: { value: "Dias Mora", show: false },
                    tipo_pago: { value: "Tipo Pago", show: true },
                    usuario: { value: "Usuario", show: true },
                    estado: { value: "Estado", show: false },
                    activa: { value: "Validez", show: true },
                    observacion: { value: "Observacion", show: false },
                    canal: { value: "Canal", show: false },
                    contacto_cliente: { value: "Contacto Cli.", show: objeto.usuario.empresa.usar_clientes_contactos },
                    fecha_ingreso_hotel: { value: "Fecha Ing. Hotel", show: objeto.usuario.empresa.usar_hoteles },
                    fecha_salida_hotel: { value: "Fecha Sal. Hotel", show: objeto.usuario.empresa.usar_hoteles },
                },
                opciones_permisos:{
                    transaccion_por_defecto:objeto.usuario.empresa.usar_facturacion?GlobalVariable.Dictionary.EGRE_FACTURACION:GlobalVariable.Dictionary.EGRE_PROFORMA,
                    tipo_pago_por_defecto:GlobalVariable.Dictionary.TIPO_PAGO_CONT,
                    columna:"fecha",
                    direccion:"asc",
                    cantidad_decimal:false,
                    busqueda_codigo_barra:false,
                    busqueda_producto:true,
                    modificar_precio_unitario:false,
                    modificar_cantidad:false,
                    ver_excel:true,
                    ver_pdf:true,
                    canales_asignados:[],
                    impresion:{
                        lineas_items:true,
                        papel_factura:GlobalVariable.Dictionary.PAPEL_ROLLO,
                        papel_nota_venta:GlobalVariable.Dictionary.PAPEL_ROLLO,
                        papel_recibo_cobro:GlobalVariable.Dictionary.PAPEL_ROLLO,
                        nota_almacen:false,
                        datos_credito:true,
                        al_guardar:true,
                        usuario:true,
                        numero_pedido:true,
                        numero_copias:1,
                        adicion_copia:GlobalVariable.Dictionary.COPIA_IMPRESION_ADICION_HOJA,
                        disenio_logo_impresion:GlobalVariable.Dictionary.DISENIO_LOGO_IMPRESION_IZQUIERDA,
                        codigo_producto:GlobalVariable.Dictionary.IMPRESION_CODIGO_PRODUCTO_SIN
                    },
                    cantidad_por_defecto:"1",
                    cantidad_habilitado_busqueda:true,
                    ver_descuento_almacen:false,
                    ver_subida_comprobante_adjunto:false
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_CLIENTES){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    codigo: { value: "Codigo", show: objeto.usuario.empresa.usar_ventas },
                    identificacion: { value: "Identificación", show: true },
                    descripcion: { value: "Descripcion", show: objeto.usuario.empresa.usar_ventas },
                    contacto: { value: "Contacto", show: false },
                    razon_social: { value: "Razon Social", show: objeto.usuario.empresa.usar_ventas },
                    nit: { value: "Nit", show: objeto.usuario.empresa.usar_ventas },
                    direccion: { value: "Direccion", show: true },
                    telefono1: { value: "Telefono 1", show: true },
                    imagen: { value: "Imagen", show: objeto.usuario.empresa.usar_ventas },
                    email: { value: "E-mail", show: true },
                    categoria: { value: objeto.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_CATEGORIA), show: false },
                    subcategoria: { value: objeto.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_SUBCATEGORIA), show: false },
                    bloqueado: { value: "Bloqueado", show: objeto.usuario.empresa.usar_bloqueo_clientes }
                },
                opciones_permisos:{
                    ver_excel:true,
                    ver_pdf:true
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_CIERRES_CAJA){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    sucursal: { value: "Sucursal", show: objeto.usuario.empresa.usar_sucursales },
                    usuario: { value: "Usuario", show: true },
                    fecha_apertura: { value: "Fecha Apertura", show: true },
                    fecha_cierre: { value: "Fecha Cierre", show: true },
                    saldo_inicial: { value: "Saldo Inicial", show: true },
                    ingresos: { value: "Ingresos", show: false },
                    salidas: { value: "Salidas", show: false },
                    ventas_contado_recibos: { value: "Ventas Contado Recibos", show: false },
                    ventas_contado_facturas: { value: "Ventas Contado Facturas", show: false },
                    ventas_credito: { value: "Ventas Credito", show: false },
                    compras_contado: { value: "Compras Contado", show: false },
                    compras_credito: { value: "Compras Credito", show: false },
                    saldo_faltante: { value: "Saldo Faltante", show: true },
                    saldo_sobrante: { value: "Saldo Sobrante", show: true },
                    saldo_caja: { value: "Saldo Caja", show: true },
                    numero_documento: { value: "Num. Doc.", show: true },
                    estado: { value: "Estado", show: true }
                },
                opciones_permisos:{
                    columna:"fecha_apertura",
                    direccion:"desc",
                    impresion:{
                        papel_cierre:GlobalVariable.Dictionary.PAPEL_CARTA,
                        usuario:true,
                        disenio_logo_impresion:GlobalVariable.Dictionary.DISENIO_LOGO_IMPRESION_IZQUIERDA
                    }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_INVENTARIOS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    codigo: { value: "Codigo", show: true },
                    producto: { value: "Producto", show: true },
                    unidad_medida: { value: "Unid. Med.", show: true },
                    descripcion: { value: "Descripción", show: true },
                    cantidad: { value: "Cantidad", show: true },
                    precio_unitario: { value: "P.U.", show: true },
                    grupo: { value: "Grupo", show: true },
                    sub_grupo: { value: "Sub Grupo", show: true },
                    codigo_fabrica: { value: "Cod. Fabrica", show: false },
                    observacion: { value: "Observacion", show: false },
                    fecha_vencimiento:{ value: "Fecha Venc.", show: objeto.usuario.empresa.usar_vencimientos },
                    lote:{ value: "Lote", show: objeto.usuario.empresa.usar_vencimientos }
                },
                opciones_permisos:{
                    ver_excel:true,
                    ver_pdf:true,
                    impresion:{
                        papel_reporte_almacenes:GlobalVariable.Dictionary.PAPEL_CARTA,
                        disenio_logo_impresion:GlobalVariable.Dictionary.DISENIO_LOGO_IMPRESION_IZQUIERDA
                    }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_PEDIDOS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    sucursal: { value: "Sucursal", show: objeto.usuario.empresa.usar_sucursales },
                    despacho: { value: "Despacho", show: objeto.usuario.empresa.usar_pedidos_restaurante || objeto.usuario.empresa.usar_pedidos_distribucion || objeto.usuario.empresa.usar_pedidos_en_linea },
                    tiempo_ingreso: { value: "Fecha Ing.", show: true },
                    fecha_salida: { value: "F. Entrega", show: false },
                    numero_pedido: { value: "Num. Pedido", show: true },
                    identificacion_cliente: { value: "Cliente", show: true },
                    mesa: { value: "Mesa", show: true },
                    total: { value: "Total", show: true },
                    usuario: { value: "Usuario", show: false },
                    activo: { value: "Activo", show: true },
                    estado: { value: "Estado", show: true },
                    tiempo_despacho: { value: "Despacho", show: false },
                    tipo_pago: { value: "Tipo de Pago", show: false },
                    observacion: { value: "Obs.", show: true },
                    contacto_cliente: { value: "Contacto Cli.", show: objeto.usuario.empresa.usar_clientes_contactos },
                },
                opciones_permisos:{
                    transaccion_por_defecto:objeto.usuario.empresa.usar_facturacion?GlobalVariable.Dictionary.EGRE_FACTURACION:GlobalVariable.Dictionary.EGRE_PROFORMA,
                    tipo_pago_por_defecto:GlobalVariable.Dictionary.TIPO_PAGO_CONT,
                    orden_por_columna:"tiempo_ingreso",
                    orden_direccion:"asc",
                    registro_despacho_por_defecto:GlobalVariable.Dictionary.DESPACHO_MESA,
                    mostrar_productos_publico:true,
                    controlar_cantidades_panel:objeto.usuario.empresa.usar_inventario,
                    controlar_cantidades_pedidos_registrados:false,
                    cobrar_pedido:false,
                    descontar_almacen:false,
                    orden_productos_panel:"nombre",
                    ver_excel:true,
                    ver_pdf:true,
                    cambiar_precios:true,
                    impresion:{
                        comanda_en_venta:false,
                        solo_comanda:false,
                        vista_previa:!objeto.usuario.empresa.usar_impresion_nativa,
                        datos_economicos_despacho:false,
                        pie_texto_despacho:"La presente nota de entrega constituye un contrato de línea de crédito rotatorio, entre la empresa (VENDEDOR) y el cliente (COMPRADOR), donde se establece la constancia de venta y entrega de mercadería, además fija el precio y el plazo de crédito. El comprador se compromete al pago del crédito hasta el plazo acordado. El incumplimiento de pago constituye una obligación exigible y de plazo vencido sin necesidad de un ningún requerimiento judicial o extrajudicial. En constancia ambas partes firman al pie."
                    }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_COMPRAS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    numero_documento: { value: "Num. Doc.", show: true },
                    sucursal: { value: "Sucursal", show: objeto.usuario.empresa.usar_sucursales },
                    almacen: { value: "Almacen", show: objeto.usuario.empresa.usar_almacenes },
                    razon_social: { value: "Razon Social", show: true },
                    nit: { value: "Nit", show: true },
                    tipo_documento: { value: "Tipo Doc.", show: true },
                    factura: { value: "Factura", show: true },
                    fecha_documento: { value: "Fecha Doc.", show: true },
                    hora_fecha: { value: "Hora - Fecha", show: true },
                    importe: { value: "Importe", show: true },
                    descuento: { value: "Descuento", show: false },
                    total: { value: "Total", show: true },
                    pagado: { value: "Pagado", show: false },
                    saldo: { value: "Saldo", show: false },
                    tipo_pago: { value: "Tipo Pago", show: true },
                    usuario: { value: "Usuario", show: true },
                    activa: { value: "Validez", show: true },
                    caja: { value: "Caja", show: false },
                },
                opciones_permisos:{
                    busqueda_codigo_barra:false,
                    tipo_documento_por_defecto:objeto.usuario.empresa.usar_facturacion?GlobalVariable.Dictionary.TIPO_DOCUMENTO_FACTURA:GlobalVariable.Dictionary.TIPO_DOCUMENTO_RECIBO,
                    tipo_pago_por_defecto:GlobalVariable.Dictionary.TIPO_PAGO_CONT,
                    columna:"fecha",
                    direccion:"asc",
                    cantidad_decimal:false,
                    impresion:{
                        papel_nota_compra:GlobalVariable.Dictionary.PAPEL_CARTA,
                        papel_recibo_pago:GlobalVariable.Dictionary.PAPEL_CARTA,
                        datos_credito:true,
                        usuario:false,
                        disenio_logo_impresion:GlobalVariable.Dictionary.DISENIO_LOGO_IMPRESION_IZQUIERDA
                    },
                    ver_subida_comprobante_adjunto:false
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_RUTAS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    horas: { value: "Horas", show: true },
                    minutos: { value: "Minutos", show: true },
                    punto_origen: { value: "Origen", show: true },
                    punto_destino: { value: "Destino", show: true },
                    distancia_kilometros: { value: "km", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_VIAJES){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    vehiculo: { value: "Vehiculo", show: true },
                    tiempo_salida: { value: "Salida", show: true },
                    tiempo_llegada: { value: "Llegada", show: true },
                    origen: { value: "Origen", show: true },
                    destino: { value: "Destino", show: true },
                    kilometraje_inicial: { value: "km inicial", show: true },
                    kilometraje_final: { value: "km final", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_TAREAS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    nombre: { value: "Nombre", show: true },
                    descripcion: { value: "Descripcion", show: true },
                    curso: { value: "Curso", show: true },
                    materia: { value: "Materia", show: true },
                    fecha_creacion: { value: "Fecha Creación", show: true },
                    fecha_entrega: { value: "Fecha entrega", show: true },
                    archivos: { value: "Archivos", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_PANTALLA_DESPACHO){
            configuracion_aplicacion={
                visualizacion_columnas: {

                },
                opciones_permisos:{
                    despachar_sin_cobrar:false,
                    emitir_sonido_recepcion:false
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_CUENTAS_POR_PAGAR){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    codigo: { value: "Codigo", show: true },
                    identificacion: { value: "Identificación", show: true },
                    razon_social: { value: objeto.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL), show: true },
                    nit: { value: "Nit", show: true },
                    total: { value: "Total", show: true },
                    a_cuenta: { value: "A Cuenta", show: true },
                    saldo: { value: "Saldo", show: true },
                    direccion: { value: "Direccion", show: false },
                    telefono1: { value: "Telefono 1", show: false },
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_CUENTAS_POR_COBRAR){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    codigo: { value: "Codigo", show: true },
                    identificacion: { value: "Identificación", show: true },
                    razon_social: { value: objeto.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL), show: true },
                    nit: { value: "Nit", show: true },
                    total: { value: "Total", show: true },
                    devoluciones: { value: "Devolucion", show: true },
                    a_cuenta: { value: "A Cuenta", show: true },
                    saldo: { value: "Saldo", show: true },
                    direccion: { value: "Direccion", show: false },
                    telefono1: { value: "Telefono 1", show: false },
                    contacto_cliente: { value: "Contacto Cli.", show: objeto.usuario.empresa.usar_clientes_contactos },
                },
                opciones_permisos:{
                    impresion:{
                        papel_pdf:GlobalVariable.Dictionary.PAPEL_CARTA
                    }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_PROVEEDORES){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    codigo: { value: "Codigo", show: true },
                    identificacion: { value: "Identificación", show: true },
                    razon_social: { value: "Razón Social", show: true },
                    nit: { value: "Nit", show: true },
                    direccion: { value: "Dirección", show: true },
                    telefono: { value: "Teléfono", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_EMPLEADOS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    codigo: { value: "Codigo", show: true },
                    apellido_paterno: { value: "Apellido Paterno", show: true },
                    apellido_materno: { value: "Apellido Materno", show: true },
                    nombres: { value: "Nombres", show: true },
                    imagen: { value: "Fotografia", show: true },
                    ci: { value: "C.I.", show: true },
                    activo: { value: "Activo", show: true }
                },
                opciones_permisos:{
                    columna:"apellido_paterno",
				    direccion:"asc",
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_MOVIMIENTOS_INVENTARIO){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    tipo: { value: "Movimiento", show: true },
                    clase: { value: "Transaccion", show: true },
                    numero_documento: { value: "Num. Doc.", show: true },
                    sucursal: { value: "Sucursal O.", show: true },
                    almacen: { value: "Almacen O.", show: true },
                    sucursal_destino: { value: "Sucursal D.", show: true },
                    almacen_traspaso: { value: "Almacen D.", show: true },
                    fecha: { value: "Fecha Doc.", show: true },
                    createdAt: { value: "Hora - Fecha", show: true },
                    total: { value: "Importe", show: true },
                    usuario: { value: "Usuario", show: true },
                    observacion: { value: "Observación", show: true },
                    activa: { value: "Estado", show: true }
                },
                opciones_permisos:{
                    busqueda_codigo_barra:false,
                    columna:"fecha",
				    direccion:"asc",
                    impresion:{
                        papel:GlobalVariable.Dictionary.PAPEL_CARTA
                    },
                    transacciones_asignadas:[]
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_COTIZACIONES){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    nombre: { value: "Nombre", show: true },
                    descripcion: { value: "Descripción", show: true },
                    fecha: { value: "Fecha", show: true },
                    numero_documento: { value: "Numero Doc.", show: true },
                    importe: { value: "Importe", show: true },
                    usuario: { value: "Usuario", show: true },
                    estado: { value: "Estado", show: true },
                    tiempo_validez: { value: "Tiempo Val.", show: true }
                },
                opciones_permisos:{
                    cantidad_decimal:false,
                    impresion:{
                        papel_nota_cotizacion:GlobalVariable.Dictionary.PAPEL_CARTA,
                        usuario:true,
                        disenio_logo_impresion:GlobalVariable.Dictionary.DISENIO_LOGO_IMPRESION_IZQUIERDA
                    },
                    cliente_registrado:false
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_LECTURACION){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    codigo_vecino: { value: "Codigo", show: true },
                    nombre: { value: "Vecino", show: true },
                    codigo_medidor: { value: "Cod. Medidor", show: true },
                    gestion: { value: "Gestión", show: true },
                    mes: { value: "Mes", show: true },
                    categoria: { value: "Categoria", show: true },
                    lectura_anterior: { value: "Lect. Ant.", show:true },
                    lectura_actual: { value: "Lect. Act.", show: true },
                    consumo: { value: "Consumo", show: true },
                    inferido: { value: "Inferido", show: true },
                    observacion: { value: "Obs.", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_VECINOS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    codigo: { value: "Codigo", show: true },
                    apellido_paterno: { value: "Ap. Paterno", show: true },
                    apellido_materno: { value: "Ap. Materno", show: true },
                    nombres: { value: "Nombres", show: true },
                    imagen: { value: "Fotografía", show: true },
                    ci: { value: "C.I.", show: true },
                    telefono_movil: { value: "Telf. Móvil", show: true },
                    categoria: { value: "Categoria", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_COMBOS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    publicado_panel: { value: "Pub. Panel", show: true },
                    codigo: { value: "Codigo", show: objeto.usuario.empresa.usar_ventas },
                    nombre: { value: "Nombre", show: true },
                    imagen: { value: "Imagen", show: false },
                    unidad_medida: { value: "Und. Med.", show: objeto.usuario.empresa.usar_ventas },
                    precio_unitario: { value: "P.U.", show: true },
                    descripcion: { value: "Descripcion", show: objeto.usuario.empresa.usar_ventas }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_USUARIOS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    georeferenciacion_automatico: { value: "Geo Auto", show: (objeto.usuario.id_empresa?objeto.usuario.empresa.usar_georeferenciacion_usuarios:false) },
                    nombre: { value: "Nombre", show: true },
                    primer_apellido: { value: "1er Apellido", show: true },
                    segundo_apellido: { value: "2do Apellido", show: true },
                    foto: { value: "Foto", show: true },
                    nombre_usuario: { value: "Usuario", show: true },
                    rol: { value: "Rol", show: true },
                    empresa: { value: "Empresa", show: objeto.usuario.id_empresa?false:true },
                    sucursal: { value: "Sucursal", show: true },
                    activo: { value: "Activo", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_PRODUCTOS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    publicado_tienda: { value: "Pub. Tienda", show: objeto.usuario.empresa.usar_pedidos_en_linea },
                    publicado_panel: { value: "Pub. Panel", show: true },
                    inventario_activado: { value: "Inv. Activo", show: objeto.usuario.empresa.usar_inventario },
                    vencimiento_activado: { value: "Venc. Activo", show: objeto.usuario.empresa.usar_vencimientos },
                    producible: { value: "Producible", show: objeto.usuario.empresa.usar_produccion },
                    codigo: { value: "Codigo", show: objeto.usuario.empresa.usar_ventas },
                    nombre: { value: "Nombre", show: true },
                    imagen: { value: "Imagen", show: true },
                    unidad_medida: { value: "Und. Med.", show: objeto.usuario.empresa.usar_ventas },
                    precio_unitario: { value: "P.U.", show: true },
                    inventario_minimo: { value: "Inv. Min.", show: objeto.usuario.empresa.usar_inventario },
                    descripcion: { value: "Descripcion", show: objeto.usuario.empresa.usar_ventas },
                    tipo_producto: { value: "Tipo", show: false },
                    grupo: { value: "Grupo", show: true },
                    sub_grupo: { value: "Sub Grupo", show: (objeto.usuario.empresa.usar_ventas || objeto.usuario.empresa.cotizaciones || objeto.usuario.empresa.usar_consumo_de_agua) },
                    codigo_fabrica: { value: "Codigo fabrica", show: false },
                    fuente_despacho: { value: "Fuente Desp.", show: false },
                    producto_sin: { value: "Producto S.I.N.", show: objeto.usuario.empresa.usar_facturacion_computarizada_en_linea },
                    sin_unidad_medida: { value: "Unid. Med. S.I.N.", show: objeto.usuario.empresa.usar_facturacion_computarizada_en_linea },
                    propiedades: { value: "Propiedades", show: false }
                },
                opciones_permisos:{
                    impresion:{
                        papel_codigo_barras:GlobalVariable.Dictionary.PAPEL_ROLLO
                    },
                    usar_codigos_sistema:true
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_VEHICULOS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    placa: { value: "Placa", show: true },
                    kilometraje_actual: { value: "Km. Actual", show: true },
                    modelo: { value: "Modelo", show: true },
                    marca: { value: "Marca", show: true },
                    descripcion: { value: "Descripcion", show: true },
                    imagen: { value: "Foto", show: true },
                    numero_ejes: { value: "Num. Ejes", show: objeto.usuario.empresa.usar_buses },
                    capacidad_asientos: { value: "Cap. Asientos", show: objeto.usuario.empresa.usar_buses },
                    numero_llantas: { value: "Num. Llantas", show: objeto.usuario.empresa.usar_buses }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_LLANTAS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    inventario_activado: { value: "Inv. Activo", show: objeto.usuario.empresa.usar_inventario },
                    codigo: { value: "Codigo", show: true },
                    imagen: { value: "Foto", show: true },
                    grupo: { value: "Marca", show: true },
                    sub_grupo: { value: "Condicion", show: true },
                    descripcion: { value: "Descripcion", show: objeto.usuario.empresa.usar_ventas },
                    medida: { value: "Medida", show: true },
                    dot: { value: "Dot", show: true },
                    banda: { value: "Banda", show: true },
                    con_aro:{ value: "Con Aro", show: true },
                    kilometraje_vida:{ value: "Km. Vida", show: true },
                    kilometraje_actual:{ value: "Km. Actual", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_REPUESTOS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    inventario_activado: { value: "Inv. Activo", show: objeto.usuario.empresa.usar_inventario },
                    codigo: { value: "Codigo", show: true },
                    nombre: { value: "Nombre", show: true },
                    imagen: { value: "Foto", show: true },
                    grupo: { value: "Grupo", show: true },
                    sub_grupo: { value: "Sub Grupo", show: true },
                    descripcion: { value: "Descripcion", show: objeto.usuario.empresa.usar_ventas },
                    vida_util_dias: { value: "Vida Util Dias", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_ESTUDIANTES){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    fotografia: { value: "Fotografía", show: true },
                    codigo: { value: "Código", show: true },
                    apellido_paterno: { value: "Apellido Paterno", show: true },
                    apellido_materno: { value: "Apellido Materno", show: true },
                    nombres: { value: "Nombres", show: true },
                    ci: { value: "C.I.", show: true },
                    telefono_movil: { value: "Telf. Móvil", show: true },
                    correo_electronico: { value: "Correo", show: true },
                    fecha_registro: { value: "Registro", show: true }
                },
                opciones_permisos:{
                    columna:"apellido_paterno",
				    direccion:"asc",
                    reporte_pagos_con_fechas:false
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_CARRERAS){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    codigo: { value: "Código", show: true },
                    nombre: { value: "Nombre", show: true },
                    descripcion: { value: "Descripción", show: true },
                    carga_horaria: { value: "Carga horaria", show: true },
                    periodicidad: { value: "Periodicidad", show: true },
                    duracion: { value: "Duración", show: true },
                    activo: { value: "Activo", show: true },
                },
                opciones_permisos:{
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_CONCEPTOS_INGRESOS_INSTITUTO){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    codigo: { value: "Código", show: true },
                    carrera: { value: "Carrera", show: true },
                    gestion: { value: "Gestion", show: true },
                    semestre: { value: "Semestre", show: true },
                    concepto: { value: "Concepto", show: true },
                    importe: { value: "Importe", show: true },
                    cuotas: { value: "Cuotas", show: true },
                    obligado: { value: "Obligado", show: true },
                    calculable: { value: "Calculable", show: true }
                },
                opciones_permisos:{
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_REPORTE_VENTAS_PRODUCTOS){
            configuracion_aplicacion={
                visualizacion_columnas: {

                },
                opciones_permisos:{
                    presentacion_cliente:"campo_texto",
                    presentacion_periodos:"fechas",
                    impresion:{
                        disenio_logo_impresion:GlobalVariable.Dictionary.DISENIO_LOGO_IMPRESION_IZQUIERDA
                    }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_REPORTE_COMPRAS_PRODUCTOS){
            configuracion_aplicacion={
                visualizacion_columnas: {

                },
                opciones_permisos:{
                    presentacion_cliente:"campo_texto",
                    presentacion_periodos:"fechas",
                    impresion:{
                        disenio_logo_impresion:GlobalVariable.Dictionary.DISENIO_LOGO_IMPRESION_IZQUIERDA
                    }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_RESERVAS){
            configuracion_aplicacion={
                visualizacion_columnas: {

                },
                opciones_permisos:{

                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_REPORTE_VENTAS){
            configuracion_aplicacion={
                visualizacion_columnas: {

                },
                opciones_permisos:{
                    presentacion_cliente:"campo_texto",
                    impresion:{
                        disenio_logo_impresion:GlobalVariable.Dictionary.DISENIO_LOGO_IMPRESION_IZQUIERDA
                    }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_MOVIMIENTOS_CAJA){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    tipo_movimiento: { value: "Tipo Mov.", show: true },
                    caja: { value: "Caja", show: true },
                    caja_origen: { value: "Caja Origen", show: false },
                    sucursal: { value: "Sucursal", show: true },
                    fecha: { value: "Fecha", show: true },
                    numero_documento: { value: "Num. Doc.", show: true },
                    saldo_anterior: { value: "Saldo Ant.", show: true },
                    importe: { value: "Importe", show: true },
                    saldo_posterior: { value: "Saldo Post.", show: true },
                    concepto: { value: "Concepto", show: true },
                    transaccion: { value: "Transaccion", show: true },
                    usuario: { value: "Usuario", show: false },
                    glosa: { value: "Glosa", show: false },
                    estado: { value: "Estado", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_SEGUIMIENTO_ESTUDIANTES){
            configuracion_aplicacion={
                visualizacion_columnas: {
                    fecha: { value: "Fecha Doc.", show: true },
                    curso: { value: "Curso", show: true },
                    materia: { value: "Materia", show:true },
                    estudiante: { value: "Estudiante", show: true },
                    categoria: { value: "Categoria", show: true },
                    observacion: { value: "Observacion", show: true },
                    usuario: { value: "Usuario", show: true }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_CITAS_MEDICAS){
            configuracion_aplicacion={
                opciones_permisos:{
                    mostrar_horas_desde:{
                        hour:15,
                        minute:0
                    },
                    mostrar_horas_hasta:{
                        hour:20,
                        minute:0
                    },
                    duracion_cita:{
                        hour:0,
                        minute:15
                    },
                    hora_trabajo_inicio:{
                        hour:8,
                        minute:30
                    },
                    hora_trabajo_fin:{
                        hour:20,
                        minute:0
                    }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_TRATAMIENTOS){
            configuracion_aplicacion={
                opciones_permisos:{
                    mostrar_horas_desde:{
                        hour:15,
                        minute:0
                    },
                    mostrar_horas_hasta:{
                        hour:20,
                        minute:0
                    },
                    duracion_cita:{
                        hour:0,
                        minute:15
                    },
                    hora_trabajo_inicio:{
                        hour:8,
                        minute:30
                    },
                    hora_trabajo_fin:{
                        hour:20,
                        minute:0
                    }
                }
            }
        }
        if(codigo_aplicacion==GlobalVariable.Dictionary.CODIGO_APP_ROL_PARTIDOS){
            configuracion_aplicacion={
                opciones_permisos:{
                    impresion:{
                        papel_planilla_partido:GlobalVariable.Dictionary.PAPEL_CARTA
                    }
                }
            }
        }
        return configuracion_aplicacion;
    }

    static completarAtributosJson(datos_anteriores,datos_actuales){
        let res=true;
        let atributos_anteriores=Object.keys(datos_anteriores);
        let atributos_actuales=Object.keys(datos_actuales);
        //iteracion para agregar nuevos atributos
        for(let i=0;i<atributos_actuales.length;i++){
            let encontrado=false;
            let atributo=atributos_anteriores.filter( atr => atr===atributos_actuales[i]);
            encontrado=atributo.length>0;
            if(!encontrado){
                datos_anteriores[atributos_actuales[i]+""]=datos_actuales[atributos_actuales[i]];
                res=false;
            }
        }
        //iteracion para eliminar atributos no contemplados
        for(let i=0;i<atributos_anteriores.length;i++){
            let encontrado=false;
            let atributo=atributos_actuales.filter( atr => atr===atributos_anteriores[i]);

            encontrado=atributo.length>0;
            if(!encontrado){
                delete datos_anteriores[atributos_anteriores[i]+""];
                res=false;
            }
        }
        return [datos_anteriores,res];
    }

    static reemplazarCaracteresEspeciales(cadena){
		let chars={
			"á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
			"à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n",
			"Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
			"À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
		let expr=/[áàéèíìóòúùñ]/ig;
		let res=cadena.replace(expr,function(e){return chars[e]});
		return res;
	}

    static ordernarMaterias(array){
		array.sort(function(a, b){
			var sortStatus = 0;
			if (a.materia.orden < b.materia.orden) {
				sortStatus = -1;
			} else if (a.materia.orden > b.materia.orden) {
					sortStatus = 1;
			}
			return sortStatus;
		});
	}

    static estaEnListaArea(areaList,area){
		var i=0,found=false;
		while(i<areaList.length && !found){
			if(areaList[i].id==area.id){
				found=true;
			}
			i++;
		}
		return found;
	}

    static calcularPromedioArea(area,calificaciones,areaList){
		var counter=0,sum=0;
		for(var i=0;i<calificaciones.length;i++){
			if(calificaciones[i].materia.area && area.id==calificaciones[i].materia.area.id){
				sum=sum+calificaciones[i].valor;
				counter++;
			}
		}
		if(counter==1){
			return 0;
		}else{
			areaList.push(area);
			var res=[];
			res.push(Math.round(sum/counter));
			res.push(counter);
			res.push(area);
			return res;
		}
	}

    static descargarDocumento(filename,fileUrl){
		var element = document.createElement('a');
		  element.setAttribute('href', fileUrl);
		  element.setAttribute('download',filename);
          element.setAttribute('target',"_blank");

		  element.style.display = 'none';
		  document.body.appendChild(element);

		  element.click();

		  document.body.removeChild(element);
	}

    static obtenerEstudianteMayorCantidadCalificaciones(calificaciones_estudiantes_materias){
        let indice_max=0,valor_max=0;
        for(let i=0;i<calificaciones_estudiantes_materias.length;i++){
            if(calificaciones_estudiantes_materias[i].inscripciones_colegio[0].calificaciones.length>valor_max){
                indice_max=i;
            }
        }
        return indice_max;
    }

    static completarMaterias(materias,calificaciones){
        for(let i=0;i<materias.length;i++){
            let materia_calificacion=calificaciones.filter(c => c.id_materia==materias[i].id);
            if(materia_calificacion.length==0){
                calificaciones.push({id_materia:materias[i].id,materia:materias[i],orden:0});
            }
        }
        return calificaciones;
    }

    static construirAsientos(asientos){
        var asientos_resultado=[],asientos_auxiliares=[];
        for(var i=0;i<asientos.length;i++){
          if(asientos[i].tipo.nombre_corto=="ASAUX" && !asientos[i].eliminado){
            asientos[i].cuenta_busqueda=true;
            asientos_auxiliares.push(asientos[i]);
          }else if(!asientos[i].eliminado){
            asientos[i].cuenta_busqueda=true;
            asientos[i].cuenta_a_buscar=asientos[i].cuenta;
            asientos_resultado.push(asientos[i]);
          }
        }

        for(var i=0;i<asientos_auxiliares.length;i++){
          asientos_resultado=Util.asignarCuentaPadre(asientos_auxiliares[i],asientos_resultado);
        }

        return asientos_resultado;
      }

      static asignarCuentaPadre(asiento_auxiliar,asientos){
        var encontrado=false;
        for(var i=0;i<asientos.length && !encontrado;i++){
          if(asientos[i].cuenta.id==asiento_auxiliar.cuenta.id_cuenta_padre){
            asientos[i].cuenta.cuenta_auxiliar=asiento_auxiliar.cuenta;
            asientos[i].cuenta.cuenta_auxiliar.asiento=JSON.parse(JSON.stringify(asiento_auxiliar));
            encontrado=true;
          }
        }
        return asientos;
      }

      static number_format(amount, decimals) {

        amount += ''; // por si pasan un numero en vez de un string
        amount = parseFloat(amount.replace(/[^0-9\.]/g, '')); // elimino cualquier cosa que no sea numero o punto

        decimals = decimals || 0; // por si la variable no fue fue pasada

        // si no es un numero o es igual a cero retorno el mismo cero
        if (isNaN(amount) || amount === 0)
            return parseFloat("0").toFixed(decimals);

        // si es mayor o menor que cero retorno el valor formateado como numero
        amount = '' + amount.toFixed(decimals);

        var amount_parts = amount.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(amount_parts[0]))
            amount_parts[0] = amount_parts[0].replace(regexp, '$1' + ',' + '$2');

        return amount_parts.join('.');
      }

      static formatearHoraCadena(horas:number,minutos:number,segundos:number){
        let cadena_respuesta=(horas<10?"0"+horas:""+horas)+":"+(minutos<10?"0"+minutos:""+minutos)+":"+(segundos<10?"0"+segundos:""+segundos);
        return cadena_respuesta;
      }

      static calcularEdad(fecha_nacimiento){
        var hoy = new Date();
        var cumpleanos = new Date(fecha_nacimiento);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
    
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
    
        return edad;
      }

      static obtenerCantidadDimensionCalificaciones(registro_profesor,estudiantes,nombre_dimension){
        let calificaciones=estudiantes[0].inscripciones_colegio[0].calificaciones=estudiantes[0].inscripciones_colegio[0].calificaciones?estudiantes[0].inscripciones_colegio[0].calificaciones:[];
		let dimension_object=registro_profesor.configuracion.filter(e => e.dimension.nombre ==nombre_dimension)[0];
		let calificaciones_encontradas=calificaciones.filter(e => e.id_dimension_calificacion && e.id_dimension_calificacion == dimension_object.dimension.id);
        calificaciones_encontradas=calificaciones_encontradas.slice(0,calificaciones_encontradas.length-2);
		return (calificaciones_encontradas.length>0?calificaciones_encontradas.length:dimension_object.configuracion.numero_casillas)+2;
      }
}
