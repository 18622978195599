<div class="modal-header">
    <div class="col-8">
      <h4 class="widget-title">Fecha de vencimiento y Lote</h4>
    </div>
    <div class="col-4">
      <button class="btn btn-primary" type="button" [disabled]="!inventario.fecha_vencimiento_texto" (click)="guardarFechaLoteInventario()">
        <i class="fa fa-save align-top bigger-110"></i>
      </button>&nbsp;
      <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
        <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
        <div class="col-5">
            <label for="form-field-select-1">{{producto.nombre}}</label>
        </div>
        <div class="col-4">
            <label for="form-field-select-1">Fecha de Vencimiento</label>

            <div class="input-group">
              <input name="fecha_ven" class="form-control" placeholder="dd/mm/yyyy"
                      name="dp" [(ngModel)]="inventario.fecha_vencimiento_texto" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
            </div> 
        </div>
        <div class="col-3">
          <label for="form-field-select-1">Lote</label>
          <input [(ngModel)]="inventario.lote" type="text" class="form-control" />
      </div>
    </div>
  </div>