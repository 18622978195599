import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { ClientesService } from '../../services/clientes/clientes.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProductosService } from '../../services/productos/productos.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { ClientesHistoricoVentasComponent } from '../../components/clientes-historico-ventas/clientes-historico-ventas.component';
import * as XLSX from 'xlsx';
import { ConfiguracionAplicacionClientesComponent } from 'src/app/base/components/configuracion-aplicacion-clientes/configuracion-aplicacion-clientes.component';

declare const resaltarPestañaMenu:any;
declare const aplicarVisorImagenes:any;
declare const aplicarWizard:any;
declare const $:any;

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent extends BaseComponent implements OnInit {

  clientes:any[]=[];
  clientes_importacion:any[]=[];
  cliente:any={};
  tipo_categoria:any={};
  contacto:any={};
  tipo_sub_categoria:any={};
  sucursales_registro:any[]=[];
  ver_registro_contacto_nuevo:boolean=true;
  modalHistoricoVentas:NgbModalRef;

  searching = false;
  searchFailed = false;
  persona_busqueda:any;

  markers:any[];
  center:any= { lat: -17.39380008501286700000, lng: -66.15696068334569000000 };
  conexion_google_map:boolean=true;
  bounds:google.maps.LatLngBounds;
  posicion_marcador:google.maps.LatLng;
  zoom = 17;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    //maxZoom: 15,
    //minZoom: 8,
  }
  @ViewChild('mapa') map:google.maps.Map;

  @ViewChildren('clientes') clientes_lectura: QueryList<any>;
  wizard_edicion_cliente:string="wizard_edicion_cliente";

  modal_cliente_modificacion:NgbModalRef;
  @ViewChild('modal_cliente_modificacion')
  private modal_cliente_modificacion_ref: TemplateRef<any>;

  modal_cliente_contactos:NgbModalRef;
  @ViewChild('modal_cliente_contactos')
  private modal_cliente_contactos_ref: TemplateRef<any>;

  modal_contacto_cliente:NgbModalRef;
  @ViewChild('modal_contacto_cliente')
  private modal_contacto_cliente_ref: TemplateRef<any>;

  mapa_clientes:NgbModalRef;
  @ViewChild('mapa_clientes')
  private mapa_clientes_ref: TemplateRef<any>;

  cliente_precios_productos:NgbModalRef;
  @ViewChild('cliente_precios_productos')
  private cliente_precios_productos_ref: TemplateRef<any>;

  producto_busqueda:any;

  clientes_importacion_modal:NgbModalRef;
  @ViewChild('clientes_importacion_modal')
  private clientes_importacion_modal_ref: TemplateRef<any>;

  sin_documentos_identidad:any[]=[];

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    private clientesService:ClientesService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
	private productosService:ProductosService) {
      super(persistenciaService,modalService,generalService,usuarioService);
    }

	async ngOnInit(): Promise<void> {
		this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		this.configuracion_pagina={};
		let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		this.filter={
			id_empresa:this.usuario.id_empresa,
			sucursales:(es_usuario_administrador?[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)):Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: {nombre:"TODOS",id:0},
			categoria: {nombre:"TODOS",id:0},
			categorias:[{nombre:"TODOS",id:0}],
			subcategoria: {nombre:"TODOS",id:0},
			subcategorias:[{nombre:"TODOS",id:0}]
		}
		await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_CLIENTES);
		this.sucursales_registro=Util.obtenerSucursalesUsuario(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(this.usuario.empresa.usar_clientes_sucursal){
				if(es_usuario_administrador){
					this.filter.id_usuario=0;
				}else{
					this.filter.id_usuario=this.usuario.id;
				}
			}else{
				this.filter.id_usuario=0;
			}
		}else{
			this.filter.id_usuario=0;
		}

		if(this.usuario.empresa.usar_clientes_sucursal){
			this.filter.sucursal=(es_usuario_administrador?{nombre:"TODOS",id:0}:Util.obtenerSucursalesUsuario(this.usuario)[0]);
		}

		this.obtenerCategoriasClientesEmpresa();
		this.obtenerSubCategoriasClientesEmpresa();
		this.obtenerCategorias2ClientesEmpresa();
		if(this.usuario.empresa.usar_precios_productos_clientes){
			this.obtenerConceptosPreciosProductos();
		}
		if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
			this.obtenerTiposDocumentosIdentidad();
		}

      	this.column = "razon_social";
		this.getSearch(this.text_search,null);
	}

  ngAfterViewInit() {
      resaltarPestañaMenu(this.router.url.substring(1)+"1");
      this.obtenerTextosEmpresa();
      this.clientes_lectura.changes.subscribe(t => {
        aplicarVisorImagenes("imagen-cliente-vista");
      });
  }

  obtenerCategoriasClientesEmpresa(){
    this.blockUI.start();
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"CATEGORIAS CLIENTES").subscribe((tipoCategoriaClientes:any)=>{
      this.tipo_categoria=tipoCategoriaClientes;
      this.filter.categorias=this.filter.categorias.concat(tipoCategoriaClientes.clases)
      this.blockUI.stop();
    });
  }

  obtenerSubCategoriasClientesEmpresa(){
    this.blockUI.start();
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SUBCATEGORIAS CLIENTES").subscribe((tipoCategoriaClientes:any)=>{
      this.tipo_sub_categoria=tipoCategoriaClientes;
      this.filter.subcategorias=this.filter.subcategorias.concat(tipoCategoriaClientes.clases)
      this.blockUI.stop();
    });
  }

  obtenerCategorias2ClientesEmpresa(){
    this.blockUI.start();
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"CATEGORIAS 2 CLIENTES").subscribe((tipoCategoriaClientes:any)=>{
      this.filter.tipo_categoria_2=tipoCategoriaClientes;
      this.blockUI.stop();
    });
  }

  obtenerConceptosPreciosProductos(){
    this.blockUI.start();
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"PRECIOS PRODUCTOS").subscribe((entidad:any)=>{
      this.filter.tipo_producto_precio=entidad;
      this.blockUI.stop();
    });
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.clientesService.obtenerListaClientes(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.clientes=dato.clientes;
			this.blockUI.stop();
		});
	}

  async crearNuevoCliente(){
	let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
	this.cliente={
		nit:"0",
	  	id_empresa: this.usuario.id_empresa, id_usuario:this.usuario.id,
	  	clientes_razon: [], cliente_destinos: [],
	  	codigo:codigo_nuevo
	}

	if(this.usuario.empresa.usar_clientes_sucursal){
	  this.cliente.sucursal=this.sucursales_registro[0];
	}

	if(this.usuario.empresa.usar_georeferenciacion_clientes){
	  this.cliente.latitud=-17.39380008501286700000;
	  this.cliente.longitud=-66.15696068334569000000;
	  try{
		let bounds = new google.maps.LatLngBounds();
		let markers = [];
		let posicion_marcador=new google.maps.LatLng(this.cliente.latitud,this.cliente.longitud);
		bounds.extend(posicion_marcador);
		markers.push({
		  position: {
			lat: this.cliente.latitud,
			lng: this.cliente.longitud,
		  },
		  label: {
			color: 'red',
		  },
		  options: {
			labelAnchor: "5 55",
			labelClass: 'marker-label',
			draggable: true
		  },
		});

		this.markers = markers;
		this.center={ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng()};
	  }catch(e){
		this.conexion_google_map=false;
		this.toastr.error("No se pudo conectar con google maps!")
	  }
	}
	this.abrirRegistroCliente();
	aplicarWizard(this.wizard_edicion_cliente,this,'guardar');
  }

  obtenerTiposDocumentosIdentidad(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.SIN.DOCUMENTOS_IDENTIDAD).subscribe((res:any)=>{
      this.sin_documentos_identidad=res?res.clases:[];
    });
  }

  async modificarCliente(cliente){
	await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
    this.cliente=cliente;
    if(this.usuario.empresa.usar_georeferenciacion_clientes){
      this.cliente.latitud=this.cliente.latitud?this.cliente.latitud:-17.39380008501286700000;
      this.cliente.longitud=this.cliente.longitud?this.cliente.longitud:-66.15696068334569000000;
      try{
        let bounds = new google.maps.LatLngBounds();
        let markers = [];
        let posicion_marcador=new google.maps.LatLng(this.cliente.latitud,this.cliente.longitud);
        bounds.extend(posicion_marcador);
        markers.push({
          position: {
            lat: this.cliente.latitud,
            lng: this.cliente.longitud,
          },
          label: {
            color: 'red',
          },
          options: {
            labelAnchor: "5 55",
            labelClass: 'marker-label',
            draggable: true
          },
        });

        this.markers = markers;
        this.center={ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng()};
      }catch(e){
        this.conexion_google_map=false;
        this.toastr.error("No se pudo conectar con google maps!")
      }
    }
    this.abrirRegistroCliente();
    aplicarWizard(this.wizard_edicion_cliente,this,'guardar');
  }

	obtenerContactosCliente(cliente){
		this.verContactos(cliente);
		this.abrirListaContactosCliente();
	}

	verContactos(cliente){
		this.blockUI.start();
		this.clientesService.obtenerCliente(cliente.id).subscribe((cliente_consultado:any)=>{
			this.blockUI.stop();
			this.cliente=cliente_consultado;
		});
  	}

	  abrirListaContactosCliente(){
		this.modal_cliente_contactos=this.modalService.open(this.modal_cliente_contactos_ref, {windowClass:"wizard-edicion-cliente",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modal_cliente_contactos.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	  }

  abrirRegistroCliente(){
    this.modal_cliente_modificacion=this.modalService.open(this.modal_cliente_modificacion_ref, {windowClass:"wizard-edicion-cliente",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.modal_cliente_modificacion.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  arrastarPinMapa(event) {
		this.cliente.latitud = event.latLng.lat();
		this.cliente.longitud = event.latLng.lng();
	}

  validarRegistroCliente(){
		let res=true;
		if(!this.cliente.codigo){
			this.toastr.error("Debe ingresar un codigo de cliente!");
			res=false;
		}
		if(!this.cliente.identificacion){
			this.toastr.error("Debe ingresar la identificacion del cliente!");
			res=false;
		}
		if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
			if(!this.cliente.tipo_documento){
			  res=res && false;
			  this.toastr.error("Debe especificar el tipo de documento del cliente!");
			}
		}
		if(this.usuario.empresa.usar_facturacion || this.usuario.empresa.usar_facturacion_computarizada_en_linea){
			if(!this.cliente.razon_social){
				res=res && false;
				this.toastr.error("Debe especificar la razon social del cliente!");
			}
			if(!this.cliente.nit){
				res=res && false;
				this.toastr.error("Debe especificar el nit/ci del cliente!");
			}
		}
		return res;
	}

	async guardar(){
		if(this.validarRegistroCliente()){
			this.blockUI.start();
			if(this.cliente.id){
				this.clientesService.actualizarCliente(this.cliente).subscribe((res:any) => {
					this.blockUI.stop();
          this.getItems();
					this.toastr.success("Cliente registrado satisfactoriamente");
					this.modal_cliente_modificacion.close();
				});
			}else{
				this.cliente.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE):this.cliente.codigo;
				this.clientesService.guardarCliente(this.cliente).subscribe((res:any) => {
					this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
					this.blockUI.stop();
					this.toastr.success("Cliente registrado satisfactoriamente");
          this.getItems();
					this.modal_cliente_modificacion.close();
				});
			}
		}else{
			this.blockUI.stop();
		}
	}

  abrirPopupConfirmacionEliminacion(cliente){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el cliente?";
		this.popupConfirmacion.componentInstance.data = cliente;

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCliente($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

  eliminarCliente(cliente){
		this.blockUI.start();
    this.clientesService.eliminarCliente(cliente.id).subscribe((res:any)=>{
      this.toastr.success(res.mensaje);
      this.getItems();
      this.blockUI.stop();
    });
  }

  abrirMapaClientes(){
		this.mapa_clientes=this.modalService.open(this.mapa_clientes_ref, {windowClass:'mapa-clientes', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.mapa_clientes.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});

		this.bounds = new google.maps.LatLngBounds();
		let markers = [];
		for (var i = 0; i < this.clientes.length; i++) {
			if(this.clientes[i].latitud && this.clientes[i].longitud){
				this.posicion_marcador=new google.maps.LatLng(this.clientes[i].latitud,this.clientes[i].longitud);
				this.bounds.extend(this.posicion_marcador);
				markers.push(this.crearMarcador(i,null,this.clientes[i].latitud,this.clientes[i].longitud,""/*this.clientes[i].identificacion*/));
			}
		}
		this.markers = markers;
		this.center={ lat: this.bounds.getCenter().lat(), lng: this.bounds.getCenter().lng()};
	}

	crearMarcador(i, idKey,lat,lng,nombre) {
		if (idKey == null) {
			idKey = "id";
		}

		var latitude = lat;
		var longitude = lng;
		var ret = {
			position: {
				lat: latitude,
				lng: longitude,
			},
			label: {
				color: 'blue',

			},
			title:nombre,
			options: {
				icon:'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
				labelContent : nombre,
				labelAnchor: "5 55",
				labelClass: 'marker-label',
			},
		};
		ret[idKey] = i;
		return ret;
	}

	imprimirMapaClientes() {
		var printContents = document.getElementById("map-view").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}

  descargarExcelEjemploImportacion(){
	var data=[];
	var cabecera=["Codigo","Identificación","Descripción", this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL),
	this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_NIT),"Dirección",this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_CATEGORIA),
	this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_SUBCATEGORIA),
	this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_CATEGORIA_2),
	"Telefono 1","Telefono 2","E-mail"
	];
	if(this.usuario.empresa.usar_georeferenciacion_clientes){
		cabecera.push("Geo Latitud");
		cabecera.push("Geo Longitud");
	}
	data.push(cabecera);
	var totalCosto = 0;
	for (var i = 1; i <= 14; i++) {
		var columns = [];
		columns.push("COD"+i);
		columns.push("CLIENTE "+i);
		columns.push("CLIENTE "+i);
		columns.push("CLIENTE "+i);
		columns.push("123456789");
		columns.push("Escriba direccion");
		columns.push("Escriba "+this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_CATEGORIA));
		columns.push("Escriba "+this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_SUBCATEGORIA));
		columns.push("Escriba "+this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_CATEGORIA_2));
		columns.push("59170723756");
		columns.push("");
		columns.push("snapcodecbba@gmail.com");
		if(this.usuario.empresa.usar_georeferenciacion_clientes){
			columns.push("-17.39380008501286700000");
			columns.push("-66.15696068334569000000");
		}
		data.push(columns);
	}

	let workbook = new Workbook();
	let worksheet = workbook.addWorksheet("CLIENTES");
	data.forEach(d => {
	let row = worksheet.addRow(d);
	}
	);

	const dobCol2 = worksheet.getColumn(2);
	dobCol2.width = 40;
	const dobCol3 = worksheet.getColumn(3);
	dobCol3.width = 20;

	workbook.xlsx.writeBuffer().then((data) => {
	let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	fs.saveAs(blob, "EJEMPLO-IMPORTACION-CLIENTES.xlsx");
	})
	this.blockUI.stop();
  }

  subirExcelClientes(event){
	this.blockUI.start();
	let me=this;
	var files = event.target.files;
	var i, f;
	for (i = 0, f = files[i]; i != files.length; ++i) {
		var reader = new FileReader();
		var name = f.name;
		reader.onload = function (e) {

			var data = e.target.result;

			var workbook = XLSX.read(data, { type: 'binary' });
			var first_sheet_name = workbook.SheetNames[0];
			var row = 2, i = 0;
			var worksheet = workbook.Sheets[first_sheet_name];
			var clientes = [];
			do {
				let cliente:any = {};
				cliente.codigo = worksheet['A' + row] != undefined && worksheet['A' + row] != "" ? worksheet['A' + row].v.toString() : null;
				cliente.identificacion = worksheet['B' + row] != undefined && worksheet['B' + row] != "" ? worksheet['B' + row].v.toString() : null;
				cliente.descripcion = worksheet['C' + row] != undefined && worksheet['C' + row] != "" ? worksheet['C' + row].v.toString() : null;
				cliente.razon_social = worksheet['D' + row] != undefined && worksheet['D' + row] != "" ? worksheet['D' + row].v.toString() : null;
				cliente.nit = worksheet['E' + row] != undefined && worksheet['E' + row] != "" ? worksheet['E' + row].v.toString() : null;
				cliente.direccion = worksheet['F' + row] != undefined && worksheet['F' + row] != "" ? worksheet['F' + row].v.toString() : null;
				cliente.categoria = worksheet['G' + row] != undefined && worksheet['G' + row] != "" ? worksheet['G' + row].v.toString() : null;
				cliente.subcategoria = worksheet['H' + row] != undefined && worksheet['H' + row] != "" ? worksheet['H' + row].v.toString() : null;
				cliente.categoria_2 = worksheet['I' + row] != undefined && worksheet['I' + row] != "" ? worksheet['I' + row].v.toString() : null;
				cliente.telefono1 = worksheet['J' + row] != undefined && worksheet['J' + row] != "" ? worksheet['J' + row].v.toString() : null;
				cliente.telefono2 = worksheet['K' + row] != undefined && worksheet['K' + row] != "" ? worksheet['K' + row].v.toString() : null;
				cliente.email = worksheet['L' + row] != undefined && worksheet['L' + row] != "" ? worksheet['L' + row].v.toString() : null;
				if(me.usuario.empresa.usar_georeferenciacion_clientes){
					cliente.latitud = worksheet['M' + row] != undefined && worksheet['M' + row] != "" ? parseFloat(worksheet['M' + row].v.toString()) : null;
					cliente.longitud = worksheet['N' + row] != undefined && worksheet['N' + row] != "" ? parseFloat(worksheet['N' + row].v.toString()) : null;
				}
				clientes.push(cliente);
				row++;
				i++;
			} while (worksheet['A' + row] != undefined);
				//$scope.guardarClientes(clientes);
				me.clientes_importacion=clientes;
				me.clientes_importacion_modal=me.modalService.open(me.clientes_importacion_modal_ref, {windowClass:"importacion-productos",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
				me.clientes_importacion_modal.result.then((result) => {
					me.closeResult = `Closed with: ${result}`;
				}, (reason) => {
					me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
				});
				me.blockUI.stop();
			};
			reader.readAsBinaryString(f);
		}
	}

	descargarExcelClientes(){
		var data=[];
		var cabecera=["Codigo", this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL),
		this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_NIT),"Dirección",this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_CATEGORIA),
		"Telefono 1","Telefono 2","E-mail",
		"Contacto","Ubicacion Geográfica","Rubro",
		"Geo Latitud","Geo Longitud"];
		data.push(cabecera);
		var totalCosto = 0;
		for (var i = 0; i < this.clientes.length; i++) {
			var columns = [];
			columns.push(this.clientes[i].codigo);
			columns.push(this.clientes[i].razon_social);
			columns.push(this.clientes[i].nit);
			columns.push(this.clientes[i].direccion);
			columns.push(this.clientes[i].categoria?this.clientes[i].categoria.nombre:"");
			columns.push(this.clientes[i].telefono1);
			columns.push(this.clientes[i].telefono2);
			columns.push(this.clientes[i].email);
			columns.push(this.clientes[i].contacto);
			columns.push(this.clientes[i].ubicacion_geografica);
			columns.push(this.clientes[i].rubro);
			columns.push(this.clientes[i].latitud);
			columns.push(this.clientes[i].longitud);
			data.push(columns);
		}

		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("CLIENTES");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 40;
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 20;

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fs.saveAs(blob, "Clientes.xlsx");
		})
		this.blockUI.stop();
	}

	abrirPopupConfirmacionEliminacionContactoCliente(cliente_contacto){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el contacto de cliente?";
		this.popupConfirmacion.componentInstance.data = cliente_contacto;

		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarContactoCliente($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	eliminarContactoCliente(contacto_cliente){
		this.blockUI.start();
		this.clientesService.eliminarContactoCliente(contacto_cliente.id).subscribe((res:any)=>{
			this.blockUI.stop();
			this.toastr.success(res.mensaje);
			this.verContactos(this.cliente);
		});
	}

	crearNuevoContacto(){
		this.reiniciarPersona();
		this.ver_registro_contacto_nuevo=true;
		this.contacto={
			id_cliente:this.cliente.id,
			persona:{ci:0}
		}
		this.modal_contacto_cliente=this.modalService.open(this.modal_contacto_cliente_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modal_contacto_cliente.result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	verRegistroContactoNuevo(valor){
		this.ver_registro_contacto_nuevo=valor;
	}

	buscarContactoCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.clientesService.buscarClienteContacto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)

  formatterContactoCliente = (x: any) => (x.nombre_completo);

  establecerContactoCliente($event){
    this.contacto.persona=$event.item
  }

  reiniciarPersona(){
	this.persona_busqueda="";
	this.contacto.persona={};
	$("#regcontactcli").val("");
  }

  	validarRegistroContactoCliente(){
		let res=true;
		if(!this.contacto.persona.nombres){
		  res=res && false;
		  this.toastr.error("Debe especificar el nombre del contacto!");
		}
		if(!this.contacto.persona.ci){
		  res=res && false;
		  this.toastr.error("Debe especificar el ci del contacto!");
		}
		return res;
	}

  	guardarRegistroContacto(){
		  if(this.validarRegistroContactoCliente()){
			this.blockUI.start();
			this.clientesService.guardarContactoCliente(this.contacto).subscribe((res:any)=>{
				this.blockUI.stop();
				this.verContactos(this.cliente);
				this.modal_contacto_cliente.close();
				this.toastr.success(res.mensaje);
			});
		  }
		/*this.contacto.$save(function(res){
			this.cerrarPopup(this.idModalRegistroContacto);
			this.verContactos(this.cliente);
			this.mostrarMensaje(res.mensaje);
		},function(err){
			this.mostrarMensaje("Ocurrio un error, intentelo nuevamente!");
		})*/
	}

	abrirPopupPreciosProductos(cliente){
		this.blockUI.start();
		this.cliente=cliente;
		this.cliente.producto_precio={};
		this.clientesService.obtenerPreciosProductosCliente(cliente.id).subscribe((productos_precios:any[])=>{
			this.blockUI.stop();
			this.cliente.productos_precios=productos_precios;
			this.cliente_precios_productos=this.modalService.open(this.cliente_precios_productos_ref, {scrollable:true, windowClass:'precios_cliente', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.cliente_precios_productos.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		});
	}

	buscarProducto = (text$: Observable<string>) =>
		text$.pipe(
		debounceTime(300),
		distinctUntilChanged(),
		tap(() => this.searching = true),
		switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
		catchError(() => {
			this.searchFailed = true;
			return of([]);
		})  ,
		tap(() => this.searching = false)
	)

  	formatterProducto = (x: any) => (x.nombre);

	establecerProducto($event){
		this.cliente.producto_precio.producto=$event.item
	}

	iniciarProductoPrecio(){
		this.producto_busqueda="";
		this.cliente.producto_precio.producto=null;
	}

	agregarProductoPrecio(precio_producto){
		precio_producto.id_cliente=this.cliente.id;
		this.cliente.productos_precios.push(precio_producto);
		this.cliente.producto_precio={};
		this.iniciarProductoPrecio();
	}

	establecerPrecioPorDefecto(producto_precio){
		for(var i=0;i<this.cliente.productos_precios.length;i++){
			if(this.usuario.empresa.usar_precios_productos_sucursal){
				/*if(producto_precio.sucursal && producto_precio.sucursal.id==this.producto.precios[i].sucursal.id){
					this.producto.precios[i].por_defecto=false;
				}*/
			}else{
				if(producto_precio.producto.id==this.cliente.productos_precios[i].producto.id && !this.cliente.productos_precios[i].eliminado){
					this.cliente.productos_precios[i].por_defecto=false;
				}
			}
		}
		producto_precio.por_defecto=true;
	}

	eliminarDetalleProductosPrecio(producto_precio){
		producto_precio.eliminado=true;
	}

	guardarPreciosProductosCliente(){
		this.blockUI.start();
		this.clientesService.guardarPreciosProductosCliente({productos_precios:this.cliente.productos_precios}).subscribe((res:any)=>{
			this.toastr.success(res.mensaje);
			this.cliente_precios_productos.close();
			this.blockUI.stop();
		});
	}

	abrirPopupHistoricoVentas(cliente){
		this.modalHistoricoVentas = this.modalService.open(ClientesHistoricoVentasComponent, {windowClass:'historico-ventas', scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modalHistoricoVentas.componentInstance.cliente = cliente;
		this.modalHistoricoVentas.componentInstance.usuario = this.usuario;

		this.modalHistoricoVentas.componentInstance.alTerminar.subscribe((res) => {
			this.modalHistoricoVentas.close();
		});
	}

	guardarClientes() {
		this.blockUI.start();
		this.clientesService.guardarClientesImportacion({ clientes: this.clientes_importacion,
			id_empresa: this.usuario.id_empresa,
			id_usuario: this.usuario.id,
			sucursal: this.filter.sucursal.id,
		 }).subscribe((res:any)=>{
			 this.blockUI.stop();
			 this.clientes_importacion_modal.close();
			 this.getItems();
			this.toastr.success(res.mensaje);
		 });
	}

	establecerRazonSocial(){
		if(!this.cliente.razon_social){
		  this.cliente.razon_social=this.cliente.identificacion;
		}
	}

	abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionClientesComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

  actualizarAtributoCliente(cliente, atributo, valor){
		this.blockUI.start();
		let datos:any={
      id: cliente.id,
			atributo,
      valor,
		};
		this.clientesService.actualizarAtributoCliente(datos).subscribe((res:any) => {
			this.getItems();
			this.toastr.success(res.mensaje);
			this.blockUI.stop();
		});
	}
}
