<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Cierres de Caja
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Cierres de Caja
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <app-paginator-header [paginator]="this"></app-paginator-header>
            </div>
            </div>
            <div class="row">
            <div class="col-12 col-md-12">
              <div class="row">
                <div *ngIf="usuario.empresa.usar_sucursales" class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                <div *ngIf="usuario.empresa.usar_sucursales" class="col-md-3 col-sm-12">
                    <select [compareWith]="compararObjectos" class="form-control" name="sucursal" [(ngModel)]="filter.sucursal">
                        <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-1 col-sm-12"><label>Usuario </label></div>
                <div class="col-md-3 col-sm-12">
                    <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                        <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                    </select>
                </div>
              </div>
            </div>
          </div>
		      </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="fa fa fa-search"></i>
                  </button>
                  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoCierre()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-purple-d1"></i>
                  </button>
                  <div class="keep-open btn-group show" title="Columns">
                    <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
                      <i class="fa fa-wrench text-blue-d1"></i>
                      <span class="caret"></span>
                    </button>
          
                    <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                        <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                          <div class="card bgc-primary brc-primary radius-0">
                            <div class="card-header">
                            <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                            </div>
                  
                            <div class="card-body p-0 bg-white">
                            <table class="table table-striped table-hover mb-0">
                              <tbody>
                                <tr *ngIf="aplicacion.puede_modificar">
                                  <td class="text-dark-m2">
                                  Configuración Vista
                                  </td>
                                  <td class="text-dark-m2">
                                    <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            </div>
                          </div>
                          </div>
                      </div>
                    <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
                  </div>
                </div>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="cierres_caja.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th *ngIf="configuracion_pagina.getConfiguration().sucursal.show"><a href="javascript:void(0)" (click)="this.sortColumn('sucursal.nombre')">Sucursal <span id="sucursal" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().usuario.show"><a href="javascript:void(0)" (click)="this.sortColumn('usuario.nombre_usuario')">Usuario <span id="usuario" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().fecha_apertura.show"><a href="javascript:void(0)" (click)="this.sortColumn('fecha_apertura')">Fecha Apertura <span id="fecha" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().fecha_cierre.show"><a href="javascript:void(0)" (click)="this.sortColumn('fecha_cierre')">Fecha Cierre <span id="fechad" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().saldo_inicial.show"><a href="javascript:void(0)" (click)="this.sortColumn('saldo_inicial')">Saldo Inicial <span id="ingresos_caja" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().ingresos.show"><a href="javascript:void(0)" (click)="this.sortColumn('depositos')">Depositos <span id="depositos" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().salidas.show"><a href="javascript:void(0)" (click)="this.sortColumn('salidas')">Salidas <span id="salidas" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().ventas_contado_recibos.show"><a href="javascript:void(0)" (click)="this.sortColumn('ventas_contado_recibos')">Ventas Contado Recibos <span id="ventas_contado_recibos" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().ventas_contado_facturas.show"><a href="javascript:void(0)" (click)="this.sortColumn('ventas_contado_facturas')">Ventas Contado Facturas <span id="ventas_contado_facturas" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().ventas_credito.show"><a href="javascript:void(0)" (click)="this.sortColumn('ventas_credito')">Ventas Credito <span id="ventas_credito" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().compras_contado.show"><a href="javascript:void(0)" (click)="this.sortColumn('compras_contado')">Compras Contado <span id="compras_contado" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().compras_credito.show"><a href="javascript:void(0)" (click)="this.sortColumn('compras_credito')">Compras Credito <span id="compras_credito" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().saldo_faltante.show"><a href="javascript:void(0)" (click)="this.sortColumn('saldo_faltante')">Saldo Faltante <span id="saldo_faltante" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().saldo_sobrante.show"><a href="javascript:void(0)" (click)="this.sortColumn('saldo_sobrante')">Saldo Sobrante <span id="saldo_sobrante" class="sort fa fa-sort icon-only"></span></a></th>
                <th><a href="javascript:void(0)" (click)="this.sortColumn('saldo_caja')">Saldo Caja <span id="saldo_caja" class="sort fa fa-sort icon-only"></span></a></th>
                <th><a href="javascript:void(0)" (click)="this.sortColumn('numero_documento')">Numero Documento <span id="numero_documento" class="sort fa fa-sort icon-only"></span></a></th>
                <th><a href="javascript:void(0)" (click)="this.sortColumn('abierto')">Estado <span id="abierto" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #cierres_caja *ngFor="let cierreCaja of cierres_caja; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().sucursal.show">
                    {{cierreCaja.sucursal}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().usuario.show">
                    {{cierreCaja.usuario}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().fecha_apertura.show">
                    {{cierreCaja.fecha_apertura | date:"dd/MM/yyyy - HH:mm"}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().fecha_cierre.show">
                    {{cierreCaja.fecha_cierre | date:"dd/MM/yyyy - HH:mm"}}
                </td>
                <!--<td>{{cierreCaja.usuario}}</td>-->
                <td *ngIf="configuracion_pagina.getConfiguration().saldo_inicial.show">{{cierreCaja.saldo_inicial.toFixed(2)}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().ingresos.show">{{cierreCaja.depositos.toFixed(2)}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().salidas.show">{{cierreCaja.salidas.toFixed(2)}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().ventas_contado_recibos.show">{{cierreCaja.ventas_contado_recibos?cierreCaja.ventas_contado_recibos.total.toFixed(2):0.00}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().ventas_contado_facturas.show">{{cierreCaja.ventas_contado_facturas?cierreCaja.ventas_contado_facturas.total.toFixed(2):0.00}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().ventas_credito.show">{{cierreCaja.ventas_credito.toFixed(2)}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().compras_contado.show">{{cierreCaja.compras_contado.toFixed(2)}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().compras_credito.show">{{cierreCaja.compras_credito.toFixed(2)}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().saldo_faltante.show">
                    <span [ngClass]="{'badge badge-lg badge-danger':cierreCaja.saldo_faltante>0}">{{cierreCaja.saldo_faltante?cierreCaja.saldo_faltante.toFixed(2):0.00}}</span>
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().saldo_sobrante.show">
                    <span [ngClass]="{'badge badge-lg badge-success':cierreCaja.saldo_sobrante>0}">{{cierreCaja.saldo_sobrante?cierreCaja.saldo_sobrante.toFixed(2):0.00}}</span>
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().saldo_caja.show">
                    <span class="badge badge-lg badge-primary">{{cierreCaja.saldo_caja?cierreCaja.saldo_caja.toFixed(2):0.00}}</span>
                </td>
                <td>
                    {{cierreCaja.numero_documento}}
                </td>
                <td>
                    <span *ngIf="cierreCaja.abierto==1" class="badge badge-md badge-success">ABIERTO</span>
                    <span *ngIf="cierreCaja.abierto==0" class="badge badge-md badge-danger">CERRADO</span>
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">  
                    <a *ngIf="cierreCaja.abierto==1 && usuario.empresa.usar_conteo_de_dinero && !cierreCaja.conteo_dinero" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="depositarDinero(cierreCaja)">          
                        <i class="fa fa-plus text-105"></i>        
                    </a>
                    <a *ngIf="cierreCaja.abierto==1 && !usuario.empresa.usar_conteo_de_dinero" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="depositarDinero(cierreCaja)">          
                        <i class="fa fa-plus text-105"></i>        
                    </a> 
                    <a *ngIf="cierreCaja.abierto==1 && usuario.empresa.usar_conteo_de_dinero && !cierreCaja.conteo_dinero" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="retirarDinero(cierreCaja)">          
                        <i class="fa fa-minus text-105"></i>        
                    </a>
                    <a *ngIf="cierreCaja.abierto==1 && !usuario.empresa.usar_conteo_de_dinero" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="retirarDinero(cierreCaja)">          
                        <i class="fa fa-minus text-105"></i>        
                    </a> 
                    <a *ngIf="cierreCaja.abierto==1 && usuario.empresa.usar_conteo_de_dinero && !cierreCaja.conteo_dinero" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirModalConteoDinero(cierreCaja)">          
                      <i class="fas fa-search-dollar text-105"></i>        
				    </a>
                    <a *ngIf="cierreCaja.abierto==1 && usuario.empresa.usar_conteo_de_dinero && cierreCaja.conteo_dinero!=null" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(cierreCaja)">          
                      <i class="fa fa-lock text-105"></i>        
				    </a>
                    <a *ngIf="cierreCaja.abierto==1 && !usuario.empresa.usar_conteo_de_dinero" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(cierreCaja)">          
                        <i class="fa fa-lock text-105"></i>        
                    </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="verTransaccionesCaja(cierreCaja)">          
                        <i class="fa fa-eye text-105"></i>        
                    </a>
                    <a *ngIf="cierreCaja.abierto==0" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirCierreCaja(cierreCaja)">          
                        <i class="fa fa-print text-105"></i>        
                      </a>
                    <a *ngIf="cierreCaja.abierto==0" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="imprimirMovimientoProductos(cierreCaja)">          
                      <i class="fa fa-print text-105"></i>        
					</a> 
                    <a *ngIf="cierreCaja.abierto==0" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="generarExcelMovimientoProductos(cierreCaja)">          
                        <i class="fa fa-file-excel text-105"></i>        
                      </a> 
                    <a *ngIf="cierreCaja.abierto==0 && usuario.empresa.usar_caja_general" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirModalIngresoCaja(cierreCaja)">          
                        <i class="fas fa-box text-105"></i>        
                      </a> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="cierres_caja.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #modal_registro_cierre_caja let-modal>
    <div class="modal-header card-header">
        <div class="col-8">
            <span class="text-blue text-125">Cierre de Caja Sucursal: {{cierre_caja.sucursal}}</span>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-primary btn-primary" (click)="cerrarCaja()" >
                <i class="fa fa-save red2 align-top bigger-125"></i>
            </button>&nbsp;
            <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
              <i class="fa fa-times red2 align-top bigger-125"></i>
          </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <span class="badge badge-primary text-white badge-lg radius-4 mb-1">Saldo Inicial</span>
            </div>
            <div class="col-sm-12 col-md-6">
                <span class="badge badge-primary text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.saldo_inicial.toFixed(2)}}</span>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-4 col-12">
                <div class="row">
                    <div class="col-sm-6 col-md-6">
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <h5 class="widget-title text-success">Ingresos</h5>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <label>Depositos caja</label>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <span class="badge badge-success text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.depositos?cierre_caja.depositos.toFixed(2):0}}</span>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <label>Ventas al contado recibos</label>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <span class="badge badge-success text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.ventas_contado_recibos.total.toFixed(2)}}</span>
                            </div>
                            <ng-container *ngIf="usuario.empresa.usar_facturacion || usuario.empresa.usar_facturacion_computarizada_en_linea">
                                <div class="col-sm-12 col-md-6">
                                    <label>Ventas al contado facturas</label>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <span class="badge badge-success text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.ventas_contado_facturas.total.toFixed(2)}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="usuario.empresa.usar_vencimiento_economico_ventas">
                                <div class="col-sm-12 col-md-6">
                                    <label>Cobros ventas al credito</label>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <span class="badge badge-success text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.ventas_credito.toFixed(2)}}</span>
                                </div>
                            </ng-container>
                            <ng-container>
                                <div class="col-sm-12 col-md-6">
                                    <label>Ventas Gift-Card Efectivo</label>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <span class="badge badge-success text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.ventas_contado_giftcard.total.toFixed(2)}}</span>
                                </div>
                            </ng-container>
                            <ng-container>
                                <div class="col-sm-12 col-md-6">
                                    <label>Ventas Efectivo Qr</label>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <span class="badge badge-success text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.ventas_contado_qr.total.toFixed(2)}}</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6">
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <h5 class="widget-title text-danger">Egresos</h5>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <label>Retiros caja</label>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <span class="badge badge-danger text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.salidas?cierre_caja.salidas.toFixed(2):0}}</span>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <label>Compras al contado</label>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <span class="badge badge-danger text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.compras_contado.toFixed(2)}}</span>
                            </div>
                            <ng-container *ngIf="usuario.empresa.usar_vencimiento_economico_compras">
                                <div class="col-sm-12 col-md-6">
                                    <label>Pagos compras al credito</label>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <span class="badge badge-danger text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.compras_credito.toFixed(2)}}</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <ng-container *ngFor="let suma_ventas_no_contado of cierre_caja.ventas_no_contado">
                    <hr>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <span class="text-purple">Ventas {{suma_ventas_no_contado.tipo_pago}}</span>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <span class="badge bgc-purple brc-purple text-white badge-lg radius-4 mb-1">Bs. {{suma_ventas_no_contado.total.toFixed(2)}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col-md-4 col-12">
                <ng-container *ngIf="usuario.empresa.usar_conteo_de_dinero">
                    <div class="row">
                        <div class="col-12">
                            <fieldset> <legend>Arqueo de Caja <i class="fas fa-money-bill-wave text-success"></i></legend>
                                <div class="row">
                                    <div class="col-4 text-primary">
                                        <label>Total Efectivo</label>&nbsp;
                                        <span class="badge badge-primary text-white badge-lg radius-4 mb-1" >Bs. {{cierre_caja.total_efectivo}}</span>
                                    </div>
                                    <div class="col-4 text-grey">
                                        <label>Total Tarjetas</label>&nbsp;
                                        <span class="badge badge-secondary text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.total_tarjetas}}</span>
                                    </div>
                                    <div class="col-4 text-success">
                                        <label>Total Pagos en línea</label>&nbsp;
                                        <span class="badge text-white badge-lg radius-4 mb-1 bgc-purple">Bs. {{cierre_caja.total_pagos_en_linea}}</span>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </ng-container>
                <!--<ng-container *ngIf="usuario.empresa.usar_conteo_de_dinero">-->
                <hr>
                <div class="row">
                    <div class="col-4">
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <span class="badge badge-danger text-white badge-lg radius-4 mb-1">Saldo Faltante</span>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                    <input disabled class="form-control" [(ngModel)]="cierre_caja.saldo_faltante" min="0" type="number" step="0.001">
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <span class="badge badge-success text-white badge-lg radius-4 mb-1">Saldo Sobrante</span>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                    <input disabled class="form-control" [(ngModel)]="cierre_caja.saldo_sobrante" min="0" type="number" step="0.001">
                            </div>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-5">
                                <span class="text-primary">Saldo Final Caja</span>
                            </div>
                            <div class="col-7">
                                <span class="badge mr-2 pos-rel p-2px text-white mt-2 radius-2 overflow-hidden text-180" [ngClass]="{'bgc-success':cierre_caja.saldo_caja>0,'bgc-danger':cierre_caja.saldo_caja<=0}">Bs. {{cierre_caja.saldo_caja}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--</ng-container>-->
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modal_apertura_caja let-modal>
    <div class="modal-header card-header">
        <div class="col-8">
            <span class="text-blue text-125">Apertura de Caja</span>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-primary btn-primary" (click)="aperturarCajas()" >
                <i class="fa fa-save red2 align-top bigger-125"></i>
            </button>&nbsp;
            <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
              <i class="fa fa-times red2 align-top bigger-125"></i>
          </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="widget-box">
            <div class="widget-body">
                <div class="widget-main">
                    <div class="row" *ngIf="usuario.empresa.usar_sucursales">
                        <div class="col-sm-12 col-md-6">
                            <label>Sucursal</label>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <select [compareWith]="compararObjectos" class="form-control" name="sucursal_usuario" [(ngModel)]="nuevo_cierre_caja.sucursal">
                                <option [ngValue]="sucursal_usuario.sucursal" *ngFor="let sucursal_usuario of usuario.sucursalesUsuario">{{sucursal_usuario.sucursal.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <label>Saldo Inicial</label>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <input class="form-control" required [(ngModel)]="nuevo_cierre_caja.saldo_inicial" min="0" type="number" step="0.001">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modal_conteo_dinero let-modal>
    <div class="modal-header card-header">
        <div class="col-8">
            <span class="text-blue text-125">Arqueo de Caja</span>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-primary btn-primary" (click)="guardarConteoCaja()" >
                <i class="fa fa-save red2 align-top bigger-125"></i>
            </button>&nbsp;
            <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
              <i class="fa fa-times red2 align-top bigger-125"></i>
          </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-md-6">
                <fieldset> <legend>Billetes <i class="fas fa-money-bill-wave text-primary"></i></legend>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 200</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_200" placeholder="200" class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 100</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_100" placeholder="100" class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 50</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_50" placeholder="50" class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 20</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_20" placeholder="20" class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 10</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_10" placeholder="10" class="form-control" />
                        </div>
                    </div>

                </fieldset>
            </div>
            <div class="col-md-6">
                <fieldset> <legend>Monedas <i class="fas fa-coins text-grey"></i></legend>

                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 5</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_5" placeholder="5" class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 2</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_2" placeholder="2" class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 1</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_1" placeholder="1" class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 0.50</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_05" placeholder="0.50" class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 0.20</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_02" placeholder="0.20" class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <label>Bs 0.10</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <input type="number" step="0" [(ngModel)]="conteo_dinero.cantidad_01" placeholder="0.10" class="form-control" />
                        </div>
                    </div>

                </fieldset>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <fieldset> <legend>Totales <i class="fas fa-calculator"></i></legend>
                    <div class="row">
                        <div class="col-4 text-primary">
                            <label>Billetes</label>&nbsp;
                            <span class="badge badge-primary text-white badge-lg radius-4 mb-1">Bs. {{sumarBilletes()}}</span>
                        </div>
                        <div class="col-4 text-grey">
                            <label>Monedas</label>&nbsp;
                            <span class="badge badge-secondary text-white badge-lg radius-4 mb-1">Bs. {{sumarMonedas()}}</span>
                        </div>
                        <div class="col-4 text-success">
                            <label>Efectivo</label>&nbsp;
                            <span class="badge badge-success text-white badge-lg radius-4 mb-1">Bs. {{sumarTotalConteoCaja()}}</span>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-3">
                <fieldset> <legend>Tarjetas <i class="fab fa-cc-visa text-primary"></i></legend>
                    <div class="row">
                        <div class="col-sm-3 col-md-3">
                            <label>Bs</label>
                        </div>
                        <div class="col-sm-9 col-md-9 p-0">
                            <input type="number" step="0.001" [(ngModel)]="conteo_dinero.total_tarjetas" placeholder="0" class="form-control" />
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-md-3">
                <fieldset> <legend>Pagos en línea <i class="fas fa-shopping-cart text-warning"></i></legend>
                    <div class="row">
                        <div class="col-sm-3 col-md-3">
                            <label>Bs</label>
                        </div>
                        <div class="col-sm-9 col-md-9 p-0">
                            <input type="number" step="0.001" [(ngModel)]="conteo_dinero.total_pagos_en_linea" placeholder="0" class="form-control" />
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-md-3">
                <fieldset> <legend>Gift Card <i class="fas fa-id-card-alt text-warning"></i></legend>
                    <div class="row">
                        <div class="col-sm-3 col-md-3">
                            <label>Bs</label>
                        </div>
                        <div class="col-sm-9 col-md-9 p-0">
                            <input type="number" step="0.001" [(ngModel)]="conteo_dinero.total_pagos_gift_card" placeholder="0" class="form-control" />
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-md-3">
                <fieldset> <legend>Pagos QR <i class="fas fa-qrcode text-blue"></i></legend>
                    <div class="row">
                        <div class="col-sm-3 col-md-3">
                            <label>Bs</label>
                        </div>
                        <div class="col-sm-9 col-md-9 p-0">
                            <input type="number" step="0.001" [(ngModel)]="conteo_dinero.total_pagos_qr" placeholder="0" class="form-control" />
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <hr>
    </div>
</ng-template>

<ng-template #modal_adicionretiro_caja let-modal>
    <div class="modal-header card-header">
        <div class="col-6">
            <span class="text-blue text-125">{{titulo_dinero}}</span>
        </div>
        <div class="col-6">
            <button type="button" class="btn btn-primary btn-primary" (click)="guardarTransaccionCaja()" >
                <i class="fa fa-save red2 align-top bigger-125"></i>
            </button>&nbsp;
            <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
              <i class="fa fa-times red2 align-top bigger-125"></i>
          </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <label>Importe</label>
            </div>
            <div class="col-sm-12 col-md-8">
                <input class="form-control" [(ngModel)]="ingreso_caja.importe" type="number" step="0.001" placeholder="Importe" />
            </div>
            <div class="col-sm-12 col-md-4">
                <label>Descripción</label>
            </div>
            <div class="col-sm-12 col-md-8">
                <textarea class="form-control" rows="5" cols="70" maxlength="190" [(ngModel)]="ingreso_caja.descripcion" placeholder="Descripción"></textarea>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modal_transacciones_caja let-modal>
    <div class="modal-header card-header">
        <div class="col-8">
            <span class="text-blue text-125">Transacciones Caja</span>
        </div>
        <div class="col-4">
          <button type="button" class="btn btn-primary btn-success" (click)="generarExcelIngresosSalidas()" >
              <i class="fa fa-file-excel red2 align-top bigger-125"></i>
          </button>&nbsp;
          <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
            <i class="fa fa-times red2 align-top bigger-125"></i>
        </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-12">
                <table class="table table-striped table-bordered table-hover">
					<thead>
						<tr>
							<th>Nº</th>
							<th>Fecha</th>
							<th>Transaccion</th>
							<th>Importe</th>
                            <th>Descripción</th>
							<th>Usuario</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let transaccion of transacciones_caja;let i=index;">
							<td>{{i + 1}}</td>
							<td>{{transaccion.fecha | date:"dd/MM/yyyy HH:mm"}}</td>
                            <td *ngIf="transaccion.transaccion.nombre_corto=='MOVING'"><span class="badge badge-sm bgc-purple brc-purple text-white arrowed-right">{{transaccion.transaccion.nombre}}</span></td>
                            <td *ngIf="transaccion.transaccion.nombre_corto=='MOVEGR'"><span class="badge btn-pink badge-sm arrowed arrowed-in-right">{{transaccion.transaccion.nombre}}</span></td>
							<td>{{transaccion.importe}}</td>
                            <td>{{transaccion.descripcion}}</td>
							<td>{{transaccion.usuario.nombre_usuario}}</td>
						</tr>
					</tbody>
				</table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modal_selecion_importe_caja let-modal>
    <div class="modal-header card-header">
        <div class="col-8">
            <span class="text-blue text-125">Deposito Caja General</span>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
              <i class="fa fa-times red2 align-top bigger-125"></i>
          </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-10">
                <label>Ventas al contado recibos:</label>
                <span class="badge badge-success text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.ventas_contado_recibos.total.toFixed(2)}}</span>
            </div>
            <div class="col-2">
                <i *ngIf="cierre_caja.ventas_contado_recibos.depositado" class="fas fa-check text-105 btn-text-success"></i>
                <a *ngIf="!cierre_caja.ventas_contado_recibos.depositado && cierre_caja.ventas_contado_recibos.total>0" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="generarIngresoCaja(cierre_caja,cierre_caja.ventas_contado_recibos.total,false)">          
                    <i class="fas fa-box text-105"></i>        
                </a>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-10">
                <label>Ventas al contado facturas: </label>
                <span class="badge badge-success text-white badge-lg radius-4 mb-1">Bs. {{cierre_caja.ventas_contado_facturas.total.toFixed(2)}}</span>
            </div>
            <div class="col-2">
                <i *ngIf="cierre_caja.ventas_contado_facturas.depositado" class="fas fa-check text-105 btn-text-success"></i>
                <a *ngIf="!cierre_caja.ventas_contado_facturas.depositado && cierre_caja.ventas_contado_facturas.total>0" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="generarIngresoCaja(cierre_caja,cierre_caja.ventas_contado_facturas.total,true)">
                    <i class="fas fa-box text-105"></i>        
                </a>
            </div>
        </div>
    </div>
</ng-template>