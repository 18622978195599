import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { EmpleadosService } from 'src/app/rrhh/services/empleados/empleados.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';
import { CarrerasService } from 'src/app/institute/services/carreras/carreras.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { Util } from 'src/app/utils/utils';
import { ConfiguracionAplicacionEmpleadosComponent } from '../configuracion-aplicacion-empleados/configuracion-aplicacion-empleados.component';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { RegistroMovimientoInventarioComponent } from 'src/app/snapquick/components/registro-movimiento-inventario/registro-movimiento-inventario.component';
import { MovimientoInventarioService } from 'src/app/snapquick/services/movimiento-inventario/movimiento-inventario.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { CompraComponent } from 'src/app/snapquick/components/compra/compra.component';
import { CursosService } from 'src/app/school/services/cursos/cursos.service';
import { MateriasService } from 'src/app/school/services/materias/materias.service';
import { CalificacionesService } from 'src/app/school/services/calificaciones/calificaciones.service';
import { OrderPipe } from 'ngx-order-pipe';

declare const resaltarPestañaMenu:any;
declare const aplicarVisorImagenes:any;
declare const aplicarWizard:any;
declare const aplicarPluginImagen:any;
declare let gapi: any;

@Component({
  selector: 'app-empleados',
  templateUrl: './empleados.component.html',
  styleUrls: ['./empleados.component.css']
})
export class EmpleadosComponent extends BaseComponent implements OnInit {

  sucursales:IMultiSelectOption[]=[];
  empleado:any={persona:{usuario:{}}};
  empleados:any[]=[];
  roles_registro:any[]=[];
  registro_instituto_profesor:any;
  registro_colegio_profesor:any;
  gestiones:any[]=[];
  sucursales_docente:any=[];
  productos_empleado:any[]=[];
  semestres:any[]=[];
  carreras:any[]=[];
  turnos:any[]=[];
  turno:any;
  dias:any=[];
  detalles_pedidos:any[]=[];
  empleado_turno_dato:any={};
  filtro_asistencia:any={};
  @ViewChildren('empleados') empleados_lectura: QueryList<any>;

  modal_empleado_modificacion:NgbModalRef;
  @ViewChild('modal_empleado_modificacion')
  private modal_empleado_modificacion_ref: TemplateRef<any>;

  modal_pago_empleado:NgbModalRef;
  @ViewChild('modal_pago_empleado')
  private modal_pago_empleado_ref: TemplateRef<any>;

  lista_turnos_modal:NgbModalRef;
  @ViewChild('lista_turnos_modal')
  private lista_turnos_modal_ref: TemplateRef<any>;

  registro_turno_modal:NgbModalRef;
  @ViewChild('registro_turno_modal')
  private registro_turno_modal_ref: TemplateRef<any>;

  wizard_edicion_empleado:string="wizard_edicion_empleado";

  generos:any[]=[];
  cargos:any[]=[];
  configuraciones_calificaciones:any;

  registro_profesor_instituto_modal:NgbModalRef;
  @ViewChild('registro_profesor_instituto_modal')
  private registro_profesor_instituto_modal_ref: TemplateRef<any>;

  registro_profesor_colegio_modal:NgbModalRef;
  @ViewChild('registro_profesor_colegio_modal')
  private registro_profesor_colegio_modal_ref: TemplateRef<any>;

  asignacion_turno_empleado_modal:NgbModalRef;
  @ViewChild('asignacion_turno_empleado_modal')
  private asignacion_turno_empleado_modal_ref: TemplateRef<any>;

  consulta_asistencia_modal:NgbModalRef;
  @ViewChild('consulta_asistencia_modal')
  private consulta_asistencia_modal_ref: TemplateRef<any>;

  modal_empleado_almacen:NgbModalRef;
  @ViewChild('modal_empleado_almacen')
  private modal_empleado_almacen_ref: TemplateRef<any>;

  popup_registro_movimiento:NgbModalRef;

  datos_trabajo_empleado:any={};

  modalEgreso:NgbModalRef;

  configuracionCalificaciones:NgbModalRef;
  @ViewChild('configuracionCalificaciones')
  private configuracionCalificacionesRef: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public empleadosService:EmpleadosService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private empresasService:EmpresaService,
    private carrerasService:CarrerasService,
    private movimientoInventarioService:MovimientoInventarioService,
    private pdfService:PdfService,
    private cursosService:CursosService,
    private materiasService:MateriasService,
    private orderPipe: OrderPipe,
    private calificacionesService:CalificacionesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    //window.location.href=GlobalVariable.SERVER_URL+"v1/#"+this.router.url;
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));
    this.configuracion_pagina={};
    this.filter={
      id_empresa:this.usuario.id_empresa
    }
    await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_EMPLEADOS);

    this.column = "codigo";
    this.getSearch(this.text_search,null);
  }

  ngAfterViewInit() {
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
    this.empleados_lectura.changes.subscribe(t => {
			aplicarVisorImagenes("imagen-empleado-vista");
		});
  }

  getItems(){
    this.blockUI.start('Recuperando... espere por favor!');
    this.empleadosService.obtenerEmpleadosEmpresa(this).subscribe((dato:any) => {
      this.setPages(dato.paginas);
      this.empleados=dato.empleados;
      this.blockUI.stop();
    });
  }

  async crearNuevoEmpleado(){
    let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_EMPLEADO);
    this.empleado={
      codigo:codigo_nuevo,
      persona: { 
        imagen: "img/icon-user-default.png" 
      }, 
      id_empresa: this.usuario.id_empresa,
      es_profesor:false,
      registros_instituto_profesor:[],
      registros_colegio_profesor:[],
      crear_almacen:false,
      almacen:{
        nombre:"ALMACEN - ",
        id_sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0].id
      }
    }

    if(this.usuario.empresa.usar_cuentas_empleados){
      this.empleado.persona.usuario={
        activo:true
      }
      let sucursales:any=await this.empresasService.obtenerSucursalesEmpresa(this.usuario.id_empresa).toPromise();
      this.llenarSucursales(sucursales);
      this.empleado.persona.usuario.sucursales=this.seleccionarSucursales([this.usuario.sucursalesUsuario[0]])
      this.sucursales_docente=sucursales;
    }
    this.abrirRegistroEmpleado();
  }

  seleccionarSucursales(sucursalesUsuario){
		var sucursales_usuario=[];
		for(var j=0;j<sucursalesUsuario.length;j++){
			sucursales_usuario.push(sucursalesUsuario[j].sucursal.id);
		}
		return sucursales_usuario;
  }

  llenarSucursales(datosSucursales){
		this.sucursales=[];
		for(var i=0;i<datosSucursales.length;i++){
			var sucursal={
				name:datosSucursales[i].nombre,
				maker: "",
				ticked:false,
				id:datosSucursales[i].id,
				almacenes:datosSucursales[i].almacenes
			}
			this.sucursales.push(sucursal);
		}
  }

  async modificarEmpleado(empleado){
    await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_EMPLEADO);
    let datos_empleado:any=await this.empleadosService.obtenerEmpleado(empleado.id).toPromise();
    this.empleado=datos_empleado;
    this.empleado.persona.fecha_nacimiento=this.empleado.persona.fecha_nacimiento?new Date(this.empleado.persona.fecha_nacimiento):null;
    this.empleado.persona.fecha_nacimiento_texto=this.empleado.persona.fecha_nacimiento?{
      year:this.empleado.persona.fecha_nacimiento.getFullYear(),
      month:this.empleado.persona.fecha_nacimiento.getMonth()+1,
      day:this.empleado.persona.fecha_nacimiento.getDate()
    }:null;

    let sucursales:any=await this.empresasService.obtenerSucursalesEmpresa(this.usuario.id_empresa).toPromise();
    this.llenarSucursales(sucursales);
    this.empleado.persona.usuario=this.empleado.persona.usuario?this.empleado.persona.usuario:{rolesUsuario:[]};
    this.empleado.persona.usuario.repeticion_clave=this.empleado.persona.usuario.clave;
    this.empleado.persona.usuario.rol=this.empleado.persona.usuario?this.empleado.persona.usuario.rolesUsuario[0]?this.empleado.persona.usuario.rolesUsuario[0].rol:null:null;
    if(!this.empleado.persona.usuario.id){
      this.empleado.persona.usuario.sucursales=this.seleccionarSucursales([this.usuario.sucursalesUsuario[0]])
    }
    this.sucursales_docente=sucursales;
    this.abrirRegistroEmpleado();
  }

  abrirRegistroEmpleado(){
    this.modal_empleado_modificacion=this.modalService.open(this.modal_empleado_modificacion_ref, {windowClass:"wizard_edicion_empleado",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.modal_empleado_modificacion.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    aplicarWizard(this.wizard_edicion_empleado,this,'guardar');
    aplicarPluginImagen();
    this.obtenerGeneros();
		this.obtenerCargos();
    if(this.usuario.empresa.usar_cuentas_empleados){
      this.obtenerRoles();
    }
  }

  obtenerGeneros(){  
    this.generalService.obtenerClases(GlobalVariable.Dictionary.GENEROS).subscribe((entidad:any)=>{
      this.generos=entidad.clases;
    });
  }
  
    obtenerCargos(){  
    this.generalService.obtenerClases(GlobalVariable.Dictionary.RRHH_CARGOS).subscribe((entidad:any)=>{
      this.cargos=entidad.clases;
    });
  }

  async obtenerRoles(){
    this.blockUI.start();
    let roles:any;
    if(this.usuario.empresa.usar_aplicaciones_por_rol){
      roles=await this.empresasService.obtenerRolesEmpresa(this.usuario.empresa.id).toPromise();
      this.blockUI.stop();
    }else{
      roles=await this.empresasService.obtenerRoles().toPromise();
      //this.roles_registro=roles;//=roles.filter(r => r.nombre.toUpperCase()!=GlobalVariable.Dictionary.ROL_ESTUDIANTE);
      this.blockUI.stop();
    }
    this.roles_registro=roles;
    this.roles_registro=this.roles_registro.filter(r => r.nombre.toUpperCase()!=GlobalVariable.Dictionary.ROL_ESTUDIANTE);
    if(this.usuario.empresa){
      this.roles_registro=this.roles_registro.filter(e => e.nombre!='SUPER-ADMINISTRADOR');
    }
  }

  async crearNuevoRegistroProfesorInstituto(){
    this.registro_instituto_profesor={};
    this.filter={
      id_empresa:this.usuario.id_empresa
    };
    await this.obtenerEscuela();
    this.obtenerGestiones();
    this.obtenerSemestresGestion();
    this.obtenerCarreras();
		this.abrirRegistroProfesorInstituto();
	}

  async crearNuevoRegistroProfesorColegio(){
    this.filter={id_empresa:this.usuario.id_empresa};
    await this.obtenerEscuela();
    this.registro_colegio_profesor={
      nivel_escolar:null,
      id_tipo_calculo:this.filter.escuela.id_tipo_calculo_calificacion
    };
    this.obtenerGestionesColegio();
    this.obtenerNiveles();
		this.abrirRegistroProfesorColegio();
	}

  async establecerGestionColegio(){
    this.registro_colegio_profesor.configuracion=await this.calificacionesService.obtenerConfiguracionCalificacion(this.registro_colegio_profesor.gestion.id,this.usuario.id_empresa,0).toPromise();
  }

  obtenerNiveles(){
    this.blockUI.start();
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.SCH.TIPO_NIVEL_ESCOLAR).subscribe((entidad:any)=>{
      this.blockUI.stop();
      this.filter.niveles=entidad.clases;
    });
  }

  async obtenerCursosPorNivel(id_nivel_escolar){
    let cursos:any=await this.cursosService.obtenerCursosNivel(this.usuario.id_empresa,id_nivel_escolar).toPromise();
    this.filter.cursos=cursos;
    this.registro_colegio_profesor.curso=null;
  }

  async cambiarNivel(id_nivel_escolar){
    this.obtenerCursosPorNivel(id_nivel_escolar);
    this.obtenerMateriasPorNivel(id_nivel_escolar);

  }

  obtenerMateriasPorNivel(id_nivel_escolar){
    this.blockUI.start();
    this.materiasService.obtenerMateriasNivel(this.usuario.id_empresa,id_nivel_escolar).subscribe((materias:any)=>{
      this.blockUI.stop();
      this.filter.materias=materias;
      this.registro_colegio_profesor.materia=null;
    });
  }

  obtenerGestiones(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_GESTIONES).subscribe((entidad:any)=>{
      this.gestiones=entidad.clases
    });
  }

  obtenerGestionesColegio(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).subscribe((entidad:any)=>{
      this.gestiones=entidad.clases
    });
  }

  obtenerSemestresGestion(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_SEMESTRES_GESTION).subscribe((entidad:any)=>{
      this.semestres=entidad.clases
    });
  }

  obtenerCarreras(){
    this.carrerasService.obtenerCarreras(this.filter.escuela.id).subscribe((carreras:any)=>{
      this.carreras=carreras;
    });
  }

  abrirRegistroProfesorInstituto(){
    this.registro_profesor_instituto_modal=this.modalService.open(this.registro_profesor_instituto_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_profesor_instituto_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  abrirRegistroProfesorColegio(){
    this.registro_profesor_colegio_modal=this.modalService.open(this.registro_profesor_colegio_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_profesor_colegio_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  agregarRegistroProfesorInstituto(){
    if(this.validarRegistroProfesorInstituto()){
      this.empleado.registros_instituto_profesor.push(this.registro_instituto_profesor);
      this.registro_profesor_instituto_modal.close();
    }
  }

  agregarRegistroProfesorColegio(){
    if(this.validarRegistroProfesorColegio()){
      this.empleado.registros_colegio_profesor.push(this.registro_colegio_profesor);
      this.registro_profesor_colegio_modal.close();
    }
  }

  validarRegistroProfesorInstituto(){
    let res=true;
		if(!this.registro_instituto_profesor.carrera){
      res=res && false;
      this.toastr.error("Debe especificar la carrera!");
    }
		return res;
  }

  validarRegistroProfesorColegio(){
    let res=true;
		if(!this.registro_colegio_profesor.gestion){
      res=res && false;
      this.toastr.error("Debe especificar la gestión!");
    }
    if(!this.registro_colegio_profesor.curso){
      res=res && false;
      this.toastr.error("Debe especificar el curso!");
    }
    if(!this.registro_colegio_profesor.materia){
      res=res && false;
      this.toastr.error("Debe especificar la materia!");
    }
		return res;
  }

  abrirPopupConfirmacionEliminacionRegistroProfesor(registro_profesor){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el registro docente?";
		this.popupConfirmacion.componentInstance.data = registro_profesor;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarRegistroProfesor($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarRegistroProfesor(registro_profesor){
    registro_profesor.eliminado=true;
  }

  abrirPopupConfirmacionEliminacionRegistroProfesorColegio(registro_profesor){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el registro docente?";
		this.popupConfirmacion.componentInstance.data = registro_profesor;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarRegistroProfesorColegio($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarRegistroProfesorColegio(registro_profesor){
    registro_profesor.eliminado=true;
  }

  buscarAplicacionesRol(rol_a_buscar){
    this.empleado.persona.usuario.id_rol=rol_a_buscar?rol_a_buscar.id:null;
    if(this.usuario.empresa.usar_aplicaciones_por_rol && rol_a_buscar){
      this.empleado.persona.usuario.aplicacionesUsuario=[];
      this.empresasService.obtenerRol(rol_a_buscar.id).subscribe((rol:any)=>{
        for(var j=0;j<rol.aplicacionesRol.length;j++){
          if(rol.aplicacionesRol[j].habilitado){
            this.empleado.persona.usuario.aplicacionesUsuario.push({id_aplicacion:rol.aplicacionesRol[j].aplicacion.id,
              aplicacion:rol.aplicacionesRol[j].aplicacion,
              puede_crear:true,
              puede_ver:true,
              puede_modificar:true,
              puede_eliminar:true});
          }
        }
      });			
    }else{
      this.empresasService.obtenerEmpresa(this.usuario.id_empresa).subscribe(empresa_a_buscar =>{
        this.buscarModulos(empresa_a_buscar);
      });
    }
  }

  buscarModulos(empresa_a_buscar){
		var aplicaciones_usuario=this.empleado.persona.usuario.aplicacionesUsuario?JSON.parse(JSON.stringify(this.empleado.persona.usuario.aplicacionesUsuario)):[];
		var empresa=empresa_a_buscar;//($.grep(this.empresas, function(e){return (e.id == empresa_a_buscar.id);})[0]);
		//var aplicaciones_usuario=[];
		this.empleado.persona.usuario.aplicacionesUsuario=/*this.usuario_item.id?this.usuario_item.aplicacionesUsuario:*/[];
		for(var i=0;i<empresa.modulos.length;i++){
			if(empresa.modulos[i].habilitado){
				for(var j=0;j<empresa.modulos[i].modulo.aplicaciones.length;j++){
					var encontrados=aplicaciones_usuario.filter(e => e.id_aplicacion == empresa.modulos[i].modulo.aplicaciones[j].aplicacion.id);
					if(encontrados.length==0){
						var duplicado=this.empleado.persona.usuario.aplicacionesUsuario.filter(e => e.id_aplicacion == empresa.modulos[i].modulo.aplicaciones[j].aplicacion.id);
						if(duplicado.length==0){
							this.empleado.persona.usuario.aplicacionesUsuario.push({id_aplicacion:empresa.modulos[i].modulo.aplicaciones[j].aplicacion.id,
								aplicacion:empresa.modulos[i].modulo.aplicaciones[j].aplicacion,
								puede_crear:true,
								puede_ver:true,
								puede_modificar:true,
								puede_eliminar:true});
						}		
					}else{
						var duplicado=this.empleado.persona.usuario.aplicacionesUsuario.filter(e => e.id_aplicacion == encontrados[0].id_aplicacion);
						if(duplicado.length==0){
							this.empleado.persona.usuario.aplicacionesUsuario.push(encontrados[0]);
						}
					}
				}	
			}
		}
  }

  async validarUsuario(usuario) {
		if(usuario.nombre_usuario){
			var res=true;
			let existencia_usuario:any=await this.usuarioService.verificarUsuario(usuario.id?usuario.id:0,usuario.nombre_usuario).toPromise();
			if(existencia_usuario.usuario){
				res=false;
				this.toastr.error("Ya existe una cuenta con el correo electronico ingresado, intente nuevamente!");
			}	
			return res;
		}
	}

  verificarClaveRepeticion(usuario){
		var res=true;
		if(usuario.clave!=usuario.repeticion_clave){
			res=false;
			this.toastr.error("Las claves ingresadas no coinciden! Vuelva a intentarlo.");
		}
		return res;
  }

  async guardar(){
    this.blockUI.start();
		if(await this.validarFormularioRegistroEmpleado()){
      this.empleado.persona.fecha_nacimiento=this.empleado.persona.fecha_nacimiento_texto?Util.convertirObjetoAfecha(this.empleado.persona.fecha_nacimiento_texto):null;
			if(this.empleado.id){
				this.empleadosService.actualizarEmpleado(this.empleado).subscribe((res:any) => {
          this.blockUI.stop();
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
          }else{
            this.getItems();
            this.toastr.success(res.mensaje);
					  this.modal_empleado_modificacion.close();
          }
				});
			}else{
        this.empleado.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_EMPLEADO):this.empleado.codigo;
				this.empleadosService.guardarEmpleado(this.empleado).subscribe((res:any) => {
          this.blockUI.stop();
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
          }else{
            this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_EMPLEADO);
            this.getItems();
            this.toastr.success(res.mensaje);
            this.modal_empleado_modificacion.close();
          }
				});
			}
		}else{
      this.blockUI.stop();
    }
  }
  
  async validarFormularioRegistroEmpleado(){
		let res=true;
    if(!this.empleado.codigo){
			this.toastr.error("Debe ingresar el código del empleado!");
			res=false;
		}
		if(!this.empleado.persona.nombres){
			this.toastr.error("Debe ingresar el nombre del empleado!");
			res=false;
		}
		if(!this.empleado.persona.genero){
			this.toastr.error("Debe ingresar el género del empleado!");
			res=false;
		}
    if(this.usuario.empresa.usar_cuentas_empleados && this.empleado.persona.usuario.nombre_usuario){
			res = res && (await this.validarUsuario(this.empleado.persona.usuario));
		  res = res && this.verificarClaveRepeticion(this.empleado.persona.usuario);

      if(!this.empleado.persona.usuario.rol){
        this.toastr.error("Debe ingresar el rol de usuario!");
        res=false;
      }
		}
		return res;
	}

  abrirPopupConfirmacionEliminacion(empleado){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar al empleado?";
		this.popupConfirmacion.componentInstance.data = empleado;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarEmpleado($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarEmpleado(empleado){
    this.blockUI.start();
    this.empleadosService.eliminarEmpleado(empleado).subscribe((res:any)=>{
      this.getItems();
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
    });
  }

  async verTurnos(){
    this.generalService.obtenerClases("DIAS").subscribe((entidad:any)=>{
      this.llenarDias(entidad.clases);
    });
    await this.verListaTurnos();
    this.abrirListaTurnos();
  }

  async verListaTurnos(){
    this.blockUI.start();
    let turnos:any=await this.empleadosService.obtenerListaTurnos(this.usuario.id_empresa).toPromise();
    this.turnos=turnos;
    this.blockUI.stop();
  }

  abrirListaTurnos(){
    this.lista_turnos_modal=this.modalService.open(this.lista_turnos_modal_ref, {windowClass:'turnos-modal', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.lista_turnos_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  llenarDias(dias){
    this.dias=[];
    for(var i=0;i<dias.length;i++){
        var dia={
            name:dias[i].nombre,
            maker: "",
            ticked:false,
            id:dias[i].id
        }
        this.dias.push(dia);
    }
  }

  abrirRegistroTurno(){
    this.registro_turno_modal=this.modalService.open(this.registro_turno_modal_ref, {windowClass:'registro-turno-modal', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_turno_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  crearNuevoTurno(){
    this.turno={
      id_empresa:this.usuario.id_empresa,
      dias:[]
    }
    this.abrirRegistroTurno();
  }

  validarRegistroTurno(){
		let res=true;
    if(!this.turno.nombre){
			this.toastr.error("Debe ingresar el nombre del turno!");
			res=false;
		}
		if(!this.turno.numero_orden){
			this.toastr.error("Debe ingresar el numero de orden del turno!");
			res=false;
		}
		if(this.turno.hora_inicio==null){
			this.toastr.error("Debe ingresar la hora de inicio del turno!");
			res=false;
		}
    if(this.turno.minuto_inicio==null){
			this.toastr.error("Debe ingresar el minuto de inicio del turno!");
			res=false;
		}
    if(this.turno.tickeo_inicio_hora_inicio==null){
			this.toastr.error("Debe ingresar la hora de inicio del tickeo de entrada!");
			res=false;
		}
    if(this.turno.tickeo_inicio_minuto_inicio==null){
			this.toastr.error("Debe ingresar el minuto de inicio del tickeo de entrada!");
			res=false;
		}
    if(this.turno.tickeo_inicio_hora_fin==null){
			this.toastr.error("Debe ingresar la hora de finalización del tickeo de entrada!");
			res=false;
		}
    if(this.turno.tickeo_inicio_minuto_fin==null){
			this.toastr.error("Debe ingresar el minuto de finalización del tickeo de entrada!");
			res=false;
		}
    if(this.turno.minutos_tolerancia_entrada==null){
			this.toastr.error("Debe ingresar los minutos de tolerancia del tickeo de entrada!");
			res=false;
		}

    if(this.turno.hora_fin==null){
			this.toastr.error("Debe ingresar la hora de finalización del turno!");
			res=false;
		}
    if(this.turno.minuto_fin==null){
			this.toastr.error("Debe ingresar el minuto de finalización del turno!");
			res=false;
		}
    if(this.turno.tickeo_fin_hora_inicio==null){
			this.toastr.error("Debe ingresar la hora de inicio del tickeo de salida!");
			res=false;
		}
    if(this.turno.tickeo_fin_minuto_inicio==null){
			this.toastr.error("Debe ingresar el minuto de inicio del tickeo de salida!");
			res=false;
		}
    if(this.turno.tickeo_fin_hora_fin==null){
			this.toastr.error("Debe ingresar la hora de finalización del tickeo de salida!");
			res=false;
		}
    if(this.turno.tickeo_fin_minuto_fin==null){
			this.toastr.error("Debe ingresar el minuto de finalización del tickeo de salida!");
			res=false;
		}
    if(this.turno.minutos_tolerancia_salida==null){
			this.toastr.error("Debe ingresar los minutos de tolerancia del tickeo de salida!");
			res=false;
		}

    if(this.turno.dias.length==0){
			this.toastr.error("Debe ingresar al menos un dia al turno!");
			res=false;
		}
		return res;
	}

  guardarTurno(){
    this.blockUI.start();
    if(this.validarRegistroTurno()){
      if(this.turno.id){
        this.empleadosService.actualizarTurno(this.turno).subscribe((res:any)=>{
          if(!res.tiene_error){
            this.toastr.success(res.mensaje);
            this.registro_turno_modal.close();
            this.verListaTurnos();
          }
          this.blockUI.stop();
        });
      }else{
        this.empleadosService.guardarTurno(this.turno).subscribe((res:any)=>{
          if(!res.tiene_error){
            this.toastr.success(res.mensaje);
            this.registro_turno_modal.close();
            this.verListaTurnos();
          }
          this.blockUI.stop();
        });
      }
    }else{
      this.blockUI.stop();
    }
  }

  modificarTurno(turno){
    this.blockUI.start();
    this.empleadosService.obtenerTurno(turno).subscribe((turno_consultado:any)=>{
      this.turno=turno_consultado;
      this.turno.dias=this.seleccionarDias(this.turno.dias);
      this.blockUI.stop();
      this.abrirRegistroTurno();
    });
  }

  seleccionarDias(dias){
		var dias_seleccionados=[];
		for(var j=0;j<dias.length;j++){
			dias_seleccionados.push(dias[j].dia.id);
		}
		return dias_seleccionados;
  }

  abrirPopupConfirmacionEliminacionTurno(turno){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el turno?";
		this.popupConfirmacion.componentInstance.data = turno;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarTurno($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarTurno(turno){
    this.blockUI.start();
    this.empleadosService.eliminarTurno(turno).subscribe((res:any)=>{
      this.verListaTurnos();
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
    });
  }

  abrirAsignacionTurnosEmpleado(){
    this.asignacion_turno_empleado_modal=this.modalService.open(this.asignacion_turno_empleado_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.asignacion_turno_empleado_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  modificarAsignacionTurnoEmpleado(empleado){
    this.verListaTurnos();
    this.empleadosService.obtenerTurnosEmpleado(empleado.id).subscribe((datos_empleado:any)=>{
      this.empleado=datos_empleado;
      this.abrirAsignacionTurnosEmpleado();
    });
  }

  agregarTurnoEmpleado(empleado_turno){
    let turno_encontrado=this.empleado.turnos.filter(t => t.turno.id==empleado_turno.turno.id);
    if(turno_encontrado==0){
      this.empleado.turnos.push({turno:empleado_turno.turno});
    }
    empleado_turno.turno=null;
  }

  guardarTurnosEmpleado(){
    this.blockUI.start();
    this.empleadosService.guardarTurnosEmpleado(this.empleado).subscribe((res:any)=>{
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
      this.asignacion_turno_empleado_modal.close();
    });
  }

  abrirPopupConfirmacionEliminacionEmpleadoTurno(empleado_turno){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el turno del empleado?";
		this.popupConfirmacion.componentInstance.data = empleado_turno;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarTurnoEmpleado($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarTurnoEmpleado(empleado_turno){
    this.empleado.turnos.splice(this.empleado.turnos.indexOf(empleado_turno), 1);
  }

  abrirConsultaAsistencia(){
    this.consulta_asistencia_modal=this.modalService.open(this.consulta_asistencia_modal_ref, {scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.consulta_asistencia_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  abrirAsistencia(){
    let fecha_actual=new Date();
    this.filtro_asistencia={
      id_empresa:this.usuario.id_empresa,
      fecha_inicio_texto:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin_texto:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      empleados_asistencia:[{id:0,persona:{nombre_completo:"TODOS"}}],
      empleado:{id:0,persona:{nombre_completo:"TODOS"}},
      fechas_visualizacion:[]
    }
    this.filtro_asistencia.fecha_asistencia=fecha_actual.getDate()+"/"+(fecha_actual.getMonth()+1)+"/"+fecha_actual.getFullYear();
    this.abrirConsultaAsistencia();
    this.empleadosService.obtenerTodosEmpleadosEmpresa(this.usuario.id_empresa).subscribe((empleados:any)=>{
      this.filtro_asistencia.empleados_asistencia=this.filtro_asistencia.empleados_asistencia.concat(empleados);
    });
  }

  buscarAsistencias(){
    this.blockUI.start();
    this.filtro_asistencia.fechas_visualizacion=[];
    let fecha_inicio_asistencia=Util.convertirObjetoAfecha(this.filtro_asistencia.fecha_inicio_texto);
    let fecha_fin_asistencia=Util.convertirObjetoAfecha(this.filtro_asistencia.fecha_fin_texto);
    this.empleadosService.obtenerAsistencias(this.filtro_asistencia).subscribe((empleados_asistencias:any)=>{
      this.filtro_asistencia.empleados_asistencias=empleados_asistencias;
      for(var dt=fecha_inicio_asistencia; dt<=fecha_fin_asistencia; dt.setDate(dt.getDate()+1)){
        this.filtro_asistencia.fechas_visualizacion.push(new Date(dt));
      }
      this.blockUI.stop();
      //document.querySelector(".focus-data").focus();
    });
  }

  obtenerResumenAsistencia(asistencias){
    var minutos_retraso=0,minutos_retraso_con_tolerancia=0,minutos_anticipacion=0,minutos_anticipacion_con_tolerancia=0;
    for(var i=0;i<asistencias.length;i++){
        let tiempo_registro:any=new Date(asistencias[i].tiempo_registro);
        if(asistencias[i].entrada){
            let tiempo_entrada:any=new Date(asistencias[i].tiempo_registro);
            tiempo_entrada.setHours(asistencias[i].turno.hora_inicio);
            tiempo_entrada.setMinutes(asistencias[i].turno.minuto_inicio);
            var diferencia = Math.round(((tiempo_registro-tiempo_entrada)/1000)/60);
            minutos_retraso=minutos_retraso+(diferencia>0?diferencia:0);
            minutos_retraso_con_tolerancia=minutos_retraso_con_tolerancia+(diferencia>asistencias[i].turno.minutos_tolerancia_entrada?diferencia-asistencias[i].turno.minutos_tolerancia_entrada:0);
        }else{
            let tiempo_salida:any=new Date(asistencias[i].tiempo_registro);
            tiempo_salida.setHours(asistencias[i].turno.hora_fin);
            tiempo_salida.setMinutes(asistencias[i].turno.minuto_fin);
            var diferencia = Math.round(((tiempo_salida-tiempo_registro)/1000)/60);
            minutos_anticipacion=minutos_anticipacion+(diferencia>0?diferencia:0);
            minutos_anticipacion_con_tolerancia=minutos_anticipacion_con_tolerancia+(diferencia>asistencias[i].turno.minutos_tolerancia_salida?diferencia-asistencias[i].turno.minutos_tolerancia_salida:0);
        }
    }
    return "<span class='text-danger'><i class='fas fa-sign-in-alt red2 text-110 mr-1'></i></span>: "+minutos_retraso+" min. <br/> <span class='text-warning'><i class='fas fa-sign-in-alt red2 text-110 mr-1'></i></span>: "+minutos_retraso_con_tolerancia +" min.<br/> <span class='text-warning'><i class='fas fa-sign-out-alt red2 text-110 mr-1'></i></span>: "+minutos_anticipacion+" min.<br/> <span class='text-primary'><i class='fas fa-sign-out-alt red2 text-110 mr-1'></i></span>: "+minutos_anticipacion_con_tolerancia+" min.";
  }

  obtenerDatosAsistencia(asistencias,date){
    var registros_fecha=asistencias.filter(e =>{
        let tiempo_registro:any=new Date(e.tiempo_registro);
        var text_tiempo_registro=tiempo_registro.getDate()+"/"+tiempo_registro.getMonth()+"/"+tiempo_registro.getFullYear();
        var text_fecha=date.getDate()+"/"+date.getMonth()+"/"+date.getFullYear();

        if(e.entrada){
            let tiempo_entrada:any=new Date(e.tiempo_registro);
            tiempo_entrada.setHours(e.turno.hora_inicio);
            tiempo_entrada.setMinutes(e.turno.minuto_inicio);
            var diferencia = Math.round(((tiempo_registro-tiempo_entrada)/1000)/60);
            if(diferencia>0){
                if(diferencia>e.turno.minutos_tolerancia_entrada){
                    e.color_tiempo="badge badge-danger";
                }else{
                    e.color_tiempo="badge badge-warning";
                }
            }else{
                e.color_tiempo="badge badge-success";
            }
        }else{
            let tiempo_salida:any=new Date(e.tiempo_registro);
            tiempo_salida.setHours(e.turno.hora_fin);
            tiempo_salida.setMinutes(e.turno.minuto_fin);
            var diferencia = Math.round(((tiempo_salida-tiempo_registro)/1000)/60);
            if(diferencia>0){
                if(diferencia>e.turno.minutos_tolerancia_salida){
                    e.color_tiempo="badge badge-danger";
                }else{
                    e.color_tiempo="badge badge-warning";
                }
            }else{
                e.color_tiempo="badge badge-success";
            }
        }
        return text_tiempo_registro == text_fecha;
    });
    return registros_fecha;
  }

  abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionEmpleadosComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

  establecerNombreAlmacen(){
      this.empleado.almacen.nombre="ALMACEN - "+
          this.empleado.persona.nombres+" "+
          (this.empleado.persona.apellido_paterno?this.empleado.persona.apellido_paterno:"")+" "+
          (this.empleado.persona.apellido_materno?this.empleado.persona.apellido_materno:"");
  }

  verAlmacen(empleado){
    this.blockUI.start();
    this.empleado=empleado;
    this.empleadosService.verAlmacenEmpleado(empleado.empleado_almacen.id_almacen).subscribe((productos:any)=>{
      this.blockUI.stop();
      this.productos_empleado=productos;
      this.modal_empleado_almacen=this.modalService.open(this.modal_empleado_almacen_ref, {scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.modal_empleado_almacen.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });
  }

  crearMovimientoInventarioInicial(empleado){
    this.popup_registro_movimiento=this.modalService.open(RegistroMovimientoInventarioComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'lg'});
    this.popup_registro_movimiento.componentInstance.usuario = this.usuario;
    this.popup_registro_movimiento.componentInstance.inhabilitar_sucursal = true;
    this.popup_registro_movimiento.componentInstance.inhabilitar_almacen = true;
    this.popup_registro_movimiento.componentInstance.inhabilitar_movimiento = true;
    this.popup_registro_movimiento.componentInstance.id_almacen_seleccionado = empleado.empleado_almacen.id_almacen;
    this.popup_registro_movimiento.componentInstance.alTerminar.subscribe((res) => {
      if(res){
        if(res.tiene_error || res.hasError){
          this.toastr.error(res.message);
          this.toastr.error(res.stack.substring(0,500));
        }else{
          this.toastr.success('Movimiento de inventario registrado exitosamente!');
          this.imprimirMovimientoInventario(res);
          this.popup_registro_movimiento.close();
        }
      }else{
        this.popup_registro_movimiento.close();
      }
      
    });
  }

  imprimirMovimientoInventario(movimiento_inventario){
    this.blockUI.start()
    this.movimientoInventarioService.obtenerMovimientoInventario(movimiento_inventario.id).subscribe((datos:any)=>{
      var movimientoInventarioConsultado=datos.movimiento_inventario;
			movimientoInventarioConsultado.sucursal=datos.sucursal;
			movimientoInventarioConsultado.numero_literal=datos.numero_literal;
			movimientoInventarioConsultado.pieFactura=datos.pieFactura;
			movimientoInventarioConsultado.sucursalDestino=datos.sucursalDestino;
			var fecha=new Date(movimientoInventarioConsultado.fecha);
      movimientoInventarioConsultado.fechaTexto=fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear();
	  this.pdfService.imprimirMovimientoInventario(movimientoInventarioConsultado,this.usuario);
	  this.blockUI.stop()
    });
  }

  pagarSueldo(empleado){
    this.blockUI.start();
    this.empleado=empleado;
    this.empleadosService.obtenerProductosInventario(empleado.empleado_almacen.id_almacen).subscribe((productos:any)=>{
      this.productos_empleado=productos;
      this.empleadosService.obtenerPedidoAsignadoEmpleado(empleado.id).subscribe((detalles_pedidos:any)=>{
        this.blockUI.stop();
          this.detalles_pedidos=detalles_pedidos;
          this.datos_trabajo_empleado.total_oro_trabajado=this.calcularTotalOroTrabajado();
          this.datos_trabajo_empleado.total_oro_joyero=this.datos_trabajo_empleado.total_oro_trabajado*1.1;
          this.datos_trabajo_empleado.total_pago_empleado=this.datos_trabajo_empleado.total_oro_trabajado*this.empleado.tarifa;
          this.datos_trabajo_empleado.total_gramos_trabajados=this.calcularTotalGramos();
          this.modal_pago_empleado=this.modalService.open(this.modal_pago_empleado_ref, {scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
          this.modal_pago_empleado.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
      });
    });
  }

  calcularTotalInventario(inventarios){
    let suma=0;
    for(let i=0;(inventarios && i<inventarios.length);i++){
        suma=suma+inventarios[i].cantidad;
    }
    return suma;
  }

  calcularTotalGramos(){
    let suma=0;
    for(let i=0;(this.detalles_pedidos && i<this.detalles_pedidos.length);i++){
        suma=suma+this.detalles_pedidos[i].cantidad;
    }
    return suma;
  }

  calcularTotalOroTrabajado(){
    var total_oro_trabajado=0;
    if(this.productos_empleado && this.detalles_pedidos){
        var total_gramos=this.calcularTotalGramos();
        var poducto_piedra=this.productos_empleado.filter(e => e.nombre == "PIEDRA")[0];
        if(poducto_piedra){
            var total_inventario_piedra=this.calcularTotalInventario(poducto_piedra.inventarios);
            total_oro_trabajado=total_inventario_piedra-total_gramos;
        }else{
            total_oro_trabajado=total_gramos;
        }
    }

    return total_oro_trabajado;
  }

  async generarEgreso(){
    this.modalEgreso = this.modalService.open(CompraComponent, {windowClass:'compra-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.modalEgreso.componentInstance.compra.usuario = this.usuario;
    this.modalEgreso.componentInstance.usuario = this.usuario;
    this.modalEgreso.componentInstance.es_almacen = false;
		this.modalEgreso.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);

    this.modalEgreso.componentInstance.inhabilitar_proveedor=true;
    this.modalEgreso.componentInstance.inhabilitar_sucursal=true;
    this.modalEgreso.componentInstance.inhabilitar_almacen=true;
    this.modalEgreso.componentInstance.inhabilitar_busqueda_item=true;

    let concepto_egreso_sueldos=await this.obtenerConceptosEgreso();
    if(concepto_egreso_sueldos){
      this.modalEgreso.componentInstance.detalleCompra = {centroCosto:concepto_egreso_sueldos,producto:{},costo_unitario:this.datos_trabajo_empleado.total_pago_empleado,cantidad:1,descuento:0,tipo_descuento:false,recargo:0,ice:0}
      this.modalEgreso.componentInstance.calcularImporte();
      this.modalEgreso.componentInstance.agregarDetalleCompra();
      this.modalEgreso.componentInstance.sumarTotal();
      this.modalEgreso.componentInstance.sumarTotalImporte();
    }

    this.modalEgreso.componentInstance.alTerminar.subscribe((res) => {
      if(res){
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
          this.toastr.error(res.stack.substring(0,500));
        }else{
          this.toastr.success(res.mensaje);
          //this.imprimirVenta(res.venta.id);
          this.modalEgreso.close();
        }
      }else{
        this.modalEgreso.close();
      }
    
    });
}
async obtenerConceptosEgreso(){
  this.blockUI.start();
  let conceptos_egreso:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_CONCEPTO_EGRESOS).toPromise();
  this.blockUI.stop();
  let concepto_sueldo=conceptos_egreso.clases.filter(e => e.nombre_corto == "SUYSA");  
  if(concepto_sueldo.length>0){
    return concepto_sueldo[0];
  }else{
    return null;
  }
}

async modificarRegistroConfiguracionNotas(registro_colegio_profesor){
  if(!registro_colegio_profesor.configuracion){
    registro_colegio_profesor.configuracion=await this.calificacionesService.obtenerConfiguracionCalificacion(registro_colegio_profesor.id_gestion,this.usuario.id_empresa,0).toPromise();
  }
  this.registro_colegio_profesor=registro_colegio_profesor;
  //this.configuraciones_calificaciones=this.orderPipe.transform(this.configuraciones_calificaciones, 'configuracion.orden',false);
  this.configuracionCalificaciones=this.modalService.open(this.configuracionCalificacionesRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'md',scrollable:true});
  this.configuracionCalificaciones.result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

async crearClassRoom(registro_profesor){
		this.blockUI.start();
    let me=this;
    this.generalService.cargarGapiScript(GlobalVariable.GAPI_SCRIPT,false,this.usuario.empresa.usar_google_class_room).subscribe(async () => {
      let promGapi=new Promise((resolve, reject) => {
        let auth2 = gapi.auth2.getAuthInstance();
        gapi.client.classroom.courses.create({
          "name": registro_profesor.gestion.nombre+" "+registro_profesor.curso.nombre_corto+" "+registro_profesor.materia.nombre,
          "description": registro_profesor.gestion.nombre+" "+registro_profesor.curso.nombre_corto+" "+registro_profesor.materia.nombre,
          "descriptionHeading": registro_profesor.gestion.nombre+" "+registro_profesor.curso.nombre_corto+" "+registro_profesor.materia.nombre,
          //"id": teacherRegistry.id.toString(),
          "ownerId":auth2.currentUser.get().getBasicProfile().getEmail(),
          //"room":registro_profesor.room?registro_profesor.room:""
        }).then(function(response) {
              resolve(response.result);
            },
          function(err) { console.error("Execute error", err); reject(err); });
      });
  
      let result= <any>await promGapi;
      console.log(result);
      registro_profesor.id_google_class_room=result.id;
        //TeacherRegistryUrl.update({ teacher_registry_id:teacherRegistry.id }, teacherRegistry,function(res) {
          gapi.client.classroom.courses.teachers.create({
            "courseId": result.id,
            "userId": me.usuario.nombre_usuario,
          }).then(function(responseTeacher) {console.log(responseTeacher);
            //me.toastr.success(res.mensaje);
            me.toastr.success("Profesor asignado a Class Room satisfactoriamente!");
            me.blockUI.stop();
          });
        //});
  
  
      //let announcement= result.announcements;
    });    
	}



}
