import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfiguracionAplicacionCierresCajaComponent } from 'src/app/base/components/configuracion-aplicacion-cierres-caja/configuracion-aplicacion-cierres-caja.component';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { CajasService } from '../../services/cajas/cajas.service';
import { CierresCajaService } from '../../services/cierres-caja/cierres-caja.service';
import { ProductosService } from '../../services/productos/productos.service';
import { RegistroMovimientoCajaComponent } from '../registro-movimiento-caja/registro-movimiento-caja.component';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';

declare const resaltarPestañaMenu:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-cierres-caja',
  templateUrl: './cierres-caja.component.html',
  styleUrls: ['./cierres-caja.component.css']
})
export class CierresCajaComponent extends BaseComponent implements OnInit {

  cierres_caja:any[]=[];
  nuevo_cierre_caja:any;
  cierre_caja:any;
  conteo_dinero:any;
  titulo_dinero:any;
  ingreso_caja:any;
  transacciones_caja:any[];
  popup_registro_movimiento:NgbModalRef;

  modal_registro_cierre_caja:NgbModalRef;
  @ViewChild('modal_registro_cierre_caja')
  private modal_registro_cierre_caja_ref: TemplateRef<any>;

  modal_apertura_caja:NgbModalRef;
  @ViewChild('modal_apertura_caja')
  private modal_apertura_caja_ref: TemplateRef<any>;

  modal_conteo_dinero:NgbModalRef;
  @ViewChild('modal_conteo_dinero')
  private modal_conteo_dinero_ref: TemplateRef<any>;

  modal_adicionretiro_caja:NgbModalRef;
  @ViewChild('modal_adicionretiro_caja')
  private modal_adicionretiro_caja_ref: TemplateRef<any>;

  modal_transacciones_caja:NgbModalRef;
  @ViewChild('modal_transacciones_caja')
  private modal_transacciones_caja_ref: TemplateRef<any>;

  modal_selecion_importe_caja:NgbModalRef;
  @ViewChild('modal_selecion_importe_caja')
  private modal_selecion_importe_caja_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    private cierresCajaService:CierresCajaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
	private productosService:ProductosService,
  public pdfService:PdfService,
  public cajasService:CajasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

    async ngOnInit(): Promise<void> {
      this.init();
      this.buscarAplicacion(this.router.url.substring(1));
      this.configuracion_pagina={};
      let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
      this.filter={
        id_empresa:this.usuario.id_empresa,
        sucursales:(es_usuario_administrador?[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)):Util.obtenerSucursalesUsuario(this.usuario)),
        sucursal: (es_usuario_administrador?{nombre:"TODOS",id:0}:Util.obtenerSucursalesUsuario(this.usuario)[0]),
        usuarios:[{nombre_usuario:"TODOS",id:0}],
		    usuario: {nombre_usuario:"TODOS",id:0},
      }
      await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_CIERRES_CAJA);
  
      if(this.usuario.empresa.usar_control_por_usuarios){
        if(es_usuario_administrador){	
          this.obtenerUsuarios();
        }else{
          this.filter.usuarios=[this.usuario]
          this.filter.usuario=this.usuario;
        }
      }else{
        this.obtenerUsuarios();
      }
  
      this.column = "fecha_apertura";
      this.direction="desc";
      this.getSearch(this.text_search,null);
    }
    
    ngAfterViewInit() {
        resaltarPestañaMenu(this.router.url.substring(1)+"1");
    }

    getItems(){
      this.blockUI.start('Recuperando... espere por favor!');
      this.cierresCajaService.obtenerListaCierresCaja(this).subscribe((dato:any) => {
        this.setPages(dato.paginas);
        this.cierres_caja=dato.cierresCaja;
        this.blockUI.stop();
      });
    }

    obtenerUsuarios(){
      this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
        this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
      });
    }

    imprimirCierreCaja(cierreCaja){
      this.cierresCajaService.obtenerCierreCaja(cierreCaja).subscribe((ccv:any)=>{
        cierreCaja=ccv;
        this.generarReporteCierreCaja(cierreCaja.sucursal,cierreCaja,[],
          [],
          [],
          [],
          cierreCaja.cajasSiguienteTurnoCerradas);
      });
    }

    generarReporteCierreCaja(sucursal,cierre,ventas,pagos,pagosCompra,comprasContado,cierresPendientes){
      var papel=[612,792];
      if(this.configuracion_pagina.getOrden().impresion.papel_cierre==GlobalVariable.Dictionary.PAPEL_OFICIO){
        papel=[612,936];
        this.imprimirReporteCierreCajaCartaOficio(sucursal,papel,cierre,ventas,pagos,pagosCompra,comprasContado,cierresPendientes);
      }else if(this.configuracion_pagina.getOrden().impresion.papel_cierre==GlobalVariable.Dictionary.PAPEL_CARTA){
        papel=[612,792];
        this.imprimirReporteCierreCajaCartaOficio(sucursal,papel,cierre,ventas,pagos,pagosCompra,comprasContado,cierresPendientes);
      }else if(this.configuracion_pagina.getOrden().impresion.papel_cierre==GlobalVariable.Dictionary.PAPEL_MEDIACARTA){
        papel=[612,396];
        this.imprimirReporteCierreCajaCartaOficio(sucursal,papel,cierre,ventas,pagos,pagosCompra,comprasContado,cierresPendientes);
      }else if(this.configuracion_pagina.getOrden().impresion.papel_cierre==GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO){
        papel=[612,468];
        this.imprimirReporteCierreCajaCartaOficio(sucursal,papel,cierre,ventas,pagos,pagosCompra,comprasContado,cierresPendientes);
      }else if(this.configuracion_pagina.getOrden().impresion.papel_cierre==GlobalVariable.Dictionary.PAPEL_ROLLO){
        var alto,totalItems=ventas.length+pagos.length+pagosCompra.length+comprasContado.length+cierresPendientes.length;
        if (cierre.conMovimientoProductos) {
          for(var i=0;i<ventas.length;i++){
            totalItems=totalItems+(ventas[i].detallesVenta.length);
          }
          /*for(var i=0;i<ventasCredito.length;i++){
            totalItems=totalItems+(ventasCredito[i].detallesVenta.length);
          }*/
          for(var i=0;i<comprasContado.length;i++){
            totalItems=totalItems+(comprasContado[i].detallesCompra.length);
          }
          /*for(var i=0;i<comprasCredito.length;i++){
            totalItems=totalItems+(comprasCredito[i].detallesCompra.length);
          }*/
        }
        if (cierre.conSaldoProductos) {
          for(var i=0;i<ventas.length;i++){
            totalItems=totalItems+(ventas[i].detallesVenta.length);
          }
          /*for(var i=0;i<ventasCredito.length;i++){
            totalItems=totalItems+(ventasCredito[i].detallesVenta.length);
          }*/
          for(var i=0;i<comprasContado.length;i++){
            totalItems=totalItems+(comprasContado[i].detallesCompra.length);
          }
          /*for(var i=0;i<comprasCredito.length;i++){
            totalItems=totalItems+(comprasCredito[i].detallesCompra.length);
          }*/
        }
        if(totalItems<=2){
          alto=420;
        }else{
          alto=420+(20*(totalItems-2))
        }console.log(totalItems);
        papel=[227,alto];
        this.imprimirReporteCierreCajaRollo(sucursal,papel,cierre,ventas,pagos,pagosCompra,comprasContado,cierresPendientes);
      }
    }

    imprimirReporteCierreCajaCartaOficio(sucursal,papel,cierre,ventas,pagos,pagosCompra,comprasContado,cierresPendientes){
      var doc = new PDFDocument({size:papel,margin:0});
      var stream = doc.pipe(blobStream());
      var fecha_reporte=new Date();

      this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"CIERRE DE CAJA",1,1,this.usuario,
        this.usuario.empresa.imagen,this.usuario.empresa.nombre,sucursal.nombre,this.usuario.empresa.direccion,
        (this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
      (this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
      (this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",this.configuracion_pagina.getOrden().impresion.papel_cierre,
      fecha_reporte,{imprimir_usuario:true});

      doc.font('Helvetica-Bold',8);
      doc.roundedRect(350,50,235,43,5).stroke();
      doc.text("Nº : ",355,55,{width:35,align:"left"});
      doc.text("SUCURSAL : ",420,55,{width:50,align:"left"});
      doc.text("FECHA APERTURA : ",355,65,{width:100,align:"left"});
      doc.text("FECHA CIERRE : ",355,75,{width:100,align:"left"});
      doc.font('Helvetica', 8);
      cierre.fecha_apertura=new Date(cierre.fecha_apertura);
      cierre.fecha_cierre=new Date(cierre.fecha_cierre);
      doc.text(cierre.numero_documento,380,55);
      doc.text((cierre.fecha_apertura.getDate()+"/"+(cierre.fecha_apertura.getMonth()+1)+"/"+cierre.fecha_apertura.getFullYear()),450,65);
      doc.text((cierre.fecha_cierre.getDate()+"/"+(cierre.fecha_cierre.getMonth()+1)+"/"+cierre.fecha_cierre.getFullYear()),450,75);
      doc.text(cierre.sucursal.nombre,470,55);

      let y=110;
      doc.font('Helvetica',8);
      doc.text("SALDO INICIAL: ",100,y);
      doc.font('Helvetica-Bold',8);
      doc.text("+ Bs "+cierre.saldo_inicial.toFixed(2),350,y);
      y=y+20;
      doc.font('Helvetica',8);
      doc.text("DEPOSITOS: ",100,y);
      doc.font('Helvetica-Bold',8);
      doc.text(cierre.depositos?"+ Bs "+cierre.depositos.toFixed(2):0,350,y);
      y=y+20;
      doc.font('Helvetica',8);
      doc.text("RETIROS: ",100,y);
      doc.font('Helvetica-Bold',8);
      doc.text((cierre.salidas?"- Bs "+cierre.salidas.toFixed(2):0),350,y);
      y=y+20;
      doc.font('Helvetica',8);
      doc.text("VENTAS AL CONTADO RECIBOS: ",100,y);
      doc.font('Helvetica-Bold',8);
      doc.text("+ Bs "+cierre.ventas_contado_recibos.total.toFixed(2),350,y);
      y=y+20;
      if(this.usuario.empresa.usar_facturacion || this.usuario.empresa.usar_facturacion_computarizada_en_linea){
        doc.font('Helvetica',8);
        doc.text("VENTAS AL CONTADO FACTURAS: ",100,y);
        doc.font('Helvetica-Bold',8);
        doc.text("+ Bs "+(cierre.ventas_contado_facturas?cierre.ventas_contado_facturas.total.toFixed(2):0),350,y);
        y=y+20;
      }
      doc.font('Helvetica',8);
      doc.text("COBROS REALIZADOS: ",100,y);
      doc.font('Helvetica-Bold',8);
      doc.text("+ Bs "+cierre.ventas_credito.toFixed(2),350,y);
      y=y+20;
      doc.font('Helvetica',8);
      doc.text("COMPRAS AL CONTADO: ",100,y);
      doc.font('Helvetica-Bold',8);
      doc.text("- Bs "+cierre.compras_contado.toFixed(2),350,y);		
      y=y+20;
      doc.font('Helvetica',8);
      doc.text("PAGOS REALIZADOS: ",100,y);
      doc.font('Helvetica-Bold',8);
      doc.text("- Bs "+cierre.compras_credito.toFixed(2),350,y);
      y=y+20;
      if(this.usuario.empresa.usar_conteo_de_dinero){
        doc.font('Helvetica',8);
        doc.text("SALDO FALTANTE: ",100,y);
        doc.font('Helvetica-Bold',8);
        doc.text("- Bs "+cierre.saldo_faltante.toFixed(2),350,y);
        y=y+20;
        doc.font('Helvetica',8);
        doc.text("SALDO SOBRANTE: ",100,y);
        doc.font('Helvetica-Bold',8);
        doc.text("+ Bs "+cierre.saldo_sobrante.toFixed(2),350,y);
        y=y+20;
      }
      doc.font('Helvetica',8);
      doc.text("SALDO FINAL CAJA: ",100,y);
      doc.font('Helvetica-Bold',8);
      doc.text("Bs "+cierre.saldo_caja.toFixed(2),350,y);
      y=y+20;
    
      for(let i=0;i<cierre.ventas_no_contado.length;i++){
        doc.font('Helvetica',8);
        doc.text("VENTAS "+cierre.ventas_no_contado[i].tipo_pago+": ",100,y);
        doc.font('Helvetica-Bold',8);
        doc.text("Bs "+cierre.ventas_no_contado[i].total.toFixed(2),350,y);
        y=y+20;
      }

      if(this.usuario.empresa.usar_conteo_de_dinero){
        doc.text("-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------",0,doc.y,{align:'center'});
        doc.font('Helvetica',8);
        doc.text("ARQUEO DE CAJA: ",100,y);
        doc.font('Helvetica-Bold',8);
        doc.text("Bs "+(cierre.conteo_dinero.total_efectivo+cierre.conteo_dinero.total_tarjetas+cierre.conteo_dinero.total_pagos_en_linea).toFixed(2),350,y);
        y=y+20;
        doc.font('Helvetica',8);
        doc.text("TOTAL EFECTIVO ",100,y);
        doc.font('Helvetica-Bold',8);
        doc.text("Bs "+(cierre.conteo_dinero?cierre.conteo_dinero.total_efectivo.toFixed(2):0.00),350,y);
        y=y+20;
        doc.font('Helvetica',8);
        doc.text("TOTAL TARJETAS: ",100,y);
        doc.font('Helvetica-Bold',8);
        doc.text("Bs "+(cierre.conteo_dinero?cierre.conteo_dinero.total_tarjetas.toFixed(2):0.00),350,y);
        y=y+20;
        doc.font('Helvetica',8);
        doc.text("TOTAL PAGOS EN LÍNEA: ",100,y);
        doc.font('Helvetica-Bold',8);
        doc.text("Bs "+(cierre.conteo_dinero?cierre.conteo_dinero.total_pagos_en_linea.toFixed(2):0.00),350,y);
        y=y+20;
      }

      doc.text("---------------------------------------------                                                ---------------------------------------------",50,papel[1]-90,{align:'center'});
      doc.text("ENTREGUE CONFORME                                                              RECIBI CONFORME",50,papel[1]-80,{align:'center'});
      
      doc.end();
      stream.on('finish', function() {
        var fileURL = stream.toBlobURL('application/pdf');
        window.open(fileURL,'_blank','location=no');
      });
      this.blockUI.stop();
    }
  
    imprimirReporteCierreCajaRollo(sucursal,papel,cierre,ventas,pagos,pagosCompra,comprasContado,cierresPendientes){
      var doc = new PDFDocument({size:papel,margins:{top:10,bottom:10,left:10,right:20}});
      var stream = doc.pipe(blobStream());
      cierre.fecha_apertura=new Date(cierre.fecha_apertura);
      cierre.fecha_cierre=new Date(cierre.fecha_cierre);
      doc.moveDown(2);
      doc.font('Helvetica-Bold',8);
      doc.text("CIERRE DE CAJA",{align:'center'});
      if(this.usuario.empresa.usar_sucursales){
        doc.text("SUCURSAL: "+sucursal.nombre,{align:'center'});
      }
      doc.text("Fecha Apertura: "+cierre.fecha_apertura.getDate()+"/"+(cierre.fecha_apertura.getMonth()+1)+"/"+cierre.fecha_apertura.getFullYear()+" - "+"Fecha Cierre: "+cierre.fecha_cierre.getDate()+"/"+(cierre.fecha_cierre.getMonth()+1)+"/"+cierre.fecha_cierre.getFullYear(),{align:'center'});
      doc.text("Nro. Doc. : "+cierre.numero_documento,{align:'center'});
  
      if(cierresPendientes.length>0){
        doc.moveDown(0.4);
        var saldoTurnoY=doc.y;
        doc.text("SALDO ANTERIORES TURNOS: ",{align:'left'});
        doc.font('Helvetica',8);
        doc.moveDown(0.4);
        var y=doc.y; var sumaSaldoAnterioresTurnos=0;
        for(var i=0;i<cierresPendientes.length;i++){
          doc.text((cierresPendientes[i].cierreCaja?cierresPendientes[i].cierreCaja.usuario.nombre_usuario:cierresPendientes[i].usuario),60,y,{width:90});
          doc.text((cierresPendientes[i].cierreCaja?cierresPendientes[i].cierreCaja.importe:cierresPendientes[i].importe),150,y);
          sumaSaldoAnterioresTurnos=sumaSaldoAnterioresTurnos+(cierresPendientes[i].cierreCaja?cierresPendientes[i].cierreCaja.importe:cierresPendientes[i].importe);
          y=y+20;
        }
        doc.font('Helvetica-Bold',8);
        doc.text(sumaSaldoAnterioresTurnos,0,saldoTurnoY,{align:'right'});
        doc.y=y;
        doc.moveDown(0.4);
        doc.x=10;
      }
  
      doc.moveDown(0.6);
      doc.font('Helvetica',8);
      var currentY=doc.y;
      doc.text("SALDO INICIAL: ",{align:'left',width:100});
      doc.text("+ Bs "+cierre.saldo_inicial.toFixed(2),0,currentY,{align:'right'});
      doc.x=10;
  
      doc.moveDown(0.4);
      doc.font('Helvetica',8);
      currentY=doc.y;
      doc.text("DEPOSITOS: ",{align:'left',width:100});
      doc.text("+ Bs "+(cierre.depositos?cierre.depositos.toFixed(2):0),0,currentY,{align:'right'});
      doc.x=10;
  
      doc.moveDown(0.4);
      doc.font('Helvetica',8);
      currentY=doc.y;
      doc.text("RETIROS: ",{align:'left',width:100});
      doc.text("- Bs "+(cierre.salidas?cierre.salidas.toFixed(2):0),0,currentY,{align:'right'});
      doc.x=10;
  
      doc.moveDown(0.4);
      doc.font('Helvetica',8);
      let yContadoRecibos=doc.y;
      doc.text("VENTAS AL CONTADO RECIBOS: ",{align:'left'});
      doc.text("+ Bs "+cierre.ventas_contado_recibos.total.toFixed(2),0,yContadoRecibos,{align:'right'});
      doc.x=10;

      if(this.usuario.empresa.usar_facturacion || this.usuario.empresa.usar_facturacion_computarizada_en_linea){
        doc.moveDown(0.4);
        doc.font('Helvetica',8);
        let yContadoFacturas=doc.y;
        doc.text("VENTAS AL CONTADO FACTURAS: ",{align:'left'});
        doc.text("+ Bs "+(cierre.ventas_contado_facturas?cierre.ventas_contado_facturas.total.toFixed(2):0),0,yContadoFacturas,{align:'right'});
        doc.x=10;
      }

      doc.moveDown(0.4);
      doc.font('Helvetica',8);
      var cobroY=doc.y;
      doc.text("COBROS REALIZADOS: ",{align:'left'});
      doc.text("+ Bs "+cierre.ventas_credito.toFixed(2),0,cobroY,{align:'right'});
      doc.x=10;
      
      doc.moveDown(0.4);
      doc.font('Helvetica',8);
      var compraContadoY=doc.y;
      doc.text("COMPRAS AL CONTADO: ",{align:'left'});
      doc.font('Helvetica',8);
      doc.text("- Bs "+cierre.compras_contado.toFixed(2),0,compraContadoY,{align:'right'});
      doc.x=10;
  
      doc.moveDown(0.4);
      doc.font('Helvetica',8);
      var pagoY=doc.y;
      doc.text("PAGOS REALIZADOS: ",{align:'left'});
      doc.text("- Bs "+cierre.compras_credito.toFixed(2),0,pagoY,{align:'right'});
      doc.x=10;
  
      if(this.usuario.empresa.usar_conteo_de_dinero){
        doc.moveDown(0.4);
        doc.x=10;
        doc.font('Helvetica',8);
        currentY=doc.y;
        doc.text("SALDO FALTANTE: ",{align:'left'});
        doc.text("- Bs "+cierre.saldo_faltante.toFixed(2),0,currentY,{align:'right'});
        doc.x=10;
        doc.moveDown(0.4);
        currentY=doc.y;
        doc.text("SALDO SOBRANTE: ",{align:'left'});
        doc.text("+ Bs "+cierre.saldo_sobrante.toFixed(2),0,currentY,{align:'right'});
      }

      doc.x=10;
      doc.moveDown(0.4);
      doc.font('Helvetica-Bold',8);
      currentY=doc.y;
      doc.text("SALDO FINAL CAJA: ",{align:'left'});
      doc.text("Bs "+cierre.saldo_caja.toFixed(2),0,currentY,{align:'right'});
      //doc.moveDown(0.4);
      doc.x=10;
      doc.font('Helvetica',8);
      var currentY=doc.y;
      for(let i=0;i<cierre.ventas_no_contado.length;i++){
        doc.moveDown(0.4);
        currentY=doc.y;
        doc.x=10;
        doc.text("VENTAS "+cierre.ventas_no_contado[i].tipo_pago+": ",{align:'left',width:150});
        doc.text("Bs "+cierre.ventas_no_contado[i].total.toFixed(2),0,currentY,{align:'right'});
      }
      if(this.usuario.empresa.usar_conteo_de_dinero){
        doc.text("-----------------------------------------------------------------------------",0,doc.y,{align:'center'});
        doc.moveDown(0.4);
        doc.x=10;
        currentY=doc.y;
        doc.font('Helvetica-Bold',8);
        doc.text("ARQUEO DE CAJA ",{align:'left',width:100});
        doc.text("Bs "+(cierre.conteo_dinero.total_efectivo+cierre.conteo_dinero.total_tarjetas+cierre.conteo_dinero.total_pagos_en_linea).toFixed(2),0,currentY,{align:'right'});
        doc.moveDown(0.4);
        doc.x=10;
        currentY=doc.y;
        doc.font('Helvetica',8);
        doc.text("TOTAL EFECTIVO: ",{align:'left',width:100});
        doc.text("Bs "+(cierre.conteo_dinero?cierre.conteo_dinero.total_efectivo.toFixed(2):0.00),0,currentY,{align:'right'});
        doc.moveDown(0.4);
        doc.x=10;
        currentY=doc.y;
        doc.font('Helvetica',8);
        doc.text("TOTAL TARJETAS: ",{align:'left',width:100});
        doc.text("Bs "+(cierre.conteo_dinero?cierre.conteo_dinero.total_tarjetas.toFixed(2):0.00),0,currentY,{align:'right'});
        doc.moveDown(0.4);
        doc.x=10;
        currentY=doc.y;
        doc.font('Helvetica',8);
        doc.text("TOTAL PAGOS EN LÍNEA: ",{align:'left',width:100});
        doc.text("Bs "+(cierre.conteo_dinero?cierre.conteo_dinero.total_pagos_en_linea.toFixed(2):0.00),0,currentY,{align:'right'});
      }
      
      doc.moveDown(1.6);
      doc.x=10;
  
      if(cierre.conMovimientoProductos){
        doc.font('Helvetica-Bold',8);
        doc.text("RESUMEN MOVIMIENTOS VENTA PRODUCTOS: ",{align:'center'});
        doc.font('Helvetica',8);
        doc.moveDown(0.4);
        y=doc.y;
        for(var i=0;i<ventas.length;i++){
          for(var j=0;j<ventas[i].detallesVenta.length;j++){
            doc.text(ventas[i].detallesVenta[j].cantidad,20,y);
            doc.text(ventas[i].detallesVenta[j].producto.nombre,50,y,{width:100});
            doc.text(ventas[i].detallesVenta[j].total,160,y);
            y=y+20;
          }
        }
        /*for(var i=0;i<ventasCredito.length;i++){
          for(var j=0;j<ventasCredito[i].detallesVenta.length;j++){
            doc.text(ventasCredito[i].detallesVenta[j].cantidad,20,y);
            doc.text(ventasCredito[i].detallesVenta[j].producto.nombre,50,y,{width:100});
            doc.text(ventasCredito[i].detallesVenta[j].total,160,y);
            y=y+20;
          }
        }*/
        doc.y=y;
        doc.moveDown(0.4);
        doc.x=10;
  
        doc.font('Helvetica-Bold',8);
        doc.text("RESUMEN MOVIMIENTOS COMPRA PRODUCTOS: ",{align:'center'});
        doc.font('Helvetica',8);
        doc.moveDown(0.4);
        y=doc.y;
        for(var i=0;i<comprasContado.length;i++){
          for(var j=0;j<comprasContado[i].detallesCompra.length;j++){
            doc.text(comprasContado[i].detallesCompra[j].cantidad,20,y);
            doc.text(comprasContado[i].detallesCompra[j].producto.nombre,50,y,{width:100});
            doc.text(comprasContado[i].detallesCompra[j].total,160,y);
            y=y+20;
          }
        }
        /*for(var i=0;i<comprasCredito.length;i++){
          for(var j=0;j<comprasCredito[i].detallesCompra.length;j++){
            doc.text(comprasCredito[i].detallesCompra[j].cantidad,20,y);
            doc.text(comprasCredito[i].detallesCompra[j].producto.nombre,50,y,{width:100});
            doc.text(comprasCredito[i].detallesCompra[j].total,160,y);
            y=y+20;
          }
        }*/
        doc.y=y;
        doc.moveDown(0.4);
        doc.x=10;
      }
  
      if(cierre.conSaldoProductos){
        doc.font('Helvetica-Bold',8);
        doc.text("RESUMEN SALDOS DE VENTA PRODUCTOS: ",{align:'center'});
        doc.font('Helvetica',8);
        doc.moveDown(0.4);
        y=doc.y;
        for(var i=0;i<ventas.length;i++){
          for(var j=0;j<ventas[i].detallesVenta.length;j++){
            doc.text(ventas[i].detallesVenta[j].producto.nombre,50,y,{width:100});
            var saldoInventario=0;
            for(var k=0;k<ventas[i].detallesVenta[j].producto.inventarios.length;k++){
              if(ventas[i].detallesVenta[j].producto.inventarios[k].almacen.id_sucursal==sucursal.id){
                saldoInventario=saldoInventario+ventas[i].detallesVenta[j].producto.inventarios[k].cantidad;
              }
            }
            doc.text(saldoInventario+" Und.",160,y);
            y=y+20;
          }
        }
        /*for(var i=0;i<ventasCredito.length;i++){
          for(var j=0;j<ventasCredito[i].detallesVenta.length;j++){
            doc.text(ventasCredito[i].detallesVenta[j].producto.nombre,50,y,{width:100});
            var saldoInventario=0;
            for(var k=0;k<ventasCredito[i].detallesVenta[j].producto.inventarios.length;k++){
              if(ventasCredito[i].detallesVenta[j].producto.inventarios[k].almacen.id_sucursal==sucursal.id){
                saldoInventario=saldoInventario+ventasCredito[i].detallesVenta[j].producto.inventarios[k].cantidad;
              }
            }
            doc.text(saldoInventario+" Und.",160,y);
            y=y+20;
          }
        }*/
        doc.y=y;
        doc.moveDown(0.4);
        doc.x=10;
  
        doc.font('Helvetica-Bold',8);
        doc.text("RESUMEN SALDOS DE COMPRA PRODUCTOS: ",{align:'center'});
        doc.font('Helvetica',8);
        doc.moveDown(0.4);
        y=doc.y;
        for(var i=0;i<comprasContado.length;i++){
          for(var j=0;j<comprasContado[i].detallesCompra.length;j++){
            doc.text(comprasContado[i].detallesCompra[j].producto.nombre,50,y,{width:100});
            var saldoInventario=0;
            for(var k=0;k<comprasContado[i].detallesCompra[j].producto.inventarios.length;k++){
              if(comprasContado[i].detallesCompra[j].producto.inventarios[k].almacen.id_sucursal==sucursal.id){
                saldoInventario=saldoInventario+comprasContado[i].detallesCompra[j].producto.inventarios[k].cantidad;
              }
            }
            doc.text(saldoInventario+" Und.",160,y);
            y=y+20;
          }
        }
        /*for(var i=0;i<comprasCredito.length;i++){
          for(var j=0;j<comprasCredito[i].detallesCompra.length;j++){
            doc.text(comprasCredito[i].detallesCompra[j].producto.nombre,50,y,{width:100});
            var saldoInventario=0;
            for(var k=0;k<comprasCredito[i].detallesCompra[j].producto.inventarios.length;k++){
              if(comprasCredito[i].detallesCompra[j].producto.inventarios[k].almacen.id_sucursal==sucursal.id){
                saldoInventario=saldoInventario+comprasCredito[i].detallesCompra[j].producto.inventarios[k].cantidad;
              }
            }
            doc.text(saldoInventario+" Und.",160,y);
            y=y+20;
          }
        }*/
        doc.y=y;
        doc.moveDown(0.4);
        doc.x=10;
      }
  
      doc.font('Helvetica-Bold',8);
      doc.x=10;
      doc.text("-----------------------------      -----------------------------",0,doc.y+40,{align:'center'});
      doc.text("ENTREGUE CONFORME   RECIBI CONFORME",{align:'center'});
  
      doc.moveDown(0.4);
      doc.font('Helvetica',8);
      var currentDate=new Date();
      doc.text("Usuario: "+cierre.usuario.persona.nombres+" "+currentDate.getDate()+"/"+(currentDate.getMonth()+1)+"/"+currentDate.getFullYear()+" "+currentDate.getHours()+":"+currentDate.getMinutes(),{align:'center'});
      
      doc.end();
      stream.on('finish', function() {
        var fileURL = stream.toBlobURL('application/pdf');
        window.open(fileURL,'_blank','location=no');
      });
      this.blockUI.stop();
    }

    abrirConfiguracionAplicacion(){
      this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionCierresCajaComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
      this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
      this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
      this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
        this.configuracion_aplicacion_modal.close();
      });
    }

    imprimirMovimientoProductos(cierre_caja){
      this.blockUI.start();
      this.cierresCajaService.obtenerMovimientosProductosCierreCaja(cierre_caja).subscribe((datos_cierre:any)=>{
        this.blockUI.stop();
        var papel=[612,792];
        if(this.configuracion_pagina.getOrden().impresion.papel_cierre==GlobalVariable.Dictionary.PAPEL_OFICIO){
          papel=[612,936];
        }else if(this.configuracion_pagina.getOrden().impresion.papel_cierre==GlobalVariable.Dictionary.PAPEL_CARTA){
          papel=[612,792];
        }else if(this.configuracion_pagina.getOrden().impresion.papel_cierre==GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO){
          papel=[612,468];
        }else if(this.configuracion_pagina.getOrden().impresion.papel_cierre==GlobalVariable.Dictionary.PAPEL_ROLLO){
          var alto,totalItems=datos_cierre.movimiento_productos.length;
          if(totalItems<=2){
            alto=200;
          }else{
            alto=200+(20*(totalItems-2))
          }
          papel=[227,alto];
          this.imprimirMovimientoProductosRollo(papel,cierre_caja,datos_cierre);				
        }
      });
    }

    imprimirMovimientoProductosRollo(papel,cierre,datos_cierre){
      var doc = new PDFDocument({size:papel,margins:{top:10,bottom:10,left:10,right:20}});
      var stream = doc.pipe(blobStream());
      cierre.fecha_apertura=new Date(cierre.fecha_apertura);
      cierre.fecha_cierre=new Date(cierre.fecha_cierre);
      doc.moveDown(2);
      doc.font('Helvetica-Bold',8);
      doc.text("CIERRE DE CAJA",{align:'center'});
      if(this.usuario.empresa.usar_sucursales){
        doc.text("SUCURSAL: "+cierre.sucursal,{align:'center'});
      }
      doc.text("Fecha Apertura: "+cierre.fecha_apertura.getDate()+"/"+(cierre.fecha_apertura.getMonth()+1)+"/"+cierre.fecha_apertura.getFullYear()+" - "+"Fecha Cierre: "+cierre.fecha_cierre.getDate()+"/"+(cierre.fecha_cierre.getMonth()+1)+"/"+cierre.fecha_cierre.getFullYear(),{align:'center'});
      doc.text("Nro. Doc. : "+cierre.numero_documento,{align:'center'});
  
      doc.moveDown(0.8);
      doc.font('Helvetica-Bold',8);
      var yContado=doc.y;
      doc.text("Nº",20,yContado);
      doc.text("Nombre",40,yContado,{width:110});
      doc.text("Cant.",145,yContado,{width:90});
      doc.text("P.U.",170,yContado,{width:90});
      doc.text("Total",190,yContado,{width:90});
      doc.font('Helvetica',8);
      doc.moveDown(0.4);
      doc.font('Helvetica',8);
      let y=doc.y,suma=0,sumaPago=0,sumaCobro=0;
      for(var i=0;i<datos_cierre.movimiento_productos.length;i++){
        doc.text(""+(i+1),20,y);
        doc.text(datos_cierre.movimiento_productos[i].nombre,40,y,{width:110});
        doc.text(datos_cierre.movimiento_productos[i].cantidad,150,y,{width:90});
        doc.text(datos_cierre.movimiento_productos[i].precio_unitario,170,y,{width:90});
        doc.text(datos_cierre.movimiento_productos[i].total,190,y,{width:90});
        suma=suma+datos_cierre.movimiento_productos[i].total;
        y=y+20;
      }
  
      doc.moveDown(2);
      doc.x=10;
      doc.font('Helvetica-Bold',8);
      var currentY=doc.y;
      doc.text("Importe Bs: "+(Math.round(suma*100)/100),{align:'right'});
      //doc.text("Importe Bs: "+(Math.round(datos_cierre.resumen_ventas.importe*100)/100),{align:'right'});
      doc.text("Descuentos Bs: "+(Math.round(datos_cierre.resumen_ventas.descuento*100)/100),{align:'right'});
      doc.text("Total Bs: "+(Math.round(datos_cierre.resumen_ventas.total*100)/100),{align:'right'});
      //doc.text("Bs "+cierre.ventas_no_contado.toFixed(2),0,currentY,{align:'right'});
      
      doc.end();
      stream.on('finish', function() {
        var fileURL = stream.toBlobURL('application/pdf');
        window.open(fileURL,'_blank','location=no');
      });
      this.blockUI.stop();
    }

    crearNuevoCierre(){
      this.nuevo_cierre_caja={
        id_empresa:this.usuario.id_empresa,
        id_usuario:this.usuario.id,
        saldo_inicial:0,
        gastos:0,
        sucursal:this.usuario.sucursalesUsuario[0].sucursal
      };
      this.abrirAperturaCaja();
    }

    validarApertura(){
      let res=true;
      if(this.nuevo_cierre_caja.saldo_inicial!=0 && !this.nuevo_cierre_caja.saldo_inicial){
        res=res && false;
        this.toastr.error("Debe especificar el saldo inicial!");
      }
      return res;
    }

    aperturarCajas(){
      if(this.validarApertura()){
        this.blockUI.start();
        this.cierresCajaService.aperturarCierreCaja(this.nuevo_cierre_caja).subscribe((res:any)=>{
          this.blockUI.stop();
          if(res.tiene_error){
            this.toastr.warning(res.mensaje);
          }else{
            this.modal_apertura_caja.close();
            this.getItems();
            this.toastr.success(res.mensaje);
          }
        });
      }
    }

    abrirAperturaCaja(){
      this.modal_apertura_caja=this.modalService.open(this.modal_apertura_caja_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.modal_apertura_caja.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    abrirRegistroCierreCaja(){
      this.modal_registro_cierre_caja=this.modalService.open(this.modal_registro_cierre_caja_ref, {windowClass:"cierre-caja",scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
      this.modal_registro_cierre_caja.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    sumarVentasEfectivoGiftCard(cierre_caja){
      var suma=0;
      cierre_caja.ventas_contado_giftcard={total:0};
      for(var i=0;i<cierre_caja.ventasNoContado.length;i++){
        if(cierre_caja.ventasNoContado[i].tipoPago.nombre.includes('GIFT') || cierre_caja.ventasNoContado[i].tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_GFTC){
          cierre_caja.ventas_contado_giftcard.total=cierre_caja.ventas_contado_giftcard.total+(cierre_caja.ventasNoContado[i].total-cierre_caja.ventasNoContado[i].importe_gift_card);
        }
      }
      return cierre_caja.ventas_contado_giftcard.total;
    }
    
    sumarVentasEfectivoQr(cierre_caja){
      var suma=0;
      cierre_caja.ventas_contado_qr={total:0};
      for(var i=0;i<cierre_caja.ventasNoContado.length;i++){
        if(cierre_caja.ventasNoContado[i].tipoPago.nombre.includes('QR') || cierre_caja.ventasNoContado[i].tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CODQR){
          cierre_caja.ventas_contado_qr.total=cierre_caja.ventas_contado_qr.total+(cierre_caja.ventasNoContado[i].total-cierre_caja.ventasNoContado[i].importe_qr);
        }
      }
      return cierre_caja.ventas_contado_qr.total;
    }

    sumarVentasContado(cierre_caja){
      var suma=0;
      cierre_caja.ventas_contado_recibos={total:0};cierre_caja.ventas_contado_facturas={total:0};
      for(var i=0;i<cierre_caja.ventasContado.length;i++){
        if(cierre_caja.ventasContado[i].transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION || cierre_caja.ventasContado[i].transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
          cierre_caja.ventas_contado_facturas.total=cierre_caja.ventas_contado_facturas.total+cierre_caja.ventasContado[i].total;
        }else{
          cierre_caja.ventas_contado_recibos.total=cierre_caja.ventas_contado_recibos.total+cierre_caja.ventasContado[i].total;
        }
      }
      return cierre_caja.ventas_contado_recibos.total+cierre_caja.ventas_contado_facturas.total;
    }
  
    sumarVentasNoContado(){
      let sumas_ventas_no_contado=[];
      let id_tipos_pagos = this.cierre_caja.ventasNoContado.filter((venta, i, arr) => arr.findIndex(t => t.id_tipo_pago === venta.id_tipo_pago) === i);
      for(var i=0;i<id_tipos_pagos.length;i++){
        let ventas_no_contado=this.cierre_caja.ventasNoContado.filter(e => e.id_tipo_pago == id_tipos_pagos[i].id_tipo_pago);
        let suma_no_contado=0;
        for(let j=0;j<ventas_no_contado.length;j++){
          if(ventas_no_contado[j].tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_GFTC ||
            ventas_no_contado[j].tipoPago.nombre.includes("GIFT")
          ){
            suma_no_contado=suma_no_contado+(ventas_no_contado[j].importe_gift_card);
          }else if(ventas_no_contado[j].tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CODQR ||
            ventas_no_contado[j].tipoPago.nombre.includes("QR")
          ){
            suma_no_contado=suma_no_contado+(ventas_no_contado[j].importe_qr);
          }else{
            suma_no_contado=suma_no_contado+ventas_no_contado[j].total;
          }
        }
        sumas_ventas_no_contado.push({tipo_pago:id_tipos_pagos[i].tipoPago.nombre,total:Math.round(suma_no_contado*100)/100});
      }
      this.cierre_caja.ventas_no_contado=sumas_ventas_no_contado;
    }
  
    sumarVentasCredito(ventasCredito){
      var sumaCredito=0;
      for(var i=0;i<ventasCredito.length;i++){
        if(ventasCredito[i].pagosVenta.length>0){
          if(ventasCredito[i].pagosVenta[0].a_cuenta_anterior>0){
            sumaCredito=sumaCredito+ventasCredito[i].pagosVenta[0].a_cuenta_anterior;
          }
        }else{
          sumaCredito=sumaCredito+ventasCredito[i].a_cuenta;
        }
      }
      return sumaCredito;
    }
  
    sumarComprasContado(cierre_caja){
      var sumaComprasContado=0;
      for(var i=0;i<cierre_caja.comprasContado.length;i++){
        sumaComprasContado=sumaComprasContado+cierre_caja.comprasContado[i].total;
      }
      cierre_caja.compras_contado=sumaComprasContado;
      return sumaComprasContado;
    }
  
    sumarComprasCredito(comprasCredito){
      var sumaComprasCredito=0;
      for(var i=0;i<comprasCredito.length;i++){
        if(comprasCredito[i].pagosCompra.length>0){
          if(comprasCredito[i].pagosCompra[0].a_cuenta_anterior>0){
            sumaComprasCredito=sumaComprasCredito+comprasCredito[i].pagosCompra[0].a_cuenta_anterior;
          }
        }else{
          if(comprasCredito[i].a_cuenta){
            sumaComprasCredito=sumaComprasCredito+comprasCredito[i].a_cuenta;
          }
        }
      }
      return sumaComprasCredito;
    }
  
    sumarPagosVenta(cierre_caja){
      var sumaPago=0;
      for(var i=0;i<cierre_caja.pagosVenta.length;i++){
        sumaPago=sumaPago+cierre_caja.pagosVenta[i].monto_pagado;
      }
      cierre_caja.ventas_credito=sumaPago;
      return sumaPago;
    }
  
    sumarPagosCompra(cierre_caja){
      var sumaCobro=0;
      for(var i=0;i<cierre_caja.pagosCompra.length;i++){
        sumaCobro=sumaCobro+cierre_caja.pagosCompra[i].monto_pagado;
      }
      cierre_caja.compras_credito=sumaCobro;
      return sumaCobro;
    }

    abrirPopupConfirmacionEliminacion(cierre_caja){
      this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
      this.popupConfirmacion.componentInstance.message = "¿Esta seguro de cerrar la caja?";
      this.popupConfirmacion.componentInstance.data = cierre_caja;
      
      this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
        if($e.confirm){
          this.abrirModalCierreCaja(cierre_caja);
        }
        this.popupConfirmacion.close();
          });
    }

    abrirModalCierreCaja(cierre_caja){
      this.blockUI.start();
      var inicio=new Date();
      var fin=new Date();
      let id_usuario=this.usuario.empresa.usar_caja_pos_sucursal?0:this.usuario.id;
      this.cierresCajaService.obtenerVentasContado({ids_sucursales:cierre_caja.id_sucursal,inicio:inicio,fin:fin,id_usuario:id_usuario,cierre_caja:{id:0}}).subscribe((ventasContado:any[])=>{
        this.cierresCajaService.obtenerPagosVenta({ids_sucursales:cierre_caja.id_sucursal,inicio:inicio,fin:fin,id_usuario:id_usuario,cierre_caja:{id:0}}).subscribe((pagos:any[])=>{
          this.cierresCajaService.obtenerPagosCompra({ids_sucursales:cierre_caja.id_sucursal,inicio:inicio,fin:fin,id_usuario:id_usuario,cierre_caja:{id:0}}).subscribe((pagosCompra:any[])=>{
            this.cierresCajaService.obtenerComprasContado({ids_sucursales:cierre_caja.id_sucursal,inicio:inicio,fin:fin,id_usuario:id_usuario,cierre_caja:{id:0}}).subscribe((comprasContado:any[])=>{
              this.cierresCajaService.obtenerCierresCajaPendientes({ids_sucursales:cierre_caja.id_sucursal}).subscribe((cierresPendientes:any[])=>{
                this.cierresCajaService.obtenerVentasNoContado({ids_sucursales:cierre_caja.id_sucursal,inicio:inicio,fin:fin,id_usuario:id_usuario,cierre_caja:{id:0}}).subscribe((ventasNoContado:any[])=>{
                  this.cierre_caja=cierre_caja;
                      var generado=false;
                      this.cierre_caja.fecha_cierre=new Date();
                      this.cierre_caja.saldo_faltante=0;
                      this.cierre_caja.saldo_sobrante=0;
                      this.cierre_caja.ventasContado=ventasContado.filter(e => e.id_sucursal == this.cierre_caja.id_sucursal);
                      this.cierre_caja.ventasNoContado=ventasNoContado.filter(e => e.id_sucursal == this.cierre_caja.id_sucursal);
                      this.cierre_caja.pagosVenta=pagos.filter(e => e.venta.id_sucursal ==this.cierre_caja.id_sucursal);
                      this.cierre_caja.pagosCompra=pagosCompra.filter(e => e.compra.id_sucursal == this.cierre_caja.id_sucursal);
                      this.cierre_caja.comprasContado=comprasContado.filter(e => e.id_sucursal == this.cierre_caja.id_sucursal);
                      this.cierre_caja.cierresPendientesLeidos=cierresPendientes.filter(e => e.id_sucursal == this.cierre_caja.id_sucursal);
                      if(this.cierre_caja.ventasContado.length>0 ||
                        this.cierre_caja.pagosVenta.length>0 ||
                        this.cierre_caja.pagosCompra.length>0 ||
                        this.cierre_caja.comprasContado.length>0 ||
                        this.cierre_caja.cierresPendientesLeidos.length>0 ||
                        this.cierre_caja.ventasNoContado.length>0){
                        generado=true;
                        this.cierre_caja.cierresPendientes=[];
                        /*if(this.cierre_caja.cierresPendientesLeidos.length>0){
                          this.llenarCierresPendientes(this.cierre_caja,this.cierre_caja.cierresPendientesLeidos);
                        }*/
                      }
                      this.blockUI.stop();
                      if(!generado){
                        this.toastr.info("No existen Movimientos!");
                      }else{
                        this.sumarVentasNoContado();
                        this.calcularImporteCierre();
                        if(this.usuario.empresa.usar_conteo_de_dinero){
                          if(this.cierre_caja.saldo_caja>this.cierre_caja.total_efectivo){
                            this.cierre_caja.saldo_faltante=this.cierre_caja.saldo_caja-this.cierre_caja.total_efectivo;
                          }
                          if(this.cierre_caja.saldo_caja<this.cierre_caja.total_efectivo){
                            this.cierre_caja.saldo_sobrante=this.cierre_caja.total_efectivo-this.cierre_caja.saldo_caja;
                          }
                        }
  
                        this.abrirRegistroCierreCaja();
                      }
                });
              });
            });
          });
        });
      });
    }

    calcularImporteCierre(){
      var importeCierresPendientes=0;
      for(var i=0;this.cierre_caja.cierresPendientes && i<this.cierre_caja.cierresPendientes.length;i++){
        importeCierresPendientes=importeCierresPendientes+this.cierre_caja.cierresPendientes[i].importe;
      }
      this.cierre_caja.saldo_caja=(Math.round((importeCierresPendientes+this.cierre_caja.saldo_inicial+this.cierre_caja.depositos-this.cierre_caja.salidas+
                      this.sumarVentasContado(this.cierre_caja)-
                      this.sumarComprasContado(this.cierre_caja)+
                      this.sumarPagosVenta(this.cierre_caja)-
                      this.sumarPagosCompra(this.cierre_caja)+
                      this.sumarVentasEfectivoGiftCard(this.cierre_caja)+
                      this.sumarVentasEfectivoQr(this.cierre_caja)-
                      this.cierre_caja.saldo_faltante+this.cierre_caja.saldo_sobrante)*100)/100);
    }

    validarCierreCaja(){
      let res=true;
      if(this.cierre_caja.saldo_faltante!=0 && !this.cierre_caja.saldo_faltante){
        res=res && false;
        this.toastr.error("Debe especificar el saldo faltante!");
      }
      if(this.cierre_caja.saldo_sobrante!=0 && !this.cierre_caja.saldo_sobrante){
        res=res && false;
        this.toastr.error("Debe especificar el saldo sobrante!");
      }
      return res;
    }

    cerrarCaja(){
      if(this.validarCierreCaja()){
        this.blockUI.start();
        this.guardarCierreCaja();
      }
    }

    guardarCierreCaja(){
      this.blockUI.start();
      this.cierresCajaService.cerrarCierreCaja(this.cierre_caja).subscribe((res:any)=>{
        this.blockUI.stop();
        this.imprimirCierreCaja(this.cierre_caja);
        this.modal_registro_cierre_caja.close();
        this.getItems();
        this.toastr.success('Cierre de caja registrado satisfactoriamente!');
      });
    }

    abrirModalConteoDinero(cierre_caja){
      this.conteo_dinero={
        caja:cierre_caja,
        cantidad_200:0,
        cantidad_100:0,
        cantidad_50:0,
        cantidad_20:0,
        cantidad_10:0,
        cantidad_5:0,
        cantidad_2:0,
        cantidad_1:0,
        cantidad_05:0,
        cantidad_02:0,
        cantidad_01:0,
        usuario:this.usuario,
        total_tarjetas:0,
        total_pagos_en_linea:0
      };
      this.modal_conteo_dinero=this.modalService.open(this.modal_conteo_dinero_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.modal_conteo_dinero.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    validarConteoCaja(){
      let res=true;
      if(this.conteo_dinero.cantidad_200!=0 && !this.conteo_dinero.cantidad_200){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de billetes de 200!");
      }
      if(this.conteo_dinero.cantidad_100!=0 && !this.conteo_dinero.cantidad_100){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de billetes de 100!");
      }
      if(this.conteo_dinero.cantidad_50!=0 && !this.conteo_dinero.cantidad_50){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de billetes de 50!");
      }
      if(this.conteo_dinero.cantidad_20!=0 && !this.conteo_dinero.cantidad_20){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de billetes de 20!");
      }
      if(this.conteo_dinero.cantidad_10!=0 && !this.conteo_dinero.cantidad_10){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de billetes de 10!");
      }
      if(this.conteo_dinero.cantidad_5!=0 && !this.conteo_dinero.cantidad_5){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de monedas de 5!");
      }
      if(this.conteo_dinero.cantidad_2!=0 && !this.conteo_dinero.cantidad_2){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de monedas de 2!");
      }
      if(this.conteo_dinero.cantidad_1!=0 && !this.conteo_dinero.cantidad_1){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de monedas de 1!");
      }
      if(this.conteo_dinero.cantidad_05!=0 && !this.conteo_dinero.cantidad_05){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de monedas de 0.5 ctvs!");
      }
      if(this.conteo_dinero.cantidad_02!=0 && !this.conteo_dinero.cantidad_02){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de monedas de 0.2 ctvs!");
      }
      if(this.conteo_dinero.cantidad_01!=0 && !this.conteo_dinero.cantidad_01){
        res=res && false;
        this.toastr.error("Debe especificar la cantidad de monedas de 0.1 ctvs!");
      }
      if(this.conteo_dinero.total_tarjetas!=0 && !this.conteo_dinero.total_tarjetas){
        res=res && false;
        this.toastr.error("Debe especificar el importe de tarjetas!");
      }
      if(this.conteo_dinero.total_pagos_en_linea!=0 && !this.conteo_dinero.total_pagos_en_linea){
        res=res && false;
        this.toastr.error("Debe especificar el importe de pagos en línea!");
      }
      return res;
    }

    guardarConteoCaja(){
      if(this.validarConteoCaja()){
        this.blockUI.start();
        this.cierresCajaService.guardarArqueoCaja(this.conteo_dinero).subscribe((res:any)=>{
          this.modal_conteo_dinero.close();
          this.toastr.success("Conteo de dinero registrado satisfactoriamente!");
          this.getItems();
          this.blockUI.stop();
        });
      }
    }

    sumarBilletes(){
      if(this.conteo_dinero){
        this.conteo_dinero.total_billetes=this.conteo_dinero.cantidad_200*200+
                this.conteo_dinero.cantidad_100*100+
                this.conteo_dinero.cantidad_50*50+
                this.conteo_dinero.cantidad_20*20+
                this.conteo_dinero.cantidad_10*10;
        return this.conteo_dinero.total_billetes;
      }
    }
  
    sumarMonedas(){
      if(this.conteo_dinero){
        this.conteo_dinero.total_monedas=this.conteo_dinero.cantidad_5*5+
                  this.conteo_dinero.cantidad_2*2+
                  this.conteo_dinero.cantidad_1*1+
                  this.conteo_dinero.cantidad_05*0.5+
                  this.conteo_dinero.cantidad_02*0.2+
                  this.conteo_dinero.cantidad_01*0.1;
        return this.conteo_dinero.total_monedas;
      }
    }
  
    sumarTotalConteoCaja(){
      if(this.conteo_dinero){
        this.conteo_dinero.total_efectivo=this.conteo_dinero.total_billetes+this.conteo_dinero.total_monedas;
        return this.conteo_dinero.total_efectivo;
      }
    }

    depositarDinero(cierre_caja){
      this.blockUI.start();
      this.generalService.obtenerClases("MOVING").subscribe((entidad:any)=>{
        this.titulo_dinero="Depositar dinero";
        this.ingreso_caja={
          importe:0,
          id_cierre_caja:cierre_caja.id,
          id_usuario:this.usuario.id,
          transaccion:entidad
        };
        this.abrirAdicionRetiroModal();
        this.blockUI.stop();
      });
    }

    retirarDinero(cierre_caja){
      this.blockUI.start();
      this.generalService.obtenerClases("MOVEGR").subscribe((entidad:any)=>{
        this.titulo_dinero="Retirar dinero";
        this.ingreso_caja={
          importe:0,
          id_cierre_caja:cierre_caja.id,
          id_usuario:this.usuario.id,
          transaccion:entidad
        };
        this.abrirAdicionRetiroModal();
        this.blockUI.stop();
      });
    }

    abrirAdicionRetiroModal(){
      this.modal_adicionretiro_caja=this.modalService.open(this.modal_adicionretiro_caja_ref, {ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
      this.modal_adicionretiro_caja.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    validarTransaccionCaja(){
      let res=true;
      if(this.ingreso_caja.importe<=0 || !this.ingreso_caja.importe){
        res=res && false;
        this.toastr.error("Debe especificar el importe de la transaccion!");
      }
      return res;
    }

    guardarTransaccionCaja(){
      if(this.validarTransaccionCaja()){
        this.blockUI.start();
        this.cierresCajaService.guardarTransaccionCaja(this.ingreso_caja).subscribe((res:any)=>{
          this.blockUI.stop();
          this.modal_adicionretiro_caja.close();
          this.toastr.success("Transaccion de caja registrada satisfactoriamente!");
          this.getItems();
        });
      }
    }

    verTransaccionesCaja(cierre_caja){
      this.blockUI.start();
      this.cierresCajaService.obtenerTransaccionesCaja(cierre_caja).subscribe((transacciones:any[])=>{
        this.blockUI.stop();
        this.transacciones_caja=transacciones;
        this.modal_transacciones_caja=this.modalService.open(this.modal_transacciones_caja_ref, {windowClass:"lista_transacciones",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
        this.modal_transacciones_caja.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      });
    }

    async abrirModalIngresoCaja(cierre_caja){
      if(this.usuario.empresa.usar_facturacion || this.usuario.empresa.usar_facturacion_computarizada_en_linea){
        this.cierre_caja=cierre_caja;
        this.modal_selecion_importe_caja=this.modalService.open(this.modal_selecion_importe_caja_ref, {ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
        this.modal_selecion_importe_caja.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }else{
        this.generarIngresoCaja(cierre_caja,cierre_caja.saldo_caja,false);
      }
    }

    async generarIngresoCaja(cierre_caja,importe,facturadas){
      this.modal_selecion_importe_caja.close();
      this.popup_registro_movimiento=this.modalService.open(RegistroMovimientoCajaComponent, {windowClass:'registro-movimiento-caja',ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'md'});
      this.popup_registro_movimiento.componentInstance.usuario = this.usuario;
      this.popup_registro_movimiento.componentInstance.inhabilitar_sucursal = true;
      this.popup_registro_movimiento.componentInstance.inhabilitar_concepto = true;
      this.popup_registro_movimiento.componentInstance.inhabilitar_tipo_movimiento = true;
      this.popup_registro_movimiento.componentInstance.inhabilitar_fecha = false;
      this.popup_registro_movimiento.componentInstance.inhabilitar_importe = true;
      this.popup_registro_movimiento.componentInstance.inhabilitar_glosa = true;
      this.popup_registro_movimiento.componentInstance.nombre_corto_tipo_movimiento=GlobalVariable.Dictionary.MOV_ING;
      this.popup_registro_movimiento.componentInstance.nombre_corto_concepto=GlobalVariable.Dictionary.CONCEPTO_MOVIMIENTO_CAJA_DEPOSITO_VENTAS;
      this.popup_registro_movimiento.componentInstance.movimiento_caja.sucursal = {id:cierre_caja.id_sucursal,nombre:cierre_caja.sucursal};
      this.popup_registro_movimiento.componentInstance.movimiento_caja.importe=importe;
      cierre_caja.fecha_apertura=new Date(cierre_caja.fecha_apertura);
      cierre_caja.fecha_cierre=new Date(cierre_caja.fecha_cierre);
      this.popup_registro_movimiento.componentInstance.movimiento_caja.glosa="Por cierre de caja en punto de venta "+(facturadas?"facturas":"recibos")+": fecha del "+(cierre_caja.fecha_apertura.getDate()+"/"+(cierre_caja.fecha_apertura.getMonth()+1)+"/"+cierre_caja.fecha_apertura.getFullYear())+" al "+(cierre_caja.fecha_cierre.getDate()+"/"+(cierre_caja.fecha_cierre.getMonth()+1)+"/"+cierre_caja.fecha_cierre.getFullYear());
      this.popup_registro_movimiento.componentInstance.alTerminar.subscribe((res) => {
      if(res){
        if(facturadas){
          cierre_caja.ventas_contado_facturas.id_movimiento_caja=res.movimiento_caja.id;
          cierre_caja.ventas_contado_facturas.depositado=true;
        }else{
          cierre_caja.ventas_contado_recibos.id_movimiento_caja=res.movimiento_caja.id;
          cierre_caja.ventas_contado_recibos.depositado=true;
        }
        this.cierresCajaService.actualizarDestinoCaja(cierre_caja).subscribe((resC:any)=>{
          this.toastr.success(resC.mensaje);
          this.imprimirMovimientoCaja(res.movimiento_caja);
          this.getItems();
        });
      }
        this.popup_registro_movimiento.close();
      });  
    }

    async imprimirMovimientoCaja(movimiento_caja){
      movimiento_caja=await this.cajasService.obtenerMovimientoCaja(movimiento_caja.id).toPromise();
      this.blockUI.start();
      this.pdfService.imprimirMovimientoCaja(movimiento_caja);
      this.blockUI.stop();
    }

    generarExcelIngresosSalidas(){
      this.blockUI.start();
      var data=[];
      var cabecera=["Nº", "Fecha","Transaccion", "Importe", "Descripción","Usuario"];
      data.push(cabecera);
      for (var i = 0; i < this.transacciones_caja.length; i++) {
        var columns = [];
        columns.push(i+1);
        this.transacciones_caja[i].fecha=new Date(this.transacciones_caja[i].fecha);
        let fecha=this.transacciones_caja[i].fecha.getDate()+"/"+(this.transacciones_caja[i].fecha.getMonth()+1)+"/"+this.transacciones_caja[i].fecha.getFullYear();
        columns.push(fecha);
        columns.push(this.transacciones_caja[i].transaccion.nombre);
        columns.push(this.transacciones_caja[i].importe);
        columns.push(this.transacciones_caja[i].descripcion);
        columns.push(this.transacciones_caja[i].usuario.nombre_usuario);
        data.push(columns);
      }

      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("TRANSACCIONES CAJA");
      data.forEach(d => {
      let row = worksheet.addRow(d);
      }
      );

      const dobCol2 = worksheet.getColumn(2);
      dobCol2.width = 20;
      const dobCol3 = worksheet.getColumn(3);
      dobCol3.width = 30;
      const dobCol5 = worksheet.getColumn(5);
      dobCol5.width = 30;

      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "TRANSACCIONES_CAJA.xlsx");
      })
      this.blockUI.stop();
    }

    generarExcelMovimientoProductos(cierre_caja){
      this.blockUI.start();
      this.cierresCajaService.obtenerMovimientosProductosCierreCaja(cierre_caja).subscribe((datos_cierre:any)=>{
        this.blockUI.stop();
        let data=[];
        let cabecera=["Nº","Producto","Cantidad","Precio Unitario","Total"];
        data.push(cabecera);
        for(var i=0;i<datos_cierre.movimiento_productos.length;i++){
          var columns = [];
          columns.push(i+1);
          columns.push(datos_cierre.movimiento_productos[i].nombre);
          columns.push(datos_cierre.movimiento_productos[i].cantidad);
          columns.push(datos_cierre.movimiento_productos[i].precio_unitario);
          columns.push(datos_cierre.movimiento_productos[i].total);
          data.push(columns);
        }
        data.push(["","","","Importe",datos_cierre.resumen_ventas.importe]);
        data.push(["","","","Descuento",datos_cierre.resumen_ventas.descuento]);
        data.push(["","","","Total",datos_cierre.resumen_ventas.total]);

        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("MOVIMIENTOS_PRODUCTOS_CAJA");
        data.forEach(d => {
        let row = worksheet.addRow(d);
        }
        );

        const dobCol2 = worksheet.getColumn(2);
        dobCol2.width = 30;
        const dobCol3 = worksheet.getColumn(3);
        dobCol3.width = 10;
        const dobCol5 = worksheet.getColumn(5);
        dobCol5.width = 10;

        workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "MOVIMIENTOS_PRODUCTOS_CAJA.xlsx");
        })
        this.blockUI.stop();   
      });
    }

}
