import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { EscenariosService } from '../../services/escenarios/escenarios.service';
import { GoogleMap,MapInfoWindow, MapMarker } from '@angular/google-maps';

declare const resaltarPestañaMenu:any;
declare const aplicarWizard:any;

@Component({
  selector: 'app-escenarios',
  templateUrl: './escenarios.component.html',
  styleUrls: ['./escenarios.component.css']
})
export class EscenariosComponent extends BaseComponent implements OnInit {

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  infoContent: string;

  escenarios:any[]=[];
  escenario:any;
  departamentos:any[]=[];
  municipios:any[]=[];
  wizard_edicion_escenario:string="wizard_edicion_escenario";

  modal_escenario_modificacion:NgbModalRef;
  @ViewChild('modal_escenario_modificacion')
  private modal_escenario_modificacion_ref: TemplateRef<any>;

  mapa_escenarios:NgbModalRef;
  @ViewChild('mapa_escenarios')
  private mapa_escenarios_ref: TemplateRef<any>;

  markers:any[];
  center:any= { lat: -17.39380008501286700000, lng: -66.15696068334569000000 };
  conexion_google_map:boolean=true;
  bounds:google.maps.LatLngBounds;
  posicion_marcador:google.maps.LatLng;
  zoom = 17;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    //maxZoom: 15,
    //minZoom: 8,
  }
  @ViewChild('mapa') map:google.maps.Map;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private escenariosService: EscenariosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		this.configuracion_pagina={};
		this.filter={
			id_empresa:this.usuario.id_empresa
		}
    this.column = "updatedAt";
    this.direction = "desc";
		this.getSearch(this.text_search,null);
		//this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_RUTAS);
    this.obtenerDepartamentos();
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.escenariosService.obtenerListaEscenarios(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.escenarios=dato.escenarios;
			this.blockUI.stop();
		});
	}

  async crearNuevoEscenario(){
    let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESCENARIO);
    this.escenario={
      id_empresa: this.usuario.id_empresa,
      codigo:codigo_nuevo,
      importe_partido:0
    }
  
    this.escenario.latitud=-17.39380008501286700000;
    this.escenario.longitud=-66.15696068334569000000;
    try{
      let bounds = new google.maps.LatLngBounds();
      let markers = [];
      let posicion_marcador=new google.maps.LatLng(this.escenario.latitud,this.escenario.longitud);
      bounds.extend(posicion_marcador);
      markers.push({
        position: {
        lat: this.escenario.latitud,
        lng: this.escenario.longitud,
        },
        label: {
        color: 'red',
        },
        options: {
        labelAnchor: "5 55",
        labelClass: 'marker-label',
        draggable: true
        },
      });
  
      this.markers = markers;
      this.center={ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng()};
      }catch(e){
      this.conexion_google_map=false;
      this.toastr.error("No se pudo conectar con google maps!")
    }

    this.abrirRegistroEscenario();
    aplicarWizard(this.wizard_edicion_escenario,this,'guardar');
  }

  abrirRegistroEscenario(){
    this.modal_escenario_modificacion=this.modalService.open(this.modal_escenario_modificacion_ref, {windowClass:"wizard-edicion-escenario",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.modal_escenario_modificacion.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  arrastarPinMapa(event) {
		this.escenario.latitud = event.latLng.lat();
		this.escenario.longitud = event.latLng.lng();
	}

  async establecerDepartamento(){
    if(this.escenario.departamento){
      let municipios:any=await this.generalService.obtenerClasesDatos(this.escenario.departamento.nombre_corto+"M").toPromise();
      this.municipios=municipios;
    }
  }

  obtenerDepartamentos(){
		this.generalService.obtenerClases("DEP").subscribe((entidad:any)=>{
      this.departamentos=entidad?entidad.clases:[];
    });
  }

  validarRegistroEscenario(){
		let res=true;
		if(!this.escenario.codigo){
			this.toastr.error("Debe ingresar un codigo de cliente!");
			res=false;
		}
		if(!this.escenario.nombre){
			this.toastr.error("Debe ingresar la identificacion del cliente!");
			res=false;
		}
		return res;
	}

  async guardar(){
		if(this.validarRegistroEscenario()){
			this.blockUI.start();
			if(this.escenario.id){
				this.escenariosService.actualizarEscenario(this.escenario).subscribe((res:any) => {
					this.blockUI.stop();
          this.getItems();
					this.toastr.success("Escenario registrado satisfactoriamente");
					this.modal_escenario_modificacion.close();
				});
			}else{
				this.escenario.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESCENARIO):this.escenario.codigo;
				this.escenariosService.guardarEscenario(this.escenario).subscribe((res:any) => {
					this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESCENARIO);
					this.blockUI.stop();
					this.toastr.success("Cliente registrado satisfactoriamente");
          this.getItems();
					this.modal_escenario_modificacion.close();
				});
			}
		}else{
			this.blockUI.stop();
		}
	}

  async modificarEscenario(escenario){
    await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESCENARIO);
    this.escenario=escenario;
    await this.establecerDepartamento();
      this.escenario.latitud=this.escenario.latitud?this.escenario.latitud:-17.39380008501286700000;
        this.escenario.longitud=this.escenario.longitud?this.escenario.longitud:-66.15696068334569000000;
        try{
          let bounds = new google.maps.LatLngBounds();
          let markers = [];
          let posicion_marcador=new google.maps.LatLng(this.escenario.latitud,this.escenario.longitud);
          bounds.extend(posicion_marcador);
          markers.push({
            position: {
              lat: this.escenario.latitud,
              lng: this.escenario.longitud,
            },
            label: {
              color: 'red',
            },
            options: {
              labelAnchor: "5 55",
              labelClass: 'marker-label',
              draggable: true
            },
          });
  
          this.markers = markers;
          this.center={ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng()};
        }catch(e){
          this.conexion_google_map=false;
          this.toastr.error("No se pudo conectar con google maps!")
        }
      this.abrirRegistroEscenario();
      aplicarWizard(this.wizard_edicion_escenario,this,'guardar');
    }

    abrirMapaEscenarios(){
      this.mapa_escenarios=this.modalService.open(this.mapa_escenarios_ref, {windowClass:'mapa-escenarios', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
      this.mapa_escenarios.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  
      this.bounds = new google.maps.LatLngBounds();
      let markers = [];
      for (var i = 0; i < this.escenarios.length; i++) {
        if(this.escenarios[i].latitud && this.escenarios[i].longitud){
          this.posicion_marcador=new google.maps.LatLng(this.escenarios[i].latitud,this.escenarios[i].longitud);
          this.bounds.extend(this.posicion_marcador);
          markers.push(this.crearMarcador(i,null,this.escenarios[i].latitud,this.escenarios[i].longitud,""/*this.clientes[i].identificacion*/));
        }
      }
      this.markers = markers;
      this.center={ lat: this.bounds.getCenter().lat(), lng: this.bounds.getCenter().lng()};
    }

    crearMarcador(i, idKey,lat,lng,nombre) {
      if (idKey == null) {
        idKey = "id";
      }
  
      var latitude = lat;
      var longitude = lng;
      var ret = {
        position: {
          lat: latitude,
          lng: longitude,
        },
        label: {
          color: 'blue',
  
        },
        title:nombre,
        options: {
          icon:GlobalVariable.SERVER_URL+'img/marker-escenario.png',
          labelContent : nombre,
          labelAnchor: "5 55",
          labelClass: 'marker-label',
        },
      };
      ret[idKey] = i;
      return ret;
    }

    public clickMarcador(marker: MapMarker) {
      //this.infoContent = content;
      this.infoWindow.open(marker);
    }

    imprimirMapaEscenarios() {
      var printContents = document.getElementById("map-view").innerHTML;
      var popupWin = window.open('', '_blank', 'width=300,height=300');
      popupWin.document.open();
      popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
      popupWin.document.close();
    }

}
