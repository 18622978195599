<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Estudiante</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardar()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarRegistroEstudiante()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-md-4 col-12">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <label>Código</label>
                </div>
                <div class="col-sm-12 col-md-6">
                    <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="estudiante.codigo" placeholder="Código" class="form-control" />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <label>Apellido Paterno</label>
                </div>
                <div class="col-sm-12 col-md-9">
                    <input (change)="establecerRazonSocial()" name="apellido_paterno" required type="text" id="apellido_paterno" [(ngModel)]="estudiante.persona.apellido_paterno" placeholder="Apellido Paterno" class="form-control" />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <label>Apellido Materno</label>
                </div>
                <div class="col-sm-12 col-md-9">
                    <input (change)="establecerRazonSocial()" type="text" id="apellido_materno" name="apellido_materno" [(ngModel)]="estudiante.persona.apellido_materno" placeholder="Apellido Materno" class="form-control"  />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <label>Nombres</label>
                </div>
                <div class="col-sm-12 col-md-9">
                    <input (change)="establecerRazonSocial()" required type="text" id="nombre" name="nombre" [(ngModel)]="estudiante.persona.nombres" placeholder="Nombres" [ngClass]="{'brc-danger-m1':(!estudiante.persona.nombres)}" class="form-control"  />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <label>Genero</label>
                </div>
                <div class="col-sm-12 col-md-9">
                    <select [compareWith]="compararObjectos" class="form-control" required name="genero" [(ngModel)]="estudiante.persona.genero">
                        <option *ngIf="!estudiante.id" [ngValue]="null" selected>Ninguno</option>
                        <option [ngValue]="genero" *ngFor="let genero of generos">{{genero.nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <label>Fecha de Nacimiento</label>
                </div>
                <div class="col-sm-12 col-md-9">
                    <div class="input-group">
                        <input id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="estudiante.persona.fecha_nacimiento_texto" ngbDatepicker #d="ngbDatepicker" [minDate]="fecha_nacimiento_minimo">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <label>Telefono Móvil</label>
                </div>
                <div class="col-sm-12 col-md-9">
                    <input type="text" id="telefono_movil" name="telefono_movil" [(ngModel)]="estudiante.persona.telefono_movil" placeholder="Teléfono Móvil" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <label>Categoría</label>
                </div>
                <div class="col-sm-12 col-md-9">
                    <select [compareWith]="compararObjectos" class="form-control" required name="categoria_1" [(ngModel)]="estudiante.categoria_1">
                        <option [ngValue]="categoria" *ngFor="let categoria of tipo_categoria.clases ">{{categoria.nombre}}</option>
                    </select>
                </div>
            </div>
            <ng-container *ngIf="usuario.empresa.usar_sucursales">
                <div class="row">
                    <div class="col-sm-12 col-md-3">
                        <label>Sucursal</label>
                    </div>
                    <div class="col-sm-12 col-md-9">
                        <select [compareWith]="compararObjectos" name="sucursal" class="form-control" [(ngModel)]="estudiante.sucursal">
                            <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                        </select>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-8 col-12">
            <div class="form-row">
                <div class="col-12 col-form-label text-sm-center text-grey">
                    <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light">
                            <i class="fa fa-table mr-2px"></i> Inscripción 
                            <a title="Registro Inscripcion" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearNuevaInscripcionInstituto()" *ngIf="usuario.empresa.usar_estudiantes_instituto">
                                <i class="ace-icon fa fa-plus bigger-130"></i>
                            </a>
                            <a title="Registro Inscripcion" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearNuevaInscripcionColegio()" *ngIf="usuario.empresa.usar_estudiantes_colegio">
                                <i class="ace-icon fa fa-plus bigger-130"></i>
                            </a>
                        </h5>
                        </div>
                        <div class="card-body p-0 bg-white" *ngIf="usuario.empresa.usar_estudiantes_colegio">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th><label>Nº</label></th>
                                        <th><label>Gestión</label></th>
                                        <th><label>Curso</label></th>
                                        <th><label>Estado</label></th>
                                        <th style="text-align: center; width: 140px; " data-field="tools">
                                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                            <div class="fht-cell"></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let inscripcion of estudiante.inscripciones_colegio;let i=index;">
                                        <tr *ngIf="!inscripcion.eliminado">
                                            <td>
                                                {{(i+1)}}
                                            </td>
                                            <td>
                                                {{inscripcion.gestion.nombre}}
                                            </td>
                                            <td>
                                                {{inscripcion.curso.nombre}}
                                            </td>
                                            <td *ngIf="inscripcion.activo"><span class="badge badge-md badge-success">ACTIVO</span></td>
                                            <td *ngIf="!inscripcion.activo"><span class="badge badge-md badge-danger">ANULADO</span></td>
                                            <td>
                                                <a *ngIf="inscripcion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarInscripcionColegio(inscripcion)">
                                                    <i class="ace-icon fa fa-pencil-alt bigger-130"></i>
                                                </a>
                                                <a title="Migrar Datos" *ngIf="!inscripcion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirModalMigracionInscripcion(inscripcion)">
                                                    <i class="ace-icon fas fa-exchange-alt bigger-130"></i>
                                                </a>
                                                <a *ngIf="inscripcion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(inscripcion)">
                                                    <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                </a>
                                                <a *ngIf="!inscripcion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionInscripcion(inscripcion)">
                                                    <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #registro_inscripcion_colegio_modal let-modal>
    <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Inscripción</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="agregarInscripcionColegio()" class="btn btn-primary"  >
              <i class="fa fa-plus text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.close('')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
        <div class="col-4">
            <label>Gestión</label>
        </div>
        <div class="col-8">
            <select [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="inscripcion.gestion">
                
                <option [ngValue]="gestion" *ngFor="let gestion of gestiones | filterBy:{habilitado:true}">{{gestion.nombre}}</option>
            </select>
        </div>
        <div class="col-4">
            <label>Nivel</label>
        </div>
        <div class="col-8">
            <select [compareWith]="compararObjectos" name="nivel" class="form-control" [(ngModel)]="inscripcion.nivel" (change)="obtenerCursosPorNivel(inscripcion.nivel.id)">
                <option [ngValue]="null" [selected]="true">Seleccionar Nivel</option>
                <option [ngValue]="nivel" *ngFor="let nivel of filter.niveles_escolares">{{nivel.nombre}}</option>
            </select>
        </div>
        <div class="col-4">
            <label>Curso</label>
        </div>
        <div class="col-8">
            <select [compareWith]="compararObjectos" name="curso" class="form-control" [(ngModel)]="inscripcion.curso">
                <option [ngValue]="curso" *ngFor="let curso of filter.cursos">{{curso.nombre}}</option>
            </select>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-6">
            <label>¿Consolidado?</label>
        </div>
        <div class="col-6">
            <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="inscripcion.consolidado"/>
        </div>
        <div class="col-4">
            <label>Observación</label>
        </div>
        <div class="col-8">
            <input type="text" name="observacion" [(ngModel)]="inscripcion.observacion" placeholder="Observación" class="form-control"/>
        </div>
      </div>
    </div>
</ng-template>