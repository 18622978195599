import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../../base/services/general/general.service';
import { InventariosService } from '../../../services/inventarios/inventarios.service';
import { PdfService } from '../../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../../base/services/usuario/usuario.service';
import { FechaVencimientoModificacionComponent } from '../../fecha-vencimiento-modificacion/fecha-vencimiento-modificacion.component';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-vencimiento-producto',
  templateUrl: './vencimiento-producto.component.html',
  styleUrls: ['./vencimiento-producto.component.css']
})
export class VencimientoProductoComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;

  usuario:any;
  vencimiento_productos:any[]=[];
  vencimiento_productos_filtrados:any[]=[];
  filtro_inventario: any ={};

  constructor(
    public persistenciaService:PersistenciaService,
    public inventariosService:InventariosService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public pdfService:PdfService,
    public usuarioService:UsuarioService
  ) {
    super(persistenciaService,modalService,generalService,usuarioService)
   }

  ngOnInit(): void {
    this.obtenerListaVencimientoProductos();
  }

  obtenerListaVencimientoProductos(){
    this.blockUI.start();
    this.inventariosService.obtenerListaVencimientoProductos(this.usuario.id_empresa).subscribe((datos:any)=>{
      this.vencimiento_productos = datos;
      this.vencimiento_productos_filtrados = datos;
      this.blockUI.stop();
    });
  }

  filtrarProductos(texto: string) {
		let texto_minus = texto.toLowerCase();
		if(texto === '' ) {
			this.vencimiento_productos_filtrados=this.vencimiento_productos;
		} else {
		  this.vencimiento_productos_filtrados = this.vencimiento_productos.filter((inventario) => ((inventario.producto.nombre).toLowerCase().includes(texto_minus)));
		}
   }




  imprimirListaVencimientoProductos () {
    this.blockUI.start();
    let vencimientosProductos=this.vencimiento_productos_filtrados;
    var doc = new PDFDocument({ size: 'letter', margin: 10 });
    var stream = doc.pipe(blobStream());
    // draw some text
    var saldoFisico = 0;
    var saldoValuado = 0;

    var y = 140, itemsPorPagina = 30, items = 0, pagina = 1, totalPaginas = Math.ceil(vencimientosProductos.length / itemsPorPagina);
    this.dibujarCabeceraPDFVencimientoProductos(doc, 1, totalPaginas);
    for (var i = 0; i < vencimientosProductos.length && items <= itemsPorPagina; i++) {

      doc.rect(30, y - 10, 555, 20).stroke();
      doc.font('Helvetica', 7);
      if (vencimientosProductos[i].producto.codigo == null) {
        doc.text("", 40, y);
      } else {
        doc.text(vencimientosProductos[i].producto.codigo, 40, y);
      }
      //
      doc.text(vencimientosProductos[i].producto.nombre, 120, y - 6, { width: 140 });
      vencimientosProductos[i].fecha_vencimiento = new Date(vencimientosProductos[i].fecha_vencimiento);
      doc.text(vencimientosProductos[i].producto.unidad_medida, 260, y, { width: 50 });
      doc.text(vencimientosProductos[i].almacen.sucursal.nombre, 305, y - 6, { width: 60 });
      doc.text(vencimientosProductos[i].almacen.nombre, 375, y - 6, { width: 60 });
      doc.text(vencimientosProductos[i].fecha_vencimiento.getDate() + "/" + (vencimientosProductos[i].fecha_vencimiento.getMonth() + 1) + "/" + vencimientosProductos[i].fecha_vencimiento.getFullYear(), 445, y, { width: 50 });
      doc.text(vencimientosProductos[i].lote, 490, y, { width: 50 });
      doc.text(vencimientosProductos[i].cantidad, 540, y, { width: 50 });
      doc.text(vencimientosProductos[i].producto.descuento + "%", 560, y, { width: 50 });
      y = y + 20;
      items++;

      if (items == itemsPorPagina) {
        doc.addPage({ margin: 0, bufferPages: true });
        y = 140;
        items = 0;
        pagina = pagina + 1;

        this.dibujarCabeceraPDFVencimientoProductos(doc, pagina, totalPaginas);

        doc.font('Helvetica', 7);
      }
    }
    doc.end();
    stream.on('finish', function () {
      var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL, '_blank', 'location=no');
    });
    this.blockUI.stop();

  }

  dibujarCabeceraPDFVencimientoProductos(doc, pagina, totalPaginas) {
    var fechaActual = new Date();
    let min:any = fechaActual.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    doc.font('Helvetica-Bold', 10);
    doc.text(this.usuario.empresa.nombre, 0, 35, { align: "center" });
    doc.font('Helvetica', 8);
    doc.text("COCHABAMBA - BOLIVIA", 0, 50, { align: "center" });
    doc.font('Helvetica-Bold', 10);
    doc.text("LISTA DE VENCIMIENTOS PRODUCTOS", 0, 65, { align: "center" });
    doc.rect(210, 75, 180, 0);
    doc.font('Helvetica-Bold', 7);
    doc.text("PÁGINA " + pagina + " DE " + totalPaginas, 0, 740, { align: "center" });
    doc.rect(30, 100, 555, 30).stroke();
    doc.font('Helvetica-Bold', 8);
    doc.text("Codigo", 45, 110);
    doc.text("Productos", 120, 110, { width: 50 });
    doc.text("Unid. de Medida", 260, 110, { width: 40 });
    doc.text("Sucursal", 310, 110, { width: 60 });
    doc.text("Almacen", 370, 110, { width: 60 });
    doc.text("Venc.", 440, 110, { width: 60 });
    doc.text("Lote", 490, 110, { width: 50 });
    doc.text("Cant.", 530, 110, { width: 50 });
    doc.text("Desc.", 550, 110, { width: 50 });
    doc.font('Helvetica', 7);
    doc.text("usuario : " + this.usuario.nombre_usuario, 475, 740);
    doc.text("fecha : " + fechaActual.getDate() + "/" + (fechaActual.getMonth() + 1) + "/" + fechaActual.getFullYear() + "  " + fechaActual.getHours() + ":" + min, 475, 750);
  }

  cerrarComponente(){
    this.alTerminar.emit();
   }

  abrirPopupActualizacionFechaLote(producto,inventario) {
      this.configuracion_aplicacion_modal=this.modalService.open(FechaVencimientoModificacionComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
      this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
      this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
      this.configuracion_aplicacion_modal.componentInstance.producto=producto;
      this.configuracion_aplicacion_modal.componentInstance.inventario=inventario;
      this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          this.obtenerListaVencimientoProductos();
        }
        this.configuracion_aplicacion_modal.close();
      });
  }

}
