<div class="page-content container container-plus">
    <div class="page-header border-0 mb-3">
      <h1 class="page-title text-primary-d2 text-150">
        Laboratorios
        <small class="page-info text-secondary-d4">
          <i class="fa fa-angle-double-right text-80 opacity-1"></i>
          Clínicos
        </small>
      </h1>
    </div>

    <div class="bgc-primary-d1 text-white px-3 py-25">
        <div class="row">
          <div class="col-md-6 col-12">
            <span class="text-90">Lista de</span>
            Laboratorios
          </div>
          <div class="col-md-6 col-12">
            <div class="columns columns-right btn-group float-right">
              <button title="Nuevo" (click)="crearRegistroLaboratorio()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
              <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
            </div>
          </div>
        </div>
      </div>
  
      <div class="bootstrap-table bootstrap4">
        <div class="fixed-table-toolbar">
          <div class="row">
            <div class="col-9 col-md-9">
              <div class="row">
                <div class="col-12 col-md-12">
                  <app-paginator-header [paginator]="this"></app-paginator-header>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="row">
                    <div class="col-md-4 col-sm-12">
                    <div class="input-group">
                        <label for="feini">Desde </label>&nbsp;
                        <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                        </div>
                    </div>  
                    </div>
                    <div class="col-md-4 col-sm-12">
                    <div class="input-group">
                        <label for="fefin">Hasta </label>&nbsp;
                        <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>  
                </div>
                    <div class="col-md-1 col-sm-12" *ngIf="usuario.empresa.usar_sucursales"><label>Sucursal </label></div>
                    <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_sucursales">
                      <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                          <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                      </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label>Usuario </label>&nbsp;
                        <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                            <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <label>Paciente </label>
                      <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.paciente">
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <label>Observación </label>
                      <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.observacion">
                    </div>
                  </div>
                </div>
              </div>
                </div>
            <div class="col-3 col-md-3">
              <div class="row">
                <div class="col-12">
                  <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="d-block h-4 fa fa fa-search text-150"></i>
                  </button>
                </div>
              </div>
            </div>
              </div>
        </div>
  
        <div class="fixed-table-container" style="padding-bottom: 0px;">
          <div class="fixed-table-header">
            <table></table>
          </div>
          <div class="fixed-table-body table-responsive">
            <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
              <span class="loading-wrap">
                <span class="animation-wrap">
                  <span class="animation-dot"></span>
                </span>
              </span>
            </div>
            <table *ngIf="laboratorios.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
              <thead class="bgc-white text-grey text-uppercase text-80">
                  <tr>
                      <th class="detail" rowspan="1">
                          <div class="th-inner ">Nº</div>
                      </th>
                      <th>Código</th>
                      <th>Paciente</th>
                      <th>Num. Doc.</th>
                      <th>Fecha Hora</th>
                      <th>Observación</th>
                      <th>Validez</th>
                      <th style="text-align: center; width: 140px; " data-field="tools">
                        <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                        <div class="fht-cell"></div>
                      </th>
                  </tr>
              </thead>
  
              <tbody>
                  <tr *ngFor="let laboratorio of laboratorios; let i=index;">
                      <td>{{i+1}}</td>
                      <td>{{laboratorio.codigo}}</td>
                      <td>{{laboratorio.paciente.cliente.persona.nombre_completo}}</td>
                      <td>{{laboratorio.correlativo}}</td>
                      <td>{{laboratorio.fecha_hora | date:"HH:mm - dd/MM/yyyy"}}</td>
                      <td>{{laboratorio.observacion}}</td>
                      <td *ngIf="laboratorio.activo"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                      <td *ngIf="!laboratorio.activo"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                      <td style="text-align: center; width: 140px; ">
                          <div class="d-none d-lg-inline text-muted">
                              <a *ngIf="laboratorio.activo" (click)="modificarLaboratorio(laboratorio)" title="Modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" >
                                  <i class="fas fa-edit"></i>
                              </a>
                              <a *ngIf="laboratorio.activo && !laboratorio.id_venta" (click)="generarVentaLaboratorio(laboratorio)" title="Cobrar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" >
                                <i class="fas fa-money-bill-alt"></i>
                              </a>
                              <a (click)="generarPdfSolicitudLaboratorio(laboratorio)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)">
                                <i class="fa fa-print bigger-260 red2"></i>
                              </a>
                              <a (click)="verLaboratorio(laboratorio)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                                  <i class="ace-icon fa fa-search-plus bigger-130"></i>
                              </a>
                              <a (click)="abrirPopupConfirmacionEliminacion(laboratorio)" *ngIf="laboratorio.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)">
                                <i class="fa fa-trash-alt"></i>
                              </a>
                          </div>
                          <div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                            <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                            <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                              <div class="dropdown-inner">
                                <a *ngIf="laboratorio.activo" (click)="modificarLaboratorio(laboratorio)" title="Modificar" class="dropdown-item" href="javascript:void(0)" >
                                  <i class="fas fa-edit text-primary mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Modificar
                              </a>
                              <a *ngIf="laboratorio.activo && !laboratorio.id_venta" (click)="generarVentaLaboratorio(laboratorio)" title="Cobrar" class="dropdown-item" href="javascript:void(0)" >
                                <i class="fas fa-money-bill-alt text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Cobrar
                              </a>
                              <a (click)="generarPdfSolicitudLaboratorio(laboratorio)" class="dropdown-item" href="javascript:void(0)">
                                <i class="fa fa-print bigger-260 text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a (click)="verLaboratorio(laboratorio)" class="dropdown-item" href="javascript:void(0)">
                                  <i class="ace-icon fa fa-search-plus bigger-130 text-primary mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver
                              </a>
                              <a (click)="abrirPopupConfirmacionEliminacion(laboratorio)" *ngIf="laboratorio.activo" class="dropdown-item" href="javascript:void(0)">
                                <i class="fa fa-trash-alt text-danger mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Eliminar
                              </a>
                              </div>
                            </div>
                          </div>
  
                          <!--<div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                            <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                            <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                              <div class="dropdown-inner">
                                <a *ngIf="usuario.empresa.usar_consumo_de_agua" class="dropdown-item" href="javascript:void(0)" (click)="imprimirVentaConsumoAgua(venta)">
                                  <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                                </a>
                                <a *ngIf="!usuario.empresa.usar_consumo_de_agua" class="dropdown-item" href="javascript:void(0)" (click)="imprimirVenta(venta)">
                                  <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                                </a>
                                <a class="dropdown-item" href="javascript:void(0)" (click)="verVenta(venta)">
                                    <i class="fa fa-search-plus text-blue-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver
                                </a>
                                <a *ngIf="venta.activa" class="dropdown-item" [disabled]="!aplicacion.puede_eliminar" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(venta)">
                                  <i class="fa fa-trash text-danger-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Anular
                                </a>
                              </div>
                            </div>
                          </div>-->
                      </td>
                  </tr>
                  <tr *ngIf="laboratorios.length>0">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>											
                  </tr>
              </tbody>
            </table>
          </div>
          <div class="fixed-table-footer">
            <table><thead><tr></tr></thead></table>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
</div>