import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnapquickRoutingModule } from './snapquick-routing.module';
import { FormsModule } from '@angular/forms';
import { BaseModule } from '../base/base.module';
import { ProductosComponent } from './components/productos/productos.component';
import { CombosComponent } from './components/combos/combos.component';
import { VentasComponent } from './components/ventas/ventas.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { CotizacionesComponent } from './components/cotizaciones/cotizaciones.component';
import { CotizacionComponent } from './components/cotizacion/cotizacion.component';
import { PagoVentaComponent } from './components/pago-venta/pago-venta.component';
import { ListaPagosVentasComponent } from './components/lista-pagos-ventas/lista-pagos-ventas.component';
import { VistaVentaComponent } from './components/vista-venta/vista-venta.component';
import { RegistroProductoComponent } from './components/registro-producto/registro-producto.component';
import { InventarioComponent } from './components/inventario/inventario.component';
import { MovimientoInventarioComponent } from './components/movimiento-inventario/movimiento-inventario.component';
import { RegistroMovimientoInventarioComponent } from './components/registro-movimiento-inventario/registro-movimiento-inventario.component';
import { VencimientoCreditoComponent } from './components/notificaciones/vencimiento-credito/vencimiento-credito.component';
import { VencimientoDeudaComponent } from './components/notificaciones/vencimiento-deuda/vencimiento-deuda.component';
import { PagoCompraComponent } from './components/pago-compra/pago-compra.component';
import { InventarioMinimoComponent } from './components/notificaciones/inventario-minimo/inventario-minimo.component';
import { VencimientoProductoComponent } from './components/notificaciones/vencimiento-producto/vencimiento-producto.component';
import { PedidosNotificacionComponent } from './components/notificaciones/pedidos-notificacion/pedidos-notificacion.component';
import { ComprasComponent } from './components/compras/compras.component';
import { CompraComponent } from './components/compra/compra.component';
import { VistaCompraComponent } from './components/vista-compra/vista-compra.component';
import { PedidosComponent } from './components/pedidos/pedidos.component';
import { VistaPedidoComponent } from './components/vista-pedido/vista-pedido.component';
import { PedidoPanelComponent } from './components/pedido-panel/pedido-panel.component';
import { ReporteVentasProductosComponent } from './components/reporte-ventas-productos/reporte-ventas-productos.component';
import { ListaPagosComprasComponent } from './components/lista-pagos-compras/lista-pagos-compras.component';
import { MovimientoProductoComponent } from './components/movimiento-producto/movimiento-producto.component';
import { RegistroDespachoComponent } from './components/registro-despacho/registro-despacho.component';
import { CertificacionSinComponent } from './components/certificacion-sin/certificacion-sin.component';
import { ProveedoresComponent } from './components/proveedores/proveedores.component';
import { RegistroProveedorComponent } from './components/registro-proveedor/registro-proveedor.component';
import { CuentasPorCobrarComponent } from './components/cuentas-por-cobrar/cuentas-por-cobrar.component';
import { CuentasPorPagarComponent } from './components/cuentas-por-pagar/cuentas-por-pagar.component';
import { ClientesHistoricoVentasComponent } from './components/clientes-historico-ventas/clientes-historico-ventas.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { InventarioExternoComponent } from './components/inventario-externo/inventario-externo.component';
import { LibroComprasComponent } from './components/libro-compras/libro-compras.component';
import { LibroVentasComponent } from './components/libro-ventas/libro-ventas.component';
import { PantallaDespachoComponent } from './components/pantalla-despacho/pantalla-despacho.component';
import { RegistroDosificacionComponent } from './components/registro-dosificacion/registro-dosificacion.component';
import { DosificacionesComponent } from './components/dosificaciones/dosificaciones.component';
import { BlockUIModule } from 'ng-block-ui';
import { NgbDate, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorTokenService } from '../base/services/interceptor-token/interceptor-token.service';
import { NgbDateCustomParserFormatter } from '../utils/dateformat';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { GoogleMapsModule } from '@angular/google-maps';
import { RecaptchaModule } from 'ng-recaptcha';
import { GlobalVariable } from '../global';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { OrderModule } from 'ngx-order-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { WebcamModule } from 'ngx-webcam';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { PantallasComponent } from './components/pantallas/pantallas.component';
import { ProveedoresHistoricoComprasComponent } from './components/proveedores-historico-compras/proveedores-historico-compras.component';
import { CierresCajaComponent } from './components/cierres-caja/cierres-caja.component';
import { InventarioConversionComponent } from './components/inventario-conversion/inventario-conversion.component';
import { ReportePagosInstitutoComponent } from './components/reporte-pagos-instituto/reporte-pagos-instituto.component';
import { WhatsappConfiguracionComponent } from './components/whatsapp-configuracion/whatsapp-configuracion.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ConversacionWhatsappComponent } from './components/conversacion-whatsapp/conversacion-whatsapp.component';
import { PedidoComponent } from './components/pedido/pedido.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ImpresionCodigosBarrasComponent } from './components/impresion-codigos-barras/impresion-codigos-barras.component';
import { ConfiguracionAplicacionReportevpComponent } from '../base/components/configuracion-aplicacion-reportevp/configuracion-aplicacion-reportevp.component';
import { ReporteVentasComponent } from './components/reporte-ventas/reporte-ventas.component';
import { PagosServiciosBasicosComponent } from './components/pagos-servicios-basicos/pagos-servicios-basicos.component';
import { ListaDevolucionesVentasComponent } from './components/lista-devoluciones-ventas/lista-devoluciones-ventas.component';
import { CalendarioPedidosComponent } from './components/calendario-pedidos/calendario-pedidos.component';
import { SinListaEventosSignificativosComponent } from './components/sin-lista-eventos-significativos/sin-lista-eventos-significativos.component';
import { ReporteComprasProductosComponent } from './components/reporte-compras-productos/reporte-compras-productos.component';
import { CajaComponent } from './components/caja/caja.component';
import { MovimientosCajaComponent } from './components/movimientos-caja/movimientos-caja.component';
import { RegistroMovimientoCajaComponent } from './components/registro-movimiento-caja/registro-movimiento-caja.component';
import { ListaChequesComponent } from './components/lista-cheques/lista-cheques.component';
import { CategoriaTiendaComponent } from './components/categoria-tienda/categoria-tienda.component';
import { NgxMaskModule } from 'ngx-mask';
import { FechaVencimientoModificacionComponent } from './components/fecha-vencimiento-modificacion/fecha-vencimiento-modificacion.component';

const config: SocketIoConfig = { url: GlobalVariable.SERVER_URL, options: {} };

@NgModule({
  declarations: [
    ProductosComponent,
    CombosComponent,
    VentasComponent,
    ReportesComponent,
    CotizacionesComponent,
    CotizacionComponent,
    PagoVentaComponent,
    ListaPagosVentasComponent,
    VistaVentaComponent,
    RegistroProductoComponent,
    InventarioComponent,
    MovimientoInventarioComponent,
    RegistroMovimientoInventarioComponent,
    VencimientoCreditoComponent,
    VencimientoDeudaComponent,
    PagoCompraComponent,
    InventarioMinimoComponent,
    VencimientoProductoComponent,
    PedidosNotificacionComponent,
    ComprasComponent,
    CompraComponent,
    VistaCompraComponent,
    PedidosComponent,
    VistaPedidoComponent,
    PedidoPanelComponent,
    ReporteVentasProductosComponent,
    ListaPagosComprasComponent,
    MovimientoProductoComponent,
    RegistroDespachoComponent,
    CertificacionSinComponent,
    ProveedoresComponent,
    RegistroProveedorComponent,
    CuentasPorCobrarComponent,
    CuentasPorPagarComponent,
    DosificacionesComponent,
    RegistroDosificacionComponent,
    PantallaDespachoComponent,
    LibroVentasComponent,
    LibroComprasComponent,
    InventarioExternoComponent,
    ClientesComponent,
    ClientesHistoricoVentasComponent,
    PantallasComponent,
    ProveedoresHistoricoComprasComponent,
    CierresCajaComponent,
    InventarioConversionComponent,
    ReportePagosInstitutoComponent,
    WhatsappConfiguracionComponent,
    ConversacionWhatsappComponent,
    PedidoComponent,
    ImpresionCodigosBarrasComponent,
    ConfiguracionAplicacionReportevpComponent,
    ReporteVentasComponent,
    PagosServiciosBasicosComponent,
    ListaDevolucionesVentasComponent,
    CalendarioPedidosComponent,
    SinListaEventosSignificativosComponent,
    ReporteComprasProductosComponent,
    CajaComponent,
    MovimientosCajaComponent,
    RegistroMovimientoCajaComponent,
    ListaChequesComponent,
    CategoriaTiendaComponent,
    FechaVencimientoModificacionComponent
  ],
  imports: [
    SnapquickRoutingModule,
    CommonModule,
    FormsModule,
    BaseModule,
    BrowserModule,
    BlockUIModule.forRoot({
      message: 'Procesando...'
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    OrderModule,
    NgbModule,
    FilterPipeModule,
    SocketIoModule.forRoot(config),
    NgxBootstrapMultiselectModule,
    GoogleMapsModule,
    RecaptchaModule,
    PdfViewerModule,
    WebcamModule,
    YouTubePlayerModule,
    QRCodeModule,
    NgxBarcodeModule,
    NgxMaskModule.forRoot()
  ],
  providers: [{provide:NgbDateParserFormatter,useClass:NgbDateCustomParserFormatter},
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorTokenService, multi: true}],
  exports:[
    ProductosComponent,
    CombosComponent,
    VentasComponent,
    ReportesComponent,
    CotizacionesComponent,
    CotizacionComponent,
    PagoVentaComponent,
    ListaPagosVentasComponent,
    VistaVentaComponent,
    RegistroProductoComponent,
    InventarioComponent,
    MovimientoInventarioComponent,
    RegistroMovimientoInventarioComponent,
    VencimientoCreditoComponent,
    VencimientoDeudaComponent,
    PagoCompraComponent,
    InventarioMinimoComponent,
    VencimientoProductoComponent,
    PedidosNotificacionComponent,
    ComprasComponent,
    CompraComponent,
    VistaCompraComponent,
    PedidosComponent,
    VistaPedidoComponent,
    PedidoPanelComponent,
    ReporteVentasProductosComponent,
    ListaPagosComprasComponent,
    MovimientoProductoComponent,
    RegistroDespachoComponent,
    CertificacionSinComponent,
    ProveedoresComponent,
    RegistroProveedorComponent,
    CuentasPorCobrarComponent,
    CuentasPorPagarComponent,
    DosificacionesComponent,
    RegistroDosificacionComponent,
    PantallaDespachoComponent,
    LibroVentasComponent,
    LibroComprasComponent,
    InventarioExternoComponent,
    ClientesComponent,
    ClientesHistoricoVentasComponent,
    PantallasComponent
  ]
})
export class SnapquickModule { }
