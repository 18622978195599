<div class="modal-header">
    <span class="text-blue text-125">Registro Estudiante</span>

    <div class="card-toolbar ml-auto no-border pr-2 d-none">
    <label>
        <span class="align-middle d-block d-sm-inline">Validation:</span>
        <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
    </label>
    </div>
    <div class="card-toolbar pl-3">
    <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
        <i class="fa fa-chevron-left"></i>
    </button>
    <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
        <i class="fa fa-chevron-right"></i>
    </button>
    <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
        <i class="fa fa-arrow-right"></i>
    </button>
    <button (click)="cerrarRegistroEstudiante()" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
        <i class="fas fa-times-circle"></i>
        </button>
    </div>
</div>
<div class="modal-body px-2 py-2 mb-4">
    <div id="{{wizard_edicion_estudiante}}" class="d-none">
        <ul class="mx-auto">
          <li class="wizard-progressbar"></li>
          <li>
            <a href="#step-1">
              <span class="step-title">
                  1
              </span>

              <span class="step-title-done">
                  <i class="fa fa-check text-success-m1"></i>
              </span>
            </a>
            <span class="step-description">
                    Datos
            </span>
          </li>
          <li>
            <a href="#step-2">
              <span class="step-title">
                  2
              </span>

              <span class="step-title-done">
                  <i class="fa fa-check text-success-m1"></i>
              </span>
            </a>
            <span class="step-description">
                    Adicionales
            </span>
          </li>
          <li *ngIf="(usuario.empresa.usar_estudiantes_colegio || usuario.empresa.usar_estudiantes_instituto) && !es_rol_profesor">
            <a href="#step-3">
              <span class="step-title">
                  3
              </span>

              <span class="step-title-done">
                  <i class="fa fa-check text-success-m1"></i>
              </span>
            </a>
            <span class="step-description">
                    Inscripción
            </span>
          </li>
          <li *ngIf="usuario.empresa.usar_estudiantes_colegio">
            <a href="#step-4">
              <span class="step-title">
                  4
              </span>

              <span class="step-title-done">
                  <i class="fa fa-check text-success-m1"></i>
              </span>
            </a>
            <span class="step-description">
                    Tutores
            </span>
          </li>
          <li *ngIf="usuario.empresa.usar_cuentas_estudiantes && (aplicacion_usuarios.puede_crear || aplicacion_usuarios.puede_modificar)">
            <a href="#step-5">
              <span class="step-title">
                  5
              </span>

              <span class="step-title-done">
                  <i class="fa fa-check text-success-m1"></i>
              </span>
            </a>
            <span class="step-description">
                    Cuenta de Usuario
            </span>
          </li>
          <li *ngIf="usuario.empresa.usar_hoja_rude">
            <a href="#step-6">
              <span class="step-title">
                  6
              </span>

              <span class="step-title-done">
                  <i class="fa fa-check text-success-m1"></i>
              </span>
            </a>
            <span class="step-description">
                    RUDE
            </span>
          </li>
        </ul>

        <div class="px-2 py-2 mb-4">
          <div id="step-1" class="">
            <div class="  form-row">
                <div class="col-2 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Código
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input [disabled]="codigo_automatico" type="text" id="codigo" name="codigo" [(ngModel)]="estudiante.codigo" placeholder="Código" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Apellido Paterno
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input (change)="establecerRazonSocial()" type="text" id="apellido_paterno" name="apellido_paterno" [(ngModel)]="estudiante.persona.apellido_paterno" placeholder="Apellido Paterno" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-form-label text-center" [ngClass]="{'col-2':usuario.empresa.usar_sucursales,'col-3':!usuario.empresa.usar_sucursales}">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Apellido Materno
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input (change)="establecerRazonSocial()" type="text" id="apellido_materno" name="apellido_materno" [(ngModel)]="estudiante.persona.apellido_materno" placeholder="Apellido Materno" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-form-label text-center" [ngClass]="{'col-3':usuario.empresa.usar_sucursales,'col-4':!usuario.empresa.usar_sucursales}">
                    <div class="row">
                        <div [ngClass]="{'text-danger-m1':(!estudiante.persona.nombres)}" class="col-12 col-form-label">
                            Nombres
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 px-0">
                                <input (change)="establecerRazonSocial()" required type="text" id="nombre" name="nombre" [(ngModel)]="estudiante.persona.nombres" placeholder="Nombres" [ngClass]="{'brc-danger-m1':(!estudiante.persona.nombres)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                <i *ngIf="!estudiante.persona.nombres" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                            </div>
    
    
                            <div *ngIf="!estudiante.persona.nombres" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                <div class="arrow brc-danger-m1 mt-0"></div>
                                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                            </div>
    
                            <span *ngIf="!estudiante.persona.nombres" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                        </div>
                    </div>
                </div>
                <div *ngIf="usuario.empresa.usar_sucursales" class="col-2 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Sucursal
                        </div>
                        <div class="col-12">
                            <select [compareWith]="compararObjectos" name="sucursal" class="form-control" [(ngModel)]="estudiante.sucursal">
                                <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="  form-row">
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Género
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <select [compareWith]="compararObjectos" class="form-control" required name="genero" [(ngModel)]="estudiante.persona.genero">
                                    <option *ngIf="!estudiante.id" [ngValue]="null" selected>Ninguno</option>
                                    <option [ngValue]="genero" *ngFor="let genero of generos">{{genero.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Número de C.I.
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="ci" name="ci" [(ngModel)]="estudiante.persona.ci" placeholder="C.I." class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Categoria
                            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_ESTUDIANTE_CATEGORIAS,'obtenerCategoriasEstudiantesEmpresa')"></span>
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <select [compareWith]="compararObjectos" class="form-control" required name="categoria_1" [(ngModel)]="estudiante.categoria_1">
                                    <option [ngValue]="categoria" *ngFor="let categoria of tipo_categoria.clases ">{{categoria.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center" *ngIf="usuario.empresa.usar_estudiantes_colegio">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Código R.U.D.E.
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="codigo_rude" name="codigo_rude" [(ngModel)]="estudiante.codigo_rude" placeholder="Código R.U.D.E." class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="  form-row">
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Fecha de Nacimiento
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <input id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                        name="dp" [(ngModel)]="estudiante.persona.fecha_nacimiento_texto" ngbDatepicker #d="ngbDatepicker" [minDate]="fecha_nacimiento_minimo">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Teléfono Fijo
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="telefono" name="telefono" [(ngModel)]="estudiante.persona.telefono" placeholder="Teléfono Fijo" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Teléfono Móvil
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="telefono_movil" name="telefono_movil" [(ngModel)]="estudiante.persona.telefono_movil" placeholder="Teléfono Móvil" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Correo Electrónico
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="email" id="correo_electronico" name="correo_electronico" [(ngModel)]="estudiante.persona.correo_electronico" placeholder="Correo Electrónico" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div id="step-2">
            <div class="  form-row">
                <div class="col-4 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Dirección Zona/Villa
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="direccion_zona" name="direccion_zona" [(ngModel)]="estudiante.persona.direccion_zona" placeholder="Zona" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Dirección Avenida/Calle
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="direccion" name="direccion" [(ngModel)]="estudiante.persona.direccion" placeholder="Dirección" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Dirección Número de Vivienda
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="direccion_numero" name="direccion_numero" [(ngModel)]="estudiante.persona.direccion_numero" placeholder="Número" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-4 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            ¿El estudiante vive con?
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <select [compareWith]="compararObjectos" class="form-control" required name="vive_con" [(ngModel)]="estudiante.vive_con">
                                    <option [ngValue]="categoria" *ngFor="let categoria of tipo_vive_con.clases ">{{categoria.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row" *ngIf="usuario.empresa.usar_ingresos_economicos_instituto || usuario.empresa.usar_ingresos_economicos_colegio">
                <div class="col-4 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Razón Social
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" name="razon_social" [(ngModel)]="estudiante.persona.cliente.razon_social" placeholder="Razón social" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Tipo Documento
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <select [compareWith]="compararObjectos" class="form-control" required name="tipo_documento" [(ngModel)]="estudiante.persona.cliente.tipo_documento">
                                    <option [ngValue]="tipo_documento" *ngFor="let tipo_documento of sin_documentos_identidad">{{tipo_documento.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Nit/C.I.
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" name="nit" [(ngModel)]="estudiante.persona.cliente.nit" placeholder="Nit" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Complemento
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input required type="text" name="complemento" [(ngModel)]="estudiante.persona.cliente.complemento" placeholder="Complemento" class="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="  form-row">
                <div class="col-4 col-form-label text-sm-right">
                    Fotografía
                </div>
                <div class="col-2" *ngIf="estudiante.id">
                    <img  src="{{rest_server+estudiante.persona.imagen}}" width="100">
                </div>
                <div class="col-6">
                    <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                        <div>
                            <input type="file" class="ace-file-input" id="ace-file-input2" multiple autocomplete="off" (change)="subirImagen($event)" />
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div id="step-3" *ngIf="(usuario.empresa.usar_estudiantes_colegio || usuario.empresa.usar_estudiantes_instituto) && !es_rol_profesor">
            <div class="form-row">
                <div class="col-12 col-form-label text-sm-center text-grey">
                    <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light">
                            <i class="fa fa-table mr-2px"></i> Inscripción 
                            <a title="Registro Inscripcion" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearNuevaInscripcionInstituto()" *ngIf="usuario.empresa.usar_estudiantes_instituto">
                                <i class="ace-icon fa fa-plus bigger-130"></i>
                            </a>
                            <a title="Registro Inscripcion" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearNuevaInscripcionColegio()" *ngIf="usuario.empresa.usar_estudiantes_colegio">
                                <i class="ace-icon fa fa-plus bigger-130"></i>
                            </a>
                        </h5>
                        </div>
                
                        <div class="card-body p-0 bg-white" *ngIf="usuario.empresa.usar_estudiantes_instituto">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th>
                                            <label>Gestión</label>
                                        </th>
                                        <th>
                                            <label>Semestre</label>
                                        </th>
                                        <th>
                                            <label>Carrera</label>
                                        </th>
                                        <th><label>Grupo</label></th>
                                        <th>
                                            <label>Observación</label>
                                        </th>
                                        <th>
                                            <label>Estado</label>
                                        </th>
                                        <th>
                                            <label>Acciones</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let inscripcion of estudiante.inscripciones_instituto;let i=index;">
                                        <tr>
                                            <td>
                                                {{(i+1)}}
                                            </td>
                                            <td>
                                                {{inscripcion.gestion?inscripcion.gestion.nombre:''}}
                                            </td>
                                            <td>
                                                {{inscripcion.semestre?inscripcion.semestre.nombre:''}}
                                            </td>
                                            <td>
                                                {{inscripcion.carrera.nombre}}
                                            </td>
                                            <td>
                                                {{inscripcion.grupo?inscripcion.grupo.nombre:''}}
                                            </td>
                                            <td>
                                                {{inscripcion.observacion}}
                                            </td>
                                            <td *ngIf="inscripcion.activo"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                                            <td *ngIf="!inscripcion.activo"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                                            <td>
                                                <a *ngIf="inscripcion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarInscripcionInstituto(inscripcion)">
                                                    <i class="ace-icon fa fa-pencil-alt bigger-130"></i>
                                                </a>
                                                <a *ngIf="inscripcion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(inscripcion)">
                                                    <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-body p-0 bg-white" *ngIf="usuario.empresa.usar_estudiantes_colegio">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th><label>Nº</label></th>
                                        <th><label>Gestión</label></th>
                                        <th><label>Curso</label></th>
                                        <th><label>¿Consolidado?</label></th>
                                        <th><label>Observación</label></th>
                                        <th><label>Estado</label></th>
                                        <th><label>Acciones</label></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let inscripcion of estudiante.inscripciones_colegio;let i=index;">
                                        <tr *ngIf="!inscripcion.eliminado">
                                            <td>
                                                {{(i+1)}}
                                            </td>
                                            <td>
                                                {{inscripcion.gestion.nombre}}
                                            </td>
                                            <td>
                                                {{inscripcion.curso.nombre}}
                                            </td>
                                            <td *ngIf="inscripcion.consolidado"><span class="badge badge-md badge-success">SÍ</span></td>
                                            <td *ngIf="!inscripcion.consolidado"><span class="badge badge-md badge-warning">NO</span></td>
                                            <td>
                                                {{inscripcion.observacion}}
                                            </td>
                                            <td *ngIf="inscripcion.activo"><span class="badge badge-md badge-success">ACTIVO</span></td>
                                            <td *ngIf="!inscripcion.activo"><span class="badge badge-md badge-danger">ANULADO</span></td>
                                            <td>
                                                <a *ngIf="inscripcion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarInscripcionColegio(inscripcion)">
                                                    <i class="ace-icon fa fa-pencil-alt bigger-130"></i>
                                                </a>
                                                <a title="Migrar Datos" *ngIf="!inscripcion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirModalMigracionInscripcion(inscripcion)">
                                                    <i class="ace-icon fas fa-exchange-alt bigger-130"></i>
                                                </a>
                                                <a *ngIf="inscripcion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(inscripcion)">
                                                    <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                </a>
                                                <a *ngIf="!inscripcion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionInscripcion(inscripcion)">
                                                    <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div id="step-4" *ngIf="usuario.empresa.usar_estudiantes_colegio">
            <div class="form-row">
                <div class="col-12 col-form-label text-sm-center text-grey">
                    <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light">
                            <i class="fa fa-table mr-2px"></i> Tutores 
                            <a title="Registro Tutor" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearNuevoRegistroTutor()">
                                <i class="ace-icon fa fa-plus bigger-130"></i>
                            </a>&nbsp;
                            <a title="Búsqueda Tutor" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirModalBusquedaTutor()">
                                <i class="ace-icon fa fa-search bigger-130"></i>
                            </a>
                        </h5>
                        </div>
                
                        <div class="card-body p-0 bg-white">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Nombre Tutor</th>
                                        <th>Parentesco</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let tutor of estudiante.tutores; let i=index;" >
                                        <tr *ngIf="tutor.activo && !tutor.eliminado">
                                            <td>{{i+1}}</td>
                                            <td>{{tutor.persona.apellido_paterno}} {{tutor.persona.apellido_materno}} {{tutor.persona.nombres}}</td>
                                            <td>{{tutor.parentesco?tutor.parentesco.nombre:''}}</td>
                                            <td>
                                                <a *ngIf="tutor.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarTutor(tutor)">          
                                                    <i class="fa fa-pencil-alt text-105"></i>        
                                                </a>
                                                <a *ngIf="tutor.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionTutor(tutor)">
                                                    <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                </a>
                                            </td>
                                        </tr>
                                     </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div id="step-5" *ngIf="usuario.empresa.usar_cuentas_estudiantes && (aplicacion_usuarios.puede_crear || aplicacion_usuarios.puede_modificar)">
            <div class="  form-row">
                <div [ngClass]="{'text-danger-m1':(!estudiante.persona.usuario.nombre_usuario)}" class="col-4 col-form-label text-sm-right">
                    Usuario
                </div>
                <div class="col-8">
                    <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                        <input (blur)="validarUsuario(estudiante.persona.usuario)" [disabled]="estudiante.persona.usuario.id" required type="email" id="nombre_usuario" name="nombre_usuario" [(ngModel)]="estudiante.persona.usuario.nombre_usuario" placeholder="Nombre de usuario" [ngClass]="{'brc-danger-m1':(!estudiante.persona.usuario.nombre_usuario)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                        <i *ngIf="!estudiante.persona.usuario.nombre_usuario" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                    </div>
                    <div *ngIf="!estudiante.persona.usuario.nombre_usuario" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                        <div class="arrow brc-danger-m1 mt-0"></div>
                        <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                    </div>
                    <span *ngIf="!estudiante.persona.usuario.nombre_usuario" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                </div>

                <div [ngClass]="{'text-danger-m1':(!estudiante.persona.usuario.clave)}" class="col-4 col-form-label text-sm-right">
                    Clave
                </div>
                <div class="col-8">
                    <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                        <input required type="password" id="clave" name="clave" [(ngModel)]="estudiante.persona.usuario.clave" placeholder="Clave" [ngClass]="{'brc-danger-m1':(!estudiante.persona.usuario.clave)}" class="form-control form-control-lg col-12 pr-45 pl-25" />
                        <i *ngIf="!estudiante.persona.usuario.clave" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                    </div>
                    <div *ngIf="!estudiante.persona.usuario.clave" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                        <div class="arrow brc-danger-m1 mt-0"></div>
                        <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                    </div>
                    <span *ngIf="!estudiante.persona.usuario.clave" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                </div>

                <div [ngClass]="{'text-danger-m1':(!estudiante.persona.usuario.clave)}" class="col-4 col-form-label text-sm-right">
                    Repeticion Clave
                </div>
                <div class="col-8">
                    <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                        <input value="{{estudiante.persona.usuario.clave}}" (blur)="verificarClaveRepeticion(estudiante.persona.usuario)" required type="password" id="repeticion_clave" name="repeticion_clave" [(ngModel)]="estudiante.persona.usuario.repeticion_clave" placeholder="Repeticion Clave" [ngClass]="{'brc-danger-m1':(!estudiante.persona.usuario.repeticion_clave)}" class="form-control form-control-lg col-12 pr-45 pl-25" />
                        <i *ngIf="!estudiante.persona.usuario.repeticion_clave" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                    </div>
                    <div *ngIf="!estudiante.persona.usuario.repeticion_clave" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                        <div class="arrow brc-danger-m1 mt-0"></div>
                        <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                    </div>
                    <span *ngIf="!estudiante.persona.usuario.repeticion_clave" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                </div>

                <div class="col-4 col-form-label text-sm-right">
                    Rol
                </div>
                <div class="col-8">
                    <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                        <select disabled (change)="establecerAplicacionesUsuario(estudiante.persona.usuario.rol)" [compareWith]="compararObjectos" class="form-control" required name="rol" [(ngModel)]="estudiante.persona.usuario.rol">
                            <option *ngIf="!estudiante.persona.usuario.id" [ngValue]="null" selected>Ninguno</option>
                            <option [ngValue]="rol" *ngFor="let rol of roles_registro">{{rol.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="  form-row">
                <div class="col-sm-2 col-form-label text-sm-right">¿Activo?</div>
                <div class="col-sm-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                        <label>
                            <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.persona.usuario.activo"/>
                        </label>
                    </div>
                </div>
                <div class="col-sm-2 col-form-label text-sm-right">¿Cierre de Sesión Automático?</div>
                <div class="col-sm-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                        <label>
                            <input disabled name="switch-field-2" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.persona.usuario.cierre_sesion_automatico"/>
                        </label>
                    </div>
                </div>
                <div class="col-sm-2 col-form-label text-sm-right">¿Multidispositivo?</div>
                <div class="col-sm-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                        <label>
                            <input disabled name="switch-field-3" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.persona.usuario.multidispositivo"/>
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="!estudiante.persona.usuario.id">
                    <div class="col-sm-2 col-form-label text-sm-right">¿Enviar Correo?</div>
                    <div class="col-sm-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <label>
                                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.persona.usuario.enviar_correo"/>
                            </label>
                        </div>
                    </div>
                </ng-container>
            </div>
          </div>
          <div id="step-6" *ngIf="usuario.empresa.usar_hoja_rude">
            <fieldset>
                <legend>Datos de Nacimiento</legend>
                <div class="form-row">
                <div class="col-1">
                    <label>Pais Nacimiento</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input type="text" name="pais_nacimiento" [(ngModel)]="estudiante.persona.pais_nacimiento" placeholder="Pais Nacimiento" class="form-control form-control-lg col-12"  />
                    </div>
                </div>
                <div class="col-1">
                    <label>Departamento Nacimiento</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input type="text" name="ciudad_nacimiento" [(ngModel)]="estudiante.persona.ciudad_nacimiento" placeholder="Ciudad Nacimiento" class="form-control form-control-lg col-12"  />
                    </div>
                </div>
                <div class="col-1">
                    <label>Provincia Nacimiento</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input type="text" name="provincia_nacimiento" [(ngModel)]="estudiante.persona.provincia_nacimiento" placeholder="Provincia Nacimiento" class="form-control form-control-lg col-12"  />
                    </div>
                </div>
                <div class="col-1">
                    <label>Localidad Nacimiento</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input type="text" name="localidad_nacimiento" [(ngModel)]="estudiante.persona.localidad_nacimiento" placeholder="Localidad Nacimiento" class="form-control form-control-lg col-12"  />
                    </div>
                </div>
                </div>
                <legend>Certificado de Nacimiento</legend>
                <div class="form-row">
                    <div class="col-1">
                        <label>Oficialia Nº</label>
                    </div>
                    <div class="col-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="certificado_nacimiento_oficialia" [(ngModel)]="estudiante.persona.certificado_nacimiento_oficialia" placeholder="Oficialia Nº" class="form-control form-control-lg col-12"  />
                        </div>
                    </div>
                    <div class="col-1">
                        <label>Libro Nº</label>
                    </div>
                    <div class="col-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="certificado_nacimiento_libro" [(ngModel)]="estudiante.persona.certificado_nacimiento_libro" placeholder="Libro Nº" class="form-control form-control-lg col-12"  />
                        </div>
                    </div>
                    <div class="col-1">
                        <label>Partida Nº</label>
                    </div>
                    <div class="col-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="certificado_nacimiento_partida" [(ngModel)]="estudiante.persona.certificado_nacimiento_partida" placeholder="Partida Nº" class="form-control form-control-lg col-12"  />
                        </div>
                    </div>
                    <div class="col-1">
                        <label>Folio Nº</label>
                    </div>
                    <div class="col-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="certificado_nacimiento_folio" [(ngModel)]="estudiante.persona.certificado_nacimiento_folio" placeholder="Folio Nº" class="form-control form-control-lg col-12"  />
                        </div>
                    </div>
                </div>
                <legend>Datos de Dirección</legend>
                <div class="form-row">
                    <div class="col-1">
                        <label>Dirección Departamento</label>
                    </div>
                    <div class="col-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="direccion_ciudad" [(ngModel)]="estudiante.persona.direccion_ciudad" placeholder="Ciudad" class="form-control form-control-lg col-12"  />
                        </div>
                    </div>
                    <div class="col-1">
                        <label>Dirección Provincia</label>
                    </div>
                    <div class="col-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="direccion_provincia" [(ngModel)]="estudiante.persona.direccion_provincia" placeholder="Provincia" class="form-control form-control-lg col-12"  />
                        </div>
                    </div>
                    <div class="col-1">
                        <label>Dirección Municipio</label>
                    </div>
                    <div class="col-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="direccion_municipio" [(ngModel)]="estudiante.persona.direccion_municipio" placeholder="Municipio" class="form-control form-control-lg col-12"  />
                        </div>
                    </div>
                    <div class="col-1">
                        <label>Dirección Localidad</label>
                    </div>
                    <div class="col-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="direccion_localidad" [(ngModel)]="estudiante.persona.direccion_localidad" placeholder="Localidad" class="form-control form-control-lg col-12"  />
                        </div>
                    </div>
                </div>
            </fieldset>
            <hr>
            <div class="  form-row">
                <div class="col-2">
                    <label>¿El Estudiante presenta alguna discapacidad?</label>
                </div>
                <div class="col-1">
                  <label>
                      <input name="presenta_discapacidad" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.presenta_discapacidad"/>
                  </label>
                </div>
                <div class="col-2">
                    <label>Nº de Registro de discapacidad</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input type="text" name="numero_registro_discapacidad" [(ngModel)]="estudiante.numero_registro_discapacidad" placeholder="Nº de Registro de discapacidad" class="form-control form-control-lg col-12"  />
                    </div>
                </div>
                <div class="col-2">
                    <label>Tipo de Discapacidad</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="tipo_discapacidad" [(ngModel)]="estudiante.tipo_discapacidad">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_tipo_discapacidad.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-2">
                    <label>Grado de Discapacidad</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="grado_discapacidad" [(ngModel)]="estudiante.grado_discapacidad">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_grado_discapacidad.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr>
            <div class="  form-row">
                <div class="col-1">
                    <label>Idioma de Infancia</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input type="text" name="idioma_infancia" [(ngModel)]="estudiante.idioma_infancia" placeholder="Idioma de Infancia" class="form-control form-control-lg col-12"  />
                    </div>
                </div>
                <div class="col-1">
                    <label>Idiomas que habla</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input type="text" name="idiomas_frecuentes" [(ngModel)]="estudiante.idiomas_frecuentes" placeholder="Idiomas que habla" class="form-control form-control-lg col-12"  />
                    </div>
                </div>
                <div class="col-1">
                    <label>¿Pertenece a alguna Nacion?</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="nacion_pertenencia" [(ngModel)]="estudiante.nacion_pertenencia">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_pertenencia_nacion.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-2">
                    <label>¿Existe algun centro de salud en su comunidad?</label>
                </div>
                <div class="col-1">
                  <label>
                      <input name="existe_centro_salud_comunidad" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.existe_centro_salud_comunidad"/>
                  </label>
                </div>
            </div>
            <hr>
            <div class="  form-row">
                <div class="col-2">
                    <label>El año pasado, por problemas de salud, ¿acudio o se atendio en?</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="asistencia_centro_salud" [(ngModel)]="estudiante.asistencia_centro_salud">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_asistencia_centro_salud.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-2">
                    <label>El año pasado, ¿Cuantas veces fue al centro de Salud?</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="cantidad_asistencia_centro_salud" [(ngModel)]="estudiante.cantidad_asistencia_centro_salud">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_cantidad_asistencia_centro_salud.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-1">
                    <label>¿Tiene seguro de salud?</label>
                </div>
                <div class="col-1">
                    <label>
                        <input name="tiene_seguro_salud" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.tiene_seguro_salud"/>
                    </label>
                </div>
                <div class="col-1">
                    <label>¿Tiene acceso a agua por cañeria de red?</label>
                </div>
                <div class="col-1">
                  <label>
                      <input name="tiene_acceso_agua_red" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.tiene_acceso_agua_red"/>
                  </label>
                </div>
            </div>
            <hr>
            <div class="  form-row">
                <div class="col-2">
                    <label>¿Tiene baño en su vivienda?</label>
                </div>
                <div class="col-1">
                  <label>
                      <input name="tiene_bano_vivienda" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.tiene_bano_vivienda"/>
                  </label>
                </div>
                <div class="col-2">
                    <label>¿Tiene red de alcantarillado?</label>
                </div>
                <div class="col-1">
                  <label>
                      <input name="tiene_alcantarillado" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.tiene_alcantarillado"/>
                  </label>
                </div>
                <div class="col-2">
                    <label>¿Usa energia electrica para alumbrar su vivienda?</label>
                </div>
                <div class="col-1">
                  <label>
                      <input name="tiene_energia_electrica" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.tiene_energia_electrica"/>
                  </label>
                </div>
            </div>
            <hr>
            <div class="  form-row">
                <div class="col-2">
                    <label>¿Cuenta con servicio de recojo de basura?</label>
                </div>
                <div class="col-1">
                    <label>
                        <input name="tiene_servicio_recojo_basura" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.tiene_servicio_recojo_basura"/>
                    </label>
                </div>
                <div class="col-1">
                    <label>La vivienda que ocupa el hogar es:</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="condicion_vivienda" [(ngModel)]="estudiante.condicion_vivienda">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_condicion_vivienda.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-1">
                    <label>El estudiante accede a internet en:</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="acceso_internet" [(ngModel)]="estudiante.acceso_internet">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_acceso_internet.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-1">
                    <label>¿Con que frecuencia usa internet?</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="frecuencia_acceso_internet" [(ngModel)]="estudiante.frecuencia_acceso_internet">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_frecuencia_acceso_internet.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr>
            <div class="  form-row">
                <div class="col-2">
                    <label>En la pasada gestion, ¿El estudiante trabajo?</label>
                </div>
                <div class="col-1">
                    <label>
                        <input name="gestion_pasada_trabajo" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.gestion_pasada_trabajo"/>
                    </label>
                </div>
                <div class="col-1">
                    <label>En la pasada gestion, ¿En que actividad trabajo el estudiante?</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="actividad_trabajo" [(ngModel)]="estudiante.actividad_trabajo">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_actividad_trabajo.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-1">
                    <label>¿En que turno trabajo el estudiante?</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="turno_trabajo" [(ngModel)]="estudiante.turno_trabajo">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_turno_trabajo.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-1">
                    <label>¿Con que frecuencia trabajo?</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="frecuencia_trabajo" [(ngModel)]="estudiante.frecuencia_trabajo">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_frecuencia_trabajo.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr>
            <div class="  form-row">
                <div class="col-1">
                    <label>¿Recibio algun pago?</label>
                </div>
                <div class="col-1">
                    <label>
                        <input name="recibio_pago_trabajo" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.recibio_pago_trabajo"/>
                    </label>
                </div>
                <div class="col-2">
                    <label>Generalmente, ¿Como llega el estudiante a la Unidad Educativa?</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="llegada_escuela" [(ngModel)]="estudiante.llegada_escuela">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_llegada_escuela.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-4">
                    <label>Segun el medio de transporte señalado, ¿Cual es el tiempo maximo que demora el estudiante desde su vivienda hasta la Unidad Educativa?</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="tiempo_llegada_escuela" [(ngModel)]="estudiante.tiempo_llegada_escuela">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_tiempo_llegada_escuela.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr>
            <div class="  form-row">
                <div class="col-2">
                    <label>¿El estudiante abandono la Unidad Educativa el año pasado?</label>
                </div>
                <div class="col-1">
                    <label>
                        <input name="abandono_escuela" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="estudiante.abandono_escuela"/>
                    </label>
                </div>
                <div class="col-2">
                    <label>¿Cuales fueron las razones de abandono escolar?</label>
                </div>
                <div class="col-2">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" required name="razon_abandono_escuela" [(ngModel)]="estudiante.razon_abandono_escuela">
                            <option [ngValue]="categoria" *ngFor="let categoria of tipo_razon_abandono_escuela.clases ">{{categoria.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
</div>

<ng-template #registro_inscripcion_instituto_modal let-modal>
    <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Inscripción</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="agregarInscripcionInstituto()" class="btn btn-primary"  >
              <i class="fa fa-plus text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.close('')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
        <div class="col-1">
            <label>Gestión</label>
        </div>
        <div class="col-2">
            <select (change)="ficha_economica_estudiante.ngOnInit()" [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="inscripcion.gestion">
                <option [ngValue]="null" [selected]="true">Seleccionar gestión</option>
                <option [ngValue]="gestion" *ngFor="let gestion of gestiones">{{gestion.nombre}}</option>
            </select>
        </div>
        <div class="col-1">
            <label>Semestre</label>
        </div>
        <div class="col-3">
            <select (change)="ficha_economica_estudiante.ngOnInit()" [compareWith]="compararObjectos" name="semestre" class="form-control" [(ngModel)]="inscripcion.semestre">
                <option [ngValue]="null" [selected]="true">Seleccionar Semestre</option>
                <option [ngValue]="semestre" *ngFor="let semestre of semestres">{{semestre.nombre}}</option>
            </select>
        </div>
        <div class="col-1">
            <label>Carrera</label>
        </div>
        <div class="col-4">
            <select (change)="ficha_economica_estudiante.ngOnInit()" [compareWith]="compararObjectos" name="carrera" class="form-control" [(ngModel)]="inscripcion.carrera">
                <option [ngValue]="null" [selected]="true">Seleccionar carrera</option>
                <option [ngValue]="carrera" *ngFor="let carrera of carreras">{{carrera.nombre}}</option>
            </select>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-1">
            <label>Grupo</label>
        </div>
        <div class="col-3">
            <select [compareWith]="compararObjectos" name="grupo" class="form-control" [(ngModel)]="inscripcion.grupo">
                <option [ngValue]="null" [selected]="true">Seleccionar grupo</option>
                <option [ngValue]="grupo" *ngFor="let grupo of tipo_grupo.clases">{{grupo.nombre}}</option>
            </select>
            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_INSCRIPCION_GRUPOS,'obtenerGrupoInscripcionEmpresa')"></span>
        </div>
        <div class="col-1">
            <label>Publicidad</label>
        </div>
        <div class="col-3">
            <select [compareWith]="compararObjectos" name="publicidad" class="form-control" [(ngModel)]="inscripcion.publicidad">
                <option [ngValue]="null" [selected]="true">Seleccionar publicidad</option>
                <option [ngValue]="publicidad" *ngFor="let publicidad of tipo_publicidad.clases">{{publicidad.nombre}}</option>
            </select>
        </div>
        <div class="col-1">
            <label>Periodo</label>
        </div>
        <div class="col-3">
            <select [compareWith]="compararObjectos" name="periodo" class="form-control" [(ngModel)]="inscripcion.periodo">
                <option [ngValue]="null" [selected]="true">Seleccionar periodo</option>
                <option [ngValue]="periodo" *ngFor="let periodo of periodos_clase">{{periodo.nombre}} {{(periodo.descripcion?periodo.descripcion:"")}} {{periodo.hora_inicio}}:{{periodo.minuto_inicio}}-{{periodo.hora_fin}}:{{periodo.minuto_fin}} - <span *ngFor="let dia of periodo.dias">{{dia.dia.nombre}} </span></option>
            </select>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-1">
            <label>Fecha Inicio</label>
        </div>
        <div class="col-3">
            <div class="input-group">
                <input name="birtfecha_inicio_textohday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="inscripcion.fecha_inicio_texto" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div> 
        </div>
          <div class="col-sm-12 col-md-3">
              <label>Observación</label>
          </div>
          <div class="col-sm-12 col-md-5">
              <input type="text" name="observacion" [(ngModel)]="inscripcion.observacion" placeholder="Observación" class="form-control"/>
          </div>
      </div>
      <hr>
        <div class="row">
            <div class="col-12" [hidden]="inscripcion.id || !inscripcion.carrera">
                <app-ficha-economica-estudiante #ficha_economica_estudiante
                    [usuario]="usuario"
                    [estudiante]="estudiante"
                    [inscripcion_instituto]="inscripcion"
                    [es_modal_interno]="true">
                </app-ficha-economica-estudiante>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #registro_inscripcion_colegio_modal let-modal>
    <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Inscripción</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="agregarInscripcionColegio()" class="btn btn-primary"  >
              <i class="fa fa-plus text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.close('')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
        <div class="col-1">
            <label>Gestión</label>
        </div>
        <div class="col-2">
            <select [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="inscripcion.gestion">
                
                <option [ngValue]="gestion" *ngFor="let gestion of gestiones | filterBy:{habilitado:true}">{{gestion.nombre}}</option>
            </select>
        </div>
        <div class="col-1">
            <label>Nivel</label>
        </div>
        <div class="col-3">
            <select [compareWith]="compararObjectos" name="nivel" class="form-control" [(ngModel)]="inscripcion.nivel" (change)="obtenerCursosPorNivel(inscripcion.nivel.id)">
                <option [ngValue]="null" [selected]="true">Seleccionar Nivel</option>
                <option [ngValue]="nivel" *ngFor="let nivel of filter.niveles_escolares">{{nivel.nombre}}</option>
            </select>
        </div>
        <div class="col-1">
            <label>Curso</label>
        </div>
        <div class="col-4">
            <select [compareWith]="compararObjectos" name="curso" class="form-control" [(ngModel)]="inscripcion.curso">
                <option [ngValue]="curso" *ngFor="let curso of filter.cursos">{{curso.nombre}}</option>
            </select>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-2">
            <label>¿Consolidado?</label>
        </div>
        <div class="col-2">
            <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="inscripcion.consolidado"/>
        </div>
        <div class="col-2">
            <label>Observación</label>
        </div>
        <div class="col-6">
            <input type="text" name="observacion" [(ngModel)]="inscripcion.observacion" placeholder="Observación" class="form-control"/>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #registro_tutor_modal let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Registro Tutor</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="agregarTutor()" class="btn btn-primary"  >
                <i class="fa fa-plus text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.close('')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="  form-row">
            <div class="col-4 col-form-label text-center">
                <div class="row">
                    <div class="col-12 col-form-label">
                        Apellido Paterno
                    </div>
                    <div class="col-12">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="tutor_apellido_paterno" [(ngModel)]="tutor.persona.apellido_paterno" placeholder="Apellido Paterno" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 col-form-label text-center">
                <div class="row">
                    <div class="col-12 col-form-label">
                        Apellido Materno
                    </div>
                    <div class="col-12">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="tutor_apellido_materno" [(ngModel)]="tutor.persona.apellido_materno" placeholder="Apellido Materno" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 col-form-label text-center">
                <div class="row">
                    <div [ngClass]="{'text-danger-m1':(!tutor.persona.nombres)}" class="col-12 col-form-label">
                        Nombres
                    </div>
                    <div class="col-12">
                        <div class="d-inline-flex align-items-center col-12 px-0">
                            <input required type="text" name="tutor_nombre" [(ngModel)]="tutor.persona.nombres" placeholder="Nombres" [ngClass]="{'brc-danger-m1':(!tutor.persona.nombres)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            <i *ngIf="!tutor.persona.nombres" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="  form-row">
            <div class="col-4 col-form-label text-center">
                <div class="row">
                    <div class="col-12 col-form-label">
                        C.I.
                    </div>
                    <div class="col-12">
                        <div class="d-inline-flex align-items-center col-12 px-0">
                            <input required type="text" name="tutor_ci" [(ngModel)]="tutor.persona.ci" placeholder="Nombres" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="profession">Profesión</label>
                    <input class="form-control" name="profession" type="text" [(ngModel)]="tutor.persona.profesion" placeholder="Ej: Ing. de sistemas"/>
                </div>
            </div>
            <div class="col-4 col-form-label text-center">
                <div class="row">
                    <div class="col-12 col-form-label">
                        Fecha de Nacimiento
                    </div>
                    <div class="col-12">
                        <div class="input-group">
                            <input name="tutorfc" class="form-control" placeholder="dd/mm/yyyy"
                                    [(ngModel)]="tutor.persona.fecha_nacimiento_texto" ngbDatepicker #df="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="df.toggle()" type="button"></button>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="work_place">Ocupación lab.</label>
                    <input class="form-control" name="work_place" type="text" [(ngModel)]="tutor.lugar_trabajo" placeholder="Ej: casa"/>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label for="phone">Telefono fijo</label>
                    <input class="form-control" name="phone" type="tel" [(ngModel)]="tutor.persona.telefono" placeholder="Ej: 4433307">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="mobile_phone">Teléfono celular</label>
                    <input class="form-control" id="mobile_phone" name="mobile_phone" type="text" [(ngModel)]="tutor.persona.telefono_movil" placeholder="Ej: 70723756">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="email">Correo electronico</label>
                    <input class="form-control" name="email" type="email" [(ngModel)]="tutor.persona.correo_electronico" placeholder="nombre@ejemplo.com">
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="language">Idioma que habla</label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="lenguaje" [(ngModel)]="tutor.persona.lenguaje">
                        <option [ngValue]="null" [selected]="true">Seleccionar Idioma</option>
                        <option [ngValue]="lenguaje" *ngFor="let lenguaje of idiomas;">{{lenguaje.nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="degree">Grado de ins.</label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="grado_academico" [(ngModel)]="tutor.persona.grado">
                        <option [ngValue]="null" [selected]="true">Seleccionar Grado</option>
                        <option [ngValue]="grado_academico" *ngFor="let grado_academico of grados_academicos;">{{grado_academico.nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="relationship">Parentesco</label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="parentesco" [(ngModel)]="tutor.parentesco">
                        <option [ngValue]="null" [selected]="true">Seleccionar Parentesco</option>
                        <option [ngValue]="parentesco" *ngFor="let parentesco of parentescos;">{{parentesco.nombre}}</option>
                    </select>
                </div>
            </div>
        </div>	
        <div class="form-row">
            <div class="col-md-2">
                <div class="form-group">
                    <label for="principal_tutor">¿Es Tutor Principal?</label>
                    <label>
                        <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="tutor.tutor_principal"/>
                    </label>
                </div>
            </div>
            <ng-container *ngIf="usuario.empresa.usar_cuentas_estudiantes && (aplicacion_usuarios.puede_crear || aplicacion_usuarios.puede_modificar)">
                <div class="col-md-10">
                    <div class="form-row">
                        <div class="col-md-7">
                            <div class="form-group">
                                <label for="tutorusername">Nombre de usuario:</label>
                                <input autocomplete="off" [disabled]="tutor.persona.usuario.id" class="form-control" name="tutorusername" type="text" (blur)="validarUsuario(tutor.persona.usuario)" [(ngModel)]="tutor.persona.usuario.nombre_usuario"/>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label for="tutorrol">Rol</label>
                                <select disabled [compareWith]="compararObjectos" class="form-control" required name="rol" [(ngModel)]="tutor.persona.usuario.rol">
                                    <option *ngIf="!tutor.persona.usuario.id" [ngValue]="null" selected>Ninguno</option>
                                    <option [ngValue]="rol" *ngFor="let rol of roles_registro">{{rol.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="tutorpassword">Clave</label>
                                <input class="form-control" name="tutor_clave" type="password" [(ngModel)]="tutor.persona.usuario.clave"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="tutorpassword">Repetición Clave</label>
                                <input class="form-control" name="tutor_repeticion_clave" type="password" (blur)="verificarClaveRepeticion(tutor.persona.usuario)" [(ngModel)]="tutor.persona.usuario.repeticion_clave"/>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>	
      </div>
</ng-template>