import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';

declare const aplicarSubidaArchivo:any;
declare const $:any;

@Component({
  selector: 'app-vista-venta',
  templateUrl: './vista-venta.component.html',
  styleUrls: ['./vista-venta.component.css']
})
export class VistaVentaComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  venta:any={
    almacen:{},
    detallesVenta:[]
  };
  usuario:any;
  global_variable=GlobalVariable;
  @BlockUI() blockUI: NgBlockUI;
  gloval_variable:any=GlobalVariable;
  
  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public ventasService:VentasService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.obtenerVenta();
  }

  obtenerVenta(){
    this.blockUI.start();
    this.ventasService.obtenerVenta(this.venta.id).subscribe((venta:any) => {
      this.venta=venta;
      this.venta.subidas=[];
      setTimeout(function() {
        $(".dz-remove").trigger('click');
      }, 0);
      this.blockUI.stop();
		});
  }

  ngAfterViewInit(){
    this.obtenerTextosEmpresa();
    aplicarSubidaArchivo('form-documentos-venta','template-documentos-venta',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:2});
  }

  cerrarModal(){
    this.alTerminar.emit();
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.venta.subidas.push(base64EncodedImage);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.venta.subidas.splice(me.venta.documentos.indexOf(base64EncodedImage),1);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  guardarDocumentos(){
    this.blockUI.start();
    this.ventasService.subirDocumentosVenta({documentos:this.venta.documentos,subidas:this.venta.subidas,id_venta:this.venta.id,id_empresa:this.usuario.id_empresa}).subscribe((res:any)=>{
      this.toastr.success(res.mensaje);
      this.blockUI.stop();
      this.obtenerVenta();
    });
  }

}
