<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Estudiantes
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Estudiantes
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <app-paginator-header [paginator]="this"></app-paginator-header>
            </div>
            </div>
            <div class="row">
            <div class="col-12 col-md-12">
              <div class="row">
                  <ng-container *ngIf="usuario.empresa.usar_sucursales">
                    <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                    <div class="col-md-3 col-sm-12">
                      <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                          <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                      </select>
                    </div>
                  </ng-container>
                  <div class="col-md-2 col-sm-12"><label>Código </label></div>
                  <div class="col-md-4 col-sm-12">
                    <input type="text" name="codigo_estudiante" [(ngModel)]="filter.codigo" placeholder="Código" class="form-control"  />
                  </div>
                  <ng-container *ngIf="usuario.empresa.usar_estudiantes_instituto">
                    <div class="col-md-2 col-sm-12"><label>Carrera </label></div>
                    <div class="col-md-4 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" name="grupo" [(ngModel)]="filter.carrera">
                            <option [ngValue]="carrera" *ngFor="let carrera of filter.carreras">{{carrera.nombre}}</option>
                        </select>
                    </div>
                  </ng-container>
              </div>
            </div>
          </div>
		      </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="fa fa fa-search"></i>
                  </button>
                  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoEstudiante()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-purple-d1"></i>
                  </button>
                  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoRapidoEstudiante()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-success py-1 bs-print" type="button">
                    <i class="fa fa-plus text-success-d1"></i>
                  </button>
                  <button *ngIf="usuario.empresa.usar_estudiantes_colegio" (click)="abrirReporteCursos()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1 dropdown-toggle" type="button" title="Reporte Cursos">
                    <i class="fas fa-chart-bar text-success-d1"></i>
                  </button>
                  <button *ngIf="usuario.empresa.usar_cobros_banco_colegio" (click)="abrirModalEstudiantesArchivoBanco()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" title="Generar Archivo Banco">
                    <i class="fas fa-file-invoice-dollar text-primary-d1"></i>
                  </button>
                  <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                    <i class="fa fa-wrench text-blue-d1"></i>
                    <span class="caret"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                    <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                      <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                        </div>
              
                        <div class="card-body p-0 bg-white">
                        <table class="table table-striped table-hover mb-0">
                          <tbody>
                            <tr *ngIf="aplicacion.puede_modificar">
                              <td class="text-dark-m2">
                              Configuración Aplicación
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                              </td>
                            </tr>
                            <tr *ngIf="usuario.empresa.usar_estudiantes_colegio">
                              <td class="text-dark-m2">
                              Importación Estudiantes
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirModalImportacionEstudiantes()" href="javascript:void(0)" class="text-success"><i class="fa fa-upload text-success"></i></a>
                              </td>
                            </tr>
                            <tr *ngIf="usuario.empresa.usar_estudiantes_colegio">
                              <td class="text-dark-m2">
                              Importación Tutores
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirModalImportacionTutores()" href="javascript:void(0)" class="text-success"><i class="fa fa-upload text-success"></i></a>
                              </td>
                            </tr>
                            <tr *ngIf="usuario.empresa.usar_mensajeria_redes_sociales">
                              <td class="text-dark-m2">
                              Grupos Whatsapp
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirParticipantesGrupoWhatsapp()" href="javascript:void(0)" class="text-success"><i class="fas fa-user-friends text-purple-d1"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                      </div>
                  </div>
                  <div class="keep-open btn-group show" title="Columns">
                    <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
                  </div>
                </div>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="estudiantes.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th *ngIf="configuracion_pagina.getConfiguration().fotografia.show" class="center">Fotografía </th>
                <th *ngIf="configuracion_pagina.getConfiguration().codigo.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().apellido_paterno.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('apellido_paterno')">Apellido Paterno <span id="apellido_paterno" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().apellido_materno.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('apellido_materno')">Apellido Materno <span id="apellido_materno" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().nombres.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('nombres')">Nombres <span id="nombres" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().ci.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('ci')">C.I. <span id="ci" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="usuario.empresa.usar_estudiantes_instituto && configuracion_pagina.getConfiguration().carrera.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('ci')">Carrera <span id="carrera" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="usuario.empresa.usar_estudiantes_instituto && configuracion_pagina.getConfiguration().grupo.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('grupo')">Grupo <span id="grupo" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().telefono_movil.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('telefono_movil')">Teléfono Móvil <span id="telefono_movil" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().correo_electronico.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('correo_electronico')">Correo Electrónico <span id="correo_electronico" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().fecha_registro.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('createdAt')">Fecha Registro <span id="fecha_registro" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #estudiantes *ngFor="let estudiante of estudiantes; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().fotografia.show">
                    <div class="imagen-estudiante-vista clearfix">
                        <a href="{{rest_server+estudiante.imagen}}" data-rel="colorbox" title="{{estudiante.nombre_completo}}">
                            <img alt="sin imagen" src="{{rest_server+estudiante.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
                        </a>
                    </div>
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    {{estudiante.codigo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().apellido_paterno.show">
                    {{estudiante.apellido_paterno}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().apellido_materno.show">
                    {{estudiante.apellido_materno}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().nombres.show">
                    {{estudiante.nombres}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().ci.show">
                    {{estudiante.ci}}
                </td>
                <td *ngIf="usuario.empresa.usar_estudiantes_instituto && configuracion_pagina.getConfiguration().carrera.show">{{estudiante.carrera}}</td>
                <td *ngIf="usuario.empresa.usar_estudiantes_instituto && configuracion_pagina.getConfiguration().grupo.show">{{estudiante.grupo}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().telefono_movil.show">
                    {{estudiante.telefono_movil}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().correo_electronico.show">
                    {{estudiante.correo_electronico}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().fecha_registro.show">
                  {{estudiante.createdAt | date:'dd/MM/yyyy'}}
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" (click)="modificarEstudiante(estudiante)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					          </a>
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarRapidoEstudiante(estudiante)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					          </a>
                    <a *ngIf="aplicacion.puede_ver" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verEstudiante(estudiante)">          
                      <i class="fa fa-search text-105"></i>        
					          </a>
                    <a title="Ficha Económica" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirFichaEconomica(estudiante)">
                      <i class="ace-icon fas fa-money-check-alt bigger-130"></i>
                    </a>
                    <a *ngIf="usuario.empresa.usar_estudiantes_instituto" title="Asignación de materias" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirAsignacionMaterias(estudiante)">
                      <i class="ace-icon fas fa-id-card-alt bigger-130"></i>
                    </a>
                    <a *ngIf="usuario.empresa.usar_hoja_rude" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="imprimirRude(estudiante)">          
                      <i class="fa fa-file-pdf text-105"></i>        
                    </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopuDocumentosEstudiante(estudiante)">          
                      <i class="fas fa-file-upload text-105"></i>
                    </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirPopupAsuntoAdministrativo(estudiante)">          
                      <i class="fas fa-bell text-105"></i>
                    </a>
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(estudiante)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="estudiantes.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #importacion_estudiantes let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Estudiantes a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button [disabled]="importacion.estudiantes.length==0" class="btn btn-primary" type="button" (click)="guardarImportacionEstudiantes()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
    <div class="row">
      <div class="col-1"><label>Gestión Escolar </label></div>
      <div class="col-md-2 col-sm-2">
          <select [compareWith]="compararObjectos" class="form-control" name="gestion" [(ngModel)]="filter.gestion">
            <option [ngValue]="null" [selected]="true">Seleccionar</option>
              <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones_escolares">{{gestion.nombre}}</option>
          </select>
      </div>
      <div class="col-1"><label>Nivel Escolar </label></div>
      <div class="col-md-2 col-sm-2">
          <select [compareWith]="compararObjectos" class="form-control" name="nivel" [(ngModel)]="filter.nivel_escolar" (change)="obtenerCursosPorNivel(filter.nivel_escolar.id)">
            <option [ngValue]="null" [selected]="true">Seleccionar</option>
              <option [ngValue]="nivel" *ngFor="let nivel of filter.niveles_escolares">{{nivel.nombre}}</option>
          </select>
      </div>
      <div class="col-1"><label>Curso </label></div>
      <div class="col-md-2 col-sm-2">
          <select [compareWith]="compararObjectos" class="form-control" name="curso" [(ngModel)]="filter.curso">
            <option [ngValue]="null" [selected]="true">Seleccionar</option>
              <option [ngValue]="curso" *ngFor="let curso of filter.cursos">{{curso.nombre}}</option>
          </select>
      </div>
      <div class="col-3">
        <button [disabled]="!filter.curso" class="btn btn-success" type="button" (click)="descargarExcelImportacion()">
          <i class="fa fa-download align-top bigger-110"></i>
        </button>&nbsp;
        <button [disabled]="!filter.curso" (click)="clickBotonSubir('subir-excel-estudiantes')" class="fileUpload btn btn-primary" aria-label="Export" type="button" title="Subir excel estudiantes" aria-expanded="false">
          <i class="fa fa-upload align-top bigger-110 fileUpload-button"></i>
          <input id="subir-excel-estudiantes" (change)="subirArchivoExcelImportacionEstudiantes($event)" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".xlsx">
        </button>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12 col-12">
        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
          <thead fix-head class="table-head-color sticky-nav">
            <tr>
              <th>
                Nº
              </th>
              <th>
                Código
              </th>
              <th>
                Rude
              </th>
              <th>
                 Apellido Paterno
              </th>
              <th>
                Apellido Materno
              </th>
              <th>
                Nombres
              </th>
              <th>
                Pais Nac.
              </th>
              <th>
                Depto. Nac.
              </th>
              <th>
                Prov. Nac
              </th>
              <th>
                Loc. Nac.
              </th>
              <th>
                Cert. Nac. Oficialia
              </th>
              <th>
                Cert. Nac. Libro
              </th>
              <th>
                Cert. Nac. Partida
              </th>
              <th>
                Cert. Nac. Folio
              </th>
              <th>
                Fecha de Nac. (mm/dd/aaaa)
              </th>
              <th>
                Nº C.I.
              </th>
              <th>
                C.I. Complemento
              </th>
              <th>
                C.I. Expedido
              </th>
              <th>
                Género (M/F)
              </th>
              <th>
                Presenta Discapacidad (SI/NO)
              </th>
              <th>
                Nº Registro Discapacidad
              </th>
              <th>
                Direccion Departamento
              </th>
              <th>
                Direccion Provincia
              </th>
              <th>
                Direccion Municipio
              </th>
              <th>
                Direccion Localidad
              </th>
              <th>
                Dirección (Zona/Villa)
              </th>
              <th>
                Dirección (Avenida/Calle)
              </th>
              <th>
                Dirección (Número Vivienda)
              </th>
              <th>
                Telf. Fijo
              </th>
              <th>
                Telf. Movil
              </th>
              <th>
                Idioma Infancia
              </th>
              <th>
                Idiomas Frecuentes
              </th>
              <th>
                Existencia Centro Salud (SI/NO)
              </th>
              <th>
                Tiene Seguro de Salud (SI/NO)
              </th>
              <th>
                Tiene Agua por Red (SI/NO)
              </th>
              <th>
                Tiene Baño (SI/NO)
              </th>
              <th>
                Tiene Alcantarillado (SI/NO)
              </th>
              <th>
                Tiene Energia Electrica (SI/NO)
              </th>
              <th>
                Tiene Servicio Recojo Basura (SI/NO)
              </th>
              <th>
                Estudiante Trabajo (SI/NO)
              </th>
              <th>
                Recibio Pago Trabajo (SI/NO)
              </th>
              <th>
                Abandono Escuela (SI/NO)
              </th>
              <th>
                U. E. Anterior
              </th>
              <th>
                Codigo S.I.E.
              </th>
              <th>
                Vive Con
              </th>
              <th>
                Correo Electronico
              </th>
              <th>
                Usuario
              </th>
              <th>
                Clave
              </th>
              <th>
                Observación
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let estudiante of importacion.estudiantes; let i=index;">
              <td>{{i+1}}</td>
              <td>{{estudiante.codigo}}</td>
              <td>{{estudiante.codigo_rude}}</td>
              <td>{{estudiante.persona.apellido_paterno}}</td>
              <td>{{estudiante.persona.apellido_materno}}</td>
              <td>{{estudiante.persona.nombres}}</td>
              <td>{{estudiante.persona.pais_nacimiento}}</td>
              <td>{{estudiante.persona.ciudad_nacimiento}}</td>
              <td>{{estudiante.persona.provincia_nacimiento}}</td>
              <td>{{estudiante.persona.localidad_nacimiento}}</td>
              <td>{{estudiante.persona.certificado_nacimiento_oficialia}}</td>
              <td>{{estudiante.persona.certificado_nacimiento_libro}}</td>
              <td>{{estudiante.persona.certificado_nacimiento_partida}}</td>
              <td>{{estudiante.persona.certificado_nacimiento_folio}}</td>
              <td *ngIf="estudiante.persona.fecha_nacimiento!=null">{{estudiante.persona.fecha_nacimiento | date:"dd/MM/yyyy"}}</td><td *ngIf="estudiante.persona.fecha_nacimiento==null"></td>
              <td>{{estudiante.persona.ci}}</td>
              <td>{{estudiante.persona.ci_complemento}}</td>
              <td>{{estudiante.persona.ci_expedido}}</td>
              <td>{{estudiante.persona.genero?estudiante.persona.genero.nombre:''}}</td>
              <td>{{estudiante.presenta_discapacidad?"SI":"NO"}}</td>
              <td>{{estudiante.numero_registro_discapacidad}}</td>
              <td>{{estudiante.persona.direccion_ciudad}}</td>
              <td>{{estudiante.persona.direccion_municipio}}</td>
              <td>{{estudiante.persona.direccion_provincia}}</td>
              <td>{{estudiante.persona.direccion_localidad}}</td>
              <td>{{estudiante.persona.direccion_zona}}</td>
              <td>{{estudiante.persona.direccion}}</td>
              <td>{{estudiante.persona.direccion_numero}}</td>
              <td>{{estudiante.persona.telefono}}</td>
              <td>{{estudiante.persona.telefono_movil}}</td>
              <td>{{estudiante.idioma_infancia}}</td>
              <td>{{estudiante.idiomas_frecuentes}}</td>
              <td>{{estudiante.existe_centro_salud_comunidad?"SI":"NO"}}</td>
              <td>{{estudiante.tiene_seguro_salud?"SI":"NO"}}</td>
              <td>{{estudiante.tiene_acceso_agua_red?"SI":"NO"}}</td>
              <td>{{estudiante.tiene_bano_vivienda?"SI":"NO"}}</td>
              <td>{{estudiante.tiene_alcantarillado?"SI":"NO"}}</td>
              <td>{{estudiante.tiene_energia_electrica?"SI":"NO"}}</td>
              <td>{{estudiante.tiene_servicio_recojo_basura?"SI":"NO"}}</td>
              <td>{{estudiante.gestion_pasada_trabajo?"SI":"NO"}}</td>
              <td>{{estudiante.recibio_pago_trabajo?"SI":"NO"}}</td>
              <td>{{estudiante.abandono_escuela?"SI":"NO"}}</td>
              <td>{{estudiante.inscripcion.nombre_ultima_escuela}}</td>
              <td>{{estudiante.inscripcion.codigo_sie_ultima_escuela}}</td>
              <td>{{estudiante.vive_con.nombre}}</td>
              <td>{{estudiante.persona.correo_electronico}}</td>
              <td>{{estudiante.persona.usuario.nombre_usuario}}</td>
              <td>{{estudiante.persona.usuario.clave}}</td>
              <td>{{estudiante.inscripcion.observacion}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
	</div>
</ng-template>

<ng-template #importacion_tutores let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Tutores a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button [disabled]="importacion.tutores.length==0" class="btn btn-primary" type="button" (click)="guardarImportacionTutores()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-success" type="button" (click)="descargarExcelImportacionTutores()">
				<i class="ace-icon fa fa-download red2 align-top bigger-110"></i>
			</button>&nbsp;
      <button (click)="clickBotonSubir('subir-excel-tutores')" class="fileUpload btn btn-primary" aria-label="Export" type="button" title="Subir excel tutores" aria-expanded="false">
        <i class="fa fa-upload align-top bigger-110 fileUpload-button"></i>
        <input id="subir-excel-tutores" (change)="subirArchivoExcelImportacionTutores($event)" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".xlsx">
      </button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
    <div class="row">
      <div class="col-12 col-12">
        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
          <thead fix-head class="table-head-color sticky-nav">
            <tr>
              <th>
                Nº
              </th>
              <th>
                Códigos Estudiantes (Separado por ',')
              </th>
              <th>
                 Apellido Paterno
              </th>
              <th>
                Apellido Materno
              </th>
              <th>
                Nombres
              </th>
              <th>
                Nº C.I.
              </th>
              <th>
                Parentesco
              </th>
              <th>
                Profesion
              </th>
              <th>
                Telefono Fijo
              </th>
              <th>
                Telefono Movil
              </th>
              <th>
                Correo Electronico
              </th>
              <th>
                Direccion Domicilio
              </th>
              <th>
                Direccion Trabajo
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let tutor of importacion.tutores; let i=index;">
              <td>{{i+1}}</td>
              <td>{{tutor.codigos_estudiantes}}</td>
              <td>{{tutor.persona.apellido_paterno}}</td>
              <td>{{tutor.persona.apellido_materno}}</td>
              <td>{{tutor.persona.nombres}}</td>
              <td>{{tutor.persona.ci}}</td>
              <td>{{tutor.parentesco?tutor.parentesco.nombre:''}}</td>
              <td>{{tutor.profesion}}</td>
              <td>{{tutor.persona.telefono}}</td>
              <td>{{tutor.persona.telefono_fijo}}</td>
              <td>{{tutor.persona.correo_electronico}}</td>
              <td>{{tutor.persona.direccion}}</td>
              <td>{{tutor.direccion_trabajo}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
	</div>
</ng-template>

<ng-template #archivo_banco_estudiantes let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Generación Archivo Banco</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button [disabled]="importacion.estudiantes.length==0" class="btn btn-primary" type="button" (click)="guardarImportacionEstudiantes()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
    <div class="row">
      <div class="col-1">Gestión Escolar </div>
      <div class="col-1">
          <select [compareWith]="compararObjectos" class="form-control" name="gestion" [(ngModel)]="filter.gestion" (change)="obtenerConceptosPagoColegio(filter.gestion)">
            <option [ngValue]="null" [selected]="true">Seleccionar</option>
              <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones_escolares">{{gestion.nombre}}</option>
          </select>
      </div>
      <div class="col-1"><label>Concepto de Pago </label></div>
      <div class="col-2">
          <select [compareWith]="compararObjectos" class="form-control" name="gestion" [(ngModel)]="filter.concepto_pago">
            <option [ngValue]="null" [selected]="true">Seleccionar</option>
              <option [ngValue]="concepto_pago" *ngFor="let concepto_pago of filter.conceptos_pagos">{{concepto_pago.concepto.nombre}}</option>
          </select>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-4"><label>Estudiante </label></div>
          <div class="col-8">
            <input id="nom_est" name="bus_est"
            [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerEstudiante($event)"
            type="text" class="form-control" [(ngModel)]="busqueda_estudiante" 
            [ngbTypeahead]="buscarEstudiante" placeholder="Buscar estudiante" autocomplete="off"/>
            <ng-template #rt let-r="result" let-t="term">
              <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
              <ngb-highlight [result]="r.codigo+' - '+r.apellido_paterno+' '+r.apellido_materno+' '+r.nombres" [term]="t"></ngb-highlight>
            </ng-template>
          </div>
        </div>
        <div class="row"  *ngFor="let estudiante of estudiantes_archivo_banco;">
          <div class="col-10">
            <span class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{estudiante.apellido_paterno}} {{estudiante.apellido_materno}} {{estudiante.nombres}}</span>
          </div>
          <div class="col-2">
            <a class="card-toolbar-btn text-danger-m1" href="javascript:void(0)" (click)="eliminarEstudianteBanco(estudiante)">
              <i class="fa fa-trash bigger-130 red2"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-1"><label>Opción </label></div>
      <div class="col-2">
          <select [compareWith]="compararObjectos" class="form-control" name="curso" [(ngModel)]="filter.opcion">
            <option [ngValue]="null" [selected]="true">Seleccionar</option>
              <option [ngValue]="opcion" *ngFor="let opcion of filter.opciones_archivo">{{opcion.codigo}}-{{opcion.descripcion}}</option>
          </select>
      </div>
      <div class="col-1">
        <button [disabled]="!filter.concepto_pago || !filter.gestion" class="btn btn-primary" type="button" (click)="buscarConceptosPagosEstudiantesBanco()">
          <i class="fa fa-search align-top bigger-110"></i>
        </button>&nbsp;
        <button [disabled]="!filter.opcion" class="btn btn-success" type="button" (click)="descargarEstudiantesBanco()">
          <i class="fa fa-download align-top bigger-110"></i>
        </button>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12 col-12">
        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
          <thead fix-head class="table-head-color sticky-nav">
            <tr>
              <th>
                Nº
              </th>
              <th>
                Código
              </th>
              <th>
                 Apellido Paterno
              </th>
              <th>
                Apellido Materno
              </th>
              <th>
                Nombres
              </th>
              <th>
                Código de Curso
              </th>
              <th>
                Código Concepto de Pago
              </th>
              <th>
                Concepto de Pago
              </th>
              <th>
                Importe
              </th>
              <th>
                Número Cuotas
              </th>
              <th>
                Razón Social
              </th>
              <th>
                NIT
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let dato of conceptos_pagos_estudiantes_banco; let i=index;">
              <td>{{i+1}}</td>
              <td>{{dato.codigo}}</td>
              <td>{{dato.apellido_paterno}}</td>
              <td>{{dato.apellido_materno}}</td>
              <td>{{dato.nombres}}</td>
              <td>{{dato.codigo_curso}}</td>
              <td>{{dato.codigo_concepto_pago}}</td>
              <td>{{dato.nombre_concepto}}</td>
              <ng-container *ngIf="filter.opcion.codigo=='1' && filter.opcion.version=='1'">
                <td>{{dato.saldo}}</td>
                <td>1</td>
              </ng-container>
              <ng-container *ngIf="filter.opcion.version!='1'">
                <td>{{dato.importe}}</td>
                <td>{{dato.cuotas}}</td>
              </ng-container>
              <td>{{dato.razon_social}}</td>
              <td>{{dato.nit}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
	</div>
</ng-template>