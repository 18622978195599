<div class="modal-header card-header">
    <div class="col-12">
        <div class="row">
            <div class="col-8">
                <span class="text-blue text-125">Datos de Conversion</span>
            </div>
            <div class="col-4">
                <button  id="venta_guardar" type="button" class="btn btn-primary" (click)="guardarConversion()">
                    <i class="fa fa-save align-top bigger-125"></i>
                </button>&nbsp;
                <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="cerrarComponente()" >
                    <i class="fa fa-times red2 align-top bigger-125"></i>
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal-body card-body">
    <div class="widget-body">
        <div class="row">
            <div class="col-md-4 col-xs-12 no-padding">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <div class="row">
                                <div class="col-6">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Fecha</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="input-group">
                                            <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                                    name="dp" [(ngModel)]="movimiento_conversion.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-xs-6">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Acción</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [compareWith]="compararObjectos" id="conversion" name="conversion" class="form-control" [(ngModel)]="conversion_movimiento_inventario" (change)="establecerAccion()">
                                            <option [ngValue]="conversion" *ngFor="let conversion of converiones_movimiento_inventario">{{conversion.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="conversion_movimiento_inventario.nombre_corto!=global_variable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_TRASPASO">
                                <hr>
                                <div class="row">
                                    <div class="col-6" *ngIf="usuario.empresa.usar_sucursales">
                                        <div class="col-12 col-sm-12 col-xs-12">
                                            <label>Sucursal</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-12 col-sm-12 col-xs-12">
                                            <select [disabled]="inhabilitar_sucursal" [compareWith]="compararObjectos" id="sucursal" name="sucursal" class="form-control" 
                                            [(ngModel)]="movimiento_conversion.sucursal" (change)="obtenerAlmacenesActividades(movimiento_conversion.sucursal.id)">
                                                <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6" *ngIf="usuario.empresa.usar_almacenes || usuario.empresa.usar_almacenes_empleado">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Almacen</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [disabled]="inhabilitar_almacen" [compareWith]="compararObjectos" id="almacen" name="almacen" class="form-control" 
                                            [(ngModel)]="movimiento_conversion.almacen" >
                                                <option [ngValue]="almacen" *ngFor="let almacen of almacenes">{{almacen.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="conversion_movimiento_inventario.nombre_corto==global_variable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_TRASPASO">
                                <hr>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Sucursal Origen</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="sucursal_origen" name="sucursal_origen" class="form-control" 
                                            [(ngModel)]="movimiento_conversion.sucursal" (change)="obtenerAlmacenesSucursalesDiferente(movimiento_conversion.sucursal.id)">
                                                <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Sucursal Destino</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="sucursal_destino" name="sucursal_destino" class="form-control" 
                                            [(ngModel)]="movimiento_conversion.sucursalDestino" (change)="obtenerAlmacenesDiferente(movimiento_conversion.sucursalDestino)">
                                                <option [ngValue]="null" [selected]="true">Seleccionar</option>
                                                <option [ngValue]="sucursalDiferente" *ngFor="let sucursalDiferente of sucursalesDiferente">{{sucursalDiferente.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Almacen Origen</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="almacen_origen" name="almacen_origen" class="form-control" 
                                            [(ngModel)]="movimiento_conversion.almacen" (change)="obtenerAlmacenesDiferente(movimiento_conversion.sucursalDestino)">
                                                <option [ngValue]="almacen" *ngFor="let almacen of almacenes">{{almacen.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Almacen Destino</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="almacen_destino" name="almacen_destino" class="form-control" 
                                            [(ngModel)]="movimiento_conversion.almacenDestino" >
                                            <option [ngValue]="null" [selected]="true">Seleccionar</option>
                                                <option [ngValue]="almacenDiferente" *ngFor="let almacenDiferente of almacenesDiferente">{{almacenDiferente.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <hr>
                            <div class="row">
                                
                                <div class="col-7">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>Observación</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" autocomplete="off" id="venta_observacion" name="observacion" type="text" [(ngModel)]="movimiento_conversion.observacion" placeholder="Observacion">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-xs-12 p-0" *ngIf="conversion_movimiento_inventario.nombre_corto==global_variable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_TRASPASO">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>PRODUCTO</label>
                                        </th>
                                        <th>
                                            <label>CÓD. ITEM</label>
                                        </th>
                                        <th>
                                            <label>UNID. MED.</label>
                                        </th>
                                        <th *ngIf="usuario.empresa.usar_vencimientos && detalleMovimientoInventario.producto.activar_vencimiento">
                                            <label>VENCIMIENTO</label>
                                        </th>
                                        <th>
                                            <label>INVENTARIO DISPONIBLE</label>
                                        </th>
                                        <th>
                                            <label>CANT. (u)</label>
                                        </th>
                                        <th>
                                            <label>ACC.</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td id="gallery">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <input autocomplete="off"
                                                    [disabled]="!movimiento_conversion.almacen || !movimiento_conversion.almacenDestino || !movimiento_conversion.sucursalDestino || detalleMovimientoInventario.producto.id" id="id_producto" [(ngModel)]="producto_busqueda"
                                                    [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                                                    [inputFormatter]="formatterProducto"  (selectItem)="establecerProductoTraspaso($event)"
                                                    type="text" class="form-control"/>
                                                    <ng-template #rtp let-r="result" let-t="term">
                                                        <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                        <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                                    </ng-template>
                                                    <a href="javascript:void(0)" *ngIf="detalleMovimientoInventario.producto && detalleMovimientoInventario.producto.id" (click)="reiniciarDetalleMovimientoInventario()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger">
                                                        <i class="ace-icon fa fa-trash bigger-130"></i>
                                                    </a>
                                                    <hr>                                                 
                                                </div>
                                                <div class="col-md-3" *ngIf="detalleMovimientoInventario.producto.id">
                                                    <div class="imagen-producto-vista clearfix" *ngIf="detalleMovimientoInventario.producto && detalleMovimientoInventario.producto.id">
                                                        <a href="{{rest_server+detalleMovimientoInventario.producto.imagen}}" data-rel="colorbox" title="{{detalleMovimientoInventario.producto.nombre}}">
                                                            <img alt="sin imagen" src="{{rest_server+detalleMovimientoInventario.producto.imagen}}" width="30" height="30" class="d-zoom-3" data-size="1000x700" />
                                                        </a>
                                                    </div> 
                                                </div>
                                                <div class="col-md-9" *ngIf="detalleMovimientoInventario.producto.id && aplicacion_producto.puede_modificar">
                                                    <label *ngIf="usuario.empresa.usar_inventario">¿Activar Inventario?
                                                        <input (change)="actualizarAtributoProducto(detalleMovimientoInventario.producto,'activar_inventario',detalleMovimientoInventario.producto.activar_inventario)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalleMovimientoInventario.producto.activar_inventario"/>
                                                    </label>
                                                    <label *ngIf="usuario.empresa.usar_vencimientos">¿Activar Vencimiento?
                                                        <input (change)="actualizarAtributoProducto(detalleMovimientoInventario.producto,'activar_vencimiento',detalleMovimientoInventario.producto.activar_vencimiento)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalleMovimientoInventario.producto.activar_vencimiento"/>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.producto.codigo}}
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.producto.unidad_medida}}
                                        </td>
                                        <td *ngIf="detalleMovimientoInventario.producto && detalleMovimientoInventario.producto.id && usuario.empresa.usar_vencimientos && detalleMovimientoInventario.producto.activar_vencimiento">
                                            <select *ngIf="detalleMovimientoInventario.producto.activar_inventario" name="fechaVencimiento" style="width:95px;" id="venta_fechaVencimiento" [compareWith]="compararObjectos" class="form-control" [(ngModel)]="detalleMovimientoInventario.inventarioProducto" (change)="actualizarInventarioDisponibleFechaVencimiento()">
                                                <option [ngValue]="inventario" *ngFor="let inventario of inventariosDisponibleProducto">{{'FV:'+inventario.fechaVencimientoTexto}}</option>
                                            </select>
                                            <label>Lote: {{detalleMovimientoInventario.lote}}</label>
                                        </td>
                                        <td>
                                            <div class="c100 p{{porcentaje}} small {{color}}" *ngIf="detalleMovimientoInventario.producto.activar_inventario">
                                                <span id="CantProd" style="color:black !important;">{{detalleMovimientoInventario.inventario_disponible}}</span>
                                                <div class="slice">
                                                    <div class="bar"></div>
                                                    <div class="fill"></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <input (keypress)="interceptarTecla($event,'agregar_detalle_venta',false)" id="cantidad" name="cantidad" step="0.001" min="0.1" max="{{detalleMovimientoInventario.inventario_disponible}}" class="form-control" [(ngModel)]="detalleMovimientoInventario.cantidad" type="number" />
                                        </td>
                                        <td>
                                            <button id="agregar_detalle_venta" type="button" [disabled]="!detalleMovimientoInventario.producto.id"  class="btn btn-success btn-sm" (click)="agregarDetalleMovimientoInventario(detalleMovimientoInventario)">
                                                <i class="ace-icon fa fa-plus bigger-110"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="widget-box">
                    <div class="card bgc-dark" *ngIf="movimiento_conversion.detallesMovimientoInventario.length>0">
                        <div class="card-header card-header-sm">
                            <h5 class="card-title text-110 text-white font-light pt-1">DETALLE DE MOVIMIENTO DE INVENTARIO</h5>
                        </div>
                        <div class="card-body bg-white p-0" *ngIf="movimiento_conversion.detallesMovimientoInventario.length>0">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th>
                                            <label>NOMBRE</label>
                                        </th>
                                        <th>
                                            <label>CÓD. ITEM</label>
                                        </th>
                                        <th>
                                            <label>UNID. MED.</label>
                                        </th>
                                        <th *ngIf="usuario.empresa.usar_vencimientos">
                                            <label>VENCIMIENTO</label>
                                        </th>
                                        <th *ngIf="usuario.empresa.usar_vencimientos">
                                            <label>LOTE</label>
                                        </th>
                                        <th>
                                            <label>C.U. (Bs/u)</label>
                                        </th>
                                        <th>
                                            <label>CANT. (u)</label>
                                        </th>
                                        <th data-field="tools">
                                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                            <div class="fht-cell"></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detalleMovimientoInventario of movimiento_conversion.detallesMovimientoInventario; let i=index;">
                                        <td>
                                            {{i+1}}
                                        </td>
                                        <td >
                                            {{detalleMovimientoInventario.producto.nombre}}
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.producto.codigo}}
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.producto.unidad_medida}}
                                        </td>
                                        <td *ngIf="usuario.empresa.usar_vencimientos">
                                            {{detalleMovimientoInventario.fecha_vencimiento | date:'dd/MM/yyyy'}}
                                        </td>
                                        <td *ngIf="usuario.empresa.usar_vencimientos">
                                            {{detalleMovimientoInventario.lote}}
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.costo_unitario}}
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.cantidad}}
                                        </td>
                                        <td>
                                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarDetalleMovimiento(detalleMovimientoInventario)">
                                                <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="conversion_movimiento_inventario.nombre_corto==global_variable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_MIGRACION">
                <div class="col-8">
                    <div class="row">
                        <div class="col-12">
                            <fieldset>
                                <legend>Producto Fuente</legend>
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <input autocomplete="off" [disabled]="(movimiento_conversion.producto_fuente && movimiento_conversion.producto_fuente.id)" [(ngModel)]="producto_fuente_busqueda"
                                            [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="pfrtp" name="producto_fuente_busqueda"
                                            [inputFormatter]="formatterProducto"  (selectItem)="establecerProductoFuente($event)"
                                            type="text" class="form-control"/>
                                        <ng-template #pfrtp let-r="result" let-t="term">
                                            <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                            <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                        </ng-template>
        
                                        <a href="javascript:void(0)" *ngIf="movimiento_conversion.producto_fuente && movimiento_conversion.producto_fuente.id" (click)="reiniciarProductoFuente()" class="card-toolbar-btn text-danger-m1">
                                            <i class="ace-icon fa fa-trash bigger-130"></i>
                                        </a>
                                    </div>
                                    <div class="col-5">
                                        <label>Movimiento: {{movimiento_conversion.clase.nombre}}</label>
                                    </div>
                                    <div class="col-1">
                                        <i class="fas fa-sign-out-alt text-danger"></i>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-12">
                            <fieldset>
                                <legend>Producto Destino</legend>
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <input autocomplete="off" [disabled]="(movimiento_conversion.producto_destino && movimiento_conversion.producto_destino.id)" [(ngModel)]="producto_destino_busqueda"
                                            [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_destino_busqueda"
                                            [inputFormatter]="formatterProducto"  (selectItem)="establecerProductoDestino($event)"
                                            type="text" class="form-control"/>
                                        <ng-template #rtp let-r="result" let-t="term">
                                            <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                            <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                        </ng-template>
        
                                        <a href="javascript:void(0)" *ngIf="movimiento_conversion.producto_destino && movimiento_conversion.producto_destino.id" (click)="reiniciarProductoDestino()" class="card-toolbar-btn text-danger-m1">
                                            <i class="ace-icon fa fa-trash bigger-130"></i>
                                        </a>
                                    </div>
                                    <div class="col-5">
                                        <label>Movimiento: {{movimiento_conversion.movimiento_destino.clase.nombre}}</label>
                                    </div>
                                    <div class="col-1">
                                        <i class="fas fa-sign-in-alt text-success"></i>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-4">
                            <label>Cantidad: </label>
                            <input [(ngModel)]="movimiento_conversion.cantidad" min="1" type="number" step="0.001" class="form-control" />
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="conversion_movimiento_inventario.nombre_corto==global_variable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_PRODUCCION">
                <div class="col-8">
                    <div class="row">
                        <div class="col-12">
                            <fieldset>
                                <legend>Producto</legend>
                                <div class="row">
                                    <div class="col-7">
                                        <input autocomplete="off" [disabled]="(movimiento_conversion.producto_fuente && movimiento_conversion.producto_fuente.id)" [(ngModel)]="producto_fuente_busqueda"
                                            [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="pfrtp" name="producto_fuente_busqueda"
                                            [inputFormatter]="formatterProducto"  (selectItem)="establecerProductoFuente($event)"
                                            type="text" class="form-control"/>
                                        <ng-template #pfrtp let-r="result" let-t="term">
                                            <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                            <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                        </ng-template>
        
                                        <a href="javascript:void(0)" *ngIf="movimiento_conversion.producto_fuente && movimiento_conversion.producto_fuente.id" (click)="reiniciarProductoFuente()" class="card-toolbar-btn text-danger-m1">
                                            <i class="ace-icon fa fa-trash bigger-130"></i>
                                        </a>
                                    </div>
                                    <div class="col-4">
                                        <label>Movimiento: {{movimiento_conversion.clase.nombre}}</label>
                                    </div>
                                    <div class="col-1">
                                        <i class="fas fa-sign-in-alt text-success"></i>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-12">
                            <label>Cantidad: </label>
                            <input [(ngModel)]="movimiento_conversion.cantidad" min="1" type="number" step="0.001" class="form-control col-4" />
                        </div>&nbsp;
                        <hr>
                        <div class="col-12" *ngIf="movimiento_conversion.producto_fuente">
                            <fieldset>
                                <div class="row">
                                    <div class="col-7">
                                        <div class="widget-box">
                                            <div class="card bgc-dark">
                                                <div class="card-header card-header-sm">
                                                    <h5 class="card-title text-110 text-white font-light pt-1">Receta</h5>
                                                </div>
                                                <div class="card-body bg-white p-0">
                                                    <table class="table table-striped table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <label>Nº</label>
                                                                </th>
                                                                <th>
                                                                    <label>Producto</label>
                                                                </th>
                                                                <th>
                                                                    <label>Código</label>
                                                                </th>
                                                                <th>
                                                                    <label>Unid. Med.</label>
                                                                </th>
                                                                <th>
                                                                    <label>Cant.</label>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let producto_base of movimiento_conversion.producto_fuente.productosBase; let i=index;">
                                                                <td>
                                                                    {{i+1}}
                                                                </td>
                                                                <td >
                                                                    {{producto_base.productoBase.nombre}}
                                                                </td>
                                                                <td>
                                                                    {{producto_base.productoBase.codigo}}
                                                                </td>
                                                                <td>
                                                                    {{producto_base.productoBase.unidad_medida}}
                                                                </td>
                                                                <td>
                                                                    {{producto_base.formulacion}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <label>Movimiento: {{movimiento_conversion.movimiento_destino.clase.nombre}}</label>
                                    </div>
                                    <div class="col-1">
                                        <i class="fas fa-sign-out-alt text-danger"></i>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="conversion_movimiento_inventario.nombre_corto==global_variable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_MEZCLA">
                <div class="col-8">
                    <div class="row">
                        <div class="col-12 p-0">
                            <div class="widget-box">
                                <div class="widget-body">
                                    <div class="widget-main">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <label>PRODUCTO</label>
                                                    </th>
                                                    <th>
                                                        <label>CÓD. ITEM</label>
                                                    </th>
                                                    <th>
                                                        <label>UNID. MED.</label>
                                                    </th>
                                                    <th *ngIf="usuario.empresa.usar_vencimientos && detalleMovimientoInventario.producto.activar_vencimiento">
                                                        <label>VENCIMIENTO</label>
                                                    </th>
                                                    <th *ngIf="usuario.empresa.usar_vencimientos && detalleMovimientoInventario.producto.activar_vencimiento">
                                                        <label>LOTE</label>
                                                    </th>
                                                    <th>
                                                        <label>INVENTARIO DISPONIBLE</label>
                                                    </th>
                                                    <th>
                                                        <label>C.U. (Bs/u)</label>
                                                    </th>
                                                    <th>
                                                        <label>CANT. (u)</label>
                                                    </th>
                                                    <th>
                                                        <label>ACC.</label>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td id="gallery">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input autocomplete="off"
                                                                [disabled]="!movimiento_conversion.almacen || detalleMovimientoInventario.producto.id" id="id_producto" [(ngModel)]="producto_busqueda"
                                                                [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                                                                [inputFormatter]="formatterProducto"  (selectItem)="establecerProductoTraspaso($event)"
                                                                type="text" class="form-control"/>
                                                                <ng-template #rtp let-r="result" let-t="term">
                                                                    <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                                    <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                                                </ng-template>
                                                                <a href="javascript:void(0)" *ngIf="detalleMovimientoInventario.producto && detalleMovimientoInventario.producto.id" (click)="reiniciarDetalleMovimientoInventario()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger">
                                                                    <i class="ace-icon fa fa-trash bigger-130"></i>
                                                                </a>
                                                                <hr>                                                 
                                                            </div>
                                                            <div class="col-md-3" *ngIf="detalleMovimientoInventario.producto.id">
                                                                <div class="imagen-producto-vista clearfix" *ngIf="detalleMovimientoInventario.producto && detalleMovimientoInventario.producto.id">
                                                                    <a href="{{rest_server+detalleMovimientoInventario.producto.imagen}}" data-rel="colorbox" title="{{detalleMovimientoInventario.producto.nombre}}">
                                                                        <img alt="sin imagen" src="{{rest_server+detalleMovimientoInventario.producto.imagen}}" width="30" height="30" class="d-zoom-3" data-size="1000x700" />
                                                                    </a>
                                                                </div> 
                                                            </div>
                                                            <div class="col-md-9" *ngIf="detalleMovimientoInventario.producto.id && aplicacion_producto.puede_modificar">
                                                                <label *ngIf="usuario.empresa.usar_inventario">¿Activar Inventario?
                                                                    <input (change)="actualizarAtributoProducto(detalleMovimientoInventario.producto,'activar_inventario',detalleMovimientoInventario.producto.activar_inventario)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalleMovimientoInventario.producto.activar_inventario"/>
                                                                </label>
                                                                <label *ngIf="usuario.empresa.usar_vencimientos">¿Activar Vencimiento?
                                                                    <input (change)="actualizarAtributoProducto(detalleMovimientoInventario.producto,'activar_vencimiento',detalleMovimientoInventario.producto.activar_vencimiento)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalleMovimientoInventario.producto.activar_vencimiento"/>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{detalleMovimientoInventario.producto.codigo}}
                                                    </td>
                                                    <td>
                                                        {{detalleMovimientoInventario.producto.unidad_medida}}
                                                    </td>
                                                    <td *ngIf="usuario.empresa.usar_vencimientos && detalleMovimientoInventario.producto.activar_vencimiento">
                                                        <div class="input-group">
                                                            <input id="mov_fecha_vencimiento" name="mov_fecha_vencimiento" class="form-control" placeholder="dd/mm/yyyy"
                                                                    name="dp" [(ngModel)]="detalleMovimientoInventario.fechaVencimientoTexto" ngbDatepicker #dv="ngbDatepicker"
                                                                    (keypress)="interceptarTecla($event,'mov_lote',true)">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary calendar" (click)="dv.toggle()" type="button"></button>
                                                            </div>
                                                        </div> 
                                                    </td>
                                                    <td *ngIf="usuario.empresa.usar_vencimientos && detalleMovimientoInventario.producto.activar_vencimiento">
                                                        <input id="mov_lote" (keypress)="interceptarTecla($event,'id_costo_unitario',true)" type="text" [(ngModel)]="detalleMovimientoInventario.lote" class="form-control">
                                                    </td>
                                                    <td>
                                                        <div class="c100 p{{porcentaje}} small {{color}}" *ngIf="detalleMovimientoInventario.producto.activar_inventario">
                                                            <span id="CantProd" style="color:black !important;">{{detalleMovimientoInventario.inventario_disponible}}</span>
                                                            <div class="slice">
                                                                <div class="bar"></div>
                                                                <div class="fill"></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <input (keypress)="interceptarTecla($event,'cantidad',true)" id="id_costo_unitario" name="costo_unitario" step="0.001" min="0.1" (keypress)="interceptarTecla($event,'cantidad',true)" class="form-control" [(ngModel)]="detalleMovimientoInventario.costo_unitario" type="number" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input (keypress)="interceptarTecla($event,'agregar_detalle_mezcla',false)" id="cantidad" name="cantidad" step="0.001" min="0.1" max="{{detalleMovimientoInventario.inventario_disponible}}" class="form-control" [(ngModel)]="detalleMovimientoInventario.cantidad" type="number" />
                                                    </td>
                                                    <td>
                                                        <button id="agregar_detalle_mezcla" type="button" [disabled]="!detalleMovimientoInventario.producto.id"  class="btn btn-success btn-sm" (click)="agregarDetalleMovimientoMezcla(detalleMovimientoInventario)">
                                                            <i class="ace-icon fa fa-plus bigger-110"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="widget-box">
                                <div class="card bgc-dark" *ngIf="movimiento_conversion.detallesMovimientoInventario.length>0">
                                    <div class="card-header card-header-sm">
                                        <h5 class="card-title text-110 text-white font-light pt-1">DETALLE DE MOVIMIENTO DE INVENTARIO</h5>
                                    </div>
                                    <div class="card-body bg-white p-0" *ngIf="movimiento_conversion.detallesMovimientoInventario.length>0">
                                        <table class="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <label>Nº</label>
                                                    </th>
                                                    <th>
                                                        <label>NOMBRE</label>
                                                    </th>
                                                    <th>
                                                        <label>CÓD. ITEM</label>
                                                    </th>
                                                    <th>
                                                        <label>UNID. MED.</label>
                                                    </th>
                                                    <th *ngIf="usuario.empresa.usar_vencimientos">
                                                        <label>VENCIMIENTO</label>
                                                    </th>
                                                    <th *ngIf="usuario.empresa.usar_vencimientos">
                                                        <label>LOTE</label>
                                                    </th>
                                                    <th>
                                                        <label>C.U. (Bs/u)</label>
                                                    </th>
                                                    <th>
                                                        <label>CANT. (u)</label>
                                                    </th>
                                                    <th data-field="tools">
                                                        <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                                        <div class="fht-cell"></div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let detalleMovimientoInventario of movimiento_conversion.detallesMovimientoInventario; let i=index;">
                                                    <td>
                                                        {{i+1}}
                                                    </td>
                                                    <td >
                                                        {{detalleMovimientoInventario.producto.nombre}}
                                                    </td>
                                                    <td>
                                                        {{detalleMovimientoInventario.producto.codigo}}
                                                    </td>
                                                    <td>
                                                        {{detalleMovimientoInventario.producto.unidad_medida}}
                                                    </td>
                                                    <td *ngIf="usuario.empresa.usar_vencimientos">
                                                        {{detalleMovimientoInventario.fecha_vencimiento | date:'dd/MM/yyyy'}}
                                                    </td>
                                                    <td *ngIf="usuario.empresa.usar_vencimientos">
                                                        {{detalleMovimientoInventario.lote}}
                                                    </td>
                                                    <td>
                                                        {{detalleMovimientoInventario.costo_unitario}}
                                                    </td>
                                                    <td>
                                                        {{detalleMovimientoInventario.cantidad}}
                                                    </td>
                                                    <td>
                                                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarDetalleMovimiento(detalleMovimientoInventario)">
                                                            <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        
                                                    </td>
                                                    <td>
                                                        <b>TOTALES</b>
                                                    </td>
                                                    <td>
                                                        
                                                    </td>
                                                    <td >
                                                        
                                                    </td>
                                                    <td>
                                                        
                                                    </td>
                                                    <td>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <fieldset>
                                <legend>Producto Destino</legend>
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <input autocomplete="off" [disabled]="(movimiento_conversion.producto_destino && movimiento_conversion.producto_destino.id)" [(ngModel)]="producto_destino_busqueda"
                                            [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_destino_busqueda"
                                            [inputFormatter]="formatterProducto"  (selectItem)="establecerProductoDestino($event)"
                                            type="text" class="form-control"/>
                                        <ng-template #rtp let-r="result" let-t="term">
                                            <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                            <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                        </ng-template>
        
                                        <a href="javascript:void(0)" *ngIf="movimiento_conversion.producto_destino && movimiento_conversion.producto_destino.id" (click)="reiniciarProductoDestino()" class="card-toolbar-btn text-danger-m1">
                                            <i class="ace-icon fa fa-trash bigger-130"></i>
                                        </a>
                                    </div>
                                    <div class="col-5">
                                        <label>Movimiento: {{movimiento_conversion.movimiento_destino.clase.nombre}}</label>
                                    </div>
                                    <div class="col-1">
                                        <i class="fas fa-sign-in-alt text-success"></i>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-4">
                            <label>Cantidad: </label>
                            <input [disabled]="true" [(ngModel)]="movimiento_conversion.cantidad" min="1" type="number" step="0.001" class="form-control" />
                        </div>
                    </div>
                </div>
            </ng-container>
            
        </div>
    </div>
</div>
  