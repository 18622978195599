<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Datos Venta</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="cerrarModal()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row p-1">
        <div class="col-md-4">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Transacción : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{venta.transaccion.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Cliente : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{venta.cliente.identificacion}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_NIT)}}  : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        {{venta.nit_ci}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}}  : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        {{venta.razon_social}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Num. Documento : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        {{venta.factura}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Fecha : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{venta.fecha | date:"dd/MM/yyyy"}}
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row" *ngIf="usuario.empresa.usar_facturacion">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Autorización : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.autorizacion}}
                    </label>    
                </div>
            </div>

            <div class="row" *ngIf="usuario.empresa.usar_facturacion">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Código de control : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.codigo_control}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="usuario.empresa.usar_sucursales">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Sucursal : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.sucursal.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="usuario.empresa.usar_almacenes && venta.almacen">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Almacen : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.almacen.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Documentos : 
                    </label>    
                </div>
                <div class="col-5">
                    <div class="row">
                    <ng-container *ngFor="let archivo of venta.documentos">
                        <div class="col">
                            <a title="{{archivo}}" class="btn btn-md" [ngClass]="{'btn-purple':(archivo.includes('.zip') || archivo.includes('.rar')),'btn-danger':archivo.includes('.pdf'),'btn-success':(archivo.includes('.xls') || archivo.includes('.xlsx')),'btn-primary':(archivo.includes('.doc') || archivo.includes('.docx') || archivo.includes('.jpg') || archivo.includes('.png') || archivo.includes('.gif') || archivo.includes('.jpeg') || archivo.includes('.mp3') || archivo.includes('.mpeg') || archivo.includes('.mp4'))}" target="_blank" href="{{rest_server+archivo}}">
                                <i class="fas" [ngClass]="{'fa-file-archive':(archivo.includes('.zip') || archivo.includes('.rar')),'fa-file-pdf':archivo.includes('.pdf'),'fa-file-excel':(archivo.includes('.xls') || archivo.includes('.xlsx')),'fa-file-word':(archivo.includes('.doc') || archivo.includes('.docx')),'fa-file-image':(archivo.includes('.jpg') || archivo.includes('.png') || archivo.includes('.gif') || archivo.includes('.jpeg')),'fa-file-audio':(archivo.includes('.mp3') || archivo.includes('.mpeg')),'fa-file-video':(archivo.includes('.mp4'))}"></i>
                                </a>&nbsp;
                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(venta)">
                                    <i class="fa fa-trash-alt"></i>
                                </a>
                        </div>
                    </ng-container> 
                        <div class="col p-0">
                            <form id="form-documentos-venta" action="/upload" style="min-height: 0px !important; padding: 0px !important;" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1">
                                <div class="fallback d-none">
                                    <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                                </div>
                
                                <div class="dz-default dz-message" style="margin: 0px !important;">
                                    <span class="text-150  text-grey-d2">
                                    <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-1x mt-4"></i>
                                    </span>
                                </div>
                            </form>
                
                                <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                            <div id="template-documentos-venta" class="d-none">
                                <div class="dz-preview dz-file-preview" style="margin: 0px !important; min-height: 50px !important;">
                                    <div class="dz-image">
                                    <img alt="Archivo" data-dz-thumbnail="" />
                                    </div>
                
                                    <div class="dz-details">
                                    <div class="dz-size">
                                        <span data-dz-size=""></span>
                                    </div>
                
                                    <div class="dz-filename">
                                        <span data-dz-name=""></span>
                                    </div>
                                    </div>
                
                                    <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                                    <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                                    </div>
                
                                    <div class="dz-error-message">
                                    <span data-dz-errormessage=""></span>
                                    </div>
                
                                    <div class="dz-success-mark">
                                    <span class="fa-stack fa-lg text-150">
                                        <i class="fa fa-circle fa-stack-2x text-white"></i>
                                        <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                                    </span>
                                    </div>
                
                                    <div class="dz-error-mark">
                                    <span class="fa-stack fa-lg text-150">
                                        <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                                        <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <a *ngIf="venta.subidas.length>0" class="btn radius-1 btn-md btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="guardarDocumentos()">
                                <i class="fa fa-save text-110"></i>
                            </a> 
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Tipo de pago : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.tipoPago.nombre}}
                    </label>    
                </div>
            </div>

            <div class="row" *ngIf="venta.saldo">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Total : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.total}}
                    </label>    
                </div>
            </div>
            <ng-container *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CONT || venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_GFTC || venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CONTADO_TRANSFERENCIA || (venta.tipoPago.nombre && venta.tipoPago.nombre.includes('GIFT')) || venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CODQR || (venta.tipoPago.nombre && venta.tipoPago.nombre.includes('QR'))">
                <div class="row">
                    <div class="col-7 alert alert-info pr-0 mb-0">
                        <label class="text-primary p-0">
                            <i class="fas fa-money-bill-alt"></i> Pagado : 
                        </label>    
                    </div>
                    <div class="col-5">
                        <label>
                            {{venta.pagado}}
                        </label>    
                    </div>
                </div>
                <div class="row">
                    <div class="col-7 alert alert-info pr-0 mb-0">
                        <label class="text-primary p-0">
                            <i class="fas fa-money-bill-alt"></i> Cambio : 
                        </label>    
                    </div>
                    <div class="col-5">
                        <label>
                            {{venta.cambio}}
                        </label>    
                    </div>
                </div>
            </ng-container>
            <div class="row" *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_GFTC || (venta.tipoPago.nombre && venta.tipoPago.nombre.includes('GIFT'))">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Importe GIFT-CARD : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.importe_gift_card}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CODQR || (venta.tipoPago.nombre && venta.tipoPago.nombre.includes('QR'))">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Importe QR : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.importe_qr}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Observación : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.observacion}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="venta.tipoPago.nombre=='CREDITO'">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Días : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.dias_credito}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="venta.tipoPago.nombre=='CREDITO'">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> A Cuenta (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.a_cuenta}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="venta.tipoPago.nombre=='CREDITO'">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Saldo (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.saldo}}
                    </label>    
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="table-fixed-header" *ngIf="venta.detallesVenta.length>0">
        <h5 class="text-blue-d2 mb-3">Detalle de venta</h5>
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>
                        <label>NOMBRE</label>
                    </th>
                    <th>
                        <label>CÓDIGO ITEM</label>
                    </th>
                    <th>
                        <label>UNIDAD DE MEDIDA</label>
                    </th>
                    <th>
                        <label>PRECIO UNITARIO (Bs/u)</label>
                    </th>
                    <th>
                        <label>CANTIDAD (u)</label>
                    </th>
                    <th>
                        <label>IMPORTE (Bs)</label>
                    </th>
                    <th>
                        <label>DESCUENTOS (-)</label>
                    </th>
                    <th>
                        <label>TOTAL (Bs)</label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let detalleVenta of venta.detallesVenta">
                    <td>
                        {{detalleVenta.producto?detalleVenta.producto.nombre+(detalleVenta.descripcion?' '+detalleVenta.descripcion:''):detalleVenta.combo?detalleVenta.combo.nombre:detalleVenta.descripcion}}
                    </td>
                    <td>
                        {{detalleVenta.producto?detalleVenta.producto.codigo:detalleVenta.combo?detalleVenta.combo.codigo:""}}
                    </td>
                    <td>
                        {{detalleVenta.producto?detalleVenta.producto.unidad_medida:detalleVenta.combo?detalleVenta.combo.unidad_medida:"UND"}}
                    </td>
                    <td>
                        {{detalleVenta.precio_unitario.toFixed(2)}}
                    </td>
                    <td>
                        {{detalleVenta.cantidad}}
                    </td>
                    <td>
                        {{detalleVenta.importe.toFixed(2)}}
                    </td>
                    <td>
                        {{detalleVenta.descuento?detalleVenta.descuento.toFixed(2):0}} <i *ngIf="detalleVenta.tipo_descuento" class="fas fa-percent text-primary"></i> <i *ngIf="!detalleVenta.tipo_descuento" class="fas fa-dollar-sign text-primary"></i>
                    </td>
                    <td>
                        {{detalleVenta.total.toFixed(2)}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>TOTALES</b>
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        {{venta.importe.toFixed(2)}}
                    </td>
                    <td>
                        {{venta.descuento.toFixed(2)}}
                    </td>
                    <td>
                        {{venta.total.toFixed(2)}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--<hr *ngIf="usuario.empresa.usar_devoluciones_ventas && venta.devoluciones_venta.length>0">
    <div class="table-fixed-header" *ngIf="usuario.empresa.usar_devoluciones_ventas && venta.devoluciones_venta.length>0">
        <h5 class="text-blue-d2 mb-3">Devoluciones venta</h5>
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>
                        <label>NOMBRE</label>
                    </th>
                    <th>
                        <label>CÓDIGO ITEM</label>
                    </th>
                    <th>
                        <label>UNIDAD DE MEDIDA</label>
                    </th>
                    <th>
                        <label>CANTIDAD (u)</label>
                    </th>
                </tr>
            </thead>
            <tbody *ngFor="let devolucion_venta of venta.devoluciones_venta">
                <tr *ngFor="let detalle_devolucion of devolucion_venta.detalles_devolucion">
                    <td>
                        {{detalle_devolucion.producto?detalle_devolucion.producto.nombre:detalle_devolucion.combo?detalle_devolucion.combo.nombre:detalle_devolucion.descripcion}}
                    </td>
                    <td>
                        {{detalle_devolucion.producto?detalle_devolucion.producto.codigo:detalle_devolucion.combo?detalle_devolucion.combo.codigo:""}}
                    </td>
                    <td>
                        {{detalle_devolucion.producto?detalle_devolucion.producto.unidad_medida:detalle_devolucion.combo?detalle_devolucion.combo.unidad_medida:"UND"}}
                    </td>
                    <td>
                        {{detalle_devolucion.cantidad}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>-->
</div>