<div class="page-content container container-plus">
    <div class="page-header border-0 mb-3">
      <h1 class="page-title text-primary-d2 text-150">
        Citas
        <small class="page-info text-secondary-d4">
          <i class="fa fa-angle-double-right text-80 opacity-1"></i>
          Médicas
        </small>
      </h1>
    </div>


    <!-- message to be displayed on touch devices -->
    <div id="alert-1" class="d-none alert bgc-white border-l-4 brc-purple-m1 shadow-sm" role="alert">
      Touch a date slot and hold down to add a new event
    </div>

    <div class="bgc-primary-d1 text-white px-3 py-25">
        <div class="row">
          <div class="col-md-6 col-12">
            <span class="text-90">Citas</span>
            Médicas
          </div>
          <div class="col-md-6 col-12">
            <div class="columns columns-right btn-group float-right">
              <button title="Nuevo" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-primary"></i>
              </button>
              <button (click)="abrirModalFueraServicioCita()" title="Nuevo" class="btn btn-outline-default btn-smd bgc-white btn-h-light-secondary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="far fa-calendar-plus"></i>
              </button>
              <div class="keep-open btn-group show" title="Columns">
                <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
                  <i class="fa fa-wrench text-blue-d1"></i>
                  <span class="caret"></span>
                </button>
      
                <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                  <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                    <div class="card bgc-primary brc-primary radius-0">
                      <div class="card-header">
                      <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                      </div>
            
                      <div class="card-body p-0 bg-white">
                      <table class="table table-striped table-hover mb-0">
                        <tbody>
                          <tr *ngIf="aplicacion.puede_modificar">
                            <td class="text-dark-m2">
                            Configuración Vista
                            </td>
                            <td class="text-dark-m2">
                              <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="bootstrap-table bootstrap4">
        <div class="fixed-table-toolbar">
          <div class="row">
            <div class="col-9 col-md-9">
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="row">
                    <div class="col-md-4 col-sm-12">
                    <div class="input-group">
                        <label for="feini">Desde </label>&nbsp;
                        <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                        </div>
                    </div>  
                    </div>
                    <div class="col-md-4 col-sm-12">
                    <div class="input-group">
                        <label for="fefin">Hasta </label>&nbsp;
                        <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>  
                    </div>
                    <div class="col-md-1 col-sm-12" ><label>Tipo </label></div>
                    <div class="col-md-3 col-sm-12">
                      <select [compareWith]="compararObjectos" class="form-control" required name="tipo" [(ngModel)]="filter.tipo">
                          <option [ngValue]="tipo" *ngFor="let tipo of filter.tipos_cita_medica">{{tipo.nombre}}</option>
                      </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <label>Paciente </label>
                      <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.paciente">
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <label>Observación </label>
                      <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.observacion">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 col-md-3">
              <div class="row">
                <div class="col-12">
                  <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="d-block h-4 fa fa fa-search text-150"></i>
                  </button>
                </div>
              </div>
            </div>
              </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-12 col-sm-8 col-md-8" id='calendar-container'>
        <div class="card acard">
          <div class="card-body p-lg-4">
            <div id='calendar' class="text-blue-d1"></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4 col-md-4 p-0">
        <div class="bgc-secondary-l4 border-1 brc-secondary-l2 shadow-sm p-35 radius-1">
          <div class="row">
            <div class="col-7 pr-0">
              <p class="text-120 text-primary-d2">
                Fichas Historias Paciente
              </p>
            </div>
            <div class="col-5 pl-0">
              <div class="input-group">
                  <input required id="ficha_date" name="ficha_date" class="form-control" placeholder="dd/mm/yyyy"
                          name="dp" [(ngModel)]="filter.fecha_historias" ngbDatepicker #fci="ngbDatepicker" (ngModelChange)="obtenerFichasHistoriasPacientes()">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="fci.toggle()" type="button"></button>
                  </div>
              </div>  
            </div>
          </div>

          <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th class="detail" rowspan="1">
                        <div class="th-inner ">Ficha</div>
                    </th>
                    <th>Datos</th>
                    <th data-field="tools">
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let cita_medica of fichas_citas_medicas | filterBy:{consolidado:true}; let i=index;">
                    <td>
                      <span class="m-1 bgc-success-l3 border-1 brc-success-m3 text-dark-tp3 text-180 px-25 py-3px radius-round">
                        {{cita_medica.numero_ficha}}
                      </span>
                    </td>
                    <td>
                      <div class="row">
                        <div class="col-12">
                          Paciente: {{cita_medica.paciente.cliente.persona.nombres}} {{cita_medica.paciente.cliente.persona.apellido_paterno}} {{cita_medica.paciente.cliente.persona.apellido_materno}}
                        </div>
                        <div class="col-12">
                          Cita: {{cita_medica.fecha_hora | date:"dd/MM/yyyy - HH:mm"}}
                        </div>
                        <div class="col-12">
                          Llegada: {{cita_medica.tiempo_llegada | date:"dd/MM/yyyy - HH:mm"}}
                        </div>
                        <div class="col-12">
                          Tipo: {{cita_medica.tipo.nombre}}
                        </div>
                      </div>
                    </td>
                    <td style="text-align: center; ">
                        <div class="d-none d-lg-inline text-muted">
                            <a title="Ver" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="verKardexPaciente(cita_medica.paciente)">
                                <i class="fas fa-eye"></i>
                            </a>
                            <a (click)="abrirModalRegistroReceta(cita_medica.historia_paciente,cita_medica.paciente)" title="Receta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)">
                              <i class="fas fa-file-alt"></i>
                            </a>
                           <!--<a title="Modificar Historia" *ngIf="!cita_medica.historia_paciente.id_venta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirModalRegistroHistoriaPaciente(cita_medica)">
                                <i class="ace-icon fa fa-edit"></i>
                            </a>-->
                            <a title="Modificar Cita Medica" *ngIf="!cita_medica.historia_paciente.id_venta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarCitaMedica(cita_medica)">
                              <i class="ace-icon fa fa-pencil-alt"></i>
                            </a>
                            <a title="Imprimir Historia" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="generarPdfHistoriaPaciente(cita_medica)">
                                <i class="fa fa-file-pdf"></i>
                            </a>
                            <a title="Generar Venta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="cita_medica.historia_paciente.activo" (click)="generarVentaCitaMedica(cita_medica)">
                              <i class="fas fa-money-bill-alt"></i>
                            </a>
                            <a title="Cobrar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="cita_medica.historia_paciente.activo && cita_medica.historia_paciente.id_venta && cita_medica.historia_paciente.venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE && cita_medica.historia_paciente.venta.saldo>0" (click)="crearPagoVenta(cita_medica)">
                              <i class="fas fa-dollar-sign"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(cita_medica)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                        </div>
                        <div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                          <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                          <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                            <div class="dropdown-inner">
                              <a title="Ver" class="dropdown-item" href="javascript:void(0)" (click)="verKardexPaciente(cita_medica.paciente)">
                                <i class="fas fa-eye text-warning mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver
                              </a>
                              <!--<a title="Modificar Historia" *ngIf="!cita_medica.historia_paciente.id_venta" class="dropdown-item" href="javascript:void(0)" (click)="abrirModalRegistroHistoriaPaciente(cita_medica)">
                                  <i class="ace-icon fa fa-edit text-primary mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Historia
                              </a>-->
                              <a title="Modificar Cita Medica" *ngIf="!cita_medica.historia_paciente.id_venta" class="dropdown-item" href="javascript:void(0)" (click)="modificarCitaMedica(cita_medica)">
                                <i class="ace-icon fa fa-pencil-alt text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Cita
                              </a>
                              <a title="Imprimir Historia" class="dropdown-item" href="javascript:void(0)" (click)="generarPdfHistoriaPaciente(cita_medica)">
                                  <i class="fa fa-file-pdf text-danger mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a title="Generar Venta" class="dropdown-item" href="javascript:void(0)" *ngIf="cita_medica.historia_paciente.activo  && !cita_medica.historia_paciente.id_venta" (click)="generarVentaCitaMedica(cita_medica)">
                                <i class="fas fa-money-bill-alt text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Venta
                              </a>
                              <a title="Cobrar" class="dropdown-item" href="javascript:void(0)" *ngIf="cita_medica.historia_paciente.activo && cita_medica.historia_paciente.id_venta && cita_medica.historia_paciente.venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE && cita_medica.historia_paciente.venta.saldo>0" (click)="crearPagoVenta(cita_medica)">
                                <i class="fas fa-dollar-sign text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Cobrar
                              </a>
                              <a class="dropdown-item" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(cita_medica)">
                                <i class="fa fa-trash-alt text-danger mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Eliminar
                              </a>
                            </div>
                          </div>
                        </div>

                      
                    </td>
                </tr>
            </tbody>
          </table>
        </div>

        </div>
    </div>
</div>

<ng-template #modal_registro_cita_medica let-modal>
    <div class="modal-header card-header">
        <div class="col-md-6 col-xs-6">
            <span class="text-blue text-125">Registro Cita Médica</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="guardarCitaMedica()" class="btn btn-primary">
            <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
            <button *ngIf="cita_medica.id && es_evento && !cita_medica.historia_paciente" class="btn btn-danger" type="button" (click)="abrirPopupConfirmacionEliminacion(cita_medica)">
            <i class="fa fa-trash-alt red2 text-110 mr-1"></i>
            </button>&nbsp;
            <button *ngIf="cita_medica.id && es_evento && !cita_medica.historia_paciente" class="btn btn-success" type="button" (click)="abrirModalRegistroHistoriaPaciente(cita_medica)">
              <i class="fas fa-arrow-alt-circle-right"></i>
              </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <div class="input-group">
                            <label for="feini">Fecha </label>&nbsp;
                        </div>  
                    </div>
                    <div class="col-12">
                        <div class="input-group">
                            <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="cita_medica.fecha_texto" ngbDatepicker #fi="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            <div class="col-1 p-0">
                <label for="form-field-select-11">Hora</label>
            </div>
            <div class="col-5 p-0">
                <ngb-timepicker [(ngModel)]="cita_medica.hora_texto"></ngb-timepicker>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-2">
                <label for="form-field-select-1">Paciente</label>
            </div>
            <div class="col-8">
              <ng-container *ngIf="!dato_paciente_lista">
                <ng-container *ngIf="cita_medica.paciente.id">
                  <span class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{cita_medica.paciente.cliente.identificacion}}</span><br>
                </ng-container>
                <input id="paciente_texto" name="paciente_texto" *ngIf="!cita_medica.paciente.id"
                [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerPaciente($event)"
                type="text" class="form-control" [(ngModel)]="cita_medica.paciente_texto" 
                [ngbTypeahead]="buscarPaciente" placeholder="Buscar paciente" autocomplete="off"
                />
                <ng-template #rt let-r="result" let-t="term">
                  <ngb-highlight [result]="r.cliente.identificacion" [term]="t"></ngb-highlight>
                </ng-template>
              </ng-container>
              <select *ngIf="dato_paciente_lista" [compareWith]="compararObjectos" class="form-control" required name="paciente" [(ngModel)]="cita_medica.paciente">
                <option [ngValue]="paciente" *ngFor="let paciente of filter.pacientes">{{paciente.cliente.persona.nombres}} {{paciente.cliente.persona.apellido_paterno}} {{paciente.cliente.persona.apellido_materno}}</option>
              </select>
            </div>
            <div class="col-2 p-0">
              <a *ngIf="!cita_medica.paciente.id" title="Nuevo Paciente" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="crearNuevoPaciente()">
                <i class="fas fa-plus"></i>
              </a>
              <a *ngIf="cita_medica.paciente.id" href="javascript:void(0)" (click)="modificarPaciente(cita_medica.paciente)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success">
                  <i class="fa fa-edit bigger-130"></i>
              </a>
              <a *ngIf="cita_medica.paciente.id" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="reiniciarPaciente()">
                <i class="fa fa-trash bigger-130 red2"></i>
              </a>
              <a title="Presentacion Paciente" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="cambiarPresentacionPaciente()">
                <i class="fas fa-exchange-alt"></i>
              </a>
            </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-3">
            <label for="form-field-select-1">Tipo</label>
          </div>
          <div class="col-8">
            <select [compareWith]="compararObjectos" class="form-control" name="tipo" [(ngModel)]="cita_medica.tipo">
              <option [ngValue]="tipo" *ngFor="let tipo of tipo_consulta.clases" >{{tipo.nombre}}</option>
            </select>
          </div>
          <div class="col-1">
            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_CONSULTAS_MEDICAS,'obtenerTiposConsulta')"></span>
          </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-2">
                <label for="form-field-select-1">Observación</label>                
            </div>
            <div class="col-10">
              <input type="text" class="form-control" [(ngModel)]="cita_medica.observacion" placeholder="Observación"/>
            </div>
        </div>
        <ng-container *ngIf="cita_medica.historia_paciente">
          <hr>
          <div class="row">
              <div class="col-3">
                  <label for="form-field-select-1">Número de páginas</label>                
              </div>
              <div class="col-3">
                <input type="text" class="form-control" [(ngModel)]="cita_medica.historia_paciente.numero_paginas" placeholder="Número páginas"/>
              </div>
          </div>
        </ng-container>
    </div>    
</ng-template>

<ng-template #modal_registro_fuera_servicio_cita let-modal>
  <div class="modal-header card-header">
      <div class="col-md-7 col-xs-7">
          <span class="text-blue text-125">Registro Fuera de Servicio</span>
      </div>
      <div class="col-md-5 col-xs-5">
          <button (click)="guardarFueraServicioCita()" class="btn btn-primary">
          <i class="fa fa-save text-110 mr-1"></i>
          </button>&nbsp;
          <button *ngIf="fuera_servicio_cita.id" class="btn btn-danger" type="button" (click)="abrirPopupConfirmacionEliminacionFueraServicio(fuera_servicio_cita)">
          <i class="fa fa-trash-alt red2 text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
          <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-6">
              <div class="row">
                  <div class="col-12">
                      <div class="input-group">
                          <label for="feini">Fecha </label>&nbsp;
                      </div>  
                  </div>
                  <div class="col-12">
                      <div class="input-group">
                          <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                  name="dp" [(ngModel)]="fuera_servicio_cita.fecha_texto" ngbDatepicker #fi="ngbDatepicker">
                          <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                          </div>
                      </div>  
                  </div>
              </div>
          </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-1">
          <label for="form-field-select-11">Hora Inicio</label>
        </div>
        <div class="col-5">
            <ngb-timepicker [(ngModel)]="fuera_servicio_cita.hora_inicio_texto"></ngb-timepicker>
        </div>
        <div class="col-1">
          <label for="form-field-select-11">Hora Fin</label>
        </div>
        <div class="col-5">
            <ngb-timepicker [(ngModel)]="fuera_servicio_cita.hora_fin_texto"></ngb-timepicker>
        </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-2">
              <label for="form-field-select-1">Observación</label>                
          </div>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="fuera_servicio_cita.observacion" placeholder="Observación"/>
          </div>
      </div>
  </div>    
</ng-template>

<ng-template #modal_registro_historia_paciente let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-6">
          <span class="text-blue text-125">Registro Historia Paciente</span>
      </div>
      <div class="col-md-4 col-xs-6">
          <button (click)="guardarHistoriaPaciente()" class="btn btn-primary">
          <i class="fa fa-save text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
          <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
    <div class="row p-1">
    <div class="col-md-7">
        <div class="row">
            <div class="col-5 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fas fa-user-injured"></i> Paciente : 
                </label>    
            </div>
            <div class="col-7">
                <label>
                    {{historia_paciente.cita_medica.paciente.cliente.persona.nombre_completo}}
                </label>    
            </div>
        </div>
        <div class="row">
            <div class="col-5 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fa fa-calendar red2 text-110 mr-1"></i> Fecha de Nacimiento : 
                </label>    
            </div>
            <div class="col-7">
                <label>
                    {{historia_paciente.cita_medica.paciente.cliente.persona.fecha_nacimiento | date:'dd/MM/yyyy'}}
                </label>    
            </div>
        </div>
    </div>
    <div class="col-md-5">
      <div class="row">
        <div class="col-7 alert alert-info pr-0 mb-0">
            <label class="text-primary p-0">
                <i class="fas fa-sort-numeric-up-alt"></i> Edad : 
            </label>    
        </div>
        <div class="col-5">
            <label>
                45
            </label>    
        </div>
      </div>
        <div class="row">
            <div class="col-7 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fas fa-phone"></i> Telefono : 
                </label>    
            </div>
            <div class="col-5">
                <label>
                  {{historia_paciente.cita_medica.paciente.cliente.persona.telefono_movil}} - {{historia_paciente.cita_medica.paciente.cliente.persona.telefono}}
                </label>    
            </div>
        </div>
    </div>
    </div>
    <hr>
    <div class="row p-1">
      <div class="col-3 alert alert-info pr-0 mb-0">
        <label class="text-primary p-0">
            <i class="fas fa-calendar"></i> Fecha: 
        </label>    
      </div>
      <div class="col-3">
          <label>
              {{historia_paciente.cita_medica.fecha_hora | date:'dd/MM/yyyy : HH:mm'}}
          </label>    
      </div>
      <div class="col-3 alert alert-info pr-0 mb-0">
          <label class="text-primary p-0">
            <i class="fas fa-procedures"></i> Tipo : 
          </label>    
          <label class="text-primary p-0">
            <i class="fas fa-hospital-user"></i> Tratamiento: 
        </label>  
      </div>
      <div class="col-3">
          <label>
              {{historia_paciente.cita_medica.tipo.nombre}}
          </label>  
          <label>
            {{historia_paciente.cita_medica.tratamiento?historia_paciente.cita_medica.tratamiento.nombre:''}}
          </label>    
      </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-3">
            <label for="form-field-select-1">Número de páginas</label>                
        </div>
        <div class="col-3">
          <input type="text" class="form-control" [(ngModel)]="historia_paciente.numero_paginas" placeholder="Número páginas"/>
        </div>
    </div>
  </div>    
</ng-template>