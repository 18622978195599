import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../../../../app/global';

@Injectable({
  providedIn: 'root'
})
export class CalificacionesService {

  constructor(private http: HttpClient) { }

  public obtenerConfiguracionCalificacion(id_gestion,id_empresa,id_intervalo_escolar){
    return this.http.get(GlobalVariable.API_SERVER_URL+"configuracion-calificacion-gestion/"+id_gestion+"/empresa/"+id_empresa+"/intervalo-escolar/"+id_intervalo_escolar,GlobalVariable.httpOptions);
  }

  public obtenerPlanillaCalificacion(id_empresa,id_registro_profesor,id_gestion,id_escuela,id_curso,id_materia,id_intervalo_escolar){
    return this.http.get(GlobalVariable.API_SERVER_URL+"planilla-calificacion/empresa/"+id_empresa+"/registro-profesor/"+id_registro_profesor+"/gestion/"+id_gestion+"/escuela/"+id_escuela+"/curso/"+id_curso+"/materia/"+id_materia+"/intervalo-escolar/"+id_intervalo_escolar,GlobalVariable.httpOptions);
  }

  public obtenerPlanillaCalificacionInicial(id_empresa,id_registro_profesor,id_gestion,id_escuela,id_curso,id_materia,id_intervalo_escolar){
    return this.http.get(GlobalVariable.API_SERVER_URL+"planilla-calificacion-inicial/empresa/"+id_empresa+"/registro-profesor/"+id_registro_profesor+"/gestion/"+id_gestion+"/escuela/"+id_escuela+"/curso/"+id_curso+"/materia/"+id_materia+"/intervalo-escolar/"+id_intervalo_escolar,GlobalVariable.httpOptions);
  }

  public obtenerCabeceraPlanillaCalificacion(id_registro_profesor,id_intervalo_escolar){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cabecera-registro-profesor/"+id_registro_profesor+"/intervalo-escolar/"+id_intervalo_escolar,GlobalVariable.httpOptions);
  }

  public obtenerListaRangosCalificacionesLiteral(id_gestion,id_escuela){
    return this.http.get(GlobalVariable.API_SERVER_URL+"literal-rangos-calificacion/gestion/"+id_gestion+"/escuela/"+id_escuela,GlobalVariable.httpOptions);
  }

  public guardarPlanillaCalificacion(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"planilla-calificacion",datos,GlobalVariable.httpOptions);
  }

  public reiniciarPlanillaCalificacion(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"reiniciar-planilla-calificaciones",datos,GlobalVariable.httpOptions);
  }

  public guardarCabeceraCalificacion(cabecera_calificacion){
    return this.http.put(GlobalVariable.API_SERVER_URL+"cabecera-calificacion/"+cabecera_calificacion.id,cabecera_calificacion,GlobalVariable.httpOptions);
  }

  public guardarConfiguracionPlanilla(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"configuracion-planilla-calificacion",datos,GlobalVariable.httpOptions);
  }

  public obtenerDatosCentralizador(id_empresa,id_gestion,id_curso,id_intervalo_escolar){
    return this.http.get(GlobalVariable.API_SERVER_URL+"centralizador/empresa/"+id_empresa+"/gestion/"+id_gestion+"/curso/"+id_curso+"/intervalo-escolar/"+id_intervalo_escolar,GlobalVariable.httpOptions);
  }

  public obtenerMateriasRegistroProfesor(id_gestion,id_curso){
    return this.http.get(GlobalVariable.API_SERVER_URL+"materias-registro-profesor/gestion/"+id_gestion+"/curso/"+id_curso,GlobalVariable.httpOptions);
  }

  public obtenerProfesoresEscuela(id_gestion,id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"profesores/gestion/"+id_gestion+"/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }
}
