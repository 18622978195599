<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Escenarios
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>



    <div class="row">
      <div class="col-12 col-sm-9 col-md-12">
        <div class="bgc-primary-d1 text-white px-3 py-25">
          <div class="row">
            <div class="col-md-6 col-12">
              <span class="text-90">Lista de</span>
              Escenarios
            </div>
            <div class="col-md-6 col-12">
              <div class="columns columns-right btn-group float-right">
                <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoEscenario()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                  <i class="fa fa-plus text-purple-d1"></i>
                </button>
                <div class="export btn-group">
                  <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                    <i class="fa fa-wrench text-blue-d1"></i>
                    <span class="caret"></span>
                  </button>
                  <button title="Mapa Escenarios" (click)="abrirMapaEscenarios()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1 bs-print" type="button">
                    <i class="fas fa-map text-danger-d1"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                    <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                      <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                        </div>
  
                        <div class="card-body p-0 bg-white">
                        <table class="table table-striped table-hover mb-0">
                          <tbody>
                            <tr>
                              <td class="text-dark-m2">
                              Configuración Aplicación
                              </td>
                              <td class="text-dark-m2">
                                <!--<a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>-->
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bootstrap-table bootstrap4">
          <div class="fixed-table-toolbar">
            <div class="row">
              <div class="col-12 col-md-7">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <app-paginator-header [paginator]="this"></app-paginator-header>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-12">
                    <div class="row">
                      <div class="col-md-2 col-sm-12" ><label>Campeonato </label></div>
                      <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" required name="campeonato" [(ngModel)]="filter.campeonato">
                            <option [ngValue]="campeonato" *ngFor="let campeonato of []">{{campeonato.nombre}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-5 p-0">
                <div class="row">
                  <div class="col-12">
                    <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                      <i class="d-block h-4 fa fa fa-search text-150"></i>
                    </button>&nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="fixed-table-container" style="padding-bottom: 0px;">
            <div class="fixed-table-header" style="display: none;">
              <table></table>
            </div>
            <div id="gallery" class="fixed-table-body">
              <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
                <span class="loading-wrap">
                  <span class="animation-wrap">
                    <span class="animation-dot"></span>
                  </span>
                </span>
              </div>
              <table *ngIf="escenarios.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
                <thead class="bgc-white text-grey text-uppercase text-80">
                  <tr>
                    <th>N°</th>
                    <th class="center"><a href="javascript:void(0)">Código <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                    <th class="center"><a href="javascript:void(0)">Imagen <span id="imagen" class="sort fa fa-sort icon-only"></span></a></th>
                    <th class="center"><a href="javascript:void(0)">Nombre <span id="nombre" class="sort fa fa-sort icon-only"></span></a></th>
                    <th class="center"><a href="javascript:void(0)">Departamento <span id="departamento" class="sort fa fa-sort icon-only"></span></a></th>
                    <th class="center"><a href="javascript:void(0)">Municipio <span id="municipio" class="sort fa fa-sort icon-only"></span></a></th>
                    <th class="center"><a href="javascript:void(0)">Direccion <span id="direccion" class="sort fa fa-sort icon-only"></span></a></th>
                    <th style="text-align: center; width: 140px; " data-field="tools">
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="generarPdfPlanillaPartidoBlanco('BALONCESTO')">
                        <i class="fas fa-basketball-ball"></i>
                      </a>
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="generarPdfPlanillaPartidoBlanco('FÚTBOL DE SALÓN')">
                        <i class="fas fa-futbol"></i>
                      </a>
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="generarPdfPlanillaPartidoBlanco('VOLEIBOL')">
                        <i class="fas fa-volleyball-ball"></i>
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let escenario of escenarios; let i=index;">
                    <td>
                      {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                    </td>
                    <td>
                        {{escenario.codigo}}
                    </td>
                    <td>
                      <div class="imagen-campeonato-vista clearfix">
                        <a href="{{rest_server+escenario.imagen}}" title="{{escenario.nombre}}" data-rel="colorbox">
                            <img width="50" height="50" alt="sin imagen" src="{{rest_server+escenario.imagen}}" />
                        </a>
                    </div>
                    </td>
                    <td>{{escenario.nombre}}</td>
                    <td>{{escenario.departamento?escenario.departamento.nombre:''}}</td>
                    <td>{{escenario.municipio?escenario.municipio.nombre:''}}</td>
                    <td>{{escenario.direccion}}</td>
                    <td style="text-align: center; width: 140px; ">
                      <div class="action-buttons">
                        <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarEscenario(escenario)">
                          <i class="fa fa-pencil-alt text-105"></i>
                        </a>
                        <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(escenario)">
                          <i class="fa fa-trash-alt text-105"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="fixed-table-footer">
              <table><thead><tr></tr></thead></table>
            </div>
          </div>
          <div class="fixed-table-pagination">
            <app-paginator-footer *ngIf="escenarios.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #modal_escenario_modificacion let-modal>
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <span class="text-blue text-125">Registro Escenario</span>

              <div class="card-toolbar ml-auto no-border pr-2 d-none">
                <label>
                  <span class="align-middle d-block d-sm-inline">Validation:</span>
                  <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
                </label>
              </div>
              <div class="card-toolbar pl-3">
                <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
                  <i class="fa fa-chevron-left"></i>
                </button>
                <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
                  <i class="fa fa-chevron-right"></i>
                </button>
                <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
                  <i class="fa fa-arrow-right"></i>
                </button>
                <button (click)="modal.dismiss('Cross click')" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
                    <i class="fas fa-times-circle"></i>
                  </button>
              </div>
            </div>

            <div class="card-body px-2">
              <div id="{{wizard_edicion_escenario}}" class="d-none">
                <ul class="mx-auto">
                  <li class="wizard-progressbar"></li>
                  <li>
                    <a href="#step-1">
                      <span class="step-title">
                          1
                      </span>

                      <span class="step-title-done">
                          <i class="fa fa-check text-success-m1"></i>
                      </span>
                    </a>
                    <span class="step-description">
                            Datos del Escenario
                    </span>
                  </li>
                  <li>
                    <a href="#step-2">
                      <span class="step-title">
                          2
                      </span>

                      <span class="step-title-done">
                          <i class="fa fa-check text-success-m1"></i>
                      </span>
                    </a>
                    <span class="step-description">
                            Datos de Georeferenciacion
                    </span>
                  </li>
                </ul>

                <div class="px-2 py-2 mb-4">
                  <div id="step-1" class="">
                    <div class="  form-row">
                        <div class="col-sm-2 col-form-label text-sm-right text-grey">
                                Código
                        </div>
                        <div class="col-sm-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input [disabled]="codigo_automatico" required type="text" name="codigo" id="codigo" [(ngModel)]="escenario.codigo" placeholder="Código" class="form-control form-control col-12 text-dark-m2 brc-warning pr-45 pl-2"  />
                                <i class="fas fa-exclamation-triangle text-orange text-125 ml-n425 pos-rel"></i>
                            </div>

                            <span class="form-text d-inline-block text-orange-d2 text-600 text-95 ml-sm-2"> Código! </span>
                        </div>
                        <div [ngClass]="{'text-danger-m1':(!escenario.nombre)}" class="col-sm-2 col-form-label text-sm-right">
                            Nombre
                        </div>

                        <div class="col-sm-6">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <input required type="text" id="nombre" name="nombre" [(ngModel)]="escenario.nombre" placeholder="Nombre" [ngClass]="{'brc-danger-m1':(!escenario.nombre)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                <i *ngIf="!escenario.nombre" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                            </div>


                            <div *ngIf="!escenario.nombre" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                <div class="arrow brc-danger-m1 mt-0"></div>
                                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                            </div>

                            <span *ngIf="!escenario.nombre" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                        </div>
                    </div>
                    <hr>
                    <div class="  form-row">
                        <div class="col-3 col-form-label text-sm-right">
                            Dirección
                        </div>

                        <div class="col-9">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="direccion" name="direccion" [(ngModel)]="escenario.direccion" placeholder="Dirección" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="  form-row">
                        <div class="col-1 col-form-label text-sm-right">
                            Ciudad
                        </div>

                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <select [compareWith]="compararObjectos" class="form-control" name="departamento" [(ngModel)]="escenario.departamento" (change)="establecerDepartamento()">
                                    <option [ngValue]="departamento" *ngFor="let departamento of departamentos">{{departamento.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-1 col-form-label text-sm-right">
                            Municipio
                        </div>

                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <select [compareWith]="compararObjectos" class="form-control" name="municipio" [(ngModel)]="escenario.municipio">
                                    <option [ngValue]="municipio" *ngFor="let municipio of municipios">{{municipio.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-1 col-form-label text-sm-right">
                            Importe Partido
                        </div>

                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="number" id="importe_partido" name="importe_partido" [(ngModel)]="escenario.importe_partido" placeholder="Importe Partido" step="0.001" min="0" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                  </div>
                  <div id="step-2">
                    <div class="  row">
                        <div class="col-3 col-form-label text-sm-right">
                            Ubicación en el mapa
                          </div>
                          <div class="col-9">
                            <google-map *ngIf="conexion_google_map" #mapa
                                width="100%"
                                height="300px"
                                [center]="center"
                                [zoom]="zoom"
                                [options]="options">
                                <map-marker
                                    #markerElem
                                    *ngFor="let marker of markers"
                                    [position]="marker.position"
                                    [label]="marker.label"
                                    [title]="marker.title"
                                    [options]="marker.options"
                                    (mapDragend)="arrastarPinMapa($event)"
                                    >
                                </map-marker>
                            </google-map>
                          </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div> <!-- .card -->

        </div>
    </div>
</ng-template>

<ng-template #mapa_escenarios let-modal>
    <div class="modal-header card-header">
        <div class="col-4">
            <span class="text-blue text-125">Escenarios</span>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-primary btn-primary" (click)="imprimirMapaEscenarios()" >
                <i class="fa fa-print red2 align-top bigger-125"></i>
            </button>&nbsp;
            <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
              <i class="fa fa-times red2 align-top bigger-125"></i>
          </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="widget-body">
            <div class="row">
                <div class="col-12" id="map-view">
                    <google-map #mapa
                        width="100%"
                        height="80vh"
                        [center]="center"
                        [zoom]="zoom"
                        [options]="options">
                        <map-marker
                            #marker="mapMarker"
                            *ngFor="let marker of markers"
                            [position]="marker.position"
                            [label]="marker.label"
                            [title]="marker.title"
                            [options]="marker.options"
                            (mapClick)="clickMarcador(marker)"
                            >
                        </map-marker>
                        <map-info-window>Info Window content</map-info-window>
                    </google-map>
                </div>
            </div>
        </div>
    </div>
</ng-template>