<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Estudiantes
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista de deudas
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de deudas</span>
      Estudiantes
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <div class="row">
                  <ng-container *ngIf="usuario.empresa.usar_sucursales">
                    <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                    <div class="col-md-3 col-sm-12">
                      <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                          <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                      </select>
                    </div>
                  </ng-container>
                  <div class="col-md-1 col-sm-12"><label>Código </label></div>
                  <div class="col-md-3 col-sm-12">
                    <input type="text" name="codigo_estudiante" [(ngModel)]="filter.codigo" placeholder="Código" class="form-control"  />
                  </div>
                  <div class="col-md-1 col-sm-12"><label>Estado </label></div>
                    <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" required name="estado" [(ngModel)]="filter.estado">
                            <option [ngValue]="estado" *ngFor="let estado of filter.estados">{{estado.nombre}}</option>
                            </select>
                    </div>
              </div>
            </div>
          </div>
		      </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="buscarDeudores()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="fa fa fa-search"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                    <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                      <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                        </div>
              
                        <div class="card-body p-0 bg-white">
                        <table class="table table-striped table-hover mb-0">
                          <tbody>
                            <tr *ngIf="aplicacion.puede_modificar">
                              <td class="text-dark-m2">
                              Configuración Aplicación
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                              </td>
                            </tr>
                            <tr *ngIf="usuario.empresa.usar_estudiantes_colegio">
                              <td class="text-dark-m2">
                              Importación Estudiantes
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirModalImportacionEstudiantes()" href="javascript:void(0)" class="text-success"><i class="fa fa-upload text-success"></i></a>
                              </td>
                            </tr>
                            <tr *ngIf="usuario.empresa.usar_estudiantes_colegio">
                              <td class="text-dark-m2">
                              Importación Tutores
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirModalImportacionTutores()" href="javascript:void(0)" class="text-success"><i class="fa fa-upload text-success"></i></a>
                              </td>
                            </tr>
                            <tr *ngIf="usuario.empresa.usar_mensajeria_redes_sociales">
                              <td class="text-dark-m2">
                              Grupos Whatsapp
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirParticipantesGrupoWhatsapp()" href="javascript:void(0)" class="text-success"><i class="fas fa-user-friends text-purple-d1"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="estudiantes.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th>Sucursal</th>
                <th>Codigo</th>
                <th>Estudiante</th>
                <th>Telefonos</th>
                <th>Gestion</th>
                <th>Concepto</th>
                <th>Cuotas</th>
                <th>Importe</th>
                <th>Descuento</th>
                <th>Pagado</th>
                <th>Saldo</th>
                <th>Curso</th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #estudiantes *ngFor="let estudiante of estudiantes; let i=index;">
                <td>
                    {{(i+1)}}
                  </td>
                <td>
                    {{estudiante.sucursal}}
                </td>
                <td>
                    {{estudiante.codigo_estudiante}}
                </td>
                <td>
                    {{estudiante.estudiante}}
                </td>
                <td>
                    {{estudiante.telefono_movil}}-{{estudiante.telefono}}
                </td>
                <td>
                    {{estudiante.gestion}}
                </td>
                <td>
                    {{estudiante.concepto}}
                </td>
                <td>
                    {{estudiante.cuotas}}
                </td>
                <td>
                    {{estudiante.importe}}
                </td>
                <td>
                    {{estudiante.descuento}}
                </td>
                <td>
                    {{estudiante.pagado}}
                </td>
                <td>
                    {{estudiante.saldo}}
                </td>
                <td>
                    {{estudiante.curso}}
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarEstudiante(estudiante)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
				    </a>
                    <a title="Ficha Económica" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirFichaEconomica(estudiante)">
                      <i class="ace-icon fas fa-money-check-alt bigger-130"></i>
                    </a>   
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>