import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderPipe } from 'ngx-order-pipe';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { VentaComponent } from 'src/app/base/components/venta/venta.component';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { VentasService } from 'src/app/base/services/ventas/ventas.service';
import { GlobalVariable } from 'src/app/global';
import { LecturaMedidorService } from 'src/app/neighborhood/services/lectura-medidor/lectura-medidor.service';
import { EstudiantesService } from 'src/app/school/services/estudiantes/estudiantes.service';
import { CajasService } from 'src/app/snapquick/services/cajas/cajas.service';
import { ClientesService } from 'src/app/snapquick/services/clientes/clientes.service';
import { CombosService } from 'src/app/snapquick/services/combos/combos.service';
import { CotizacionesService } from 'src/app/snapquick/services/cotizaciones/cotizaciones.service';
import { InventariosService } from 'src/app/snapquick/services/inventarios/inventarios.service';
import { PedidosService } from 'src/app/snapquick/services/pedidos/pedidos.service';
import { ProductosService } from 'src/app/snapquick/services/productos/productos.service';
import { Util } from 'src/app/utils/utils';

declare const enfocarElemento:any;
declare const $:any;

@Component({
  selector: 'app-venta-estudiante',
  templateUrl: './venta-estudiante.component.html',
  styleUrls: ['./venta-estudiante.component.css']
})
export class VentaEstudianteComponent extends VentaComponent implements OnInit {

  servicios_pendientes:any[]=[];
  estudiante:any={persona:{cliente:{}}};
  curso:any;

  producto_consumo_agua:any;
  producto_formulario:any;
  popupConfirmacion:NgbModalRef;
  modalRegistroLecturacion:NgbModalRef;

  constructor(
    public generalService:GeneralService,
    public ventasService:VentasService,
    public productosService:ProductosService,
    public combosService:CombosService,
    public inventariosService:InventariosService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public clientesService:ClientesService,
    public pedidosService:PedidosService,
    public toastr: ToastrService,
    public cotizacionesService:CotizacionesService,
    public estudiantesService: EstudiantesService,
    public lecturasMedidorService:LecturaMedidorService,
    private orderPipe: OrderPipe,
    public empresaService:EmpresaService,
    public usuarioService:UsuarioService,
    public cajasService:CajasService
      ) {
    super(generalService,ventasService,productosService,combosService,
      inventariosService,persistenciaService,modalService,clientesService,
      pedidosService,toastr,cotizacionesService,empresaService,usuarioService,cajasService);
   }

  async ngOnInit(): Promise<void> {
    if(this.estudiante.id){
      this.venta.estudiante=await this.estudiantesService.obtenerEstudiante(this.estudiante.id).toPromise();
      await this.establecerCliente({item:this.venta.estudiante});
      this.establecerSucursal({id:this.venta.estudiante.id_sucursal});
    }else{
      this.venta.estudiante=null;
      this.establecerSucursal(this.venta.sucursales.length>=1?this.venta.sucursales[0]:null);
    }
    this.venta.descontar_almacen=false;
    this.venta.fecha=new Date();
    this.venta.fechaTexto={
			year: this.venta.fecha.getFullYear(), 
			month: (this.venta.fecha.getMonth()+1),
			day:this.venta.fecha.getDate()
		}

    this.obtenerTiposPago(this.nombre_corto_tipo_pago);
    this.obtenerMovimientosEgreso(this.nombre_corto_transaccion);

    enfocarElemento('venta_razon_social');
  }

  obtenerTiposPago(tipo_pago){
    this.blockUI.start();
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
      this.tiposPago=entidad.clases.filter(tp => (tp.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CONT || tp.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CODQR));
      this.establecerTipoPago(tipo_pago);
      this.blockUI.stop();
    });
  }

  buscarCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.estudiantesService.buscarEstudiante(this.venta.usuario.id_empresa,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatter = (x:any) => (x.codigo+" - "+(x.persona?x.persona.apellido_paterno:x.apellido_paterno)+" "+(x.persona?x.persona.apellido_materno:x.apellido_materno)+" "+(x.persona?x.persona.nombres:x.nombres));

  async establecerCliente($event){
    this.estudiante=$event.item;
    await this.obtenerPagosPendientes();
    this.venta.cliente=this.estudiante.persona.cliente;
    this.venta.razon_social=this.estudiante.persona.cliente.razon_social;
    this.venta.nit_ci=this.estudiante.persona.cliente.nit;
    enfocarElemento('venta_accion');
  }

  async obtenerPagosPendientes(){
    let estudiante, conceptos_pagos_estudiantes=[], descripcion;
    if(this.usuario.empresa.usar_ingresos_economicos_instituto){
      estudiante=await this.estudiantesService.obtenerPagosPendientesEstudiante(this.estudiante.id).toPromise(); 
      conceptos_pagos_estudiantes=estudiante.conceptos_pagos_estudiantes;
    }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
      estudiante=await this.estudiantesService.obtenerPagosPendientesEstudianteColegio(this.estudiante.id).toPromise(); 
      conceptos_pagos_estudiantes=estudiante.conceptos_pagos_estudiantes_colegio;
      conceptos_pagos_estudiantes=this.orderPipe.transform(conceptos_pagos_estudiantes, ['id_concepto_pago','cuota'],false);
    }
    if(estudiante){
      this.estudiante=estudiante;
      this.servicios_pendientes=[];
      let pago_pendiente,descuento,importe;
      for(var i=0;i<conceptos_pagos_estudiantes.length;i++){
        if(this.usuario.empresa.usar_ingresos_economicos_instituto){
          descripcion=(conceptos_pagos_estudiantes[i].concepto_pago.concepto.nombre)+" "+conceptos_pagos_estudiantes[i].cuota+" - "+(conceptos_pagos_estudiantes[i].concepto_pago.carrera.nombre+(conceptos_pagos_estudiantes[i].concepto_pago.gestion?"-"+conceptos_pagos_estudiantes[i].concepto_pago.gestion.nombre:'')+(conceptos_pagos_estudiantes[i].concepto_pago.semestre?"-"+conceptos_pagos_estudiantes[i].concepto_pago.semestre.nombre:''));
        }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
          let mes=conceptos_pagos_estudiantes[i].concepto_pago.descripcion?conceptos_pagos_estudiantes[i].concepto_pago.descripcion.clases.filter(d => d.nombre_corto==conceptos_pagos_estudiantes[i].cuota.toString()):[];
          mes=mes.length>0?mes[0]:null;
          descripcion=(conceptos_pagos_estudiantes[i].concepto_pago.concepto.nombre)+" - CUOTA: "+conceptos_pagos_estudiantes[i].cuota+(mes?" - "+mes.nombre:"")+" - GESTIÓN "+((conceptos_pagos_estudiantes[i].concepto_pago.gestion?conceptos_pagos_estudiantes[i].concepto_pago.gestion.nombre:''));
        }
        descuento=(conceptos_pagos_estudiantes[i].pagado>0)?0:conceptos_pagos_estudiantes[i].descuento;
        importe=(conceptos_pagos_estudiantes[i].pagado>0)?conceptos_pagos_estudiantes[i].saldo:conceptos_pagos_estudiantes[i].importe;
        pago_pendiente={
          id_estudiante_concepto_pago:conceptos_pagos_estudiantes[i].id,
          concepto_pago:conceptos_pagos_estudiantes[i].concepto_pago,
          descripcion:descripcion,
          cuota:conceptos_pagos_estudiantes[i].cuota,
          cantidad:1,
          precio_unitario:importe,
          importe:importe,
          descuento:descuento,
          total:conceptos_pagos_estudiantes[i].saldo,
          pagado:conceptos_pagos_estudiantes[i].saldo,
          saldo:0
        };
        this.servicios_pendientes.push(pago_pendiente);
      }
      this.venta.detallesVenta=[];
      this.blockUI.stop();
      $(".focus-data").focus();
    }else{
      this.estudiante.persona={cliente:{}};
      this.toastr.info("No se encontro informacion económica para el estudiante!");
      this.estudiante.persona={cliente:{}};
    }
  }

  sumarServiciosPendientes(){
    var sum = 0;
    for(var i = 0; i < this.servicios_pendientes.length; i++){
      sum += (this.servicios_pendientes[i].total);
    }
    sum=Math.round((sum) * 100) / 100;
    return sum;
  }

  reiniciarCliente(){
    this.venta.cliente={};
    this.venta.estudiante=null;
    this.servicios_pendientes=[];
    this.venta.detallesVenta=[];
    $("#venta_razon_social").val("");
    enfocarElemento('venta_razon_social');
  }

  agregarServicioPendiente(servicio_pendiente){
    servicio_pendiente.item=GlobalVariable.Dictionary.ITEM_SERVICIO;
    servicio_pendiente.recargo=0;
    servicio_pendiente.ice=0;
    servicio_pendiente.excento=0;
    servicio_pendiente.tipo_descuento=false;
    servicio_pendiente.tipo_recargo=false;
    var index=this.servicios_pendientes.indexOf(servicio_pendiente);
    if(servicio_pendiente.total>0){
      this.venta.detallesVenta.push(servicio_pendiente);
      this.servicios_pendientes.splice(index,1);
    }else{
      this.toastr.error("¡No puede agregar un pago pendiente con monto igual a 0!");
    }
    //this.venta.descuento=this.venta.descuento+this.detalleVenta.descuento;
    this.sumarDescuentos();
    this.sumarTotal();
    this.sumarTotalImporte();
    this.calcularSaldo();
    this.venta.pagado=this.calcularPagado();
    this.calcularCambio();
  }

  calcularPagado(){
    let suma=0;
    for(let i=0;i<this.venta.detallesVenta.length;i++){
      suma=suma+this.venta.detallesVenta[i].pagado;
    }
    return suma;
  }

  establecerPagadoDetalleVenta(detalleVenta){
    detalleVenta.saldo=detalleVenta.total-detalleVenta.pagado;
    detalleVenta.precio_unitario=detalleVenta.pagado;
    detalleVenta.importe=detalleVenta.pagado;
    detalleVenta.saldo=detalleVenta.total-detalleVenta.pagado;
    this.venta.pagado=this.calcularPagado();
  }

  calcularDescuentoDetalleVenta(detalleVenta){
    //detalleVenta.importe=Math.round((detalleVenta.cantidad*detalleVenta.precio_unitario)*1000)/1000;
    detalleVenta.total=Math.round((detalleVenta.importe-detalleVenta.descuento)*1000)/1000;
    detalleVenta.pagado=detalleVenta.total;
    this.sumarDescuentos();
    this.sumarTotalImporte();
    this.sumarTotal();
    this.calcularCambio();
  }

  sumarDescuentos(){
    this.venta.descuento=0;
    for(let i=0;i<this.venta.detallesVenta.length;i++){
      this.venta.descuento=this.venta.descuento+this.venta.detallesVenta[i].descuento;
    }
  }

  eliminarDetalleVenta(detalleVenta){
    let index=this.venta.detallesVenta.indexOf(detalleVenta);
    this.venta.detallesVenta.splice(index,1);
    this.servicios_pendientes.push(detalleVenta);
    this.servicios_pendientes=this.orderPipe.transform(this.servicios_pendientes, ['id_estudiante_concepto_pago','cuota'],false);
    this.sumarDescuentos();
    this.sumarTotal();
    this.sumarTotalImporte();
    this.calcularSaldo();
    this.calcularCambio();
  }

  abrirPopupConfirmacionEliminacion(servicio_pendiente){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el pago?";
		this.popupConfirmacion.componentInstance.data = servicio_pendiente;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarServicioPendiente($e.data);
			}
			this.popupConfirmacion.close();
        });
  }
  
  eliminarServicioPendiente(servicio_pendiente){
		this.blockUI.start();
		this.estudiantesService.eliminarConceptoPagoEstudiante(servicio_pendiente.id_estudiante_concepto_pago).subscribe((dato:any) => {
			this.obtenerPagosPendientes();
			this.toastr.success(dato.mensaje);
			this.blockUI.stop();
		});
  }

  validarVenta(){
    let res=true;
    if(!this.venta.cliente.id){
      res=res && false;
      this.toastr.error("Debe especificar al estudiante!");
    }
    if(!this.venta.cliente.razon_social || !this.venta.cliente.nit){
      res=res && false;
      this.toastr.error("Debe especificar la razon social y el nit!");
    }
    if(this.venta.detallesVenta.length==0){
      res=res && false;
      this.toastr.error("Debe agregar al menos un item a la venta!");
    }
    if(this.venta.tipoPago.nombre_corto=="CONT" && (this.venta.pagado<this.calcularPagado() || this.venta.cambio<0)){
      res=res && false;
      this.toastr.error("El importe a pagar debe ser igual o superior al importe de la venta!");
    }
    return res;
  }

  guardar(){
    if(this.validarVenta()){
      this.blockUI.start();
      let tiempoActual=new Date();
      this.venta.observacion=this.venta.observacion?this.venta.observacion+"-"+this.curso.nombre_corto:this.curso.nombre_corto;
      this.venta.usar_ingresos_economicos_instituto=this.usuario.empresa.usar_ingresos_economicos_instituto;
      this.venta.usar_ingresos_economicos_colegio=this.usuario.empresa.usar_ingresos_economicos_colegio;
		  this.venta.usar_cobros_caja_colegio=this.usuario.empresa.usar_cobros_caja_colegio;
      this.venta.fecha=Util.convertirObjetoAfecha(this.venta.fechaTexto);
      this.venta.fecha.setHours(tiempoActual.getHours());
      this.venta.fecha.setMinutes(tiempoActual.getMinutes());
      this.venta.fechaTexto=this.venta.fechaTexto.day+"/"+this.venta.fechaTexto.month+"/"+this.venta.fechaTexto.year;
      this.venta.razon_social=this.venta.cliente.razon_social;
      this.venta.nit_ci=this.venta.cliente.nit;
      this.venta.a_cuenta=this.venta.total;
      this.venta.saldo=0;
      //this.venta.total=0;
      for(let i=0;i<this.venta.detallesVenta.length;i++){
        if(this.venta.detallesVenta[i].pagado<this.venta.detallesVenta[i].total){
          this.venta.detallesVenta[i].total=this.venta.detallesVenta[i].pagado;
        }
      }
      this.sumarTotal();
      this.ventasService.guardarVenta(this.venta).subscribe((res:any) => {
        this.blockUI.stop();
        this.alTerminar.emit(res);
      });
    }
  }

}
