import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GeneralService } from '../../../base/services/general/general.service';
import { GlobalVariable } from 'src/app/global';
import { VentasService } from '../../services/ventas/ventas.service';
import { ClientesService } from '../../../snapquick/services/clientes/clientes.service';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, tap, switchMap} from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProductosService } from '../../../snapquick/services/productos/productos.service';
import { CombosService } from '../../../snapquick/services/combos/combos.service';
import { InventariosService } from '../../../snapquick/services/inventarios/inventarios.service';
import { PedidosService } from '../../../snapquick/services/pedidos/pedidos.service';
import { CotizacionesService } from '../../../snapquick/services/cotizaciones/cotizaciones.service';
import { ToastrService } from 'ngx-toastr';
import {Md5} from 'ts-md5/dist/md5';
import { RegistroClienteComponent } from '../../components/registro-cliente/registro-cliente.component';
import { EmpresaService } from '../../../base/services/empresas/empresa.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { BusquedaProductoComponent } from '../busqueda-producto/busqueda-producto.component';
import { CajasService } from 'src/app/snapquick/services/cajas/cajas.service';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { PopupConfirmacionComponent } from '../popup-confirmacion/popup-confirmacion.component';

declare const enfocarElemento:any;
declare const clickearElemento:any;
declare const aplicarVisorImagenes:any;
declare const $:any;
declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-venta',
  templateUrl: './venta.component.html',
  styleUrls: ['./venta.component.css']
})
export class VentaComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  rest_server:any=GlobalVariable.SERVER_URL;
  modalCliente:NgbModalRef;

  venta:any={
    detallesVenta:[],
    cliente:{},
    usuario:null,
    fecha:null,
    _id:null,
    id:null,
    pagado:0,
    importe_gift_card:0,
    importe_qr:0,
    cambio:0,
    fechaTexto:null,
    sucursal:null,
    a_cuenta:0,
    saldo:0,
    total:0,
    almacen:null,
    actividad:null,
    sin_documento:null,
    pagoMinimo:0,
    importe:0,
    cotizacion:null,
    filtro_consulta:null,
    pedido:{},
    es_pedido:false,
    transaccion:{},
    descuento:0,
    descontar_almacen:true,
    canal:null,
    estado:null,
    cantidad_decimal:true,
    tipoPago:{},
    dias_credito:1,
    observacion:null,
    razon_social:"",
    nit_ci:"",
    sin_en_linea:true,
    documentos:[],
    tipo_descuento:false,
    id_historia_paciente:null,
    id_equipo_1:null,
    id_equipo_2:null,
    id_partido:null
  }

  razon_busqueda:any;
  combo:any;
  almacenBusqueda:any;
  inventario_color:any;
  inventario_porcentaje:any;
  producto:any;
  configuracionVentaVista:any={};
  detalleVenta:any;
  registro_cliente:any;
  es_administrador:boolean=false;
  busqueda_codigo_barra:boolean=false;
  producto_busqueda:any;
  combo_busqueda:any;
  editar_precio:boolean=false;
  paginator:any;
  filtro:any;
  opcion_vista_busqueda:string="consulta_inventario_primer";
  almacenes:any[];
  actividades:any[];
  documentos_sector:any[];
  productos_restriccion:any[];
  canales:any[];
  clientes_nit:any[]=[];
  estados_pedido:any[];
  almacenes_consulta:any[];
  inventariosDisponibleProducto:any[]=[];
  movimientosEgreso:any[]=[];
  sucursales:any[];
  tiposPago:any[]=[];
  sucursales_consulta:any[];
  productos:any[];
  sin_documentos_identidad:any[]=[];
  sin_leyendas:any[]=[];
  sin_unidades_medidas:any[]=[];
  sin_productos_servicios:any[]=[];

  nombre_corto_tipo_pago:string=GlobalVariable.Dictionary.TIPO_PAGO_CONT;
  nombre_corto_transaccion:string=GlobalVariable.Dictionary.EGRE_PROFORMA;
  nombre_corto_estado_saneado:string=GlobalVariable.Dictionary.ESTADO_VENTA_SANEADA;
  nombre_corto_canal_directo:string=GlobalVariable.Dictionary.CANAL_VENTA_DIRECTA;
  nombre_corto_tipo_documento_cliente:string=GlobalVariable.Dictionary.SIN.TIPO_DOCUMENTO_CI;
  estados:any[]=[];
  cajas:any[]=[];

  inhabilitar_cliente:boolean=false;
  inhabilitar_sucursal:boolean=false;
  inhabilitar_busqueda_item:boolean=false;
  inhabilitar_almacen:boolean=false;
  inhabilitar_movimiento:boolean=false;
  inhabilitar_tipo_pago:boolean=false;
  inhabilitar_generar_pedido:boolean=false;
  inhabilitar_fecha:boolean=false;
  inhabilitar_a_cuenta:boolean=false;
  inhabilitar_descontar_almacen:boolean=false;
  inhabilitar_eliminacion_detalle_venta:boolean=false;

  gloval_variable:any=GlobalVariable;

  searching = false;
  searchFailed = false;  
  aplicacion_productos:any;
  aplicacion_movimientos_caja:any;

  @BlockUI() blockUI: NgBlockUI;
  shortcuts: ShortcutInput[] = [];

  constructor(public generalService:GeneralService,
    public ventasService:VentasService,
    public productosService:ProductosService,
    public combosService:CombosService,
    public inventariosService:InventariosService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public clientesService:ClientesService,
    public pedidosService:PedidosService,
    public toastr: ToastrService,
    public cotizacionesService:CotizacionesService,
    public empresaService:EmpresaService,
    public usuarioService:UsuarioService,
    public cajasService:CajasService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  async ngOnInit(): Promise<void> {
    let fecha_hora_actual=new Date();
    this.aplicacion_productos= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_PRODUCTOS)[0];
    this.aplicacion_movimientos_caja= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_MOVIMIENTOS_CAJA);
    this.aplicacion_movimientos_caja=this.aplicacion_movimientos_caja.length>0?this.aplicacion_movimientos_caja[0]:{puede_crear:false};
    this.venta.fecha=this.venta.fecha?new Date(this.venta.fecha):fecha_hora_actual;
    this.venta.fecha_texto={
			year: this.venta.fecha.getFullYear(), 
			month: (this.venta.fecha.getMonth()+1),
			day:this.venta.fecha.getDate()
		}
    this.venta.hora={
			hour:fecha_hora_actual.getHours(),
			minute:fecha_hora_actual.getMinutes()
		}
    this.iniciarDetalleVenta();

    this.obtenerTiposPago(this.nombre_corto_tipo_pago);
    await this.obtenerMovimientosEgreso(this.nombre_corto_transaccion);
		this.obtenerCanales(this.nombre_corto_canal_directo);
    this.obtenerEstados(this.nombre_corto_estado_saneado);
    this.obtenerConfiguracionVentaVista();

    if(this.venta.sucursal && this.venta.sucursal.id){
      this.venta.sucursal=this.venta.sucursales.filter(e => e.id==this.venta.sucursal.id)[0];
      await this.obtenerAlmacenesActividades();
    }else{
      this.establecerSucursal(this.venta.sucursales.length>=1?this.venta.sucursales[0]:null);
    }
    if(this.venta.cliente && this.venta.cliente.id){
      this.establecerCliente(this.venta.cliente);
    }else{
      this.buscarNit({which:13},"0");
    }

    if(this.venta.detallesVenta.length>0){
      this.establecerDetallesVenta(this.venta.detallesVenta);
    }

    if(this.usuario.empresa.usar_pedidos_produccion){
      this.venta.pedido=this.venta.pedido?this.venta.pedido:{
        detallesPedido:[],tipoDespacho:null,
        pedido_activo:true,tiempo_ingreso:this.venta.fecha,
        tiempo_despacho_texto:this.venta.fecha_texto,
        activo:true,id_usuario:this.venta.usuario.id,
        id_sucursal:this.venta.sucursal.id
      }
      this.obtenerEstadosPedido();
    }
    if(this.usuario.empresa.usar_restriccion_productos_venta){
      this.obtenerRestriccionProductos();
    }

    if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
      this.obtenerTiposDocumentosIdentidad(this.nombre_corto_tipo_documento_cliente);
      await this.obtenerSinUnidadesMedida();
      await this.obtenerListaProductosSin();
    }

    if(this.usuario.empresa.usar_caja_general){
      this.obtenerCajasEmpresa();
    }

    /*let venta=this;
    socket.on('mostrarProductoVenta', function (codigo) {
      console.log("llego codigo: "+codigo);
      venta.detalleVenta.es_servicio=false;
      venta.editar_precio=false;
      venta.buscarCodigoBarraProducto(13,codigo);
    });*/
    enfocarElemento('nit');
  }

  ngAfterViewInit(){
    this.obtenerTextosEmpresa();
    aplicarSubidaArchivo('form-documentos-venta','template-documentos-venta',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:2});
    aplicarSubidaArchivo('form-pago-comprobante','template-pago-comprobante',this,'subirArchivoComprobantePago','eliminarArchivoComprobantePago',{maximo_tamano_archivo:2});
    aplicarSubidaArchivo('form-pedido-imagen','template-pedido-imagen',this,'subirArchivoImagenPedido','eliminarArchivoPedidoImagen',{maximo_tamano_archivo:2});

    this.shortcuts.push(  
        {  
            key: "ctrl + s",  
            preventDefault: true,
            allowIn: [AllowIn.Textarea, AllowIn.Input,AllowIn.Select],
            command: (e) => {
              //console.log("clicked " , e.key);  Guardar la venta
              this.guardar();
            }
        },
        {  
          key: "ctrl + q",  
          preventDefault: true,
          allowIn: [AllowIn.Textarea, AllowIn.Input,AllowIn.Select],
          command: (e) => {
            //console.log("clicked " , e.key);  Cerrar la ventana de Venta
            this.cerrarComponente();
          }
        },
        {  
          key: "ctrl + r",  
          preventDefault: true,  
          allowIn: [AllowIn.Textarea, AllowIn.Input,AllowIn.Select],
          command: (e) => {
            //console.log("clicked " , e.key);  Cerrar la ventana de Venta
            this.reiniciarCliente();
            this.abrirPopupRegistroRapidoCliente();
          }
        });
  }

  obtenerTiposDocumentosIdentidad(nombre_corto_tipo_documento){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.SIN.DOCUMENTOS_IDENTIDAD).subscribe((res:any)=>{
      this.sin_documentos_identidad=res?res.clases:[];
      this.establecerTipoDocumentoCliente(nombre_corto_tipo_documento);
    });
  }

  async obtenerSinUnidadesMedida(){
		let entidad:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SIN.SIN_UNIDADES_MEDIDA).toPromise();
		this.sin_unidades_medidas=entidad.clases;
	}

  async obtenerListaProductosSin(){
		let productos_servicios_sin:any=await this.productosService.obtenerProductosSin(this.usuario.id_empresa).toPromise();
		this.sin_productos_servicios=productos_servicios_sin;
	}

  obtenerCajasEmpresa(){
    this.blockUI.start();
    this.cajasService.obtenerCajasEmpresa(this.usuario.id_empresa).subscribe((cajas:any)=>{
      this.cajas=cajas;
      this.blockUI.stop();
    });
  }

  establecerActividad(actividad){
    this.obtenerLeyendasFactura(actividad.nombre_corto.split("-")[0]);
  }

  obtenerLeyendasFactura(codigo_actividad){
    this.ventasService.obtenerLeyendas(codigo_actividad).subscribe((res:any)=>{
      this.sin_leyendas=res?res:[];
      this.establecerLeyendaFactura();
    });
  }

  establecerLeyendaFactura(){
    if(this.sin_leyendas.length>0){
      this.venta.leyenda=this.sin_leyendas[Math.floor(Math.random() * (this.sin_leyendas.length - 0)) + 0];
    }
  }

  establecerTipoDocumentoCliente(nombre_corto_tipo_documento){
    this.venta.cliente.tipo_documento=this.sin_documentos_identidad.filter(e => e.nombre_corto == nombre_corto_tipo_documento)[0];
  }

  obtenerTiposPago(tipo_pago){
    this.blockUI.start();
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
      this.tiposPago=entidad.clases;
      this.establecerTipoPago(tipo_pago);
      this.blockUI.stop();
    });
  }

  obtenerRestriccionProductos(){
    this.blockUI.start();
    this.productosService.obtenerProductosRestringidosUsuario(this.usuario.id).subscribe((datos:any) => {
      this.productos_restriccion=datos;
      this.blockUI.stop();
    });
  }

  async obtenerCanales(canal){
    this.blockUI.start();
    let entidad:any=await this.generalService.obtenerClases("SNAPCANVEN").toPromise();
    this.canales=entidad.clases;
    if(this.usuario.empresa.usar_permisos_avanzados_usuarios && this.configuracion_pagina.datos.opciones_permisos.canales_asignados.length>0){
      let canales=[];
      for(let i=0;i<this.configuracion_pagina.datos.opciones_permisos.canales_asignados.length;i++){
        canales.push(entidad.clases.filter(cnf => cnf.id==this.configuracion_pagina.datos.opciones_permisos.canales_asignados[i])[0]);
      }
      this.canales=canales;
      canal=this.canales[0].nombre_corto;
    }
    this.establecerCanal(canal);
    this.blockUI.stop();
  }

  obtenerEstadosPedido(){
    this.blockUI.start();
    this.generalService.obtenerClases("ESTPEDI").subscribe((entidad:any) => {
      this.estados_pedido=entidad.clases;
      var estado_pendiente=this.estados_pedido.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTPED_PEND)[0];
      this.venta.pedido.estado=estado_pendiente;
      this.blockUI.stop();
    });
  }

  obtenerEstados(estado){
    this.blockUI.start();
    this.generalService.obtenerClases("ESTVENT").subscribe((entidad:any) => {
      this.estados=entidad.clases;
      this.establecerEstado(estado);
      this.blockUI.stop();
    });
  }

  establecerEstado(nombre_corto_estado){
    let tiposP=this.estados;
    this.venta.estado=tiposP.filter(e => e.nombre_corto == nombre_corto_estado)[0];
  }

  establecerCanal(nombre_corto_canal){
    let tiposP=this.canales;
    this.venta.canal=tiposP.filter(e => e.nombre_corto == nombre_corto_canal)[0];
  }

  establecerTipoPago(nombre_corto_tipo_pago){
    let tiposP=this.tiposPago;
    this.venta.tipoPago=tiposP.filter(e => e.nombre_corto == nombre_corto_tipo_pago)[0];
  }

  async obtenerMovimientosEgreso(nombre_corto_transaccion){
    let entidad:any=await this.generalService.obtenerClases("MOVEGR").toPromise();
    let movimientosEgresoOriginal=entidad.clases;
    this.movimientosEgreso=movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_PROFORMA);
    if(this.usuario.empresa.usar_facturacion){
      this.movimientosEgreso=this.movimientosEgreso.concat(movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION));
    }
    if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
      this.movimientosEgreso=this.movimientosEgreso.concat(movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA));
    }
    if(this.usuario.empresa.usar_facturacion_manual){
      this.movimientosEgreso=this.movimientosEgreso.concat(movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL));
    }
    this.establecerMovimientoEgreso(nombre_corto_transaccion);
  }

  establecerMovimientoEgreso(nombre_corto_movimiento){
    let movEgres=this.movimientosEgreso;
    this.venta.transaccion=movEgres.filter(e => e.nombre_corto == nombre_corto_movimiento)[0];
  }

  obtenerConfiguracionVentaVista(){
    this.ventasService.obtenerConfiguracionVista(this.usuario.id_empresa).subscribe((configuracion:any) => {
      this.configuracionVentaVista=configuracion;
    });
  }

  establecerSucursal(sucursal){
    this.venta.sucursal=sucursal;
    if(this.venta.sucursal){
      this.obtenerAlmacenesActividades();
      if(this.usuario.empresa.usar_pedidos_produccion){
        this.venta.pedido.id_sucursal=this.venta.sucursal.id;
      }
    }
    if(this.usuario.empresa.usar_clientes_sucursal){
      this.reiniciarCliente();
    }
  }

  reiniciarCliente(){
    this.venta.cliente={};
    this.razon_busqueda=null;
    this.clientes_nit=[];
    $("#venta_razon_social").val("");
    enfocarElemento('venta_razon_social');
  }

  async obtenerAlmacenesActividades(){
    await this.obtenerAlmacenes();
    await this.establecerTransaccion();
  }

  async obtenerAlmacenes(){
    let datos_sucursal:any=await this.empresaService.obtenerSucursal(this.venta.sucursal.id).toPromise();
    this.almacenes=datos_sucursal.almacenes;
    if(this.usuario.empresa.usar_almacenes_usuario){
      let almacenes:any=await this.empresaService.obtenerAlmacenesUsuario(this.usuario.id).toPromise();
      this.almacenes=almacenes.length==0?datos_sucursal.almacenes:almacenes;
    }
    if(this.configuracion_pagina.datos.opciones_permisos.almacen_defecto!=null){
      this.venta.almacen=this.configuracion_pagina.datos.opciones_permisos.almacen_defecto;
    }else{
      this.venta.almacen=this.almacenes.length>0?this.almacenes[0]:null;
    }
  }

  async establecerTransaccion(){
    await this.obtenerActividades();
    await this.obtenerDocumentosFacturacion();
    await this.obtenerSucursalFacturacion();
  }

  async obtenerActividades(){
    this.actividades=[];
    if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION){
      let actividadesDosificaciones=this.venta.sucursal.actividadesDosificaciones?this.venta.sucursal.actividadesDosificaciones:[];
      for(let i=0;i<actividadesDosificaciones.length;i++){
        this.actividades.push(actividadesDosificaciones[i].actividad);
      }
      this.venta.actividad=this.actividades.length==1?this.actividades[0]:null;
    }else if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL){
      let actividades:any=await this.empresaService.obtenerActividadesSin(this.usuario.id_empresa).toPromise();
      for(let i=0;i<actividades.length;i++){
        this.actividades.push(actividades[i].actividad);
      }
      this.venta.actividad=this.actividades.length>=1?this.actividades[0]:null;
      if(this.venta.actividad){
        this.establecerActividad(this.venta.actividad);
      }
    }else{
      this.venta.actividad=null;
    }
  }

  async obtenerDocumentosFacturacion(){
    this.documentos_sector=[];
    if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL){
      let documentos:any;
      if(this.usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa){
        documentos=await this.empresaService.obtenerDocumentosSucursalSin(this.venta.sucursal.id).toPromise();
      }else{
        documentos=await this.empresaService.obtenerDocumentosSin(this.usuario.id_empresa).toPromise();
      }
      this.documentos_sector=documentos;
      this.venta.sin_documento=this.documentos_sector.length>=1?this.documentos_sector[0]:null;
    }else{
      this.venta.sin_documento=null;
    }
  }

  async obtenerSucursalFacturacion(){
    if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL){
      if(this.usuario.empresa.usar_facturacion_computarizada_en_linea_por_empresa){
        let sucursal_facturacion:any=await this.empresaService.obtenerSucrsalFacturacionSin(this.usuario.id_empresa).toPromise();
        this.venta.sucursal_facturacion=sucursal_facturacion;
      }
    }
  }

  buscarNit(evento,nit){
    this.clientes_nit=[];
    if (evento.which === 13){
      let id_sucursal=this.usuario.empresa.usar_clientes_sucursal?this.venta.sucursal.id:0;

      this.clientesService.buscarClienteNit(this.usuario.id_empresa,nit,id_sucursal).subscribe((results:any[]) => {
        if(results.length==1){
          this.establecerCliente(results[0]);
        }else if(results.length>1){
          this.establecerCliente(results[0]);
          this.clientes_nit=results;
        }else{
          this.establecerCliente({nit:nit});
          this.establecerTipoDocumentoCliente(this.nombre_corto_tipo_documento_cliente);
        }
        //this.capturarInteraccion();
      });
    }
  }

  establecerCliente(event){
    let cliente=(event.id || event.nit)?event:event.item;
    this.razon_busqueda=cliente.razon_social;
    this.venta.cliente=cliente;
    this.venta.razon_social=cliente.razon_social;
    this.venta.nit_ci=cliente.nit;
    enfocarElemento('venta_razon_social');
  }

  cambiarServicio(es_servicio){
    if(es_servicio){
      this.detalleVenta.item=GlobalVariable.Dictionary.ITEM_SERVICIO;
    }else{
      if(this.usuario.empresa.usar_combos){
        this.detalleVenta.item=GlobalVariable.Dictionary.ITEM_COMBO;
        this.detalleVenta.combo={};
      }else{
        this.detalleVenta.item=GlobalVariable.Dictionary.ITEM_PRODUCTO;
        this.detalleVenta.producto={};
      }
    }
    this.editar_precio=es_servicio;
  }

  cambiarCombo(es_combo){
    if(es_combo){
      this.detalleVenta.item=GlobalVariable.Dictionary.ITEM_COMBO;
      this.detalleVenta.combo={};
    }else{
      this.detalleVenta.item=GlobalVariable.Dictionary.ITEM_PRODUCTO;
      this.detalleVenta.producto={};
    }
  }

  calcularCambio(){
    if(this.venta.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CONT){
      this.venta.cambio=Math.round((this.venta.pagado-this.venta.total)*100)/100;
      this.venta.pagoMinimo=this.venta.total;
    }else if(this.venta.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_GFTC || (this.venta.tipoPago.nombre && this.venta.tipoPago.nombre.includes('GIFT'))){
      this.venta.cambio=Math.round(((this.venta.pagado+this.venta.importe_gift_card)-this.venta.total)*100)/100;
      this.venta.pagoMinimo=this.venta.total;
    }else if(this.venta.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CODQR || (this.venta.tipoPago.nombre && this.venta.tipoPago.nombre.includes('QR'))){
      this.venta.cambio=Math.round(((this.venta.pagado+this.venta.importe_qr)-this.venta.total)*100)/100;
      this.venta.pagoMinimo=this.venta.total;
    }else{
      this.venta.cambio=0;
      this.venta.pagoMinimo=0;
    }
  }

  cambiarTipoPago(){
    this.calcularCambio();
    if(this.venta.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_OTRO){
      this.generalService.obtenerClases("SINTIPAGOS").subscribe((res:any)=>{
        this.tiposPago=this.tiposPago.concat(res.clases);
      });
    }
  }

  enfocarElemento(keyEvent,id_elemento){
    if (keyEvent.which === 13){
      enfocarElemento(id_elemento);
    }
  }

  buscarCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.clientesService.buscarClienteNit(this.usuario.id_empresa,term,this.usuario.empresa.usar_clientes_sucursal?this.venta.sucursal.id:0)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatter = (x: any) => (x.id?(x.identificacion?x.identificacion:"")+" "+(x.razon_social?x.razon_social:""):"");

  calcularSaldo(){
    this.venta.saldo=this.venta.total-this.venta.a_cuenta;
  }

  mostrarOcultarDatosAdicionales(){
    if($(".datos-adicionales").css( "display")=="none"){
      $('.datos-adicionales').show( "slow" );
    }else{
      $( ".datos-adicionales" ).hide( 1000 );
    }
  }

  activarDesactivarBusquedaCodigoBarra(){
    this.busqueda_codigo_barra=!this.busqueda_codigo_barra;
  }

  buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterProducto = (x: any) => (x.nombre);

  establecerProducto($event){
    this.producto=$event.item
    let producto_habilitado=true;
    if(this.usuario.empresa.usar_restriccion_productos_venta){
      let productoRestringido=this.productos_restriccion.filter(e => e.producto.id==this.producto.id)[0];
      producto_habilitado=productoRestringido?false:true;
    }

    if(producto_habilitado){
      this.producto.tipoProducto=this.producto['tipoProducto']==null?{id:this.producto['tipoProducto.id'],nombre:this.producto['tipoProducto.nombre'],nombre_corto:this.producto['tipoProducto.nombre_corto']}:this.producto.tipoProducto;

      this.productosService.obtenerPreciosProductos(this.producto.id).subscribe(async (precios_datos:any[]) => {
        let precios=[];
        if(this.usuario.empresa.usar_precios_productos_sucursal){
          precios=precios.concat(precios_datos.filter(p => p.id_sucursal==this.venta.sucursal.id));
        }
        if(this.usuario.empresa.usar_precios_productos_clientes && this.venta.cliente && this.venta.cliente.id){
          precios=precios.concat(precios_datos.filter(p => p.id_cliente==this.venta.cliente.id));
        }
        if(this.usuario.empresa.usar_precios_productos_usuarios){
          precios=precios.concat(precios_datos.filter(p => p.id_usuario==this.usuario.id));
        }
        if(precios.length==0){//Sin ninguno de los anteriores tiene precio
          precios=precios_datos.filter(p => (p.id_sucursal==null && p.id_cliente==null && p.id_usuario==null));
        }
        this.producto.precios=precios;
        this.editar_precio=false;

        if(this.usuario.empresa.usar_productos_sucursal){
          let producto_sucursal:any=await this.productosService.obtenerProductoSucursal(this.usuario.id_empresa,this.producto.id,this.venta.sucursal.id).toPromise();
          this.producto.activar_inventario=producto_sucursal.productos_sucursal[0].activar_inventario;
          this.producto.activar_vencimiento=producto_sucursal.productos_sucursal[0].activar_vencimiento;
        }

        let inventarios:any=[];
        if(this.usuario.empresa.usar_inventario && this.producto.activar_inventario){
          inventarios=await this.inventariosService.obtenerInventariosProducto(this.producto.id,this.venta.almacen.id).toPromise();
        }else{
          if(this.usuario.empresa.usar_inventario){
            this.toastr.warning("El producto tiene el inventario desactivado!");
          }
        }
        this.producto.inventarios=inventarios;
        let promedio_costo_unitario=this.producto.costo_unitario==null?0:this.producto.costo_unitario,sumatoria_costo_unitario=0
        for(let i=0;i<this.producto.inventarios.length;i++){
          sumatoria_costo_unitario=sumatoria_costo_unitario+this.producto.inventarios[i].costo_unitario;
          if(this.usuario.empresa.usar_vencimientos && this.producto.activar_vencimiento){
            this.producto.inventarios[i].fecha_vencimiento=(this.producto.inventarios[i].fecha_vencimiento?new Date(this.producto.inventarios[i].fecha_vencimiento):null);
            this.producto.inventarios[i].fechaVencimientoTexto=(this.producto.inventarios[i].fecha_vencimiento?this.producto.inventarios[i].fecha_vencimiento.getDate()+"/"+(this.producto.inventarios[i].fecha_vencimiento.getMonth()+1)+"/"+this.producto.inventarios[i].fecha_vencimiento.getFullYear():"");
            //this.producto.inventarios[i].detallesMovimiento[0].movimiento.fecha=new Date(this.producto.inventarios[i].detallesMovimiento[0].movimiento.fecha);
            //this.producto.inventarios[i].detallesMovimiento[0].movimiento.fechaTexto=this.producto.inventarios[i].detallesMovimiento[0].movimiento.fecha.getDate()+"/"+(this.producto.inventarios[i].detallesMovimiento[0].movimiento.fecha.getMonth()+1)+"/"+this.producto.inventarios[i].detallesMovimiento[0].movimiento.fecha.getFullYear();
          }
        }
        if(this.producto.inventarios.length>0){
          promedio_costo_unitario=Math.round((sumatoria_costo_unitario/this.producto.inventarios.length)*100)/100;
        }
        this.inventariosDisponibleProducto=[];
        this.inventariosDisponibleProducto.push({id:0,fecha_vencimiento:"TODOS",fechaVencimientoTexto:"TODOS"});
        this.inventariosDisponibleProducto=this.inventariosDisponibleProducto.concat(this.producto.inventarios);
        let inventarioDisponible=this.obtenerInventarioTotal(this.producto);

        this.detalleVenta.producto=this.producto;
        this.detalleVenta.precio_unitario=this.producto.precio_unitario;
        this.detalleVenta.activar_inventario=this.producto.activar_inventario;
        this.detalleVenta.inventarioProducto=this.inventariosDisponibleProducto[0];
        this.detalleVenta.inventario_disponible=inventarioDisponible;
        this.detalleVenta.costos=this.producto.activar_inventario?this.producto.inventarios:[];
        this.detalleVenta.producto_precio=this.producto.precios.filter(e => e.por_defecto)[0];
        this.detalleVenta.precio_unitario=this.detalleVenta.producto_precio?this.detalleVenta.producto_precio.precio_unitario:0;
        this.detalleVenta.cantidad=this.configuracion_pagina.datos.opciones_permisos.cantidad_por_defecto=="1"?1:inventarioDisponible;
        this.detalleVenta.costo_unitario=promedio_costo_unitario;
        this.detalleVenta.tipo_descuento=this.producto.tipo_descuento;
        this.detalleVenta.descuento=this.producto.descuento?this.producto.descuento:0;
        
        let arreglo=Util.colorearInventario(inventarioDisponible,this.producto);
        this.inventario_porcentaje=arreglo[0];
        this.inventario_color=arreglo[1];
        this.calcularImporte();
        //CerrarPopup(idModalInventario);
        enfocarElemento('venta_cantidad');
        setTimeout(()=>{
          aplicarVisorImagenes("imagen-producto-vista");
        }, 200);
      });
    }else{
      this.toastr.error("El producto no esta habilitado para la venta!");
      this.iniciarDetalleVenta();
    }
  }

  obtenerInventarioTotal(producto:any){
    let cantidadTotal = 0;
    if(producto.activar_inventario){
      for(let i = 0; i < producto.inventarios.length; i++){
        cantidadTotal += (producto.inventarios[i].cantidad);
      }
      for(let j=0;j<this.venta.detallesVenta.length;j++){
        if(this.venta.detallesVenta[j].producto && this.venta.detallesVenta[j].producto.id){//es producto
          if(this.venta.detallesVenta[j].producto.id==producto.id){
            cantidadTotal=cantidadTotal-this.venta.detallesVenta[j].cantidad;
          }
        }else if(this.venta.detallesVenta[j].combo && this.venta.detallesVenta[j].combo.id){//es combo
          for(let k=0;k<this.venta.detallesVenta[j].combo.detallesCombo.length;k++){
            if(this.venta.detallesVenta[j].combo.detallesCombo[k].producto.id==producto.id){
              cantidadTotal=cantidadTotal-(this.venta.detallesVenta[j].cantidad*this.venta.detallesVenta[j].combo.detallesCombo[k].cantidad);
            }
          }
        }
      }
    }else{
      cantidadTotal=500000;
    }
    return (cantidadTotal>0?cantidadTotal:0);
  }

  calcularImporte(){
    this.detalleVenta.importe=Math.round((this.detalleVenta.cantidad*this.detalleVenta.precio_unitario)*1000)/1000;
    let descuento;
    if(this.detalleVenta.tipo_descuento){
      descuento=this.detalleVenta.importe*(this.detalleVenta.descuento/100);
    }else{
      descuento=this.detalleVenta.descuento;
    }
    this.detalleVenta.total=Math.round((this.detalleVenta.importe-descuento)*1000)/1000;
  }

  iniciarDetalleVenta(){
    this.producto_busqueda="";
    this.combo_busqueda="";
    this.detalleVenta={
      es_servicio:false,
      es_combo:false,
      item:GlobalVariable.Dictionary.ITEM_PRODUCTO,
      cantidad:1,descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false,
    }
    this.editar_precio=false;
    if(this.usuario.empresa.usar_servicios){
      if(this.configuracion_pagina.datos.opciones_permisos.busqueda_producto){
        this.detalleVenta.item=GlobalVariable.Dictionary.ITEM_PRODUCTO;
        this.detalleVenta.es_servicio=false;
        this.editar_precio=false;
      }else{
        this.detalleVenta.item=GlobalVariable.Dictionary.ITEM_SERVICIO;
        this.detalleVenta.es_servicio=true;
        this.editar_precio=true;
      }
    }else if(this.usuario.empresa.usar_combos){
      if(this.configuracion_pagina.datos.opciones_permisos.busqueda_producto){
        this.detalleVenta.item=GlobalVariable.Dictionary.ITEM_PRODUCTO;
        this.detalleVenta.es_combo=false;
        this.editar_precio=false;
      }else{
        this.detalleVenta.item=GlobalVariable.Dictionary.ITEM_COMBO;
        this.detalleVenta.es_combo=true;
        this.editar_precio=false;
      }
    }

    if(this.detalleVenta.item==GlobalVariable.Dictionary.ITEM_PRODUCTO){
      this.detalleVenta.producto={};
      $("#venta_id_producto").val("");
      enfocarElemento('venta_id_producto');
    }else if(this.detalleVenta.item==GlobalVariable.Dictionary.ITEM_COMBO){
      this.detalleVenta.combo={};
      $("#venta_id_combo").val("");
      enfocarElemento('venta_id_combo');
    }else{
      $("#venta_id_descripcion").val("");
      enfocarElemento('venta_id_descripcion');
    }
  }

  buscarCodigoBarraProducto(evento:any,codigo_barra:any){
    var venta=this;
    if (evento.which === 13){
      this.productosService.buscarProducto(this.usuario.id_empresa,codigo_barra).subscribe((results:any[]) => {
        if(results.length>=1){
          let producto_codigo=results.filter(p => (p.codigo_fabrica==codigo_barra || p.codigo==codigo_barra));
          if(producto_codigo.length>0){
            venta.establecerProducto({item:producto_codigo[0]});
            enfocarElemento('venta_cantidad');
          }
        }
      });
    }
  }

  clickearElemento(keyEvent,id_elemento){
    if (keyEvent.which === 13){
      clickearElemento(id_elemento);
    }
  }

  buscarCombo = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.combosService.buscarCombo(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterCombo = (x: any) => (x.nombre);

  establecerCombo($event){
    this.combo=$event.item;
    this.combosService.obtenerPreciosCombo(this.combo.id,this.venta.sucursal.id).subscribe((precios:any[]) => {
      this.combo.precios=precios;
      this.editar_precio=false;
      
      var ids_productos="";
      for(let j=0;j<this.combo.detallesCombo.length;j++){
        if((j+1)==this.combo.detallesCombo.length){
          ids_productos=ids_productos+this.combo.detallesCombo[j].producto.id;
        }else{
          ids_productos=ids_productos+this.combo.detallesCombo[j].producto.id+",";
        }
      }

      this.productosService.obtenerInventariosProductos(ids_productos,this.venta.almacen.id).subscribe((productos_inventario:any[]) => {
        let totalInventarioCombo=50000;
        for(let j=0;j<this.combo.detallesCombo.length;j++){
          let prodInv=productos_inventario.filter(e => e.id == this.combo.detallesCombo[j].producto.id)[0];
          let totalInvProducto=this.obtenerInventarioTotal(prodInv);

          this.combo.detallesCombo[j].costos=prodInv.inventarios;
          let totalInventarioComboPorProducto=parseInt((totalInvProducto/this.combo.detallesCombo[j].cantidad).toString());
          totalInventarioCombo=(totalInventarioComboPorProducto<totalInventarioCombo)?totalInventarioComboPorProducto:totalInventarioCombo;
        }

        this.detalleVenta.combo=this.combo;
        this.detalleVenta.precio_unitario=this.combo.precio_unitario;
        this.detalleVenta.inventario_disponible=totalInventarioCombo;

        if(this.usuario.empresa.usar_precios_productos_sucursal){
          var precio=this.combo.precios.filter(e => e.por_defecto && e.id_sucursal === this.venta.sucursal.id);
          if(precio.length>0){
            this.detalleVenta.combo_precio=precio[0];
            this.detalleVenta.precio_unitario=precio[0].precio_unitario;
          }
        }else{
          this.detalleVenta.combo_precio=this.combo.precios.filter(e => e.por_defecto)[0];
        }

        this.combo.inventario_minimo=1;
        var arreglo=Util.colorearInventario(totalInventarioCombo,this.combo);
        this.inventario_porcentaje=arreglo[0];
        this.inventario_color=arreglo[1];
        this.calcularImporte();
        enfocarElemento('venta_cantidad');
      });
    });
  }

  actualizarInventarioDisponibleFechaVencimiento(){
    if(this.detalleVenta.inventarioProducto.id!=0){
      this.detalleVenta.costos=[];
      this.detalleVenta.costos.push(this.detalleVenta.inventarioProducto);
      this.detalleVenta.inventario_disponible=this.obtenerInventarioTotalPorFechaVencimiento();
      this.detalleVenta.fecha_vencimiento=this.detalleVenta.inventarioProducto.fecha_vencimiento;
      this.detalleVenta.lote=this.detalleVenta.inventarioProducto.lote;
    }else{
      this.detalleVenta.inventario_disponible=this.obtenerInventarioTotal(this.detalleVenta.producto);
      this.detalleVenta.costos=this.detalleVenta.producto.inventarios;
      this.detalleVenta.lote="";
    }
    var arreglo=Util.colorearInventario(this.detalleVenta.inventario_disponible,this.detalleVenta.producto);
    this.inventario_porcentaje=arreglo[0];
    this.inventario_color=arreglo[1];
    this.calcularImporte();
  }

  obtenerInventarioTotalPorFechaVencimiento(){
    var cantidadTotal = this.detalleVenta.inventarioProducto.cantidad;
    for(var j=0;j<this.venta.detallesVenta.length;j++){
      if(this.venta.detallesVenta[j].producto){
        if(this.venta.detallesVenta[j].producto.id==this.detalleVenta.producto.id && this.venta.detallesVenta[j].costos[0].id==this.detalleVenta.inventarioProducto.id){
          cantidadTotal=cantidadTotal-this.venta.detallesVenta[j].cantidad;
        }
      }else{//es combo
        for(let k=0;k<this.venta.detallesVenta[j].combo.detallesCombo.length;k++){
          if(this.venta.detallesVenta[j].combo.detallesCombo[k].producto.id==this.detalleVenta.producto.id && this.venta.detallesVenta[j].combo.detallesCombo[k].costos[0].id==this.detalleVenta.inventarioProducto.id){
            cantidadTotal=cantidadTotal-(this.venta.detallesVenta[j].cantidad*this.venta.detallesVenta[j].combo.detallesCombo[k].cantidad);
          }
        }
      }
    }
    return cantidadTotal;
  }

  modificarPrecio(){
    this.editar_precio=true;
  }

  establecerPrecio(){
    this.editar_precio=false;
  }

  establecerPrecioDetalle(){
    if(this.detalleVenta.producto){
      this.detalleVenta.precio_unitario=this.detalleVenta.producto_precio.precio_unitario;
    }else{
      this.detalleVenta.precio_unitario=this.detalleVenta.combo_precio.precio_unitario;
    }
    this.calcularImporte();
  }

  validarDetalleVenta(){
    let res=true;
    if(!this.detalleVenta.cantidad){
      res=res && false;
      this.toastr.error("Debe especificar la cantidad!");
    }
    if(!this.detalleVenta.precio_unitario){
      res=res && false;
      this.toastr.error("Debe especificar el precio unitario!");
    }

    if(this.detalleVenta.item==GlobalVariable.Dictionary.ITEM_PRODUCTO){
      if(this.detalleVenta.producto.activar_inventario && this.detalleVenta.cantidad>this.detalleVenta.inventario_disponible){
        this.toastr.error("Cantidad insuficiente en inventario!");
        res=res && false;
      }
      if(!this.detalleVenta.producto.id){
        res=res && false;
        this.toastr.error("Debe especificar el producto!");
      }
      if(!this.detalleVenta.producto_precio){
        res=res && false;
        this.toastr.error("Debe especificar el concepto del precio!");
      }
    }else if(this.detalleVenta.item==GlobalVariable.Dictionary.ITEM_COMBO){
      if(this.detalleVenta.cantidad>this.detalleVenta.inventario_disponible){
        this.toastr.error("Cantidad insuficiente en inventario!");
        res=res && false;
      }
      if(!this.detalleVenta.combo.id){
        res=res && false;
        this.toastr.error("Debe especificar el combo!");
      }
    }else if(this.detalleVenta.item==GlobalVariable.Dictionary.ITEM_SERVICIO){
      if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL){
        if(!this.detalleVenta.descripcion){
          res=res && false;
          this.toastr.error("Debe especificar la descripcion del item o servicio!");
        }
        if(!this.detalleVenta.sin_unidad_medida){
          res=res && false;
          this.toastr.error("Debe especificar la unidad de medida!");
        }
        if(!this.detalleVenta.producto_sin){
          res=res && false;
          this.toastr.error("Debe especificar el codigo de item!");
        }
      }
    }
    return res;
  }

  agregarDetalleVenta(){
    if(this.validarDetalleVenta()){
      if(this.detalleVenta.item==GlobalVariable.Dictionary.ITEM_PRODUCTO){
        this.venta.descuento=(this.usuario.empresa.usar_descuento_global?this.venta.descuento:this.venta.descuento+(this.detalleVenta.tipo_descuento?this.detalleVenta.importe*(this.detalleVenta.descuento/100):this.detalleVenta.descuento));
        var detalleVentaAAgregar=this.detalleVenta;
        var detalleVentaProductoExistente=this.venta.detallesVenta.filter(e => !e.eliminado && e.producto && (e.producto.id == detalleVentaAAgregar.producto.id));
        if(detalleVentaProductoExistente.length>0){//el producto ya se encuentra en el detalle de la venta
          detalleVentaProductoExistente=detalleVentaProductoExistente[0];
          if(this.usuario.empresa.usar_vencimientos && this.detalleVenta.producto.activar_vencimiento){
            if(this.detalleVenta.inventarioProducto.id==detalleVentaProductoExistente.inventarioProducto.id){//son del mismo inventario
              detalleVentaProductoExistente.cantidad=detalleVentaProductoExistente.cantidad+detalleVentaAAgregar.cantidad;
              this.calcularImporteDetalle(detalleVentaProductoExistente);
              this.venta.detallesVenta[this.venta.detallesVenta.indexOf(detalleVentaProductoExistente)]=detalleVentaProductoExistente;
            }else{
              this.venta.detallesVenta.push(this.detalleVenta);
            }
            //console.log(this.detalleVenta);
            //console.log(detalleVentaProductoExistente);
          }else{
            detalleVentaProductoExistente.cantidad=detalleVentaProductoExistente.cantidad+detalleVentaAAgregar.cantidad;
            this.calcularImporteDetalle(detalleVentaProductoExistente);
            this.venta.detallesVenta[this.venta.detallesVenta.indexOf(detalleVentaProductoExistente)]=detalleVentaProductoExistente;
          }
        }else{
          this.venta.detallesVenta.push(this.detalleVenta);
        }
        this.inventariosDisponibleProducto=[];
        this.iniciarDetalleVenta();
      }else if(this.detalleVenta.item==GlobalVariable.Dictionary.ITEM_COMBO){
        this.venta.detallesVenta.push(this.detalleVenta);
        this.iniciarDetalleVenta();
      }else{
        this.venta.detallesVenta.push(this.detalleVenta);
        this.iniciarDetalleVenta();
      }
      this.sumarTotal();
      this.sumarTotalImporte();
      this.calcularSaldo();
      this.venta.pagado=this.venta.total;
      this.calcularCambio();
      //this.capturarInteraccion();
    }
  }

  calcularImporteDetalle(detalleVenta){
    detalleVenta.importe=Math.round((detalleVenta.cantidad*detalleVenta.precio_unitario)*1000)/1000;
    var descuento,recargo;
    if(detalleVenta.tipo_descuento){
      descuento=detalleVenta.importe*(detalleVenta.descuento/100);
    }else{
      descuento=detalleVenta.descuento;
    }
    if(detalleVenta.tipo_recargo){
      recargo=detalleVenta.importe*(detalleVenta.recargo/100);
    }else{
      recargo=detalleVenta.recargo;
    }
    detalleVenta.total=Math.round((detalleVenta.importe-descuento+(recargo?recargo:0)-(detalleVenta.ice?detalleVenta.ice:0)-(detalleVenta.excento?detalleVenta.excento:0))*1000)/1000;
    if(this.usuario.empresa.usar_obsequios && detalleVenta.es_obsequio){
      detalleVenta.importe=0;
      detalleVenta.total=0;
    }
  }

  sumarTotalImporte(){
    var sumaImporte=0;
    for(var i=0;i<this.venta.detallesVenta.length;i++){
      if(!this.venta.detallesVenta[i].eliminado){
        sumaImporte=sumaImporte+this.venta.detallesVenta[i].importe;
      }
    }		
    this.venta.importe=Math.round((sumaImporte)*1000)/1000;
  }

  sumarTotal(){
    var sumaTotal=0;
    for(var i=0;i<this.venta.detallesVenta.length;i++){
      if(!this.venta.detallesVenta[i].eliminado){
        sumaTotal=sumaTotal+this.venta.detallesVenta[i].total;
      }
    }		
    this.venta.total=Math.round((sumaTotal)*1000)/1000;
  }

  mostrarDescuentos(){
    var style=$(".des-datos").css("display");
    if(style=="none"){
      $(".des-datos").css("display","");
    }else{
      $(".des-datos").css("display","none");
    }
  }

  guardarConfiguracionVentaVista(){
    this.ventasService.actualizarConfiguracionVista(this.usuario.id_empresa,this.configuracionVentaVista).subscribe((res:any) => {
      this.toastr.info("Actualizado satisfactoriamente!");
    });
  }

  eliminarDetalleVenta(detalleVenta){
    if(detalleVenta.id){
      detalleVenta.eliminado=true;
    }else{
      this.venta.detallesVenta.splice(this.venta.detallesVenta.indexOf(detalleVenta),1);
    }
    this.venta.descuento=(this.usuario.empresa.usar_descuento_global?this.venta.descuento:this.venta.descuento-(detalleVenta.tipo_descuento?detalleVenta.importe*(detalleVenta.descuento/100):detalleVenta.descuento));
    this.sumarTotal();
    this.sumarTotalImporte();
    this.calcularSaldo();
    this.calcularCambio();
    if(this.usuario.empresa.usar_descuento_global){
      this.establecerDescuentoGlobal();
    }
  }

  guardarVentaBorrador(){
    const md5 = new Md5();
    this.venta.fecha=Util.convertirObjetoAfecha(this.venta.fecha_texto);
    let ventas_borrador=this.persistenciaService.get("ventas")?this.persistenciaService.get("ventas"):[];
    if(this.venta._id){
      ventas_borrador=ventas_borrador.filter(e => e._id != this.venta._id);
      ventas_borrador.push(this.venta);
    }else{
      this.venta._id=md5.appendStr(new Date().toUTCString()).end();
      ventas_borrador.push(this.venta);
    }
    this.persistenciaService.set("ventas",ventas_borrador);
    this.toastr.info("Venta guardada como borrador!");
    this.alTerminar.emit();
  }

  validarVenta(){
    let res=true;
    if(this.usuario.empresa.usar_facturacion_computarizada_en_linea && this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
      if(!this.venta.cliente.tipo_documento){
        res=res && false;
        this.toastr.error("Debe especificar el tipo de documento del cliente!");
      }
    }
    if(!this.venta.cliente.razon_social || !this.venta.cliente.nit){
      res=res && false;
      this.toastr.error("Debe especificar el nit/ci y razon social del cliente!");
    }
    if(!this.venta.fecha_texto || !this.venta.fecha_texto.year || !this.venta.fecha_texto.month || !this.venta.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para la venta!");
    }
    if(this.usuario.empresa.usar_facturacion_computarizada_en_linea && this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL){
      if(this.venta.factura==null || this.venta.factura==undefined){
        res=res && false;
        this.toastr.error("Debe especificar el numero de factura!");
      }
    }
    if(!this.venta.hora || (this.venta.hora.hour==null || this.venta.hora.hour==undefined) || (this.venta.hora.minute==null || this.venta.hora.minute==undefined)){
      res=res && false;
      this.toastr.error("Debe especificar la hora completa para la venta!");
    }
    if(this.venta.detallesVenta.length==0){
      res=res && false;
      this.toastr.error("Debe agregar al menos un item a la venta!");
    }
    if(this.venta.tipoPago.nombre_corto=="CRE" && (!this.venta.dias_credito)){
      res=res && false;
      this.toastr.error("Debe especificar los dias de credito de la venta!");
    }
    if(this.venta.tipoPago.nombre_corto=="CRE" && (this.venta.a_cuenta==undefined)){
      res=res && false;
      this.toastr.error("Debe especificar el importe a cuenta de la venta!");
    }
    if(this.venta.tipoPago.nombre_corto=="CONT" && (this.venta.pagado<this.venta.total || this.venta.cambio<0)){
      res=res && false;
      this.toastr.error("El importe a pagar debe ser igual o superior al importe de la venta!");
    }
    if(this.venta.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_GFTC || this.venta.tipoPago.nombre.includes('GIFT')){
      if((this.venta.pagado+this.venta.importe_gift_card)<this.venta.total || this.venta.cambio<0){
        res=res && false;
        this.toastr.error("El importe a pagar mas el importe de gift card debe ser igual o superior al importe de la venta!");
      }
    }
    if(this.venta.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CODQR || this.venta.tipoPago.nombre.includes('QR')){
      if((this.venta.pagado+this.venta.importe_qr)<this.venta.total || this.venta.cambio<0){
        res=res && false;
        this.toastr.error("El importe a pagar mas el importe de QR debe ser igual o superior al importe de la venta!");
      }
    }
    if((this.venta.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_TARJ || this.venta.tipoPago.nombre.includes('TARJETA')) && this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
      if(!this.venta.numero_tarjeta){
        res=res && false;
        this.toastr.error("Debe especificar el numero de la tarjeta!");
      }
    }
    if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION && !this.venta.actividad){
      res=res && false;
      this.toastr.error("Debe especificar la actividad!");
    }
    if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION && this.venta.sucursal.actividadesDosificaciones){
      let fecha_limite_emision=new Date(this.venta.sucursal.actividadesDosificaciones[0].dosificacion.fecha_limite_emision);
      fecha_limite_emision.setHours(23);
      fecha_limite_emision.setMinutes(59);
      fecha_limite_emision.setSeconds(59);
      let fecha_actual=new Date();
      if(fecha_limite_emision<fecha_actual){
        res=res && false;
        this.toastr.error("La fecha límite de emisión "+(fecha_limite_emision.getDate()+"/"+(fecha_limite_emision.getMonth()+1)+"/"+fecha_limite_emision.getFullYear())+" de su dosificación feneció, debe renovar!");
      }
    }
    if(this.usuario.empresa.usar_bloqueo_clientes){
      if(this.venta.cliente.bloqueado){
        res=res && false;
        this.toastr.error("Facturación para el cliente bloqueado!");
      }
    }
    return res;
  }
  
  guardar(){
    this.blockUI.start();
    this.venta.cliente.razon_social=(!this.venta.cliente.id)?this.razon_busqueda:this.venta.cliente.razon_social;
    if(this.validarVenta()){
      if(this.venta._id){
        let ventas_borrador=this.persistenciaService.get("ventas")?this.persistenciaService.get("ventas"):[];
        ventas_borrador=ventas_borrador.filter(e => e._id != this.venta._id);
        this.persistenciaService.set("ventas",ventas_borrador);
      }
      let tiempoActual=new Date();
      this.venta.usar_facturacion_computarizada_en_linea_por_sucursal_empresa=this.usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa;
      this.venta.fecha=Util.convertirObjetoAfecha(this.venta.fecha_texto);
      this.venta.fecha.setHours((this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL)?this.venta.hora.hour:tiempoActual.getHours());
      this.venta.fecha.setMinutes((this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL)?this.venta.hora.minute:tiempoActual.getMinutes());
      this.venta.fechaTexto=this.venta.fecha_texto.day+"/"+this.venta.fecha_texto.month+"/"+this.venta.fecha_texto.year;
      this.venta.razon_social=this.venta.cliente.razon_social;
      this.venta.nit_ci=this.venta.cliente.nit;
      this.venta.a_cuenta=this.venta.tipoPago.nombre_corto=="CRE"?this.venta.a_cuenta:this.venta.total;
      this.venta.saldo=this.venta.tipoPago.nombre_corto=="CRE"?this.venta.saldo:0;
      this.venta.estado=this.venta.tipoPago.nombre_corto=="CRE" && this.venta.saldo>0?this.estados.filter(e => e.nombre_corto == "ESTVENTVIG")[0]:this.estados.filter(e => e.nombre_corto == "ESTVENTSAN")[0];
      if(this.venta.id){
        this.ventasService.actualizarVenta(this.venta).subscribe((res:any)=>{
          if(this.venta.es_pedido){
            this.venta.pedido.importe=this.venta.importe;
            this.venta.pedido.total=this.venta.total;
            for(let i=0;i<this.venta.pedido.detallesPedido.length;i++){
                for(let j=0;j<this.venta.detallesVenta.length;j++){
                  if(this.venta.pedido.detallesPedido[i].descripcion==this.venta.detallesVenta[j].descripcion){
                    this.venta.pedido.detallesPedido[i].campo_personalizado_1=this.venta.detallesVenta[j].campo_personalizado_1;
                    this.venta.pedido.detallesPedido[i].cantidad=this.venta.detallesVenta[j].cantidad;
                    this.venta.pedido.detallesPedido[i].precio_unitario=this.venta.detallesVenta[j].precio_unitario;
                    this.venta.pedido.detallesPedido[i].importe=this.venta.detallesVenta[j].importe;
                    this.venta.pedido.detallesPedido[i].total=this.venta.detallesVenta[j].total;
                  }
                }
            }

            this.pedidosService.actualizarPedidoRestaurante(this.venta.pedido).subscribe((res_p:any)=>{
              this.blockUI.stop();
              this.toastr.success('Datos actualizados de forma exitosa!');
              this.alTerminar.emit(res);
            });
          }else{
            this.toastr.success('Datos actualizados de forma exitosa!');
            this.alTerminar.emit(res);
            this.blockUI.stop();
          }
        });
      }else{
        //for(let i=0;i<500;i++){
        this.ventasService.guardarVenta(this.venta).subscribe((res:any) => {
          if(this.usuario.empresa.usar_pedidos_produccion){
            if(this.venta.es_pedido){
              this.venta.pedido.detallesPedido=this.venta.detallesVenta;
              this.venta.pedido.id_venta=res.venta.id;
              this.venta.pedido.cliente=res.venta.cliente;
              this.venta.pedido.observacion=res.venta.observacion;
              this.venta.pedido.importe=res.venta.importe;
              this.venta.pedido.descuento=res.venta.descuento;
              this.venta.pedido.total=res.venta.total;
              this.venta.pedido.id_usuario=res.venta.usuario.id;
              this.venta.pedido.tiempo_ingreso=this.venta.fecha;
              this.venta.pedido.tiempo_despacho=Util.convertirObjetoAfecha(this.venta.pedido.tiempo_despacho_texto);
              this.pedidosService.guardarPedido(this.venta.pedido).subscribe((resPc:any) => {
                this.blockUI.stop();
                this.toastr.success('Pedido guardado Exitosamente!');
                this.alTerminar.emit(res);
              });
            }else{
              this.blockUI.stop();
              this.alTerminar.emit(res);
            }
          }else if(this.usuario.empresa.usar_cotizaciones && this.venta.cotizacion){
            if(!res.tiene_error){
              this.venta.cotizacion.id_venta=res.venta.id;
              this.cotizacionesService.actualizarCotizacionVenta(this.venta.cotizacion).subscribe((resc:any) => {
                this.blockUI.stop();
                this.alTerminar.emit(res);
              });
            }else{
              this.blockUI.stop();
              this.alTerminar.emit(res);
            }
          }else{
            this.blockUI.stop();
            this.alTerminar.emit(res);
          }
        });//}
      }

      /*if(venta_actual.usuario.empresa.usar_pantalla_cliente){
        socket.emit('terminarVenta',venta_actual.sucursal);
      }*/
    }else{
      this.blockUI.stop();
    }
  }

  abrirPopupConfirmacionCierreVentana(){
    if(this.venta.detallesVenta.length>2){
      this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
      this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de cerrar la venta? Todo lo ingresado se perdera.";
      
      this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
        if($e.confirm){
          this.cerrarComponente();
        }
        this.popupConfirmacion.close();
      });
    }else{
      this.cerrarComponente();
    }
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

  abrirPopupRegistroRapidoCliente(){
    this.modalCliente = this.modalService.open(RegistroClienteComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalCliente.componentInstance.cliente = JSON.parse(JSON.stringify(this.venta.cliente));
    this.modalCliente.componentInstance.sucursales = this.venta.sucursales;
    this.modalCliente.componentInstance.sucursal = this.venta.sucursal;
		this.modalCliente.componentInstance.usuario = this.usuario;
		
		this.modalCliente.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.establecerCliente(res.cliente);
					this.modalCliente.close();
				}
			}else{
				this.modalCliente.close();
			}
			
		});
		
		
		if(this.usuario.empresa.usar_pantalla_cliente){
			//socket.emit('comenzarVenta',$rootScope.venta);
		}
  }

  establecerDetallesVenta(detallesVenta){
    for(let i=0;i<detallesVenta.length;i++){
      this.calcularImporteDetalle(detallesVenta[i]);
    }
    this.venta.detallesVenta=detallesVenta;
    this.sumarTotal();
    this.sumarTotalImporte();
    this.calcularSaldo();
    if(this.usuario.empresa.usar_descuento_global){
      this.establecerDescuentoGlobal();
    }
  }

  abrirBusquedaProducto(){
    this.modalCliente = this.modalService.open(BusquedaProductoComponent, {windowClass:'busqueda-producto', ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'lg',scrollable:true});
    this.modalCliente.componentInstance.sucursal = this.venta.sucursal;
		this.modalCliente.componentInstance.usuario = this.usuario;
		
		this.modalCliente.componentInstance.alTerminar.subscribe((res) => {
			if(res){
        this.producto_busqueda=res;
        this.establecerProducto({item:res});
			}
      this.modalCliente.close();
			
		});
		
		
		if(this.usuario.empresa.usar_pantalla_cliente){
			//socket.emit('comenzarVenta',$rootScope.venta);
		}
  }

  actualizarDetalleVenta(detalle_venta){
    this.detalleVenta=detalle_venta;
    if(this.validarDetalleVenta()){
      this.calcularImporteDetalle(detalle_venta);
      this.sumarTotal();
      this.sumarTotalImporte();
      this.calcularSaldo();
      this.venta.pagado=this.venta.total;
      this.calcularCambio();
    }
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.venta.documentos.push(base64EncodedImage);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.venta.documentos.splice(me.venta.documentos.indexOf(base64EncodedImage),1);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  subirArchivoComprobantePago(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.venta.comprobante_pago=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivoComprobantePago(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.venta.comprobante_pago=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  subirArchivoImagenPedido(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.venta.pedido.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivoPedidoImagen(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.venta.pedido.imagen=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  establecerDescuentoGlobal(){
    let descuento_detalles=0,descuento=0;
    for(let i=0;i<this.venta.detallesVenta.length;i++){
      descuento_detalles=descuento_detalles+(this.venta.detallesVenta[i].tipo_descuento?(this.venta.detallesVenta[i].importe*(this.venta.detallesVenta[i].descuento/100)):this.venta.detallesVenta[i].descuento);
    }
    if(this.venta.tipo_descuento){
      descuento=(this.venta.importe-descuento_detalles)*(this.venta.descuento/100);
    }else{
      descuento=this.venta.descuento;
    }
    this.venta.total=this.venta.importe-descuento_detalles-descuento;
    this.venta.pagado=this.venta.total.toFixed(2);
    this.calcularCambio();
    this.calcularSaldo();
    this.venta.usar_descuento_global=true;
  }

}
