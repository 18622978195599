import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { Router } from '@angular/router';
import { GlobalVariable } from 'src/app/global';
import { Util } from 'src/app/utils/utils';
import { RegistroEstudianteComponent } from '../registro-estudiante/registro-estudiante.component';
import { FichaEconomicaEstudianteComponent } from '../ficha-economica-estudiante/ficha-economica-estudiante.component';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-reporte-deudas-estudiantes',
  templateUrl: './reporte-deudas-estudiantes.component.html',
  styleUrls: ['./reporte-deudas-estudiantes.component.css']
})
export class ReporteDeudasEstudiantesComponent extends BaseComponent implements OnInit {

  estudiantes:any[]=[];

  edicion_estudiante:NgbModalRef;
  ficha_economica_modal:NgbModalRef;
  estudiante:any;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
	  private estudiantesService:EstudiantesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
		this.buscarSubAplicacion(this.router.url.substring(1));
    this.configuracion_pagina={};
    this.filter={
      id_empresa:this.usuario.id_empresa,
			escuela:null,
      sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: GlobalVariable.Dictionary.SELECCION_TODOS,
      estados:[{id:1,nombre:"ACTIVOS"},{id:0,nombre:"ANULADOS"}],
      estado: {id:1,nombre:"ACTIVOS"},
		}
    await this.obtenerEscuela();  

    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_sucursales){
			if(!es_usuario_administrador){	
				this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
				this.filter.sucursal=this.filter.sucursales[0];
			}
		}
  }

  ngAfterViewInit() {
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  buscarDeudores(){
    this.blockUI.start();
    this.estudiantesService.obtenerDeudasEstudiantes(this.filter).subscribe((res:any)=>{
      this.blockUI.stop();
      this.estudiantes=res;
    });
  }

  modificarEstudiante(estudiante){
    this.blockUI.start();
    this.estudiantesService.obtenerEstudiante(estudiante.id_estudiante).subscribe((res:any)=>{
      this.blockUI.stop();
      this.estudiante=res;
		  this.abrirRegistroEstudiante();
    });
  }

  abrirRegistroEstudiante(){
		this.edicion_estudiante = this.modalService.open(RegistroEstudianteComponent, {scrollable:true,windowClass : "wizard_edicion_estudiante",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.edicion_estudiante.componentInstance.estudiante = this.estudiante;
    this.edicion_estudiante.componentInstance.usuario = this.usuario;
		this.edicion_estudiante.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.buscarDeudores();
			}
			this.edicion_estudiante.close();
    });
  }

  abrirFichaEconomica(estudiante){
    this.ficha_economica_modal = this.modalService.open(FichaEconomicaEstudianteComponent, {scrollable:true, windowClass:'ficha-economica', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.ficha_economica_modal.componentInstance.estudiante = {id:estudiante.id_estudiante,nombre_completo:estudiante.estudiante};
    this.ficha_economica_modal.componentInstance.usuario = this.usuario;
		this.ficha_economica_modal.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.getItems();
			}
			this.ficha_economica_modal.close();
    });
  }

}
